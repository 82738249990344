import React, { useState } from 'react';
import { useEffect } from 'react';
import List from '../../../components/List';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useAdminCompany } from '../../../context/contextAdminCompany';
import api from '../../../services/api';

export const ClientTasks = () => {
    const {company} = useAdminCompany();
    const token = localStorage.getItem('@Atlantico:token');
    const [loader, setLoader] = useState(true);
    const [tasksTypes, setTasksTypes] = useState([])

    useEffect(() => {
        setTasksTypes([]);

        Promise.all(company.tasks_types.map(async taskType => {
            await api.get(`v1/tasks/${taskType.type_id}`, {
                headers: {
                    Authorization: token
                }
            })
            .then(response => setTasksTypes(old => [...old, {...taskType, name: response.data.name}]))
        })).then(() => setLoader(false))   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {loader ? <Loader /> : (
                tasksTypes.length > 0 ? (
                    <List completeLine>
                        <table className='linkedClientsTable'>
                            <colgroup>
                                <col style={{width: "40%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "40%"}}/>
                            </colgroup>  
                            <tr>
                                <th>NOME</th>
                                <th>DIA</th>
                                <th>MÊS</th>
                                <th>RESPONSÁVEL</th>
                            </tr>
                            {tasksTypes.map((task, index) => (
                                <tr key={index}>
                                    <td>{task.name ? task.name : '-'}</td>
                                    <td>{task.date ? task.date : '-'}</td>
                                    <td>{task.month ? task.month : '-'}</td>
                                    <td>{task.assignment ? task.assignment : '-'}</td>
                                </tr>
                            ))}
                        </table>
                    </List>
                ) : (
                    <Message severity="warning" message="Sem clientes vinculados à essa tarefa!"/>
                )
            )}
        </div>
    )
}