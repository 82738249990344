import styled from "styled-components";

export const Division = styled.div`
  
  @media(min-width: 700px){
    display: flex;
    justify-content: space-between; 
    width: 100%;
  }
`;

export const DivInputs = styled.div`
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  padding: 0 1rem;
  flex-direction: column;
  .input{
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--divisor);
    margin-bottom: 1rem;
    .icon{
      margin-left: 1rem;
      color: var(--blue);
    }
    input{
      border: 0;
      padding: 10px;
      width: 100%;
      background-color: var(--modal);
      color: var(--text);
    }
    .remove{
      color: var(--red); 
      cursor: pointer; 
      margin-right: 1rem;
    }
    
  }
  .add{
      color: var(--green);
      cursor: pointer;
      align-self: flex-end;
      margin-right: 1rem;
  }
  
`;

export const ProfileDiv = styled.div`
  max-height: 30em;
  overflow: auto;

  .react-switch-handle{
    div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-changepassword {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem 0;
    .change-password {
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      text-decoration: none;
      justify-content: center;
      color: var(--blue);
      cursor: pointer;
      margin: 1rem 0;
    }
  }

  @media (max-width: 540px) {
    max-height: 28em;
  }
  @media (max-height: 700px) {
    max-height: 22em;
  }
`;
