import styled from 'styled-components';

export const PrivacyDiv = styled.div`
    max-height: 30em;
    overflow: auto;

    h4{
        color: var(--blue);
        text-transform: uppercase;
        margin-top: 1rem;
    }

    p, li{
        color: #6e6e6e;
        margin-top: 0.3rem;
    }
`;