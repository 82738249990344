import styled from "styled-components";

export const AvatarCircle = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isEnabled ? '#000' : 'rgba(149, 152, 154, 0.6)'} ;
  border: 15px solid ${props => props.isEnabled ? 'rgba(149, 152, 154, 0.6)' : 'rgba(241, 247, 250, 0.8)'} ;
  border-radius: 50%;
  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
`;
