import React from "react";
import { useRunrunit } from "../../../context/contextRunrunit";
import Button from "../../../components/Button";
import ModalButtons from "../../../components/ModalButtons";
import ModalContent from "../../../components/ModalContent";
import { useSubModal } from "../../../context/contextSubModal";
import api from "../../../services/api";

export const ConfirmDeleteTaskClient = ({client, task}) => {
  const { setIsSubModalVisible } = useSubModal();
  const token = localStorage.getItem('@Atlantico:token');
  const {att, setAtt} = useRunrunit();

  async function removeClient(){
    await api.delete(`v1/linkedclients/${client._id}/${task._id}`, {
        headers: {
            Authorization: token
        }
    })
    .then(() => {
      setAtt(!att)
      setIsSubModalVisible(false);
    })
    .catch(err => console.log(err))
}

  return (
    <>
      <ModalContent hasFooter={true}>
        <h3 style={{ textAlign: "center", marginBottom: "2rem", color: 'var(--text)' }}>
          Tem certeza que deseja excluir o cliente <strong>{client.name}</strong> da tarefa <strong>{task.name}</strong>?
        </h3>
      </ModalContent>
      <ModalButtons>
        <Button
          hollow={false}
          expansive={true}
          size="small"
          color="#fff"
          border="var(--red)"
          bgcolor="var(--red)"
          hover="var(--red-hover)"
          onClick={() =>  removeClient()}
        >
          Deletar
        </Button>
        <hr />
        <Button
          expansive={true}
          size="small"
          color="var(--blue)"
          hollow={true}
          border="var(--blue)"
          onClick={() => setIsSubModalVisible(false)}
        >
          Cancelar
        </Button>
      </ModalButtons>
    </>
  );
};