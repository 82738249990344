import React from 'react';
import Button from '../../components/Button';
import ModalButtons from '../../components/ModalButtons';
import ModalContent from '../../components/ModalContent';

import {DivTicket} from './styles';
import { useHistory } from 'react-router';

const Ticket = ({ticket}) => {
    const history = useHistory()

    return (
        <>
            <ModalContent hasFooter={true}>
                <DivTicket>
                    <div className="topic">
                        <h3><strong>Título: </strong>{ticket.title}</h3>
                    </div>
                    <div className="topic">
                        <h3><strong>Tipo da solicitação: </strong>{ticket.subject}</h3>
                    </div>
                    <div className="topic">
                        <h3><strong>Descrição: </strong>{ticket.description}</h3>
                    </div>
                    <div className="topic">
                        <h3><strong>Data de expectativa: </strong>{ticket.expec_date}</h3>
                    </div>
                </DivTicket>
            </ModalContent>
            <ModalButtons>
            <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={() => history.goBack()}>
                VOLTAR
            </Button>
            </ModalButtons>
        </>
    );
}

export default Ticket;