import React, { useEffect, useState } from 'react';
import Message from '../../../components/Message';
import { convertName } from '../../../utils/convertName';
import { FaLock } from 'react-icons/fa';
import { useColorMode } from '../../../context/contextColorMode';

import {ContactDetails} from './styles';

import api from '../../../services/api';
import { useSubModal } from '../../../context/contextSubModal';
import { ChangeClientPassword } from '../ChangeClientPassword';
import { useAuth } from '../../../context/contextAuth';

const ContactDetail = ({contactId}) => {
    const {user} = useAuth();
    const token = localStorage.getItem('@Atlantico:token');
    const [contact, setContact] = useState({});
    const [emails, setEmails] = useState([]);
    const [phones, setPhones] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [clients, setClients] = useState([]);
    const {isEnabled} = useColorMode();
    const {setSubModalContent} =  useSubModal();

    useEffect(() => {
        async function getData(){
            await api.get(`v1/users/${contactId}/`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(response => {
                setContact(response.data);
                setEmails(response.data.email);
                setPhones(response.data.phone);
                setSectors(response.data.profile);
            })
            .catch(err => {console.log(err)})
        }

        getData();
    }, [contactId, token]);

    useEffect(() => {
        async function getData(){
            await api.get(`v1/users/${contactId}/clients`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(response => {
                console.log(response.data);
                setClients(response.data);
            })
            .catch(err => {console.log(err)})
        }

        getData();
    }, [contactId, token]);

    console.log(clients);
      
    return (
        <ContactDetails darkMode={isEnabled}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h2>{contact.name}</h2>

                {user.profile.includes('ADMIN') && (
                    <div style={{display: 'flex', cursor: 'pointer', alignItems: 'center', marginBottom: '1rem'}}  onClick={() => setSubModalContent(<ChangeClientPassword contactId={contactId}/>)}>
                        <FaLock size={10} style={{marginRight: '1rem', color: "var(--atlantico)"}}/>
                        <h5>Alterar senha do usuário</h5>
                    </div>
                )}

            </div>

            {contact.status === false && (
                <Message severity="warning" message="Usuário inativo!"/>
            )}

            <h4>Emails</h4>
            <div className="block"> 
                <ul>
                    {emails && emails.map(email => (
                        <li>{email.value}</li>
                    ))}
                </ul>
            </div>
            <h4>Telefones</h4>
            <div className="block">
                <ul>
                    {phones && phones.map(phone => (
                        <li>{phone.value}</li>
                    ))}
                </ul>
            </div>
            <h4>Áreas de Atuação</h4>
            <div className="block">
                <ul>
                    {sectors && sectors.map(sector => (
                        <li>{convertName(sector)}</li>
                    ))}
                </ul>
            </div>
            <h4>Empresas relacionadas</h4>
            <div className="block">    
                <ul>
                    {clients && clients.map(client => {
                        if(client) return (<li>{client.reference} - {client.name}</li>)
                    })}
                </ul>
            </div>
        </ContactDetails>
    )
}

export default ContactDetail;