import styled from 'styled-components';

export const DivLinkedClients = styled.div`

    width: 100%;

    hr{
        border: 1px solid var(--divisor);
        margin: 2rem 0;
    }

    .buttons{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--blue);

      a{
        color: var(--blue);
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
    
`;

export const Search = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  .search{
    width: 100%;
    margin: 0 auto;
    height: auto;
    
  }

  .div-search {
    position: relative;
    margin: 0 2rem;

    .resultSet {
      height: auto;
      background: ${props => props.darkMode ? '#1c1c1c' : '#fff'};
      padding: 0.5rem 2rem;
      border-radius: 1rem;
      z-index: 99999 !important;
      position: absolute;
      max-height: 10em;
      overflow: auto;
      width: 100%;
      -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);
      -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);

      p{
        color: var(--text);
        margin: 1rem 0;
      }


      ul {
        list-style: none;
        li {
          padding: 1rem 0;
          cursor: pointer;
          a{
            text-decoration: none;
            color: ${props => props.darkMode ? '#666666' : '#000'};
            &:hover {
              background-color:${props => props.darkMode ? '#1c1c1c' : '#f1f7fa'};
              color: ${props => props.darkMode ? '#fff' : 'var(--blue)'};
            }
          }
        }
      }
    }
    @media (min-width: 700px) {
      margin: 0 15rem;
    }
    @media (min-width: 1024px) {
      margin: 0;
    }
  }
`;

export const InputMU = styled.div`
  position: relative;

  input {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    outline: 0;
    padding: 1.1rem 2rem;
    padding-left: 4rem;
    background-color: var(--modal);
    color: #bbbbbb;
    border: 2px var(--blue) solid;
  }

  svg {
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    color: #2699fb;
  }

  @media (min-width: 700px) {
    svg {
      top: 1rem;
    }
  }

  @media (min-width: 1025px) {
    input {
      height: auto;
      padding: 1.5rem 2rem;
      padding-left: 5rem;
      color: #bbbbbb;
    }
    svg {
      top: 1.7rem;
    }
  }
`;
