import styled from 'styled-components';

export const FormContact = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 20em;
  overflow: auto;
  h2 {
    margin-bottom: 2rem;
    color: var(--text);
  }
  ul {
    width: 100%;
    height: 80%;
    max-height: 80%;
    overflow: auto;
  }

  .item-form{
    border-bottom: 1px solid var(--text);
    margin-right: 1rem;
    display: flex;
  }

  select,
  input {
    border: 0;
    background-color: var(--modal);
    color: var(--text);
    width: 100%;
    padding: 1rem 0;
  }

  @media (max-height: 700px) {
    padding: 0;
  }

  .questions{
    display: flex;
    width: 100%;
    flex-direction: column;

    .add{
      color: var(--green);
      cursor: pointer;
      align-self: flex-end;
      margin-top: 1rem;
      margin-right: 1rem;
    }
    .remove{
      color: var(--red); 
      cursor: pointer; 
      align-self: center;
      right: 0;
    }
  }
`;

export const CustomFilter = styled.div`
    display: flex; 
    width: 100%; 
    align-items: center;
    margin-top: 1rem; 
    margin-right: 1rem;
`;

export const Switches = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  div{
    h4{
      margin-right: 1rem;
    }
    input{
      width: 100%;
      outline: 0;
      border:0;
      border-bottom: 1px solid var(--text);
      background-color: var(--modal);
      color: var(--text);
    }
    display:flex;
    align-items: center;
    margin-right: 4rem;
  }
`;