import { Grid, TextField, Divider, Card, CardContent, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useSubModal } from "../../../../context/contextSubModal";
import OrgaoEmissor from "../submodals/orgaoEmissor";

const Foreign = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [orgaoEmissor,setOrgaoEmissor] = useState({
        CODIGOORGAOEMISSORRNE: props.data.employee.CODIGOORGAOEMISSORRNE || "",
        SIGLAORGAOEMISSORRNE: props.data.employee.SIGLAORGAOEMISSORRNE || "",
    })

    useEffect(() => {

        props.handleChange(null,orgaoEmissor)

    }, [orgaoEmissor])

    return <Grid container spacing={2}>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Geral</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Data de naturalização" defaultValue={props.data.employee.DATANATURAL} name="DATANATURAL" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } type="date" InputLabelProps={{ shrink: true }} />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Data da chegada" defaultValue={props.data.employee.DATACHEGADA} name="DATACHEGADA" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } type="date" InputLabelProps={{ shrink: true }} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField select fullWidth label="Tipo de Visto" defaultValue={props.data.employee.TIPOVISTO} name="TIPOVISTO" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="12">Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa</option>
                                <option value="11">Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício de atividade remunerada no Brasil</option>
                                <option value="10">Beneficiado pelo acordo entre países do Mercosul</option>
                                <option value="9">Permanência no Brasil em razão de filhos ou cônjuges brasileiros ou em razão de reunião familiar</option>
                                <option value="8">Com residência provisória e anistiado, em situação irregular</option>
                                <option value="7">Deficiente físico e com mais de 51 anos</option>
                                <option value="6">Residente fora do Brasil</option>
                                <option value="5">Solicitante de Refúgio</option>
                                <option value="13">Refugiado</option>
                                <option value="4">Asilado</option>
                                <option value="3">Turista</option>
                                <option value="2">Visto permanente</option>
                                <option value="1">Visto temporário</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth label="Casado(a) com brasileiro(a)" defaultValue={props.data.employee.CASADOBR} name="CASADOBR" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth label="Filhos Brasileiros" defaultValue={props.data.employee.FILHOSBR} name="FILHOSBR" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </TextField>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>RNE</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <TextField fullWidth label="Número" defaultValue={props.data.employee.CARTRNE} name="CARTRNE" inputProps={{ maxLength: 20 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                            <TextField fullWidth label="Data de expedição" defaultValue={props.data.employee.EXPEDICAORNE} name="EXPEDICAORNE" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } type="date" InputLabelProps={{ shrink: true }} />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                            <input
                                name="CODIGOORGAOEMISSORRNE"
                                value={orgaoEmissor.CODIGOORGAOEMISSORRNE}
                                hidden="true"
                            />
                            <TextField
                                style={{cursor:"pointer"}}
                                fullWidth
                                label="Orgão Emissor"
                                value={orgaoEmissor.SIGLAORGAOEMISSORRNE}
                                name="SIGLAORGAOEMISSORRNE"
                                inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                                onFocus={() => {
                                    setIsSubModalVisible(true);
                                    setSubModalContent(<OrgaoEmissor setOrgaoEmissor={setOrgaoEmissor} params={["CODIGOORGAOEMISSORRNE","SIGLAORGAOEMISSORRNE"]} setIsSubModalVisible={setIsSubModalVisible} />)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={3}>
                            <TextField fullWidth label="Data de validade" defaultValue={props.data.employee.VALIDRNE} name="VALIDRNE" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } type="date" InputLabelProps={{ shrink: true }} />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Grid>

    </Grid>

}

export default Foreign