import React, { useState } from 'react';
import Button from '../../../components/Button';
import ModalButtons from '../../../components/ModalButtons';
import ModalContent from '../../../components/ModalContent';
import ToastMessage from '../../../components/ToastMessage';
import { useColorMode } from '../../../context/contextColorMode';
import { useRunrunit } from '../../../context/contextRunrunit';
import spinner from "../../../assets/images/spinner.svg";
import {FormContact} from './styles';
import { useHistory } from 'react-router'

export const RunrunitCreate = () => {
    const {isEnabled} = useColorMode();
    const {users, createTask, loading, error, success} = useRunrunit();
    const areas = ["ADMIN", "FINANC", "HR", "FTAX"]
    const [assignment, setAssignment] = useState(users[0].id);
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [area, setArea] = useState(areas[0]);
    const [competence, setCompetence] = useState(1);
    const [date, setDate] = useState(1);
    const [month, setMonth] = useState(null);
    const history = useHistory();

    const data = {
        title,
        name,
        assignment,
        area,
        competence,
        date,
        month
    }

    return (
        <>

        <ModalContent>
            {success && <ToastMessage variant="success" message="Salvo com sucesso!"/>}
            {error && <ToastMessage variant="error" message="Erro ao salvar!"/>}
          <FormContact darkMode={isEnabled}>
            <div className="twoFields">
                <div className="field">
                    <label><strong>Nome</strong></label>
                    <input placeholder="Nome" value={name} onChange={e=> setName(e.target.value)}/>
                </div>
                
                <div className="field">
                    <label><strong>Área</strong></label>
                    <select value={area} style={{ background: 'var(--modal)', color: 'var(--text)' }}  onChange={e=> setArea(e.target.value)}>
                        <option style={{color: 'var(--text)'}} value="" disabled hidden>Área</option>
                        {areas.map(ar => <option style={{color: 'var(--text)'}} value={ar} key={ar}>{ar}</option>)}
                    </select>
                </div>
            </div>
            <div className="twoFields">
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Título</strong></label>
                    <input value={title} onChange={e => setTitle(e.target.value)} placeholder="Título"/>
                </div>
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Responsável</strong></label>
                    <select value={assignment} style={{ background: 'var(--modal)', color: 'var(--text)' }} onChange={e=> setAssignment(e.target.value)}>
                        <option style={{color: 'var(--text)'}} value="" disabled hidden>Responsável</option>
                        {users.map(user => <option style={{color: 'var(--text)'}} value={user.id} key={user.id}>{user.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="twoFields">
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Competência</strong></label>
                    <input onChange={e=> setCompetence(e.target.value)} placeholder="Data de competência"/>
                </div>
                <div className="twoFields">
                    <div className="field">
                        <label><strong style={{color: 'var(--text)'}}>Dia Desejado</strong></label>
                        <input type="number" min={1} max={31} value={date} onChange={e=> setDate(e.target.value)} placeholder="Data desejada"/>
                    </div>
                    <div className="field">
                        <label><strong style={{color: 'var(--text)'}}>Mês Desejado</strong></label>
                        <input type="number" min={1} max={12} value={month} onChange={e=> setMonth(e.target.value)} placeholder="Mês desejado (opcional)"/>
                    </div>
                </div>
                
            </div>
          </FormContact>

        </ModalContent>

        <ModalButtons>
          <Button bgcolor="var(--blue)"  color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={() => createTask(data)}>
            {loading ? <img src={spinner} alt="Carregando"/> : 'CRIAR'}
          </Button>
          <hr />
          <Button color="var(--gray)" border="var(--gray)" expansive={true} hollow={true} size="small" onClick={() => history.goBack()}>
            CANCELAR
          </Button>
        </ModalButtons>
        </>

    )
}