import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { useAuth } from '../../context/contextAuth';
import { useFiles } from '../../context/contextFiles';
import { useSubModal } from '../../context/contextSubModal';
import api from '../../services/api';
import { removeId } from '../../utils/handlePaths';
import Button from '../Button';
import { DivFileOptions } from './styles';

export const OptionsFile = ({deleteAllowed, file, company}) => {
    const { setIsSubModalVisible } = useSubModal();
    const {user} = useAuth();
    const token = localStorage.getItem("@Atlantico:token");
    const {setSuccessDeleted} = useFiles();

    async function handleDelete(e, path) {
      e.preventDefault();
      return await api.delete(`v1/clients/${company}/deleteObject?path=${path}&user_id=${user._id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          setIsSubModalVisible(false);
          setSuccessDeleted(true);
        })
        .then(() => {
          setSuccessDeleted(false);
        })
        .catch((err) => {
          console.log(err);
        });
      }

    return (
      <DivFileOptions>
        <h3><strong style={{color: 'var(--text)'}}>{file.name}</strong></h3>

        {deleteAllowed && (
          <Button bgcolor="var(--red)" color="#fff" border="var(--red)" hover="var(--red-hover)" expansive={true} hollow={false} size="small" onClick={(e) => handleDelete(e, removeId(file.path))}>
            <FaTrash style={{color: "#fff", marginBottom: "0"}} />
            EXCLUIR
          </Button>
        )}

        <Button expansive={true} hollow={true} size="small" color="var(--gray)" border="var(--gray)" onClick={() => setIsSubModalVisible(false)}>
          CANCELAR
        </Button>
      </DivFileOptions>
    )
}