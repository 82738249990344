import styled from "styled-components";

export const MinhaPJ = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 90%;
  ul {
    text-align: left;
  }
  .name {
    color: var(--blue);
    margin-bottom: 2rem;
    h2 {
      margin-bottom: 0.5rem;
    }
  }
  .groupInfo {
    .info {
      color: var(--pink);
      margin-bottom: 2rem;
      svg {
        margin-bottom: 0.5rem;
      }
    }
    @media (min-width: 700px) {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      margin: 2rem 0;
    }
  }
`;

export const DivFileOptions = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    color: var(--blue);
  }
  h3 {
    margin-bottom: 2rem;
    text-align: center;
  }
`;
