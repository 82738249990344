import React, { useCallback } from "react";
import debounce from 'lodash.debounce';
import { FaSearch } from "react-icons/fa";
import { useSearch } from "../../context/contextSearch";
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { InputMU } from "./styles";

import api from "../../services/api";

const SearchInput = (props) => {
  const {primary} = useAuth();
  const {isEnabled} = useColorMode();
  const { setSearchLoader, setResult, setSearch, search } = useSearch();


  const debouncedSave = useCallback(
    debounce((newValue) => {
      setSearchLoader(true);
      setResult([]);
      if(props.id === 'admin-search'){
        if(newValue.match(/^[0-9]+$/)){ 
          api.get(`v1/clients?limit=5&skip=0&reference=${newValue}`,
            {
              headers: {
                Authorization: localStorage.getItem('@Atlantico:token'),
              },
            }
          )
          .then((response) => {
            setSearchLoader(false);    
            setResult(response.data);
          })
        }else{
          api.get(`v1/clients?limit=5&skip=0&name__regex=/${newValue}/i`,
            {
              headers: {
                Authorization: localStorage.getItem('@Atlantico:token'),
              },
            }
          )
          .then((response) => {
            setSearchLoader(false);
            setResult(response.data);
          })
        }
      } else if(props.id === 'client-search'){
        api.get(`v1/clients/${primary._id}/taskSearch?key=${newValue}`,
        {
          headers: {
            Authorization: localStorage.getItem('@Atlantico:token'),
          },
        }
        )
        .then(response => {
          setSearchLoader(false);
          setResult(response.data);
        })
      }
    }, 1000), []
  );

  const updateValue = (newValue) => {
    setSearch(newValue);
    debouncedSave(newValue);
};

  return (
    <InputMU className="input-block" darkMode={isEnabled}>
      <FaSearch size={18} />
      <form onSubmit={(e) => {e.preventDefault()}}>
        <input
          type="text"
          id="search-box"
          autoComplete="off"
          value={search}
          placeholder={props.placeholder}
          onChange={(input) => updateValue(input.target.value)}
        />
      </form>
      
    </InputMU>
  );
};

export default SearchInput;