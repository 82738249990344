import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  LinearProgress,
  CircularProgress,
  Paper,
  Typography,
  Backdrop,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";

import General from "./employee/general";
import Professionals from "./employee/professionals";
import Personal from "./employee/personal";
import Attachments from "./employee/attachments";
import Dependents from "./employee/dependents";
import api from "../../services/api";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import InputMask from 'react-input-mask';
import { validation } from "./employee/validation";
import { validarCPF } from "../../utils/validaCPF";
import { validarPIS } from "../../utils/validaPIS";
import { isValidDate } from "../../utils/isValidDate";
import { rr } from "../../services/runrunit";

import { 
  requiredData,
  initialState,
  FuncPessoa,
  FuncContrato,
  FuncSalario,
  FuncCargo,
  FuncEscala,
  FuncDependentes,
  } from "./employee/initialState";

//TnFpaDMFuncPessoaCompleto
//FunPessoaCompleto(nFpa)

//TnFpaDMFuncContrato
//FuncContrato(nFpa)

//TnFpaDMFuncSalario
//FuncSalario(nFpa)

//TnFpaDMFuncCargo
//FuncCargo(nFpa)

//TnFpaDMFuncEscala
//FuncEscala(nFpa)

//TnFpaDMDependente
//Dependente(nFpa)

const jsonToString = (initialState,data) => {

  return Object.entries(initialState).map(row => {

    if(data[row[0]]===false){
      return 0
    }

    if(data[row[0]]===true){
      return 1
    }

    if(!data[row[0]]&&data[row[0]]!=="0"){
      return "~"
    }

    return data[row[0]]

  }).join(';')

}

const PeopleEmployee = ({ id, type }) => {
  const [tab, setTab] = useState("general")
  const history = useHistory();
  const {user, primary} = useAuth();
  const {isEnabled} = useColorMode();
  const [cargos,setCargos] = useState([])
  const [funcoes,setFuncoes] = useState([])
  const [escalas,setEscalas] = useState([])
  const [sindicatos,setSindicatos] = useState([])
  const [tiposContrato,setTiposContrato] = useState([])
  const [submitError, setSubmitError] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [progress,setProgress] = useState({
    state: false,
    step: 0,
    progress:0,
  })
  const [required,setRequired] = useState({
    message:"",
    state:false,
  })
  const [success,setSuccess] = useState({
    message:"",
    state:false,
  })
  const token = localStorage.getItem("@Atlantico:token")
  
  const [employee,setEmployee] = useState(initialState)
  const [loading, setLoading] =  useState(true)
  const [progressDraft, setProgressDraft] = useState(false)
  const [draft,setDraft] = useState({
    _id:null,
  })

  const headers = {
    headers: {
      Authorization: token,
    },
  }

  const postData = (payload,status,cod=null) => (

    api.post(`v1/nweb/import`,payload,headers)
    .then(async response => {

      let payload = {
        status,
      }

      if(cod){
        payload[cod] = response.data[cod]
        payload["draft"] = {...employee, [cod]: response.data[cod]}

        setEmployee({...employee, [cod]: response.data[cod]})
      }

      return await api.put(`v1/fopag/employee/${id}`,payload,headers)
        .then(draft => (response.data[cod] || "success"))
        .catch(error => { 
    
          onSubmitError(error)
          return false
          
        })

    })
    .catch(error => { 

      onSubmitError(error)
      return false

    })

  )

  const handleDraftSave = () => {
    
    if(!employee.NOMEFUNC){

      setRequired({
        message:"Preencha ao menos o nome do empregado para salvar o rascunho.",
        state:true,
      })

      return false

    }

    setProgressDraft(true)

    const payload = {
      client: primary.reference,
      user: user._id,
      draft: employee,
    }

    if(id){
      api.put(`v1/fopag/employee/${id}`,payload,headers)
      .then(response => {
        console.log(response)
        
        setSuccess({
          message:<div><b>Rascunho</b> salvo com sucesso!</div>,
          state:true,
        })
      })
      .catch(error => {
        setRequired({
          message:"Ops! Não foi possível salvar o rascunho no momento, tente novamente mais tarde.",
          state:true,
        })
      })
      .then(() => {
        setProgressDraft(false)
      })
    }else{
      api.post(`v1/fopag/employee`,payload,headers)
      .then(response => {
        console.log(response)
        
        setSuccess({
          message:<div><b>Rascunho</b> salvo com sucesso!</div>,
          state:true,
        })

        setDraft(response.data)
      })
      .catch(error => {
        setRequired({
          message:"Ops! Não foi possível salvar o rascunho no momento, tente novamente mais tarde.",
          state:true,
        })
      })
      .then(() => {
        setProgressDraft(false)
      })
    }
    

  }

  const sendEmail = async (id) => {
    return api.post(`v1/mailer/send`,{
      recipients: user.email.map(email => ({email: email.value,name: user.name})),
      subject: "Novo cadastro de empregado registrado",
      template_id: "k68zxl29ep3gj905",
      data: {
        id,
        name: user.nickname,
        company: primary.name,
        employee: employee.NOMEFUNC,
      }
    },headers).then((response) => console.log(response)).catch((error) => console.log(error))
  }

  const handleTaskCreate = async () => {

    let blocks = ""

    if(draft._id){
      blocks = blocks + `<h2>Anexos enviados para download:</h2>`

      await api.get(`v1/clients/${primary._id}/filesList?path=rascunhos/${draft._id}/`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        
        response.data.files.map(file => {
          const formattedPath = file.name.replace('+', '%2B');

          blocks = blocks + `<div><a href='https://api.atlantico.digital/oauth/cloud/${primary._id}?path=rascunhos/${draft._id}/${formattedPath}'>${file.name}</a><div>`
        })
        

        
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
      blocks = blocks + `Obs: Não foram enviados anexos.`
    }

    blocks = blocks + `<br /><br /><h2>Dados enviados:</h2>`
    
    requiredData.map((item) => {

      let value = employee[item[0]]
      
      if(item[0]=="DATAADM"||item[0]=="DATANASC"){
        const date = new Date(value)
        value = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth()+1).toString().padStart(2, '0')}/${date.getFullYear()}`
      }

      blocks = blocks + `<div><b>${item[1]}:</b> ${value}</div>`

    })

    return rr.post('tasks', {
      task: {
        title: `${primary.reference} - ADMISSÃO - EMPREGADO - ${employee.NOMEFUNC}`,
        on_going: false,
        project_id: primary.runrunit_projects[0],
        type_id: 1690006,
        board_id: 486584,
        desired_date: null,
        description: blocks,
      }
    }, {
        headers: {
            "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
            "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
        }
    })
    .then(async response => {

      setDraft({...draft, task_id:response.data.id})

      return await api.put(`v1/fopag/employee/${id}`,{task_id:response.data.id},headers)
      .then(draft => response.data.id)
      .catch(error => false)

      
    })
    .catch(err => console.log(err))
  
  }

  const onSubmitError = (error) => {
    if(error.response){
      console.log(error.response.data)
    }

    setSubmitError(true)
  }

  const onSubmit = async (e) => {
    if(e){
      e.preventDefault()
    }

    setRequired({
      message:"",
      state:false,
    })
    
    const validate = validation(setRequired,employee,requiredData)

    if(validate){
      let funcpessoa = employee.CODIGOFUNCPESSOA
      let funccontrato = employee.CODIGOFUNCCONTR
      let task;

      let payload = {
        layout: "FuncPessoa",
        data: jsonToString(FuncPessoa,{
          ...employee,
          DDDFONE: employee.NUMEROFONE,
          DDDCELULAR: employee.NUMEROCELULAR,
        })
      }

      setProgress({
        state:true,
        step:0,
        progress:12,
      })


      if(!draft.task_id){
        if(!primary.runrunit_projects[0]) return false

        task = await handleTaskCreate()

        if(!task) return false
      }

      // if(!draft.CODIGOFUNCPESSOA){

      //   setProgress({
      //     state:true,
      //     step:1,
      //     progress:24,
      //   })

      //   funcpessoa = await postData(payload,{ person: true },"CODIGOFUNCPESSOA")
  
      //   if(!funcpessoa) return false

      // }

      // if(!draft.status.contract){

      //   payload = {
      //     layout: "FuncContrato",
      //     data: jsonToString(FuncContrato,{ ...employee, CODIGOFUNCPESSOA: funcpessoa })
      //   }
      
      //   setProgress({
      //     state:true,
      //     step:2,
      //     progress:36,
      //   })
  
      //   funccontrato = await postData(payload,{ person: true, contract: true },"CODIGOFUNCCONTR")
  
      //   if(!funccontrato) return false

      // }

      // if(!draft.status.wage){

      //   payload = {
      //     layout: "FuncSalario",
      //     data: jsonToString(FuncSalario,{ ...employee, CODIGOEMPRESA: primary.reference, CODIGOFUNCCONTR: funccontrato, DATAINICIAL: employee.DATAADM })
      //   }
      
      //   setProgress({
      //     state:true,
      //     step:3,
      //     progress:48,
      //   })
  
      //   const funcsalario = await postData(payload,{ person: true, contract: true, wage: true })
  
      //   if(!funcsalario) return false

      // }

      // if(!draft.status.office){

      //   payload = {
      //     layout: "FuncCargo",
      //     data: jsonToString(FuncCargo,{ ...employee, CODIGOEMPRESA: primary.reference, CODIGOFUNCCONTR: funccontrato, DATAINICIAL: employee.DATAADM })
      //   }
      
      //   setProgress({
      //     state:true,
      //     step:4,
      //     progress:60,
      //   })
  
      //   const funccargo = await postData(payload,{ person: true, contract: true, wage: true, office: true })
  
      //   if(!funccargo) return false

      // }

      // if(!draft.status.grade){

      //   payload = {
      //     layout: "FuncEscala",
      //     data: jsonToString(FuncEscala,{ ...employee, CODIGOEMPRESA: primary.reference, CODIGOFUNCCONTR: funccontrato, DATAINICIAL: employee.DATAADM })
      //   }
      
      //   setProgress({
      //     state:true,
      //     step:5,
      //     progress:72,
      //   })
  
      //   const funcgrade = await postData(payload,{ person: true, contract: true, wage: true, office: true, grade: true })
  
      //   if(!funcgrade) return false

      // }

      // if(!draft.status.dependent){

      //   if(employee.DEPENDENTES.length > 0) {
      
      //     setProgress({
      //       state:true,
      //       step:6,
      //       progress:84,
      //     })

      //     const data = employee.DEPENDENTES.map(person => (
      //       jsonToString(FuncDependentes,{ ...person, CODIGOEMPRESA: primary.reference, CODIGOFUNCCONTR: funccontrato })
      //     )).join('\r\n')

      //     payload = {
      //       layout: "FuncDependente",
      //       data,
      //     }

      //     const funcdependent = await postData(payload,{ person: true, contract: true, wage: true, office: true, grade: true, dependent: true })
    
      //     if(!funcdependent) return false

      //   }else{

      //     await api.put(`v1/fopag/employee/${id}`,{status: { person: true, contract: true, wage: true, office: true, grade: true, dependent: true }},headers)
      //     .then(draft => console.log("Não possui dependentes para envio"))
      //     .catch(error => { 
      //       onSubmitError(error)
      //       return false
      //     })

      //   }

      // }

      await sendEmail(draft.task_id || task);
      
      setProgress({
        state:true,
        step:7,
        progress:100,
      })

    }

  }

  const handleChange = (e,obj=false) => {
    
    if(obj){

      let newObj = {}

      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key]
      })

      setEmployee({
        ...employee,
        ...newObj,
      })

      // console.log({
      //   ...employee,
      //   ...newObj,
      // })

    }else{
      const el = e.target
      const name = el.name;
      let value = el.type === "checkbox" ? el.checked : el.value;

      if(name==="DIACONTREXP"){
        if(Number(value) > 90){
          value = "90"
        }
        else if (Number(value) <= 0){
          value = ""
        }
      }

      setEmployee({
        ...employee,
        [name]: value
      })

      console.log({
        ...employee,
        [name]: value
      })
    }
    

  }

  useEffect(() => {
    if(draft&&draft.CODIGOFUNCPESSOA){
      onSubmit()
    }
  },[draft])

  useEffect(() => {

    const cargosRequest = api.post(`v1/nweb?classe=TnFpaDCCargoEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
    const funcoesRequest = api.post(`v1/nweb?classe=TnFpaDCFuncaoEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
    const escalasRequest = api.post(`v1/nweb?classe=TnFpaDCEscalaEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
    const tiposContratoRequest = api.post(`v1/nweb?classe=TnFpaDCTipoContratoEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
  
    const requests = [cargosRequest,funcoesRequest,escalasRequest,tiposContratoRequest]
  
    if(id){
      const draft = api.get(`v1/fopag/employee?_id=${id}`,headers)
      requests.push(draft)
    }
    
    setEmployee({
      ...employee,
      CODIGOEMPRESA: primary.reference,
      CATEGORIA: type == "Empregados" ? "01" : "11",
      TIPOVINCULO: type == "Empregados" ? "" : "80",
    })

    axios.all(requests)
    .then(
      axios.spread((...responses) => {
        const responseCargos = responses[0]
        const responseFuncoes = responses[1]
        const responseEscalas = responses[2]
        const responseTiposContrato = responses[3]

        setCargos(responseCargos.data.data)
        setFuncoes(responseFuncoes.data.data)
        setEscalas(responseEscalas.data.data)
        setTiposContrato(responseTiposContrato.data.data)

        if(id!=undefined){
          const responseDraft = responses[4]
          setEmployee({
            ...employee,
            ...responseDraft.data[0].draft,
          })

          setDraft(responseDraft.data[0])
        }

        setLoading(false)
      })
    )
    .catch(error => {
      setLoadingError(true)
      setLoading(false)
      console.log(error)
    })

  },[])

  useEffect(() => {

    if(required.state){
      setTimeout(() => {
        setRequired({
          message:"",
          state:false,
        })
      }, 5000);
    }

  },[required])

  useEffect(() => {

    if(success.state){
      setTimeout(() => {
        setSuccess({
          message:"",
          state:false,
        })
      }, 5000);
    }

  },[success])

  const backdrop = () => {

    if(submitError) {

      return <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível continuar sua solicitação, volte e tente novamente mais tarde. Persistindo o problema, entre em contato com nossos canais de suporte!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type}) }}>Voltar</Button>
      </Paper>
    }

    return (
      <div>

        <LinearProgress variant="determinate" value={progress.progress} />

        <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          {progress.step < 7 && <CircularProgress size={50} style={{marginBottom:20}} />}
          {progress.step===7 && <FaCheckCircle size={100} color="#3cd56b" style={{marginBottom:20}} />}
                
          {progress.step===0 && <Typography variant="h5">Criando tarefa...</Typography>}
          {progress.step===1 && <Typography variant="h5">Cadastrando dados básicos...</Typography>}
          {progress.step===2 && <Typography variant="h5">Gerando contrato...</Typography>}
          {progress.step===3 && <Typography variant="h5">Definindo salário...</Typography>}
          {progress.step===4 && <Typography variant="h5">Atribuindo cargo...</Typography>}
          {progress.step===5 && <Typography variant="h5">Ajustando escala...</Typography>}
          {progress.step===6 && <Typography variant="h5">Cadastrando dependentes...</Typography>}
          {progress.step===7 && <div><Typography variant="h5">Cadastro enviado com sucesso!</Typography><Typography variant="body2">Você já pode fechar esta janela.</Typography></div>}
          
          {progress.step===7 && <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type}) }}>Voltar</Button>}
        </Paper>

      </div>
    )

  }

  const validaCPF = (e) => {

    if(!validarCPF(e.target.value)){
      setRequired({
        message:"O CPF informado é inválido, por favor, confira o número informado.",
        state:true,
      })
    }

  }

  if(loading){
    return (<LinearProgress />)
  }

  if(loadingError){
    return <Paper style={{padding:50,textAlign:"center"}} fullWidth elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível carregar o conteúdo necessário, tente novamente mais tarde!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type}) }}>Voltar</Button>
      </Paper>
  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

        <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
        <Grid container>
          <Grid item xs={12} style={{padding:1}}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                  select
                  fullWidth
                  label="Empresa"
                  name="CODIGOEMPRESA"
                  defaultValue={employee.CODIGOEMPRESA}
                  disabled
                  SelectProps={{
                    native: true,
                  }}
                  >
                    <option key={primary.reference} value={primary.reference}>{primary.name}</option>
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField required fullWidth id="NOMEFUNC" label="Nome" aria-label="Nome" defaultValue={employee.NOMEFUNC} name="NOMEFUNC" inputProps={{ maxLength: 70 }} onChange={ handleChange } />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <InputMask
                  mask="999.999.999-99"
                  maskChar={null}
                  required
                  defaultValue={employee.CPFFUNC}
                  placeholder="000.000.000-00"
                  onChange={ handleChange }
                  onBlur={validaCPF}
                  >
                    {(inputProps) => <TextField {...inputProps} fullWidth id="CPFFUNC" label="CPF" aria-label="CPF" name="CPFFUNC" inputProps={{ maxLength: 14 }}  />}
                  </InputMask>
                </Grid>
              </Grid>

              <ButtonGroup variant="outlined" style={{marginTop:30}} fullWidth>
                  <Button variant="contained" color={tab=="general"?"primary":"default"} onClick={()=>{setTab("general")}}>Geral</Button>
                  <Button variant="contained" color={tab=="professionals"?"primary":"default"} onClick={()=>{setTab("professionals")}}>Profissionais</Button>
                  <Button variant="contained" color={tab=="personal"?"primary":"default"} onClick={()=>{setTab("personal")}}>Pessoais</Button>
                  <Button variant="contained" color={tab=="dependents"?"primary":"default"} onClick={()=>{setTab("dependents")}}>Dependentes</Button>
                  <Button variant="contained" color={tab=="attachments"?"primary":"default"} onClick={()=>{setTab("attachments")}}>Anexos</Button>
              </ButtonGroup>

              <Card>
                <CardContent>
                  {tab==="general"&&<General data={{cargos,sindicatos,funcoes,escalas,tiposContrato,employee}} handleChange={ handleChange } />}
                  {tab==="professionals"&&<Professionals data={{employee}} handleChange={ handleChange } />}
                  {tab==="personal"&&<Personal data={{employee}} handleChange={ handleChange } />}
                  {tab==="attachments"&&<Attachments handleChange={ handleChange } draft={draft} alert={setRequired} success={setSuccess} />}
                  {tab==="dependents"&&<Dependents data={{employee}} handleChange={ handleChange } />}
                </CardContent>
              </Card>
              
          </Grid>
        </Grid>
        </div>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
              <Divider style={{marginTop:10}} />

              {required.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#FC5757",backgroundColor:"#FEDDDE"}}>{required.message}</Paper>}
              {success.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#2D3D0F",backgroundColor:"#E0EFC1"}}>{success.message}</Paper>}

              <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
                  <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type}) }}>Voltar</Button>
                  <Button onClick={handleDraftSave}>{progressDraft ? <CircularProgress size={20} /> : "Salvar Rascunho"}</Button>
                  <Button color="secondary" type="submit">Enviar</Button>
              </ButtonGroup>
      </ModalButtons>

      <Backdrop open={progress.state} style={{zIndex:1000}}>
        {backdrop()}
      </Backdrop>
    </form>
  );
};

export default PeopleEmployee;
