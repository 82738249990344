import React from "react";
import {PrivacyDiv} from './styles';

const PrivacyPolicy = () => {
  return (
    <PrivacyDiv>
      <h4><strong>Preâmbulo</strong></h4>
      <p>O Atlântico Digital tem como premissa básica a manutenção da privacidade de todos os clientes e também de todos os que visitam o site. Todos os esforços são desenvolvidos para propiciar um ambiente agradável, de navegação simples e fortemente seguro.</p>
      <p>A Política de Privacidade do Atlântico Digital também explica quais são os cuidados tomados para garantir a privacidade e segurança dos visitantes. Essa política somente se aplica aos sites de responsabilidade do Atlântico Digital (atlantico.digital) que não se responsabiliza por sites de terceiros que eventualmente estejam ligados a estes.</p>

      <h4><strong>Definição de visitantes</strong></h4>
      <p>São considerados visitantes todas as pessoas que acessam o site do Atlântico Digital, sejam estes clientes ou não. Quando algum serviço ou seção do site for de uso exclusivo de clientes essa situação será explicada com a devida mensagem.</p>

      <h4><strong>Informações sobre dados coletados dos visitantes</strong></h4>
      <p>Com o objetivo de prestar o melhor serviço possível, nosso site poderá fazer uso de recursos técnicos, atualmente conhecidos como cookies*, com o objetivo de obter informações pertinentes aos visitantes tais como data da última visita ao site, tipo de computador e/ou navegador etc. Essas informações são necessárias para permitir a personalização da navegação e melhor adequação das páginas do site ao perfil do visitante.</p>
      <p>O fornecimento destas informações não é obrigatório e, para tanto, o usuário pode configurar seu navegador para não fornecê-las, porém isso poderá afetar a utilização de algumas funcionalidades do site.</p>
      <p>Durante a navegação na área do site restrita a clientes poderá ser utilizada e gravada a identificação de IP** a fim de garantir a segurança e autenticidade das transações.</p>
      <p>Quando houver algum tipo de captura de informação diferente das citadas acima, as mesmas serão obtidas através do preenchimento de formulários apropriados, conforme descrito no tópico "Cadastramento de informações" deste documento.</p>
      <ul>
        <li><strong>• Cookie -</strong> pequeno arquivo que possibilita a coleta de algumas informações sobre a navegação do usuário. Não são coletadas e tão pouco gravadas informações pessoais com este recurso.</li>
        <li><strong>• *IP (Internet Protocol) -</strong> endereço eletrônico numérico atribuído pelo provedor de acesso ao usuário, permitindo a identificação do mesmo em horário e data determinado.</li>
      </ul>

      <h4><strong>Quais dados são coletados</strong> </h4>
      <p>- Nome completo;</p>
      <p>- Foto;</p>
      <p>- Número de CPF;</p>
      <p>- Endereço de e-mail;</p>
      <p>- Número de celular;</p>
      <p>- Data de nascimento;</p>
      <p>- Dados referentes aos seus endereços.</p>
      <p>- Endereço de cobrança;</p>
      <p>- Endereço de entrega;</p>

      <h4><strong>Outras informações que poderão ser coletadas</strong> </h4>
      <p>- Informações sobre você que se tornaram públicas por você ou que estejam disponíveis publicamente;</p>
      <p>- Informações que coletamos de terceiros como, por exemplo, complementos dos seus dados cadastrais (nome, filiação, data de nascimento, CPF, número de telefone, endereço, entre outros), score gerado por agências de crédito, se você faz parte de alguma lista de Pessoa Exposta Politicamente (PEP) ou de alguma lista de alerta (como, OFAC, CSNU e outras listas internacionais), entre outras;</p>
      <p>- Informações fornecidas por você, voluntariamente, como por meio de comunicações com nossas redes sociais.</p>

      <h4><strong>Como nós utilizamos seus dados</strong></h4>
      <p>Nós utilizamos os dados pessoais para garantir um atendimento de qualidade e uma melhor experiência na sua compra. Listamos abaixo as finalidades que poderemos utilizar seus dados pessoais:</p>
      <p><strong>Dados cadastrais:</strong></p>
      <p>- Para viabilizar a prestação de diferentes serviços disponíveis em nossa plataforma;</p>
      <p>- Para realizar o atendimento de solicitações e dúvidas em nossa Central de Atendimento;</p>
      <p>- Para identificar corretamente o Usuário;</p>
      <p>- Para enviar os produtos adquiridos ou comunicações de ofertas;</p>
      <p>- Para entrar em contato com você, quando necessário. Esse contato pode contemplar diversos assuntos, como comunicação sobre promoções e ofertas, respostas a dúvidas, respostas de reclamações e solicitações, atualizações dos pedidos realizados e informações de entrega;</p>
      <p>- Para auxiliar no diagnóstico e solução de problemas técnicos;</p>
      <p>- Para desenvolver novas funcionalidades e melhorias, melhorando a sua experiência com nossos serviços disponíveis;</p>
      <p>- Para consultar suas informações nas bases de dados de agências de crédito;</p>
      <p>- Para realizar investigações e medidas de prevenção e combate a ilícitos, fraudes, crimes financeiros e crimes de lavagem de dinheiro e/ou de financiamento ao terrorismo;</p>
      <p>- Para garantir o cumprimento de obrigação legal ou regulatória ou garantir o exercício regular de direitos do Atlântico Digital Nesses casos, podemos, inclusive, utilizar e apresentar as informações em processos judiciais e administrativos, se necessário;</p>
      <p>- Para colaborar com o cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador;</p>
      <p><strong>Dados de Navegação:</strong></p>
      <p>- Para auxiliar no diagnóstico e solução de problema técnicos;</p>
      <p>- Para desenvolver novas funcionalidades e melhorias, melhorando a sua experiência com nossos serviços disponíveis.</p>

      <h4><strong>Com quem nós podemos compartilhar os dados pessoais</strong></h4>
      <p>Para a execução das atividades acima listadas, sempre que necessário, nós poderemos compartilhar os seus dados pessoais com outras empresas do nosso grupo, com prestadores de serviço, parceiros ou com órgãos reguladores. Jamais comercializamos dados pessoais. Apresentamos a seguir um resumo destas possibilidades:</p>
      <p><strong>Prestadores de serviço: </strong>para que possamos disponibilizar nossos produtos e prestar os nossos serviços com qualidade, contamos com a colaboração de diversos prestadores de serviço, que tratam os dados pessoais coletados em nosso nome e de acordo com nossas instruções. Esses prestadores de serviço atuam principalmente para nos auxiliar nas análise antifraude, intermediação de pagamentos, gestão de campanhas de marketing, enriquecimento de base de dados e armazenamento em nuvem.</p>
      <p><strong>Autoridades judiciais, policiais ou governamentais: </strong>nós devemos fornecer dados pessoais de Clientes e/ou Usuários, em atendimento à ordem judicial, solicitações de autoridades administrativas, obrigação legal ou regulatória, bem como para agir de forma colaborativa com autoridades governamentais, em geral em investigações envolvendo atos ilícitos.</p>
      <p><strong>Potenciais compradores: </strong>podemos compartilhar seus dados pessoais com outras empresas as quais o Atlântico Digital conclua uma negociação de venda ou transferência de parte ou da totalidade da atividade comercial, negócio ou operação do Atlântico Digital Caso a venda ou transferência não sejam consumadas, solicitaremos ao potencial comprador que não faça uso e não divulgue seus dados pessoais de qualquer maneira ou forma, apagando-os por completo.</p>

      <h4><strong>Armazenamento de segurança dos dados pessoais</strong></h4>
      <p>Nós armazenamos seus dados de forma segura em data centers localizados no Brasil, bem como no exterior. Neste caso de armazenamento no exterior, são adotadas todas as medidas legais aplicáveis, em conformidade com a Lei Geral de Proteção de Dados e suas futuras regulamentações, garantindo a proteção e privacidade dos seus dados pessoais.</p>
      <p>Nós adotamos as melhores técnicas para proteger os dados pessoais coletados de acessos não autorizados, destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito, inclusive mecanismos de criptografia. Ressaltamos, contudo, que nenhuma plataforma é completamente segura. Se você tiver qualquer preocupação ou suspeita de que os seus dados estejam em risco, por favor, entre em contato conosco por meio dos nossos canais de atendimento que ficaremos felizes em te auxiliar prontamente.</p>
      <p>Seus dados pessoais serão mantidos durante todo o período que for um cliente ativo. Após esse período, podemos armazenar os seus dados pessoais por um período adicional para fins de auditoria, para possibilitar o cumprimento de obrigações legais ou regulatórias. Faremos a retenção dos seus dados pelo prazo necessário, respeitando os prazos estabelecidos na legislação aplicável.</p>

      <h4><strong>Cookies e Tecnologias de Monitoramento</strong></h4>
      <p>Podemos utilizar certas tecnologias de monitoramento para coletar as informações das atividades realizadas nas nossas plataformas. As informações coletadas por meio de tais tecnologias são utilizadas para realizar métricas de performance, identificar problemas no uso, captar o comportamento dos Usuários de forma geral e coletar dados de impressão de conteúdos.</p>

      <p>Veja abaixo algumas das tecnologias que poderão estar presentes:</p>
      <p><strong>• Cookies: </strong>cookie é um pequeno arquivo adicionado ao dispositivo do Usuário para fornecer uma experiência personalizada de acesso à plataforma. Os cookies ajudam a analisar o tráfego de internet e nos permite saber quando o Usuário visitou um site específico. Um cookie não dá acesso a um computador ou revela informações além dos dados que o usuário escolhe compartilhar conosco.</p>
      <p><strong>• Pixels: </strong>pixels são partes do código JavaScript instalados adicionados em nossas aplicações, websites ou no corpo de um e-mail, com a finalidade de rastrear coletar informações sobre as atividades dos usuários, permitindo a identificação dos seus padrões de acesso, navegação, interesse e compras de produtos, e otimizando utilizados para otimizar o direcionamento de conteúdo.</p>
      <p><strong>• Ferramentas de analytics: </strong>essas ferramentas podem coletar informações sobre a forma como os Usuários visitam nosso site, quais páginas visitam e quando visitam tais páginas, outros sites que foram visitados antes, entre outras.</p>

      <h4><strong>Cadastramento de informações</strong></h4>
      <p>Para melhor prestação de serviços, os visitantes poderão ser pedidos a cadastrar seus dados em bases de dados específicas. o Atlântico Digital pratica o melhor relacionamento com sua comunidade de visitantes praticando o Marketing de Permissão, e dessa forma, disponibilizará páginas em que o visitante cadastrado terá a liberdade de incluir, alterar ou excluir seus dados pessoais cadastrados nessas bases de dados.</p>
      <p>Nenhum compromisso de qualquer natureza será devido pelos visitantes, quando do cadastramento, exceto nas situações em que isso for explicitamente avisado.</p>

      <h4><strong>Seus direitos como titular do dados pessoais</strong></h4>
      <p>- A transparência sobre o tratamento dos seus dados pessoais é prioridade do Atlântico Digital Além das informações disponibilizadas nesta Política de Privacidade, você pode também exercer os direitos previstos na Lei Geral de Proteção de Dados, entre eles:</p>
      <p>- Confirmação da existência de tratamento de dados pessoais;</p>
      <p>- Acesso aos dados pessoais;</p>
      <p>- Revogação do consentimento;</p>
      <p>- Correção de dados pessoais incompletos, inexatos ou desatualizados;</p>
      <p>- Eliminação dos dados pessoais tratados com o consentimento ou desnecessários, excessivos ou quando entender que algum ponto da LGPD não foi atendido;</p>
      <p>- Informação sobre as hipóteses e com quais empresas, parceiros e outras instituições podemos compartilhar, ou receber dados pessoais referentes a você;</p>
      <p>- Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa, quando aplicável;</p>
      <p>- Todas as solicitações serão tratadas de forma gratuita, e serão submetidas a uma prévia avaliação da sua identidade e da viabilidade do atendimento, a fim de cumprir com eventuais obrigações que impeçam o completo atendimento das requisições dos titulares de direito.</p>

      <h4><strong>Retenção e exclusão dos seus dados pessoais</strong></h4>
      <p>Nós trataremos seus dados pessoais com elevados níveis de proteção e garantindo sua privacidade, durante todo o período que você for nosso cliente, navegando em nossas plataformas e utilizando nossos serviços, ou potencial cliente com quem dividimos nossas ofertas de produtos e serviços.</p>
      <p>Quando aplicável, e mesmo se você optar por excluir seus dados pessoais da nossa base de cadastro, o Atlântico Digital poderá reter alguns ou todos os seus dados pessoais por períodos adicionais para cumprimento de obrigações legais ou regulatórias, para o exercício regular de direitos do Atlântico Digital, eventuais ações judiciais, fins de auditoria de diversas naturezas, ou outros prazos definidos e fundamentados por bases legais que justifiquem a retenção destes dados.</p>

      <h4><strong>Da responsabilidade das informações disponibilizadas no site</strong></h4>
      <p>O Atlântico Digital utiliza todo o empenho possível para a manutenção da exatidão das informações divulgadas em seu site. E também se isenta da responsabilidade por erros ou omissões nestas informações e materiais, e não se responsabiliza por inadequação do uso destas para objetivos particulares de seus visitantes, bem como por possíveis lucros cessantes decorrentes do uso dessas informações.</p>
      <p>O Atlântico Digital se dá o direito de alterar qualquer informação em seu site sem prévio aviso a seus usuários, assim como manter o site inativo pelo período que julgar necessário.</p>

      <h4><strong>Direito de uso de imagens e textos</strong></h4>
      <p>Os documentos e informações contidos no site do Atlântico Digital constituem materiais de autoria da próprio Atlântico Digital ou de terceiros, utilizados com a devida autorização destes, protegidos por direitos autorais. Nenhuma informação deste site poderá ser utilizada sem autorização por escrito do autor ou detentor do direito sobre as mesmas, exceto quando para uso particular. Não será admitida a reprodução total ou parcial das informações do Atlântico Digital, constituindo-se crime a utilização indevida dos mesmos.</p>

      <h4><strong>Ambiente seguro</strong></h4>
      <p>O Atlântico Digital desempenha todos os esforços para proporcionar aos visitantes o melhor ambiente técnico para sua navegação, incluindo aspectos de segurança. Para tanto, toda vez que for solicitada alguma informação aos usuários, isso será feito em ambiente seguro, ou seja, com recursos de criptografia, evitando que pessoas alheias ao processo possam identificar e se utilizar dessas informações. Serão aplicados diferentes níveis de segurança, de acordo com o tipo de serviço prestado, mas, em qualquer situação, todos os dados estarão devidamente seguros.</p>

      <h4><strong>Limite de Responsabilidade</strong></h4>
      <p>Em nenhuma circunstância, o Atlântico Digital será responsável por quaisquer danos, inclusive e sem limitação, danos diretos e indiretos, especiais, incidentais ou de conseqüência, perdas ou despesas oriundos da conexão com este site ou uso do mesmo ou incapacidade de uso por qualquer parte, ou com relação a qualquer falha de desempenho, erro , omissão, interrupção, defeito, demora na operação ou transmissão, vírus de computador ou falha da linha ou do sistema, mesmo se o Atlântico Digital, ou seus representantes, estejam avisados da possibilidade de tais danos, perdas ou despesas. O conteúdo, precisão, opiniões expressas, e outros links fornecidos por estes recursos não são investigados, verificados, monitorados, ou endossados pelo Atlântico Digital.</p>

      <h4><strong>Alterações desta política de privacidade</strong></h4>
      <p>Estamos constantemente buscando aprimorar a experiência de nossos clientes. Assim, nossas práticas de tratamento de dados pessoais poderão sofrer alterações com a inclusão de novas funcionalidades e serviços.</p>
      <p>Valorizamos a transparência no modo em que tratamos seus dados pessoais. Toda vez que alguma condição relevante desta Política de Privacidade for alterada, essas alterações serão válidas, eficazes e vinculantes após a nova versão ser publicada em nosso site. Estas alterações serão comunicadas por meio de um dos canais disponibilizados por você durante o seu cadastro (e-mail, SMS, WhatsApp e outros).</p>
    </PrivacyDiv>
  );
};

export default PrivacyPolicy;
