import styled from "styled-components";

export const DivModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background-color: var(--modal);
    width: 95%;
    height: auto;
    color: #000;
    border-radius: 0.5rem;
    padding: 2rem;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .title {
        text-transform: uppercase;
        color: var(--atlantico);
      }
      .close {
        background: none;
        border: 0;
        cursor: pointer;
      }
    }
    .content {
      height: ${(props) => (props.hasFooter ? "92%" : "100%")};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media (min-width: 700px) {
    .container {
      width: 30%;
      height: auto;
      padding: 4rem;
      .content {
        height: ${(props) => (props.hasFooter ? "96%" : "100%")};
      }
    }
  }

  @media (min-width: 1025px) {
    .container {
      .content {
        height: ${(props) => (props.hasFooter ? "92%" : "100%")};
      }
    }
  }
`;
