import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import group from '../../assets/images/grupo.svg';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

import {Container} from './styles';

import api from '../../services/api';

const Csat = (props) => {
    const [loader, setLoader] = useState(false);

    const q = new URLSearchParams(props.location.search).get("q");
    const userid = new URLSearchParams(props.location.search).get("userid");
    const id = new URLSearchParams(props.location.search).get("id");
    const client = new URLSearchParams(props.location.search).get("client");
    const type = new URLSearchParams(props.location.search).get("type");

    const [comments, setComments] = useState('');

    const [commentStatus, setCommentStatus] = useState(null);
    const [responseId, setResponseId] = useState(null);

    const [successCommentSend, setSuccessCommentSend] = useState();
    const [err401, setErr401] = useState(false);
    const [err422, setErr422] = useState(false);

    useEffect(() => {
        if(type === 'ticket'){
            async function postCsat(){
                await api.post("oauth/survey/csat", {
                    answer: q,
                    type,
                    id,
                }).then(setLoader(true))
                .then(response => {
                    setLoader(false);
                    setCommentStatus(response.data.comments);
                    setResponseId(response.data._id);
                }).catch(err => {
                    setLoader(false);
                    if(err.response.status === 401){
                        setErr401(true);
                    } else if (err.response.status === 422){
                        setErr422(true);
                    }
                    
                })
            }

            postCsat();
        } else {
            async function postCsat(){
                await api.post("oauth/survey/csat", {
                    answer: q,
                    user: userid,
                    type,
                    client,
                    id,
                })
                .then(setLoader(true))
                .then(response => {
                    setLoader(false);
                    setCommentStatus(response.data.comments);
                    setResponseId(response.data._id);
                }).catch(err => {
                    setLoader(false);
                    if(err.response.status === 401){
                        setErr401(true);
                    } else if (err.response.status === 422){
                        setErr422(true);
                    }
                    
                })
            }

            postCsat();
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function submitComment() {
        await api.post(`oauth/survey/csat/${responseId}/comment`, {
            comments,
        })
        .then(response => {
            setCommentStatus(comments);
            setSuccessCommentSend(true);
        })
        .catch(err => {
            console.log(err);
        })
    }

    return (
        <Container>
            <Header size={120} location="https://app.atlantico.digital/auth"/>

            {loader ? <Loader /> : (
                commentStatus === null ? (
                    err401 ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3rem'}}><Message severity="warning" message="Usuário ou Cliente incorreto(s)!"/></div>  :
                    err422 ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3rem'}}><Message severity="warning" message="Ocorreu um erro ao processar as informações. Tente novamente."/></div> :
                    <div className="textarea-div">
                        <h4><strong>Avalie sua experiência</strong></h4>
                        <h4><strong style={{color: 'var(--gray)'}}>Em poucas palavras, descreva o que motivou sua nota sobre o processo:</strong></h4>
                        <textarea placeholder="Descreva aqui o seu motivo." rows="5" onChange={(e) => setComments(e.target.value)}/>
    
                        <Button
                            type="button"
                            bgcolor="var(--orange)"
                            color="#fff"
                            border="var(--orange)"
                            hover="var(--orange-hover)"
                            size="small"
                            hollow={false}
                            expansive={true}
                            onClick={() => submitComment()}
                        >
                            <FaArrowRight size={20} />
                        </Button>
                    </div>
                ) : (
                    successCommentSend ? (
                        <div className="textarea-div">
                            <img src={group} alt=""/>
                            <h4><strong>Obrigado por responder à Pesquisa!</strong></h4>
                            <h4><strong style={{color: 'var(--gray)'}}>O seu feedback é muito importante.</strong></h4>
                        </div> 
                    ) : (
                        <div className="textarea-div">
                            <img src={group} alt=""/>
                            <h4><strong>Já recebemos a sua avaliação!</strong></h4>
                            <h4><strong style={{color: 'var(--gray)'}}>Obrigado por responder à Pesquisa.</strong></h4>
                        </div> 
                    )
                )
            )}
            {}
            
        </Container>
        
    )
}

export default Csat;