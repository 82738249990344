import styled from 'styled-components';

export const DivLinkedClients = styled.div`

    width: 100%;

    hr{
        border: 1px solid var(--divisor);
        margin: 2rem 0;
    }

    .icons{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--blue);
    }

    .buttons{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }

    @media (min-width: 700px) {
      .buttons{
        display: flex;
        flex-direction: row;
        max-width: 60%;

        button{
          &:first-child{
            margin-right: 2rem;
          }
        }
      }
    }
    
`;

