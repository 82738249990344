import React, { useState } from "react";
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useNewContact } from "../../../context/contextNewContact";
import { useColorMode } from "../../../context/contextColorMode";
import InputMask from 'react-input-mask';

import { FormContact } from "../styles";

const Cep = ({ navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const { cep, setCep } = useNewContact();

  const { next, previous } = navigation;

  return (
    <>
      {error ? (
        <ToastMessage
          variant="info"
          message="Preencha este campo antes de continuar!"
        />
      ) : null}
      <ModalContent displayFlex={true}>
        <FormContact darkMode={isEnabled}>
          <h2>INFORME O CEP DO ENDEREÇO DO CONTATO</h2>
          <InputMask
            mask="99999-999"
            maskChar={null}
            value={cep}
            placeholder="99999-999"
            onChange={e => setCep(e.target.value)}
          />
        </FormContact>
      </ModalContent>
      <ModalButtons>
        <Button
          bgcolor="var(--blue)"
          color="#fff"
          border="var(--blue)"
          hover="var(--blue-hover)"
          expansive={true}
          hollow={false}
          size="small"
          onClick={async () => {
            await validateField(cep, 'text', next, setError);
            setError(false);
          }}
        >
          CONTINUAR
        </Button>
        <hr />
        <Button
          color="var(--gray)"
          border="var(--gray)"
          expansive={true}
          hollow={true}
          size="small"
          onClick={previous}
        >
          VOLTAR
        </Button>
      </ModalButtons>
    </>
  );
};

export default Cep;
