import styled from "styled-components";

export const DashboardPage = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background: var(--background);

  .subheader-mobile {
    text-align: center;
    margin-top: 2rem;
    @media(min-width: 700px){
      padding: 0 15rem;
    }
    @media(min-width: 1025px){
      display: none;
    }
  }

  @media(min-width: 1025px){
    padding: 4rem;
    padding-left: 25%;
  }

  @media(max-width: 1025px) {
    .sidebar-desktop {
      display: none;
    }
  }

  .icons{
    display: flex;
    justify-content: space-around;
    padding: 1.5rem 2rem;
    width: 100%;
    display: flex;
    svg {
      color: var(--blue);
      &:hover {
        color: var(--blue-hover);
      }
    }
  }

  .search{
    width: 100%;
    margin: 0 auto;
    height: auto;
    padding: 0 1rem;

    @media(min-width: 700px){
      padding: 0 10rem;
    }

    @media(min-width: 1025px){
      width: 100%;
      margin: 0 auto;
      height: auto;
      padding: 0;
      margin-top: 0rem;
    }
    
  }

  .div-search {
    position: relative;
    margin: 0 1rem;

    .resultSet {
      height: auto;
      background: ${props => props.darkMode ? '#1c1c1c' : '#fff'};
      padding: 0.5rem 2rem;
      border-radius: 1rem;
      z-index: 99999 !important;
      position: absolute;
      max-height: 10em;
      overflow: auto;
      width: 100%;
      -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);
      -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);

      p{
        color: var(--text);
        margin: 1rem 0;
      }


      ul {
        list-style: none;
        li {
          padding: 1rem 0;
          cursor: pointer;
          a{
            text-decoration: none;
            color: ${props => props.darkMode ? '#666666' : '#000'};
            &:hover {
              background-color:${props => props.darkMode ? '#1c1c1c' : '#f1f7fa'};
              color: ${props => props.darkMode ? '#fff' : 'var(--blue)'};
            }
          }
        }
      }
    }
    @media (min-width: 700px) {
      margin: 0 15rem;
    }
    @media (min-width: 1024px) {
      margin: 0;
    }
  }

  .blocks {
    padding: 1rem 2rem;

    @media(min-width: 700px){
      margin-top: 1rem;
      padding: 1rem 15rem;
    }
    @media(min-width: 1025px){
      padding: 0rem;
    }
  }
`;

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: ${props => props.darkMode ? '#121212' : 'var(--atlantico)'};

  img {
    align-self: center;
  }

  svg{
    color: #fff;
  }

  @media(min-width: 1025px){
    display: none;
  }
`;