import styled from "styled-components";

export const InvoiceDetail = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5rem;
  text-align: center;
  svg{
    color: var(--file);
    margin-bottom: 1rem;
  }
  h4 {
    color: var(--blue);
  }
  h5 {
    color: #b1b1b1;
    margin-bottom: 1rem;
  }
  p {
    line-height: 1rem;
    margin-bottom: 1rem;
    color: var(--text);
  }

  @media (min-width: 700px) {
    line-height: 2rem;
    p {
      line-height: 1.5rem;
    }
  }
`;

export const BarsCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  border-radius: 10px;
  background-color: var(--gray);
  h4 {
    color: black;
    line-height: 1.5rem;
  }
`;
