import {
    Grid,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Link,
    Button,
    LinearProgress,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";

import UploadFiles from "../../UploadFiles";
import { useAuth } from "../../../context/contextAuth";

import {
    FaPlus,
    FaTrash,
  } from "react-icons/fa";

import api from "../../../services/api";

const Attachments = (props) => {
    const { primary, user } = useAuth();
    const [fileLoading, setFileLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [tab, setTab] = useState("address")
    const token = localStorage.getItem("@Atlantico:token");

    async function DownloadFile(path) {
        const formattedPath = path.replace('+', '%2B');
        return await api.get(`v1/clients/${primary._id}/downloadUrl?path=rascunhos/${props.draft._id}/${formattedPath}&user_id=${user._id}`,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((response) => {
            window.open(response.data.url);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      async function handleDelete(e, path) {
        e.preventDefault();
        const el = e.target.parentNode.parentNode.parentNode

        return await api.delete(`v1/clients/${primary._id}/deleteObject?path=rascunhos/${props.draft._id}/${path}&user_id=${user._id}`,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((response) => {

            props.success({
                message:`Arquivo ${path} excluído com sucesso!`,
                state:true,
            })
            
            el.remove()
          })
          .catch((err) => {
            props.success({
                message:`Ops! No momento, não foi possível excluir o arquivo ${path}. Tente novamente mais tarde.`,
                state:true,
            })
            console.log(err);
          });
      }

    useEffect(() => {
        if(props.draft._id){
            async function getData(){
                await api.get(`v1/clients/${primary._id}/filesList?path=rascunhos/${props.draft._id}/`,
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                )
                .then(setFileLoading(true))
                .then((response) => {
                  setFileLoading(false);
                  setFiles(response.data.files);
                  console.log(response.data.files)
                })
                .catch((err) => {
                  setFileLoading(false);
                  console.log(err);
                });
            }
        
            getData();
        }
      }, []);

    return <Grid container spacing={2}>

        <Grid item xs={12}>
            {!props.draft._id && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#000",backgroundColor:"#fff3cd"}}>Salve o rascunho para conseguir anexar arquivos.</Paper>}
            {props.draft._id && <UploadFiles primary={primary._id} user={user._id} token={token} path={`rascunhos/${props.draft._id}`}/>}
        </Grid>

        <Grid item xs={12}>
            {fileLoading && <LinearProgress />}
            {files.length > 0 && <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Arquivos enviados</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map((row,index) => (
                            <TableRow key={index}>
                                <TableCell><Link component="button" onClick={(e) => { e.preventDefault(); DownloadFile(row.name) }}>{row.name}</Link></TableCell>
                                <TableCell width={70} align="right"><Button size="small" color="secondary" startIcon={<FaTrash size={12} />} onClick={(e) => handleDelete(e, row.name)}>Excluir</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </Grid>

    </Grid>

}

export default Attachments