import React from "react";

const StateDrop = ({ isCompany, content, ...others }) => {
  return (
    <div style={{ width: "100%" }}>
      <select value="" {...others} style={{ background: 'var(--modal)', color: 'var(--text)' }}>
        <option style={{color: 'var(--text)'}} value="" disabled hidden>
          Selecione uma opção
        </option>
        {isCompany ?
          content.map((option) => (
            <option style={{color: 'var(--text)'}} value={option.reference} key={option._id}>{option.name}</option>
          ))
        : 
          content.map((option) => (
            <option style={{color: 'var(--text)'}} value={option.option} key={option._id}>{option.option}</option>
          ))
        }
      </select>
    </div>
  );
}

export default StateDrop;
