import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Tour from "reactour";
import {
  FaBell,
  FaPen,
  FaCloudUploadAlt,
  FaWhatsapp,
  FaInfo,
  FaRandom,
  FaBars,
  FaArrowLeft,
  FaSignOutAlt,
  FaCircle,
  FaFileAlt,
  FaHeadset,
} from "react-icons/fa";
import { Hidden } from "@material-ui/core";
import logo from "../../../assets/images/logo.svg";
import logoDarkMode from '../../../assets/images/logo-dark-mode.svg';
import Button from "../../../components/Button";
import ContentBlock from "../../../components/ContentBlock";
import Navigator from "../../../components/Navigator";
import SearchInput from "../../../components/SearchInput";
import ReceivedFiles from "../../../components/ReceivedFiles";
import List from "../../../components/List";
import ToastMessage from "../../../components/ToastMessage";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import { SeeMore } from "../../../components/SeeMore";
import { useNotification } from "../../../context/contextNotification";
import { useAuth } from "../../../context/contextAuth";
import { useSearch } from "../../../context/contextSearch";
import { useTutorial } from "../../../context/contextTutorial";
import { useUploadModal } from "../../../context/contextUploadModal";
import { useColorMode } from "../../../context/contextColorMode";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { DashboardPage, PageHeader } from "./styles";
import { Link } from 'react-router-dom'

import api from "../../../services/api";
import { useSubModal } from "../../../context/contextSubModal";
import SubModal from "../../../components/SubModal";
import { ConfirmLogout } from "../../../modals/ConfirmLogout";
import TutorialGuide from "../../../modals/TutorialGuide";

export const Dashboard = () => {
  const { user, primary, signed, setUser } = useAuth();
  const history = useHistory();
  const {
    isSubModalVisible,
    setSubModalContent,
    setIsSubModalVisible
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSubModal();
  const {
    hasNotification,
    setHasNotification,
    isOpened,
    setIsOpened,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useNotification();
  const { att } = useUploadModal();
  const screenW =  window.screen.width;
  const token = localStorage.getItem("@Atlantico:token");
  const [openedDrawerMobile, setOpenedDrawerMobile] = useState(false);
  const {searchLoader, result, search, setResult, setSearch} = useSearch();
  const [lastUploads, setLastUploads] = useState([]);
  const [lastUpload, setLastUpload] = useState({});
  const [loader, setLoader] = useState();
  const [successDeleted, setSuccessDeleted] = useState(false);
  const [count, setCount] = useState(0);
  const [drawerTutorial, setDrawerTutorial] = useState(0);
  const { isEnabled } = useColorMode();
  const { isTutorialActive, setIsTutorialActive, setSteps, steps } = useTutorial();
  const commonSteps = [
    {
      selector: '#button1',
      content: 'Utilize este botão para enviar arquivos para sua nuvem segura.'
    },
    {
      selector: '#button2',
      content: 'Utilize este atalho para falar com nossos especialistas direto pelo Whatsapp.'
    },
    {
      selector: '#button3',
      content: 'Clique aqui para abrir um chamado quando precisar de algum serviço.'
    },
    {
      selector: '#search',
      content: 'Utilize este campo para pesquisar documentos que foram enviados para sua conta segura. Basta você digitar que serão exibidos os resultados logo abaixo.'
    },
    {
      selector: '#block1',
      content: 'Aqui você poderá visualizar os documentos mais recentes enviados para sua conta segura.'
    },
    {
      selector: '#block2',
      content: 'Nesta parte é exibido um histórico dos últimos arquivos enviados por você.'
    },
    {
      selector: '#block3',
      content: 'Em Minha PJ você pode conferir as informações vinculadas a sua empresa. Caso seu usuário tenha acesso a mais de uma empresa, você poderá alterar as empresas por aqui.'
    },
    {
      selector: screenW > 1024 ? '.icon-notification' : '#icon-notification',
      content: 'Aqui você receberá notificações acerca da movimentação de sua conta segura.'
    },
    {
      selector: screenW > 1024 ? '.icon-edit' : '#icon-edit',
      content: 'Por aqui você tem acesso ao seu perfil, podendo editar e-mails, telefones e até mesmo alterar sua senha.'
    },
    {
      selector: screenW > 1024 ? '.icon-logout' : '#icon-logout',
      content: 'Clicando aqui você será desconectado de sua conta segura.'
    },
    {
      selector: '.nav-switch-company',
      content: 'Clicando aqui, caso seu usuário tenha acessa a mais de uma empresa, você poderá alterar a empresa em foco.'
    },
  ];
  const [adminSteps, setAdminSteps] = useState([...commonSteps, 
  {
    selector: '.nav-received-files',
    content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
  },
  {
    selector: '.nav-documents',
    content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
  },
  {
    selector: '.nav-invoices',
    content: 'Aqui você acompanha suas faturas tendo opção de emitir uma segunda via.'
  },
  {
    selector: '.nav-pj',
    content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
  },
  {
    selector: '.nav-contacts',
    content: 'Neste canal você poderá gerenciar os usuários e perfis de acesso a sua conta segura.'
  },
  {
    selector: '.nav-help',
    content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
  },
  {
    selector: '.nav-guide',
    content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
  }]);
  const [financSteps, setFinancSteps] = useState([...commonSteps, 
  {
    selector: '.nav-received-files',
    content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
  },
  {
    selector: '.nav-documents',
    content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
  },
  {
    selector: '.nav-invoices',
    content: 'Aqui você acompanha suas faturas tendo opção de emitir uma segunda via.'
  },
  {
    selector: '.nav-pj',
    content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
  },
  {
    selector: '.nav-help',
    content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
  },
  {
    selector: '.nav-guide',
    content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
  }]);
  const [ftaxSteps, setFtaxSteps] = useState([...commonSteps, 
  {
    selector: '.nav-received-files',
    content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
  },
  {
    selector: '.nav-documents',
    content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
  },
  {
    selector: '.nav-invoices',
    content: 'Aqui você acompanha suas faturas tendo opção de emitir uma segunda via.'
  },
  {
    selector: '.nav-pj',
    content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
  },
  {
    selector: '.nav-help',
    content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
  },
  {
    selector: '.nav-guide',
    content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
  }]);
  const [hrSteps, setHrSteps] = useState([...commonSteps, 
  {
    selector: '.nav-received-files',
    content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
  },
  {
    selector: '.nav-documents',
    content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
  },
  {
    selector: '.nav-pj',
    content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
  },
  {
    selector: '.nav-help',
    content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
  },
  {
    selector: '.nav-guide',
    content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
  }]);

  const disableBody = target => disableBodyScroll(target)
  const enableBody = target => enableBodyScroll(target)

  if (!signed) {
    return <Redirect to="/auth" />;
  }

  if (user.is_admin === true){
    return <Redirect to="/admin" />;
  }

  function storagedUser() {
    const storagedUser = localStorage.getItem("@Atlantico:user");
    setUser(JSON.parse(storagedUser));
  }
  
  function handleDrawerToggleMobile() {
    setOpenedDrawerMobile(!openedDrawerMobile);
  }

  function handleToggle(curr){
    if(screenW < 1024){
      if(curr >= 10){
        setDrawerTutorial(true);
      }
      if(curr < 10){
        setDrawerTutorial(false);
      }
    } else {
      return;
    }
  }

  async function DownloadTaskFile(documentId) {
    return await api.get(`v1/users/${user._id}/downloadTaskDocument?id=${documentId}`,
        {
          headers: {
            Authorization: localStorage.getItem('@Atlantico:token'),
          },
        }
      )
      .then((response) => {
        window.open(response.data.link);
      });
  }

  async function DownloadUploadedFile(path) {
    const formattedPath = path.replace('+', '%2B');
    return await api.get(`v1/clients/${primary._id}/downloadUrl?path=${formattedPath}&user_id=${user._id}`,
        {
          headers: {
            Authorization: localStorage.getItem('@Atlantico:token'),
          },
        }
      )
      .then((response) => {
        window.open(response.data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ext(string) {
    if (string !== undefined) {
      const str2 = string.split(".");
      return str2[str2.length - 1];
    }
  }

  if (!signed) {
    return <Redirect to="/auth" />;
  } else {  

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if(user.passwords){
        setAdminSteps([...commonSteps, 
        {
          selector: '.nav-people',
          content: 'Neste canal você poderá ter acesso ao sistema de gerenciamento de pessoas.'
        },
        {
          selector: '.nav-received-files',
          content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
        },
        {
          selector: '.nav-documents',
          content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
        },
        {
          selector: '.nav-invoices',
          content: 'Aqui você acompanha suas faturas tendo opção de emitir uma segunda via.'
        },
        {
          selector: '.nav-pj',
          content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
        },
        {
          selector: '.nav-contacts',
          content: 'Neste canal você poderá gerenciar os usuários e perfis de acesso a sua conta segura.'
        },
        {
          selector: '.nav-help',
          content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
        },
        {
          selector: '.nav-guide',
          content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
        }]);
    
        setFtaxSteps([...commonSteps, 
        {
          selector: '.nav-people',
          content: 'Neste canal você poderá ter acesso ao sistema de gerenciamento de pessoas.'
        },
        {
          selector: '.nav-received-files',
          content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
        },
        {
          selector: '.nav-documents',
          content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
        },
        {
          selector: '.nav-invoices',
          content: 'Aqui você acompanha suas faturas tendo opção de emitir uma segunda via.'
        },
        {
          selector: '.nav-pj',
          content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
        },
        {
          selector: '.nav-help',
          content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
        },
        {
          selector: '.nav-guide',
          content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
        }])
    
        setFinancSteps([...commonSteps, 
        {
          selector: '.nav-people',
          content: 'Neste canal você poderá ter acesso ao sistema de gerenciamento de pessoas.'
        },
        {
          selector: '.nav-received-files',
          content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
        },
        {
          selector: '.nav-documents',
          content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
        },
        {
          selector: '.nav-invoices',
          content: 'Aqui você acompanha suas faturas tendo opção de emitir uma segunda via.'
        },
        {
          selector: '.nav-pj',
          content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
        },
        {
          selector: '.nav-help',
          content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
        },
        {
          selector: '.nav-guide',
          content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
        }])
    
        setHrSteps([...commonSteps, 
        {
          selector: '.nav-people',
          content: 'Neste canal você poderá ter acesso ao sistema de gerenciamento de pessoas.'
        },
        {
          selector: '.nav-received-files',
          content: 'Neste menu você terá acesso a uma lista dos últimos arquivos recebidos em sua conta segura.'
        },
        {
          selector: '.nav-documents',
          content: 'Em Documentos você terá acesso a toda a raiz da sua nuvem armazenada em sua conta segura.'
        },
        {
          selector: '.nav-pj',
          content: 'Em Minha PJ você terá acesso a informações e documentos referentes a sua empresa.'
        },
        {
          selector: '.nav-help',
          content: 'Em ajuda você encontrará diversos vídeos tutoriais para você tirar quaisquer dúvidas sobre sua conta segura.'
        },
        {
          selector: '.nav-guide',
          content: 'Caso precise rever esses tópicos de ajuda mais tarde é só clicar aqui.'
        }])
      } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if(user.profile.includes('ADMIN')){
        setSteps(adminSteps);
      } else if (user.profile.includes('FINANC')){
        setSteps(ftaxSteps);
      } else if (user.profile.includes('FTAX')){
        setSteps(financSteps);
      } else if (user.profile.includes('HR')){
        setSteps(hrSteps);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminSteps, financSteps, ftaxSteps, hrSteps])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const interval = setInterval(async() => {
        await api.post("oauth/validateToken", {
          token: localStorage.getItem('@Atlantico:token'),
        })
        .then((response) => {
          if (response.data.valid) {
            return;
          } else {
            return (window.location.href = "/auth");
          }
        });
      return () => clearInterval(interval);
      }, 600000)
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const timer = setTimeout(async() => {
        await api.get(`v1/clients/${primary._id}/lastUploads?limit=12`,
          {
            headers: {
              Authorization: localStorage.getItem('@Atlantico:token'),
            },
          }
        )
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setLastUpload(response.data[0]);
          response.data.splice(0, 1);
          setLastUploads(response.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    
      }, 100)
      return () => clearTimeout(timer);
    }, [primary._id, primary.runrunit_projects, token, att])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const timer = setTimeout(async () => {
        await api.get(`v1/users/${user._id}/notifications?client_id=${primary._id}&limit=1&start=0&unread=true`,{ 
          headers: { 
            Authorization: localStorage.getItem('@Atlantico:token')
          } 
        })
        .then((response) => {
          if (response.data.length !== 0) {
            setHasNotification(true);
          } else {
            setHasNotification(false);
          }
        });
      }, 100)
      return () => clearTimeout(timer);
    }, [primary, token, user._id, setHasNotification, isOpened]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const interval = setInterval(async () => {
        await api.get(`v1/users/${user._id}/notifications?client_id=${primary._id}&limit=1&start=0&unread=true`,{ 
          headers: { 
            Authorization: localStorage.getItem('@Atlantico:token') 
          } 
        })
        .then((response) => {
          if (response.data.length !== 0) {
            setHasNotification(true);
          } else {
            setHasNotification(false);
          }
        });
        setCount(count + 1);
      }, 600000);
      return () => clearInterval(interval);
    },[count, primary._id, primary.runrunit_projects, setHasNotification, token, user._id]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const tutorial = localStorage.getItem("@Atlantico:tutorial");

      if(!tutorial){
        setIsSubModalVisible(true);
        setSubModalContent(<TutorialGuide />)
      } else {
        return;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const tutorial = localStorage.getItem("@Atlantico:tutorial");

      if(tutorial){
        const timer = setTimeout(async () => {
          await api.get(`v1/survey/nps/list?user=${user._id}`, {
            headers: {
              Authorization: localStorage.getItem('@Atlantico:token'),
            }
          })
          .then(response => {
            if(response.data.length !== 0){
              if(!response.data[0].active){
                return;
              } else {
                history.push({pathname:'/dashboard/nps', state: {modal: true, props: response.data[0]}})
              }
            }
          })
        }, 100)
        return () => clearTimeout(timer);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
      <>
        {isSubModalVisible && <SubModal onClose={() => setIsSubModalVisible(false)} />}
        <DashboardPage darkMode={isEnabled} className="container">
          {successDeleted && <ToastMessage variant="success" message="Objeto deletado com sucesso!"/>}
          <nav>
            <Hidden lgUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: "100%", height: "80%" } }}
                variant="temporary"
                anchor="bottom"
                open={openedDrawerMobile}
                onClose={handleDrawerToggleMobile}
                setOpenedDrawerMobile={setOpenedDrawerMobile}
              />
            </Hidden>

            {drawerTutorial ? <Navigator  PaperProps={{ style: { width: "100%", height: "80%" } }} anchor="bottom"/> : null}

            <Navigator className="sidebar-desktop" />
          </nav>

          <PageHeader darkMode={isEnabled}>
            {openedDrawerMobile ?  <FaArrowLeft onClick={handleDrawerToggleMobile} size={30} />
            :  <FaBars id="bars" onClick={handleDrawerToggleMobile} size={30} />}
            <img src={isEnabled ? logoDarkMode : logo} width="150" alt="Atlântico Digital" />
          </PageHeader>

          <div className="subheader-mobile">
            <h3>
              <strong style={{color: 'var(--text)'}}>Olá, {user.nickname}!</strong>{" "}
            </h3>

            <div id="icons">
              <Link id="icon-notification" to={{ pathname: "/dashboard/notificacoes", state: {modal: true} }} onClick={() => setIsOpened(false)}>
                {hasNotification && <FaCircle id="circle-notification"/>}
                <FaBell/>
              </Link>

              <Link id="icon-edit" to={{ pathname: "/dashboard/perfil", state: {modal: true} }} onClick={() => storagedUser()}>
                <FaPen />
              </Link>

              <a id="icon-logout" href="#!" onClick={() => {
                setIsSubModalVisible(true);
                setSubModalContent(<ConfirmLogout />)
              }}>
                <FaSignOutAlt />
              </a>
              
            </div>
          </div>
          

          <div id="button1" className="buttons">
            <Link to={{pathname: '/dashboard/envie-arquivos/uploads', state: {modal: true, props: 'uploads'}}}>
              <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" size="large" hollow={false} expansive={true}>
                <FaCloudUploadAlt size={35} /> <p>ENVIE ARQUIVOS</p>
              </Button>
            </Link>
          </div>
          <div id="button2" className="buttons">
            <Button bgcolor="var(--green)" color="#fff" border="var(--green)" hover="var(--green-hover)" size="large" hollow={false} expansive={true} onClick={() => window.open("https://wa.me/551130030506")} >
              <FaWhatsapp size={35} /> <p>CHAME NO WHATSAPP</p>
            </Button>
          </div>
          <div id="button3" className="buttons">
            <Link to={{pathname: '/dashboard/abrir-solicitacao', state: {modal: true}}}>
              <Button bgcolor="var(--orange)" color="#fff" border="var(--orange)" hover="var(--orange-hover)" size="large" hollow={false} expansive={true}>
                <FaHeadset size={35} /> <p>ABRA UMA SOLICITAÇÃO</p>
              </Button>
            </Link>
          </div>
          
          <div className="search" id="search">
            <form>
              <SearchInput id="client-search" placeholder="Digite o que procura aqui" />
            </form>
          </div>

          {search !== "" && (
            searchLoader ? (
              <div className="div-search">
                <div className="resultSet" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Loader/>
                </div>
              </div>
            ) : (
              result.length > 0 ? (
              <div className="div-search">
                <div className="resultSet">
                  <ul>
                      {result[0].docsFound.length > 0
                        ? result[0].docsFound.map((res) => 
                            <li key={res.id} onClick={() => {
                                DownloadTaskFile(res.id);
                                setSearch('');
                                setResult([]);
                            }}>
                              {res.data_file_name}
                            </li>
                          )
                        : result.map((res) => 
                            <li key={res.id}>
                              <Link to={{pathname: `/dashboard/busca/${res.id}`, state: {modal: true, props: res}}} onClick={() => {
                                setResult([])
                                setSearch('');
                              }}>
                                {res.title}
                              </Link>
                            </li>
                        )}
                  </ul>
                </div>
              </div>
              ) : (
                <div className="div-search">
                <div className="resultSet">
                  <p>Não encontramos resultados...</p>
                </div>
              </div>
              )
            )
          )}

          <div id="block1" className="blocks">
            <ContentBlock title="CAIXA DE ENTRADA">
              <ReceivedFiles style={{ whiteSpace: "nowrap", width: "12em", overflow: "hidden"}} />
            </ContentBlock>
          </div>
          <div id="block2" className="blocks">
            <ContentBlock title="MEUS ENVIOS">
              {loader ? <Loader /> : (
                <>
                  <div className="content-envios">
                    {lastUpload ? (
                      <>
                        <div className="envios_name">
                          <h2 style={{ cursor: "pointer" }} onClick={() => DownloadUploadedFile(lastUpload.path)} >
                            <strong>{lastUpload.fileName}</strong>{" "}
                          </h2>
                          <h5>Último arquivo enviado</h5>
                        </div>
                        <div className="info_arq">
                          <div style={{ color: "var(--purple)" }}>
                            <FaCloudUploadAlt size={30} style={{ margin: 0 }} />
                            <p>{lastUpload.uploaded_at_formatted}</p>
                          </div>
                          <div style={{ color: "var(--green)" }}>
                            <p className="texto" style={{ textTransform: "uppercase" }}>
                              TIPO
                              <br />
                              <strong>{ext(lastUpload.fileName)}</strong>
                            </p>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <Message message="Nenhum envio até o momento!"/>
                    )}
                  </div>
                  {lastUpload ? (
                    <List completeLine={true} dashboard>
                        {lastUploads.map((upload) => {
                          return (
                            <li key={upload.id}>
                              <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>

                                <div className="documents"  onClick={() => DownloadUploadedFile(upload.path)}>
                                  <FaFileAlt size={32} color='var(--file)'/>
                                    <div className="info">
                                      <h4>
                                        <strong style={{ color: 'var(--file)' }}>
                                          {upload.fileName}
                                        </strong>
                                      </h4>
                                      <span><small>{upload.uploaded_at_formatted}</small></span>
                                    </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </List>
                  ) : null}
                </>
              )}
            </ContentBlock>
          </div>
          <div id="block3" className="blocks">
            <ContentBlock title="MINHA PJ">
              <div className="name_pj">
                <h2>
                  <strong>{primary.name}</strong>
                </h2>
                <h5>{primary.document}</h5>
              </div>

              <div className="info_pj">
                <h5>
                  <FaInfo size={25} />
                  <p>
                    CÓDIGO DO CLIENTE ATLÂNTICO <br />
                    {primary.reference}
                  </p>
                </h5>
              </div>

              <Link style={{width: '100%', textDecoration: 'none'}} to={{pathname: "/dashboard/alternar-empresa", state: {modal: true}}}>
                <SeeMore text="Alternar Empresas" icon={<FaRandom />} />
              </Link>
            </ContentBlock>
          </div>
        </DashboardPage>

        <Tour
          steps={steps}
          isOpen={isTutorialActive}
          onRequestClose={() => {
            setIsTutorialActive(false);
            localStorage.setItem('@Atlantico:tutorial', false);
            setDrawerTutorial(false);
          }}
          onAfterOpen={screenW > 1024 && disableBody}
          onBeforeClose={screenW > 1024 && enableBody}
          goToStep={0}
          rounded={5}
          accentColor="var(--blue)"
          getCurrentStep={curr => handleToggle(curr)}
          scrollDuration={300}

        />
      </>
    );
  }
};
