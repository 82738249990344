export const addPhone = (phone, setPhone) => {
    setPhone([...phone, { label: "work", value: "", primary: false }]);
}

export const removePhone = (phone, setPhone, position) => {
    const updatedPhones = [...phone];
    updatedPhones.splice(position, 1);
    setPhone(updatedPhones);
}

export const setPhonesValue = (phone, setPhone, position, field, value) => {
    const updatedPhones = phone.map((phone, index) => {
      if (index === position) {
        return {
          ...phone,
          [field]: value,
        };
      }
      return phone;
    });
    setPhone(updatedPhones);
}