import React from "react";
import vSign from "../../assets/images/v-sign.svg";
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../components/Button";
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";

const People = () => {
  const {user} = useAuth();
  const {isEnabled} = useColorMode();
  return (
    <>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>
          <h2>BEM-VINDO AO SISTEMA DE GERENCIAMENTO DE PESSOAS</h2>
          <img src={vSign} alt="Sinal de V com a mão" />
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
        <form
          action="https://www.dominioatendimento.com:82/login"
          name="restrictForm"
          method="post"
          id="restrictForm"
          target="_blank"
        >
          <input
            type="hidden"
            id="dataLogin"
            name="j_username"
            value={user.passwords.dominio.user}
          />
          <input
            type="hidden"
            id="dataPass"
            name="j_password"
            value={user.passwords.dominio.pass}
          />
          <iframe
            style={{ display: "none" }}
            name="iframe"
            id="dominio"
            title="dominio"
          ></iframe>
          <Button
            type="submit"
            hollow={false}
            expansive={true}
            size="small"
            bgcolor="var(--blue)"
            color="white"
            hover="var(--blue-hover)"
            border="var(--blue)"
          >
            Entrar agora
          </Button>
        </form>
      </ModalButtons>
    </>
  );
};

export default People;
