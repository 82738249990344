import React from 'react';
import Button from '../../components/Button';
import ModalButtons from '../../components/ModalButtons';
import ModalContent from '../../components/ModalContent';
import { useAuth } from '../../context/contextAuth';
import { useSubModal } from '../../context/contextSubModal';
import { useTutorial } from '../../context/contextTutorial';

const TutorialGuide = () => {
    const {user} = useAuth();
    const {setIsSubModalVisible, setSubModalContent} = useSubModal();
    const {setIsTutorialActive} = useTutorial();

    const handleTutorial = (choice) => {
        setIsTutorialActive(choice);
        localStorage.setItem('@Atlantico:tutorial', choice);
        setIsSubModalVisible(false);
        setSubModalContent(null);
    }

    return (
        <>
            <ModalContent>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <h2 style={{margin: '1rem 0', color: 'var(--text)'}}>Boas vindas <strong>{user.nickname}</strong>!
                    Identificamos que este é seu primeiro acesso na nova plataforma do <strong>Atlântico Digital</strong>!
                    Gostaria de um guia rápido das ferramentas?</h2>
                </div>
            </ModalContent>
            <ModalButtons>
                <Button
                bgcolor="var(--blue)"
                color="#fff"
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={false}
                size="small"
                onClick={() => handleTutorial(true)}
            >
                SIM
            </Button>
            <Button
                color="var(--gray)"
                border="var(--gray)"
                expansive={true}
                hollow={true}
                size="small"
                onClick={() => handleTutorial(false)}
            >
                NÃO
            </Button>
            </ModalButtons>
        </>
    )
}

export default TutorialGuide;