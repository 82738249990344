export const validateField = (field, type, next, setError) => {
    if(type === 'number'){
        if(field !== 0){
            return next();
        } else {
            setError(true);
        }
    } else {
        if(field !== ''){
            return next();
        } else {
            setError(true);
        }
    }
       
}