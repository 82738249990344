import React, { useCallback, useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaArrowRight,
} from "react-icons/fa";
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  Grid,
  Typography,
  Link,
} from '@material-ui/core'

import api from "../../../../services/api";

const Municipio = (props) => {
  const token = localStorage.getItem("@Atlantico:token")
  const siglaEstado = props.siglaEstado
  
  const loadData = async ({ skip, limit, sort, filterValue }) => {
    let filter = { Filter: {SIGLAESTADO:siglaEstado} }

    if(filterValue){
      filterValue.map(item => {
        if(item.value){
          filter.Filter[item.name] = item.value
        }
      })
    }
  
    const params = {
      classe: "TnGemDCMunicipio",
      limit,
      start:skip,
      sort,
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.post(`v1/nweb${qs}`,filter,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return ({data: response.data.data, count: parseInt(response.data.records)})
    })
    .catch((err) => {
      console.error(err)
    })
  
  }

  const dataSource = useCallback(loadData, [])

  const filterValue = [
    { name: 'NOMEMUNIC', operator: 'startsWith', type: 'string', value: '' }
  ]

  const columns = [
    { name: 'CODIGOMUNIC', header: 'Cód.', maxWidth: 80},
    { name: 'SIGLAESTADO', header: 'Estado', maxWidth: 100},
    {
        name: 'NOMEMUNIC',
        header: 'Município',
        defaultFlex: 2,
        render: ({ data }) => {
        return <Link component="button" onClick={() => { 
            props.setMunicipio({
                [props.params[0]]: data.CODIGOMUNIC,
                [props.params[1]]: data.NOMEMUNIC,
            }) 

            props.setIsSubModalVisible(false)
            }}>{data.NOMEMUNIC}</Link>
        }
    },
    {
        name: 'OPTN',
        header: '',
        maxWidth: 80,
        render: ({ data }) => {
            return <Button size="small" color="default" variant="contained" onClick={() => { 
                props.setMunicipio({
                  [props.params[0]]: data.CODIGOMUNIC,
                  [props.params[1]]: data.NOMEMUNIC,
                }) 

                props.setIsSubModalVisible(false)
            }}><FaArrowRight /></Button>
        }
    }
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  return (
    <>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginBottom:"20px"}}>Selecione um município</Typography>
            
            <ReactDataGrid
              idProperty="CODIGOMUNIC"
              columns={columns}
              dataSource={dataSource}
              defaultLimit={200}
              defaultFilterValue={filterValue}
              pagination 
              theme="default-light" 
              defaultSortInfo={{ name: 'NOMEMUNIC', dir: 1, type: 'text' }}
              style={{ minHeight: 450 }}
              // onRenderRow={onRenderRow}
             />
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default Municipio;
