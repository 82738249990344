import React, { useState, useCallback, useEffect } from 'react';
import { FaPlayCircle, FaDownload, FaSearch, FaTrash, FaUser, FaEdit } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import List from '../../../components/List';
import { useColorMode } from '../../../context/contextColorMode';
import { CSVLink } from "react-csv";
import {DivLinkedClients, InputMU, Search} from './styles';
import api from '../../../services/api';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useRunrunit } from '../../../context/contextRunrunit';
import { useSubModal } from '../../../context/contextSubModal';
import { ConfirmDeleteTaskClient } from '../ConfirmDeleteTaskClient';

const RunrunitClients = ({task}) => {
    const {isEnabled} = useColorMode();
    const {att, setAtt} = useRunrunit();
    const {setIsSubModalVisible, setSubModalContent} = useSubModal();
    const token = localStorage.getItem('@Atlantico:token');
    const {users, teams} = useRunrunit();
    const [linkedClients, setLinkedClients] = useState([])
    const [loader, setLoader] = useState(true);
    const [linkedClientsNames, setLinkedClientsNames] = useState([]);
    const headers = [
        { label: "Referência", key: "reference" },
        { label: "Nome", key: "name" },
        { label: "Data", key: "date" },
        { label: "Mês", key: "month" },
        { label: "Responsável", key: "assignment" },
    ];

    useEffect(() => {
        setLinkedClientsNames([]);

        async function getData(){
            await api.get(`v1/linkedclients/${task._id}`, {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                setLinkedClients(response.data);
                console.log(response.data)
                response.data.map(res => setLinkedClientsNames(old => [...old, res.name]))
                setLoader(false);
            })
            .catch(err => {
                console.log(err);
                setLoader(false)
            })
        }

        getData()
    }, [att])

    async function addClient(client){
        setSearch('');
        setResults([]);

        await api.post(`v1/linkedclients/${client}/${task._id}`, {
            date: null,
            month: null,
            assignment: null,
            team_id: null,
        }, {
            headers: {
                Authorization: token
            }
        })
        .then(() => setAtt(!att))
        .catch(err => console.log(err))
    }

    async function updateClient(assignment, date, month, client, team_id){
        await api.put(`v1/linkedclients/${client}/${task._id}`, {
            assignment,
            date,
            month: month ? month : '-',
            team_id,
        }, {
            headers: {
                Authorization: token
            }
        }).then(() => {})
        .catch(err => console.log(err))
    }


    async function updateClientDateRunrunit(newValue, position, assignment, month, client, team_id){
        const updatedLinkedClients = linkedClients.map((client, index) => {
            if(index === position){
                return {...client, date: newValue}
            }
            return client
        })

        setLinkedClients(updatedLinkedClients)

        await updateClient(assignment, newValue, month, client, team_id)
    }

    async function updateClientAssignmentRunrunit(newValue, position, date, month, client, team_id){
        const updatedLinkedCLients = linkedClients.map((client, index) => {
            if(index === position){
                console.log({...client, assignment: newValue})
                return {...client, assignment: newValue}
            }
            return client;
        })

        setLinkedClients(updatedLinkedCLients);

        await updateClient(newValue, date, month, client, team_id)
    }

    async function updateClientTeamRunrunit(newValue, position, date, month, client, assignment){
        const updatedLinkedCLients = linkedClients.map((client, index) => {
            if(index === position){
                console.log({...client, team_id: newValue})
                return {...client, team_id: newValue}
            }
            return client;
        })

        setLinkedClients(updatedLinkedCLients);

        await updateClient(assignment, date, month, client, newValue)
    }

    async function updateClientMonthRunrunit(newValue, position, assignment, date, client, team_id){
        const updatedLinkedCLients = linkedClients.map((client, index) => {
            if(index === position){
                return {...client, month: newValue}
            }
            return client;
        })

        setLinkedClients(updatedLinkedCLients);

        await updateClient(assignment, date, newValue, client, team_id)
    }

    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [searchLoader, setSearchLoader] = useState(false);

    var orderedClients = linkedClients.sort((a,b)=> (a.reference > b.reference ? 1 : -1))

    const debouncedSave = useCallback(
        debounce((newValue) => {
            setSearchLoader(true);
            setResults([]);
            if(newValue.match(/^[0-9]+$/)){ 
                api.get(`v1/clients?limit=5&skip=0&reference=${newValue}`, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then((response) => {
                    setSearchLoader(false);
                    setResults(response.data);
                })
            }else{
                api.get(`v1/clients?limit=5&skip=0&name__regex=/${newValue}/i`, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then((response) => {
                    setSearchLoader(false);
                    setResults(response.data);
                })
            }
        }, 1000), []
    );
    
    const updateValue = (newValue) => {
        setSearch(newValue);
        debouncedSave(newValue);
    };

    return (
        <DivLinkedClients>
            
            <div className="buttons">
                <CSVLink data={linkedClients} headers={headers} filename={`clientes_vinculados_${task.name}.csv`}>
                    <FaDownload style={{cursor: 'pointer', marginRight: '2rem'}} />
                </CSVLink>
                <Link to={{pathname: "/admin/clientes-tarefas", state: {modal: true, props: task}}} > 
                    <FaPlayCircle size={18} color='var(--blue)' style={{cursor: 'pointer', marginRight: '2rem'}}/>
                </Link>
                <Link to={{pathname: "/admin/atualizar-tarefa", state: {modal: true, props: task}}}>
                    <FaEdit size={18} color='var(--blue)' style={{cursor: 'pointer', marginRight: '2rem'}}/>
                </Link>
                <FaUser style={{marginRight: '0.5rem'}}/>
                <h4><strong>{linkedClients.length}</strong></h4>
            </div>

            <Search>
            <div className="search">
            <form>     
                <InputMU className="input-block" darkMode={isEnabled}>
                    <FaSearch size={18} />
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        <input
                        type="text"
                        id="search-box"
                        autoComplete="off"
                        placeholder="Pesquisar clientes"
                        value={search} onChange={(e) => updateValue(e.target.value)}
                        />
                    </form>
                </InputMU>
            </form>
          </div>

            {search !== "" && (
                searchLoader ? (
                    <div className="div-search">
                        <div className="resultSet" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Loader/>
                        </div>
                    </div>
                ) : (
                    results.filter(res => !linkedClientsNames.includes(res.name)).length > 0 ? (
                        <div className="div-search">
                            <div className="resultSet">
                                <ul>
                                {results.filter(res => !linkedClientsNames.includes(res.name)).map((res) => <li key={res._id} style={{cursor: 'pointer'}} onClick={() => addClient(res._id)}>{res.name}</li>)}
                                </ul>
                            </div>
                        </div>
                    ) : (

                        results.length > 0 ? (
                            <div className="div-search">
                                <div className="resultSet">
                                    <p>Cliente já vinculado a esta tarefa.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="div-search">
                                <div className="resultSet">
                                    <p>Não encontramos resultados...</p>
                                </div>
                            </div>
                        )
                        
                    )
                )
            )}
            </Search>

            {loader ? <Loader /> : (
                orderedClients.length >  0 ? (
                    <List completeLine>
                        <table className='linkedClientsTable'>
                            <colgroup>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "30%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "10%"}}/>
                                <col style={{width: "20%"}}/>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "5%"}}/>
                            </colgroup>  
                            <tr>
                                <th>REF.</th>
                                <th>NOME</th>
                                <th>DATA</th>
                                <th>MÊS</th>
                                <th>RESPONSÁVEL</th>
                                <th>TIME</th>
                                <th></th>
                            </tr>
                            {orderedClients.map((client, index) => (
                                <tr>
                                    <td>{client.reference}</td>
                                    <td>{client.name}</td>
                                    <td><input type="number" min="0" max="31" value={client.date ? (client.date === 'NULL' ? 0 : client.date) : 0} onChange={(e) => updateClientDateRunrunit(e.target.value, index, client.assignment, client.month ? client.month : '-', client._id, client.team_id)}/></td>
                                    <td><input type="text" value={client.month ? client.month : '-'} onChange={(e) => updateClientMonthRunrunit(e.target.value, index, client.assignment, client.date, client._id, client.team_id)}/></td>
                                    <td>
                                    <select value={client.assignment ? (client.assignment === 'NULL' ? "" : client.assignment) : ""} style={{ color: 'var(--text)' }} onChange={e=> updateClientAssignmentRunrunit(e.target.value, index, client.date, client.month ? client.month : '-', client._id, client.team_id)}>
                                        <option value="" key="0">Nenhum</option>
                                        {users.map(user => <option value={user.id} key={user.id}>{user.name}</option>)}
                                        {!users.find(user => user.id == client.assignment) && <option value={client.assignment} key={client.assignment}>{client.assignment}</option>}
                                    </select>
                                    </td>
                                    <td>
                                    <select value={client.team_id ? client.team_id : ""} style={{ color: 'var(--text)' }} onChange={e=> updateClientTeamRunrunit(e.target.value, index, client.date, client.month ? client.month : '-', client._id, client.assignment)}>
                                        <option value="" key="0">Nenhum</option>
                                        {teams.map(team => <option value={team.id} key={team.id}>{team.name}</option>)}
                                        {!teams.find(team => team.id == client.team_id) && <option value={client.team_id} key={client.team_id}>{client.team_id}</option>}
                                    </select>
                                    </td>
                                    <td style={{textAlign: 'center'}} onClick={() => {
                                        setIsSubModalVisible(true);
                                        setSubModalContent(<ConfirmDeleteTaskClient client={client} task={task}/>)
                                    }}><FaTrash color="var(--blue)" style={{cursor: 'pointer'}}/></td>
                                </tr>
                            ))}
                        </table>
                    </List>
                ) : (
                    <Message severity="warning" message="Sem clientes vinculados à essa tarefa!" />
                )
            )}

        </DivLinkedClients>
    )
}

export default RunrunitClients;