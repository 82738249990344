import styled from "styled-components";

export const DivNewFolder = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    color: var(--blue);
  }
  form {
    width: 100%;
    .newfolder-input {
      position: relative;
      display: flex;
      width: 100%;

      input {
        width: 100%;
        height: var(--small);
        margin-top: 0.8rem;
        border-radius: 1rem;
        outline: 0;
        padding: 1rem;
        color: #aaa69e;
        border: 1px solid black;
      }

      & + .input-block {
        margin-top: 0.5rem;
      }
    }
  }
`;

export const DivFileOptions = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    color: var(--blue);
  }
  h3 {
    margin-bottom: 2rem;
    text-align: center;
  }
`;
