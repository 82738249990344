import React, { useState } from "react";
import '@inovua/reactdatagrid-community/index.css'
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Paper,
  Divider,
  TextField,
} from '@material-ui/core'
import InputMask from 'react-input-mask';
import { validarCPF } from "../../../../utils/validaCPF";
import { isValidDate } from "../../../../utils/isValidDate";

const Dependent = (props) => {
  const index = props.rowToEdit
  const [state, setState] = useState({
    NOMEDEPEND: index!=null ? props.data.employee.DEPENDENTES[index].NOMEDEPEND : "",
    CPFDEPEND: index!=null ? props.data.employee.DEPENDENTES[index].CPFDEPEND : "",
    DATANASC: index!=null ? props.data.employee.DEPENDENTES[index].DATANASC : "",
    PARENTESCO: index!=null ? props.data.employee.DEPENDENTES[index].PARENTESCO : "",
    SEXO: index!=null ? props.data.employee.DEPENDENTES[index].SEXO : "",
    ESTADOCIVIL: index!=null ? props.data.employee.DEPENDENTES[index].ESTADOCIVIL : "",
    GRAUINSTR: index!=null ? props.data.employee.DEPENDENTES[index].GRAUINSTR : "",
    FORMACONTROLEIR: index!=null ? props.data.employee.DEPENDENTES[index].FORMACONTROLEIR : "",
    NACIONALIDADE: index!=null ? props.data.employee.DEPENDENTES[index].NACIONALIDADE : "",
    FUMANTE: "0",
    CONSOMEALCOOL: "0",
  })
  const [required,setRequired] = useState({
    message:"",
    state:false,
  })

  const validaCPF = (e) => {

    if(!validarCPF(e.target.value)){
      setRequired({
        message:"O CPF informado é inválido, por favor, confira o número informado.",
        state:true,
      })
    }

  }

  const onSubmit = (e) => {
    e.preventDefault()
    
    const requiredFields = e.target.querySelectorAll("[required]")

    setRequired({
      message:"",
      state:false,
    })

    let validate = Array.from(requiredFields).every((element,index) => {

      if(!element.value){
        const label = e.target.querySelector(`#${element.getAttribute("id")}-label`)

        setRequired({
          state:true,
          message: <div>O campo <b>{label.innerText.replace("*","")}</b>é obrigatório.</div>,
        })

        element.focus()

        return false
      }

      return true
    })

    if(validate){
      
      if(!validarCPF(e.target.CPFDEPEND.value)){
        setRequired({
          message:<div>O <b>CPF</b> informado é inválido, por favor, confira o número informado.</div>,
          state:true,
        })

        return false
      }
      
      if(!isValidDate(e.target.DATANASC.value)){
        setRequired({
          message:<div>O formato da <b>Data de Nascimento</b> está incorreto.</div>,
          state:true,
        })

        return false
      }

      if(index!=null){
  
        props.data.employee.DEPENDENTES[index] = {
          NOMEDEPEND: e.target.NOMEDEPEND.value,
          CPFDEPEND: e.target.CPFDEPEND.value,
          DATANASC: e.target.DATANASC.value,
          PARENTESCO: e.target.PARENTESCO.value,
          SEXO: e.target.SEXO.value,
          ESTADOCIVIL: e.target.ESTADOCIVIL.value,
          GRAUINSTR: e.target.GRAUINSTR.value,
          FORMACONTROLEIR: e.target.FORMACONTROLEIR.value,
          NACIONALIDADE: e.target.NACIONALIDADE.value,
          FUMANTE: "0",
          CONSOMEALCOOL: "0",
        }
  
      }else{
  
        props.data.employee.DEPENDENTES.push({
          NOMEDEPEND: e.target.NOMEDEPEND.value,
          CPFDEPEND: e.target.CPFDEPEND.value,
          DATANASC: e.target.DATANASC.value,
          PARENTESCO: e.target.PARENTESCO.value,
          SEXO: e.target.SEXO.value,
          ESTADOCIVIL: e.target.ESTADOCIVIL.value,
          GRAUINSTR: e.target.GRAUINSTR.value,
          FORMACONTROLEIR: e.target.FORMACONTROLEIR.value,
          NACIONALIDADE: e.target.NACIONALIDADE.value,
          FUMANTE: "0",
          CONSOMEALCOOL: "0",
        })
  
      }
      
      props.setIsSubModalVisible(false)

    }

  }

  return (
    <>
            
    <form noValidate onSubmit={onSubmit}>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginBottom:"20px"}}>Dependente</Typography>

              <Grid container spacing={2}>

                <Grid item xs={12}>
                    <TextField fullWidth required label="Nome" id="NOMEDEPEND" defaultValue={state.NOMEDEPEND} name="NOMEDEPEND" inputProps={{ maxLength: 70 }} />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <InputMask
                    mask="999.999.999-99"
                    maskChar={null}
                    required
                    defaultValue={state.CPFDEPEND}
                    placeholder="000.000.000-00"
                    onBlur={ validaCPF }
                    >
                      {(inputProps) => <TextField {...inputProps} fullWidth id="CPFDEPEND" label="CPF" aria-label="CPF" name="CPFDEPEND" inputProps={{ maxLength: 14 }} />}
                    </InputMask>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <TextField fullWidth required label="Data de Nascimento" id="DATANASC" defaultValue={state.DATANASC} name="DATANASC" inputProps={{ maxLength: 10 }} type="date" InputLabelProps={{ shrink: true }} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required label="Parentesco" id="PARENTESCO" defaultValue={state.PARENTESCO} name="PARENTESCO" SelectProps={{native: true}}>
                      <option value=""></option>
                      <option value="14">Outros</option>
                      <option value="21">Menor pobre, até 21 (vinte e um) anos, que crie e eduque e do qual detenha a guarda judicial</option>
                      <option value="15">Menor Sob Tutela</option>
                      <option value="18">Filho(a) ou enteado(a) em qualquer idade, quando incapacitado física e/ou mentalmente para o trabalho</option>
                      <option value="17">Filho(a) ou enteado(a) universitário(a) ou cursando escola técnica de 2º grau, até 24 (vinte e quatro) anos</option>
                      <option value="13">Filho(a) Adotivo(a)</option>
                      <option value="20">Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial, em qualquer idade, quando incapacitado física e/ou mentalmente para o trabalho</option>
                      <option value="19">Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, com idade até 24 anos, se ainda estiver cursando estabelecimento de nível superior ou escola técnica de 2º grau, desde que tenha detido sua guarda judicial até os 21 anos</option>
                      <option value="12">Irmão/Irmã</option>
                      <option value="11">Enteado/Enteada</option>
                      <option value="10">Genro/Nora</option>
                      <option value="09">Sogro/Sogra</option>
                      <option value="08">Neto/Neta</option>
                      <option value="07">Tio/Tia</option>
                      <option value="06">Sobrinho/Sobrinha</option>
                      <option value="05">Bisavô/Bisavó</option>
                      <option value="04">Avô/Avó</option>
                      <option value="03">Pai/Mãe</option>
                      <option value="16">Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos</option>
                      <option value="22">Ex-cônjuge que Receba Pensão de Alimentos</option>
                      <option value="02">Cônjuge</option>
                      <option value="01">Filho/Filha</option>
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required id="SEXO" label="Sexo" defaultValue={state.SEXO} name="SEXO" SelectProps={{native: true}}>
                      <option value=""></option>
                      <option value="1">Masculino</option>
                      <option value="2">Feminino</option>
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required id="ESTADOCIVIL" label="Estado Civil" defaultValue={state.ESTADOCIVIL} name="ESTADOCIVIL" SelectProps={{native: true}}>
                      <option value=""></option>
                      <option value="1">Solteiro</option>
                      <option value="2">Casado</option>
                      <option value="7">Casado - Comunhão Total de Bens</option>
                      <option value="8">Casado - Sem Comunhão de Bens</option>
                      <option value="9">Casado - Comunhão Parcial de Bens</option>
                      <option value="3">Divorciado ou Separado Judicialmente</option>
                      <option value="4">Viúvo</option>
                      <option value="5">Concubinato</option>
                      <option value="6">Outros</option>
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required id="GRAUINSTR" label="Grau de instrução" defaultValue={state.GRAUINSTR} name="GRAUINSTR" SelectProps={{native: true}}>
                      <option value=""></option>
                      <option value="13">Ph. D.</option>
                      <option value="12">Doutorado completo</option>
                      <option value="11">Mestrado completo</option>
                      <option value="10">Especialização</option>
                      <option value="9">Educação Superior completa</option>
                      <option value="8">Educação Superior incompleta</option>
                      <option value="7">Ensino médio completo</option>
                      <option value="6">Ensino médio incompleto</option>
                      <option value="5">Ensino fundamental completo</option>
                      <option value="4">Do 6º ao 9º ano do Ensino Fundamental incompleto</option>
                      <option value="3">5º ano completo do Ensino Fundamental</option>
                      <option value="2">Até o 5º ano incompleto do Ensino Fundamental</option>
                      <option value="1">Analfabeto</option>
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required id="FORMACONTROLEIR" label="Forma de Controle do Imposto de Renda" defaultValue={state.FORMACONTROLEIR} name="FORMACONTROLEIR" SelectProps={{native: true}}>
                      <option value=""></option>
                      <option value="2">Por Ano</option>
                      <option value="1">Por Mês</option>
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required id="NACIONALIDADE" label="Nacionalidade" defaultValue={state.NACIONALIDADE} name="NACIONALIDADE" SelectProps={{native: true}}>
                      <option value=""></option>
                      <option value="10">Brasileiro</option>
                      <option value="15">Brasileiro nascido no exterior</option>
                      <option value="20">Naturalizado brasileiro</option>
                      <option value="21">Argentino</option>
                      <option value="22">Boliviano</option>
                      <option value="23">Chileno</option>
                      <option value="24">Paraguaio</option>
                      <option value="25">Uruguaio</option>
                      <option value="26">Venezuelano</option>
                      <option value="27">Colombiano</option>
                      <option value="28">Peruano</option>
                      <option value="29">Equatoriano</option>
                      <option value="30">Alemão</option>
                      <option value="31">Belga</option>
                      <option value="32">Britânico</option>
                      <option value="34">Canadense</option>
                      <option value="35">Espanhol</option>
                      <option value="36">Norte-americano(EUA)</option>
                      <option value="37">Francês</option>
                      <option value="38">Suíço</option>
                      <option value="39">Italiano</option>
                      <option value="40">Haitiano</option>
                      <option value="41">Japonês</option>
                      <option value="42">Chinês</option>
                      <option value="43">Coreano</option>
                      <option value="44">Russo</option>
                      <option value="45">Português</option>
                      <option value="46">Paquistanês</option>
                      <option value="47">Indiano</option>
                      <option value="48">Outros latino-americanos</option>
                      <option value="49">Outros asiáticos</option>
                      <option value="50">Outros</option>
                      <option value="51">Outros Europeus</option>
                      <option value="60">Angolano</option>
                      <option value="61">Congolês</option>
                      <option value="62">Sul-Africano</option>
                      <option value="70">Outros Africanos</option>
                  </TextField>
                </Grid>

              </Grid>
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons>
        <Divider style={{marginTop:10}} />

        {required.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#FC5757",backgroundColor:"#FEDDDE"}}>{required.message}</Paper>}

        <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
            <Button onClick={() => { props.setIsSubModalVisible(false) }}>Voltar</Button>
            <Button color="secondary" type="submit">{index!=null ? "Alterar" : "Incluir"}</Button>
        </ButtonGroup>
      </ModalButtons>

      </form>
    </>
  );
};

export default Dependent;
