import styled from "styled-components";

export const FormContact = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-bottom: 2rem;
    color: var(--text);
  }

  select,
  input {
    border: 0;
    border-bottom: 1px solid var(--text);
    background-color: var(--modal);
    color: var(--text);
    width: 100%;
    padding: 1rem 0;
    font-size: 1.5rem;
  }

  .checkbox-terms {
    margin-top: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: var(--modal);
    input {
      width: auto;
      margin-right: 1rem;
    }
  }

  @media (min-width: 700px) {
    padding: 5rem 0;
    select,
    input {
      font-size: 1.5rem;
    }
  }
  @media (max-height: 700px) {
    p {
      font-size: 0.8rem;
    }
  }
`;

export const CEPData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 2rem;
  background-color: var(--background);
  border-radius: 1rem;
  h3,h4{
    color: var(--text);
  }
`;
