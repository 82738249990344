import React from "react";
import { DivModal } from "./styles";
import { FaTimes } from "react-icons/fa";
import { useColorMode } from "../../context/contextColorMode";
import { useHistory, withRouter } from "react-router-dom";
import { useAuth } from "../../context/contextAuth";

const Modal = ({ hasFooter, title, content, id = "modal", noBackground }) => {
  const {user} = useAuth();
  const history = useHistory();

  const closeModal = () => {
    if(user.is_admin){
      history.push('/admin')
    } else {
      history.push('/dashboard')
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === id) closeModal();
  };

  const {isEnabled} = useColorMode();

  return (
    <DivModal darkMode={isEnabled} id={id} onClick={handleOutsideClick} hasFooter={hasFooter} noBackground={noBackground}>
      <div className="container">
        <div className="header">
          <h2 className="title">{title}</h2>
          <button className="close" onClick={closeModal}>
            <FaTimes size={25} />
          </button>
        </div>
        <div className="content">{content}</div>
      </div>
    </DivModal>
  );
};

export default withRouter(Modal);
