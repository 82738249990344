import styled from "styled-components";

export const DivManagementPeople = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 2rem 0;
    color: var(--text);
  }
`;
