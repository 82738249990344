import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FaCloudUploadAlt, FaPlusSquare, FaSearch } from 'react-icons/fa';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import {SeeMore} from '../../../components/SeeMore';
import List from '../../../components/List';
import { formatDate } from '../../../utils/formatDate';
import { useColorMode } from '../../../context/contextColorMode';
import {useAuth} from '../../../context/contextAuth';

import {DivNps, Search} from './styles';

import api from '../../../services/api';
import { Link } from 'react-router-dom';

export const NpsAdmin = () => {
    const {isEnabled} = useColorMode();
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(false);
    const [surveys, setSurveys] = useState([]);
    const [search, setSearch] = useState('');
    const {user} = useAuth();
    const token = localStorage.getItem('@Atlantico:token');
 
    useEffect(() => {
        async function getData(){
            await api.get(`v1/survey/nps?limit=${limit}&sort=-created_at`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setSurveys(response.data);
            })
            .catch(err => {
                setLoader(false);
                console.log(err);
            })
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function getData(){
           await api.get(`v1/survey/nps?limit=${limit}&sort=-created_at`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(response => {
                setSurveys(response.data);
            })
            .catch(err => {
                console.log(err);
            })
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit]);

    const handleChange = _.debounce((e, text) => {
        setSearch(text);
        if(text !== ''){
            api.get(`v1/survey/nps/search?key=${text}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setSurveys(response.data);
            })
        } else {
            api.get(`v1/survey/nps?limit=${limit}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false)
                setSurveys(response.data);
            })
            .catch(err => {
                setLoader(false)
                console.log(err);
            })
        }
        
    }, 1000);

    return (
        <DivNps isAdmin={user.profile.includes('ADMIN')}>
            <div className="button-and-search">
                {user.profile.includes('ADMIN') && (
                    <Link style={{textDecoration: 'none'}} to={{pathname: `/admin/salvar-pesquisa`, state: {modal: true}}}>
                        <Button id="button" bgcolor="var(--blue)" color="white" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="medium"  noMargin={true}>
                            <FaCloudUploadAlt size={25} /> Nova Pesquisa
                        </Button>
                    </Link>
                )}

                <Search darkMode={isEnabled} id="search">
                    <FaSearch size={18} />
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        <input
                            type="text"
                            placeholder="Procure uma pesquisa aqui"
                            onChange={(e) => handleChange(e.preventDefault, e.target.value)}
                        />
                    </form>
                </Search>
            </div>
            <hr/>
            {loader ? <Loader /> : (
                <div className="survey-list">
                    <h3>Últimas pesquisas</h3>
                    {surveys.length === 0 ? <Message severity="warning" message="Não existem últimas pesquisas!"/> : (
                        <List completeLine> 
                            {surveys.map((sv, index) => {
                                return (
                                    <li key={index} className="item">
                                        <Link to={{pathname: '/admin/nps-detalhes', state: {modal: true, props: sv}}}>
                                            <div className="info">
                                                <h4><strong>{sv.title}</strong></h4>
                                                <h5>Criado em {formatDate(sv.created_at)[0]} às {formatDate(sv.created_at)[1]}</h5>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            })}
                            {search === '' || surveys.length === 0 ? (
                                <SeeMore text="Ver Mais" icon={<FaPlusSquare />} onClick={() => setLimit(limit + 10)}/>
                            ) : null}
                        </List>
                    )}
                   
                </div>
            )}
            
        </DivNps>
    )
}