import { Grid, TextField, Divider, Card, CardContent, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useSubModal } from "../../../../context/contextSubModal";
import Municipio from "../submodals/municipio";
import InputMask from 'react-input-mask';

const Address = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [municipio,setMunicipio] = useState({
        CODIGOMUNIC: props.data.employee.CODIGOMUNIC || "",
        NOMEMUNIC: props.data.employee.NOMEMUNIC || "",
    })
    const [estado,setEstado] = useState("")

    const handleChangeUF = (e) => {

        setMunicipio({
            CODIGOMUNIC: "",
            NOMEMUNIC: "",
        })

        setEstado(e.target.value)

        props.handleChange(e)

    }

    useEffect(() => {
        
        props.handleChange(null,municipio)

    }, [municipio])

    return <Grid container spacing={2}>

        <Grid item xs={12} lg={4}>
            <TextField select fullWidth label="Tipo do endereço" defaultValue={props.data.employee.CODIGOTIPOLOGRAD} name="CODIGOTIPOLOGRAD" SelectProps={{native: true}} onChange={ props.handleChange }>
                <option value=""></option>
                <option value="4">Avenida</option>
                <option value="33">Rua</option>
            </TextField>
        </Grid>

        <Grid item xs={12} lg={8}>
            <TextField fullWidth label="Endereço" defaultValue={props.data.employee.ENDERFUNC} name="ENDERFUNC" inputProps={{ maxLength: 40 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={4}>
            <TextField fullWidth label="Número" type="number" defaultValue={props.data.employee.NUMEROENDER} name="NUMEROENDER" inputProps={{ maxLength: 7 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={8}>
            <TextField fullWidth label="Complemento" defaultValue={props.data.employee.COMPLENDER} name="COMPLENDER" inputProps={{ maxLength: 20 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={4}>
            <InputMask
            mask="99999-999"
            maskChar={null}
            defaultValue={props.data.employee.CEPFUNC}
            placeholder="00000-000"
            onChange={ props.handleChange }
            >
            {(inputProps) => <TextField {...inputProps} fullWidth id="CEP" label="CEP"  name="CEPFUNC" inputProps={{ maxLength: 10 }} />}
            </InputMask>
        </Grid>

        <Grid item xs={12} lg={4}>
            <TextField fullWidth label="Bairro" defaultValue={props.data.employee.BAIRROFUNC} name="BAIRROFUNC" inputProps={{ maxLength: 30 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={4}>
            <TextField fullWidth label="Código postal" defaultValue={props.data.employee.CODIGOPOSTAL} name="CODIGOPOSTAL" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={6}>
            <TextField select fullWidth label="UF" defaultValue={props.data.employee.SIGLAESTADO} name="SIGLAESTADO" onChange={handleChangeUF} SelectProps={{native: true}}>
                <option value=""></option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AM">AM</option>
                <option value="AP">AP</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="EX">EX</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MG">MG</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="PR">PR</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RS">RS</option>
                <option value="SC">SC</option>
                <option value="SE">SE</option>
                <option value="SP">SP</option>
                <option value="TO">TO</option>
            </TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
            <input
                name="CODIGOMUNIC"
                value={municipio.CODIGOMUNIC}
                hidden="true"
            />
            <TextField
                style={{cursor:"pointer"}}
                fullWidth
                label="Município"
                value={municipio.NOMEMUNIC}
                name="NOMEMUNIC"
                inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                onFocus={() => {
                    setIsSubModalVisible(true);
                    setSubModalContent(<Municipio setMunicipio={setMunicipio} params={["CODIGOMUNIC","NOMEMUNIC"]} municipio={municipio} siglaEstado={estado} setIsSubModalVisible={setIsSubModalVisible} />)
                }}
            />
        </Grid>

        <Grid item xs={12} lg={12}>
            <TextField fullWidth label="E-mail" defaultValue={props.data.employee.ENDERELETR} name="ENDERELETR" inputProps={{ maxLength: 100 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={6}>
            <TextField fullWidth label="Telefone" defaultValue={props.data.employee.NUMEROFONE} name="NUMEROFONE" inputProps={{ maxLength: 100 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={6}>
            <TextField fullWidth label="Celular" defaultValue={props.data.employee.NUMEROCELULAR} name="NUMEROCELULAR" inputProps={{ maxLength: 100 }} onChange={ props.handleChange } />
        </Grid>

    </Grid>

}

export default Address