import { Grid, TextField, Divider, Card, CardContent, Typography, Button, ButtonGroup } from "@material-ui/core";
import React, { useState } from "react";

import Address from './personal/address'
import Data from './personal/data'
import Documents from './personal/documents'
import Foreign from './personal/foreign'

const Personal = (props) => {
    const [tab, setTab] = useState("address")

    return <Grid container spacing={1}>

        <Grid item xs={12} lg={12}>
            <ButtonGroup variant="outlined" size="small" style={{marginTop:10}} fullWidth>
                <Button color={tab=="address"?"primary":"default"} onClick={()=>{setTab("address")}}>Endereço</Button>
                <Button color={tab=="data"?"primary":"default"} onClick={()=>{setTab("data")}}>Dados</Button>
                <Button color={tab=="documents"?"primary":"default"} onClick={()=>{setTab("documents")}}>Documentos</Button>
                <Button color={tab=="foreign"?"primary":"default"} onClick={()=>{setTab("foreign")}}>Estrangeiro</Button>
            </ButtonGroup>
        </Grid>
        
       <Grid item xs={12}>
        {tab==="address"&&<Address data={{employee:props.data.employee}} handleChange={ props.handleChange } />}
        {tab==="data"&&<Data data={{employee:props.data.employee}} handleChange={ props.handleChange } />}
        {tab==="documents"&&<Documents data={{employee:props.data.employee}} handleChange={ props.handleChange } />}
        {tab==="foreign"&&<Foreign data={{employee:props.data.employee}} handleChange={ props.handleChange } />}
       </Grid>

    </Grid>

}

export default Personal