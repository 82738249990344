import styled from 'styled-components';

export const FormContact = styled.form`
  .twoFields{
    width: 100%;
    align-items: center;
  }

  .field{
    width: 100%;
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;

    label{
      margin-right: 1rem;
      width: 30%;
    }

    input, select{
      border: 0;
      border-bottom: 1px solid var(--text);
      background-color: var(--modal);
      color: var(--text);
      width: 65%;
      padding: 0.5rem 0;
    }
  }

  @media(min-width: 700px){
    .twoFields{
      display: flex;
    }
    .field{
      padding: 1rem;

      label{
        margin-right: 1rem;
        width: 10rem;
      }

      input, select{
        width: 100%;
      }
    }
  }
`;

export const ListClients = styled.ul`
  width: 100%;
  list-style: none;
  max-height: 8em;
  overflow: auto;

  li{
    width: 99%;
  }

  hr {
    border-color: ${props => props.darkMode ? '#3d3d3d' : '#BFE6FF'};
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @media(min-width: 700px){
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
  
`