import React from 'react';
import Button from '../../../components/Button';
import List from '../../../components/List';
import { useSubModal } from '../../../context/contextSubModal';

import {Div} from './styles';

const InsatisfiedClients = ({users, title}) => {

    const {setIsSubModalVisible, setSubModalContent} = useSubModal();

    return (
        <Div>
            <h1>{title}</h1>
            <List>
                {users.map(user => (
                    <li className="item">
                        <div className="info">
                        <h4><strong>{user.user} - {user.reference}</strong></h4>
                        <h5>{user.client}</h5>
                        </div>
                    </li>
                ))}
            </List>
            <Button 
                color="#fff"
                border="var(--blue)"
                bgcolor="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                size="small"
                onClick={() => {
                    setIsSubModalVisible(false);
                    setSubModalContent(null);
                }}
            >
                Fechar
            </Button>
        </Div>
    )
}   

export default InsatisfiedClients;