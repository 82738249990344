import React, { useEffect, useState } from 'react';
import List from '../../../components/List';
import Loader from '../../../components/Loader';
import api from '../../../services/api';

export const DetailGraphic = ({name, type, filter, startAt, endAt}) => {
    const token = localStorage.getItem('@Atlantico:token');
    const [satisfaction, setSatisfaction] = useState(null);
    const [loader, setLoader] = useState(true);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        if(name === 'Muito Insatisfeito'){
            setSatisfaction('very_dissatisfied'); 
        } else if (name === 'Insatisfeito'){
            setSatisfaction('dissatisfied'); 
        } else if (name === 'Neutro') {
            setSatisfaction('neutral');
        } else if (name === 'Satisfeito'){
            setSatisfaction('satisfied');
        } else if (name === 'Muito Satisfeito'){
            setSatisfaction('very_satisfied');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function getData(){
            await api.get(`v1/survey/csattickets?type=${type}&filter=${filter}&satisfaction=${satisfaction}&startAt=${startAt}&endAt=${endAt}`, {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                setRecords(response.data.data);
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                console.log(err);
            })
        }

        getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satisfaction, type, filter, startAt, endAt]);

    return (
        <div>
            {loader ? <Loader /> : 
                <div>
                    <h3 style={{marginBottom: 10}}><strong>{type === 'task' ? 'Tarefas' : 'Tickets'} - {name}</strong></h3>
                    <List>
                        {records.map(rec => {
                            if(rec.type === 'task'){
                                return (
                                    <div style={{width: '100%', backgroundColor: 'var(--item-list)', marginTop: '1rem', borderRadius: '1rem', padding: '1rem'}}>
                                        <h4><strong>Tarefa - {rec.id}</strong></h4>
                                        <h4>{rec.client.reference} - {rec.user.name}</h4>
                                    </div>
                                )
                            } else if (rec.type === 'ticket'){
                                return (
                                    <div style={{width: '100%', backgroundColor: 'var(--item-list)', marginTop: '1rem', borderRadius: '1rem', padding: '1rem'}}>
                                        <h4><strong>{rec.id}</strong></h4>
                                    </div>
                                )
                            }
                        })}
                    </List>
                </div>
            }

        </div>
    )
}