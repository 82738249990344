import React, { useEffect, useState } from "react";
import {
  FaFolder,
  FaCloudUploadAlt,
  FaFileAlt,
  FaArrowLeft,
  FaLocationArrow,
  FaFileDownload,
  FaEllipsisV
} from "react-icons/fa";
import Button from "../../components/Button";
import ModalButtons from "../../components/ModalButtons";
import ModalContent from "../../components/ModalContent";
import Message from '../../components/Message';
import UploadModal from "../../components/UploadModal";
import Loader from "../../components/Loader";
import ToastMessage from "../../components/ToastMessage";
import { ButtonsMark } from "../../components/ButtonsMark";
import List from "../../components/List";
import { docName, goForward, removeId } from "../../utils/handlePaths";
import { useAuth } from "../../context/contextAuth";
import { useUploadModal } from "../../context/contextUploadModal";
import { useSubModal } from "../../context/contextSubModal";
import { useColorMode } from "../../context/contextColorMode";
import UploadFiles from "../UploadFiles";
import spinner from "../../assets/images/spinner.svg";
import { DivNewFolder } from "./styles";

import api from "../../services/api";
import { createHash } from "../../utils/createHash";
import { useFiles } from "../../context/contextFiles";
import { Link, useHistory } from "react-router-dom";
import { OptionsFile } from "../../components/OptionsFile";
import { OptionsFolder } from "../../components/OptionsFolder";

export const SendFiles = ({ path }) => {
  const {
    setIsSubModalVisible,
    setSubModalContent,
  } = useSubModal();
  const {
    isUploadVisible,
    setUploadContent,
    setIsUploadVisible,
    setUploadTitle,
    att,
    setAtt,
  } = useUploadModal();
  const { primary, user } = useAuth();
  const token = localStorage.getItem("@Atlantico:token");
  const [successCreatedFolder, setSuccessCreatedFolder] = useState(false);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [loader, setLoader] = useState();
  const {successDeleted} = useFiles();
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [toZip, setToZip] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState();
  const {isEnabled} = useColorMode();
  const splitedPath = path.split('/');
  const history = useHistory();
  const [breadcrumbs, setBreadcrumbs] = useState([])

  console.log(path + '/');

  async function downloadMultipleFiles(array){
    setDownloadLoader(true);

    if(array.length === 1){
      const formattedPath = removeId(array[0].path).replace('+', '%2B');
      await api.get(`v1/clients/${primary._id}/downloadUrl?path=${formattedPath}&user_id=${user._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      ).then((response) => {
        window.open(response.data.url)
        setDownloadLoader(false);
      })
      .then(() => setFilesToDownload([]))
    } else {
      array.forEach(async file => {
        const formattedPath = removeId(file.path).replace('+', '%2B');
  
        await api.get(`v1/clients/${primary._id}/downloadUrl?path=${formattedPath}&user_id=${user._id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ).then((response) => {
          setToZip(old => [...old, {
            filename: file.name,
            url: response.data.url
          }])
        })
  
      })
    }
  }

  useEffect(() => {
    if(toZip.length !== filesToDownload.length) return;
    if(filesToDownload.length === 0) return;

    api.post(`v1/clients/${primary._id}/downloadZip`, {
      files: toZip,
      hash: createHash(16)
    }, {
      headers: {
        Authorization: token,
      },
      responseType: 'blob'
    }).then(response => {
      const data = new Date();
      var url = window.URL.createObjectURL(response.data);
      var anchor = document.createElement("a");
      anchor.download = `AD-${data.getDate()}-${(data.getMonth() + 1)}-${data.getFullYear()}-${data.getHours()}-${data.getMinutes()}-${data.getSeconds()}.zip`;
      anchor.href = url;
      anchor.click();
    }).then(() => {
      setFilesToDownload([]);
      setToZip([]);
      setDownloadLoader(false);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toZip])

  async function removeFileToDownload(file){
    const updatedDownload = filesToDownload.filter(arq => arq.name !== file.name);
    setFilesToDownload(updatedDownload);
  }

  useEffect(() => {
    async function getData(){
        await api.get(`v1/clients/${primary._id}/filesList?path=${path}/`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setFolders(response.data.folders);
          setFiles(response.data.files);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
  }, [primary._id, token, successCreatedFolder, path, successDeleted, att]);

  async function createFolder(e, newfolder) {
    e.preventDefault();
    return await api.post(`v1/clients/${primary._id}/newFolder?path=${path}&user_id=${user._id}`,
        { folder: newfolder },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(() => {
        setIsSubModalVisible(false);
        setSuccessCreatedFolder(true);
      })
      .then(() => {
        setSuccessCreatedFolder(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if(path){
      const aux = path.split('/');
      setBreadcrumbs([...aux]);
    } else {
      setBreadcrumbs('uploads')
    }
  }, [path])

  function navigateBetweenFolders(position){
    const active = breadcrumbs;
    active.splice(position + 1, active.length) 
    const newPath = active.join('/');

    console.log(newPath)
    history.push({pathname: `/dashboard/envie-arquivos/${newPath}`, state: {modal: true, props: newPath}})
  }

  return (
    <>
      {isUploadVisible ? (
        <UploadModal
          onClose={async () => {
            setIsUploadVisible(false);
            await setAtt(true);
            setAtt(false);
          }}
        />
      ) : null}
      <ModalContent hasFooter={true}>
        {loader ? (
          <Loader/>
        ) : (
          <>
            {successCreatedFolder && <ToastMessage variant="success" message="Pasta Criada com sucesso!"/>}
            {successDeleted && <ToastMessage variant="success" message="Objeto deletado com sucesso!" />}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <h4 style={{color: 'var(--text)'}}>
                <FaLocationArrow size={10} color="var(--gray)" style={{marginRight: '0.5rem'}}/>
                {breadcrumbs.map((bc, index) => (
                  <span onClick={() => breadcrumbs.length > 1 ? navigateBetweenFolders(index) : {}} style={{marginRight: '0.5rem', textDecoration: 'none', color: 'var(--text)', cursor: 'pointer', fontWeight: index === breadcrumbs.length - 1 ? 'bold' : 'lighter'}}>
                    {bc + ' /'}
                  </span>
                ))}
              </h4>
              <ButtonsMark onMarkOn={() => setFilesToDownload(files)} onMarkOff={() => setFilesToDownload([])}/>
            </div>
            <hr style={{ margin: "1rem 0" }} />
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                {splitedPath.length > 1 ? (
                  <h3 onClick={() => breadcrumbs.length > 1 ? navigateBetweenFolders(breadcrumbs.length - 2) : history.push('/dashboard')} style={{display: "flex", alignItems: "center", cursor: "pointer", padding: "1rem 0"}}>
                    <FaArrowLeft size={16} style={{ marginRight: "1rem" }} color='var(--text)' />
                    <strong style={{color:'var(--text)'}}>Voltar</strong>
                  </h3>
                ) : <div />}
                <h4><strong>{files.length} Arquivo(s)</strong></h4>
              </div>
            <List completeLine={true} darkMode={isEnabled}>
                {folders.length !== 0
                  ? folders.map(folder => {
                      return (
                        <li key={folder}>
                          <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                            <div className="documents" >
                                <Link to={{pathname: `/dashboard/envie-arquivos/${goForward(folder)}`, state: {modal: true, props: goForward(folder)}}}>
                                  <FaFolder size={32} style={{ color: "var(--folder)" }}/>
                                  <div className="info">
                                    <h4><strong style={{ color: "var(--folder)" }}>
                                        {docName(folder)}
                                    </strong></h4>
                                  </div>
                                </Link>
                            </div>

                            <FaEllipsisV style={{ margin: "0", color: "var(--gray)", cursor: 'pointer' }} size={20} onClick={() => {
                              setIsSubModalVisible(true);
                              setSubModalContent(<OptionsFolder deleteAllowed company={primary._id} folder={folder}/>)
                            }}/>

                          </div>
                        </li>
                      );
                    })
                  : null}
                {files.length !== 0
                  ? files.map(file => {
                      return (
                        <li key={file.lastModified}>
                          <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                            <div className="documents" onClick={() => {filesToDownload.includes(file) ? removeFileToDownload(file) : setFilesToDownload([...filesToDownload, file]) }}>
                              {filesToDownload.includes(file) && <input type="checkbox" style={{marginRight: 10}} checked />}
                              <FaFileAlt size={32} style={{ color: 'var(--file)' }} />
                              <div className="info">
                                <h4>
                                  <strong style={{ color: 'var(--file)' }}>
                                    {file.name}
                                  </strong>
                                </h4>
                              </div>
                            </div>

                            <FaEllipsisV style={{ margin: "0", color: "var(--gray)", cursor: 'pointer' }} size={20} onClick={() => {
                              setIsSubModalVisible(true);
                              setSubModalContent(<OptionsFile deleteAllowed company={primary._id} file={file}/>)
                            }}/>
            
                          </div>
                        </li>
                      );
                    })
                  : null}
                {folders.length === 0 && files.length === 0 ? (
                  <Message severity="warning" message="Esta pasta está vazia!"/>
                ) : null}
            </List>
          </>
        )}
      </ModalContent>
      <ModalButtons>
      {filesToDownload.length > 0 ? (
        <Button type="submit" bgcolor="var(--green)" color="#fff" border="var(--green)" hover="var(--green-hover)" expansive={true} hollow={false} size="small" onClick={() => downloadMultipleFiles(filesToDownload)}>
          {downloadLoader ? <img src={spinner} alt="Criar usuário"/> : (
            <>
            <FaFileDownload size={18} />
            <span>BAIXAR</span>
            </>
          )}
        </Button>
      ) : (
          <Button hollow={true} expansive={true} size="small" color="var(--blue)" hover="var(--blue-hover)"
            onClick={() => {
              let newFold = "";
              setIsSubModalVisible(true);
              setSubModalContent(
                <DivNewFolder>
                  <FaFolder size={48} style={{ margin: "0" }} />
                  <h3><strong style={{color: 'var(--text)'}}>Nova Pasta</strong></h3>
                  <form onSubmit={(e) => createFolder(e, newFold)}>
                    <div className="newfolder-input">
                      <input placeholder="Nome da nova pasta" name="newfolder" onChange={(e) =>  newFold = e.target.value} />
                    </div>
                    <Button type="submit" bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small">
                      CRIAR
                    </Button>
                  </form>

                  <Button expansive={true} color="var(--gray)" border="var(--gray)" hollow={true} size="small" onClick={() => setIsSubModalVisible(false)}>
                    CANCELAR
                  </Button>
                </DivNewFolder>
              );
            }}
          >
            <FaFolder size={18} />
            Nova Pasta
          </Button>
      )}
        
      <hr />

        <Button hollow={false} expansive={true} size="small" bgcolor="var(--blue)" color="white" hover="var(--blue-hover)" border="var(--blue)"
          onClick={() => {
            setIsUploadVisible(true);
            setUploadContent(<UploadFiles primary={primary._id} user={user._id} token={token} path={path}/>);
            setUploadTitle("ENVIANDO PARA");
          }}
        >
          <FaCloudUploadAlt size={18} />
          Enviar
        </Button>
      </ModalButtons>
    </>
  );
};