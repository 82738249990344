import styled from "styled-components";
import { Drawer } from "@material-ui/core";
import { Link } from "react-router-dom";

export const DrawerUI = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: ${props => props.darkMode ? '#121212' : '#fff'};
    position: fixed;
    width: 100%;
    border-radius: 1rem 1rem 0 0;

    @media(min-width: 700px){
      padding: 2rem;
    }

    @media(min-width: 1025px){
      border-radius: 0;
      border: 0;
      border-right:1px solid var(--divisor);
      width: 22%;
      padding: 0;
    }
  }

  ul {
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media(min-width: 1025px){
      border-top: 1px solid var(--divisor);
      border-radius: 10px;
    }
    a {
      display: flex;
      justify-content: left;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      li {
        display: flex;
        width: 100%;
        padding: 1.5rem;
        align-items: center;
        color: var(--text);
        transition: background-color ease-in-out 0.1s;

        @media(min-width: 700px){
          padding: 1rem;
        }

        @media(min-width: 1025px){
          padding: 1rem;
        }

        svg {
          color: var(--blue);
          margin-right: 1rem;
        }

        &:hover {
          background-color: ${props => props.darkMode ? '#1f1f1f' : '#f1f7fa'};
          color: ${props => props.darkMode ? 'var(--blue)' : 'var(--blue)'};
        }
      }
    }

    h3{
      max-width: 14em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media(min-width: 700px){
        max-width: 30em;
      }

      @media(min-width: 1025px){
        max-width: 12em;
      }
    }
  }
`;

export const PageHeader = styled.header`
  display: none;
  @media(min-width: 1025px){
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.darkMode ? '#1f1f1f' : 'var(--atlantico)'};

    .logo{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
    }

    .items{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 3rem;
      background-color: ${props => props.darkMode ? '#121212' : '#fff'};

      .name-user{
        padding: 1rem;
        h3{
          color: var(--text);
        }
      }

      .buttons{
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a{
          cursor: pointer;
          text-decoration: none;
        }

        svg{
          color: #2699fb;
          &:hover{
            color: #2074BC;
          }
          &#circle-notification{
            color: red;
            position: absolute;
            width: 8px;
            left: 5.8rem;
            top: 27rem;
          }

        }
      }
    }
  }
  
`;

export const ItemNavigator = styled(Link)`
      display: flex;
      justify-content: left;
      text-decoration: none;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
      li {
        display: flex;
        width: 100%;
        padding: 1.5rem;
        align-items: center;
        color: var(--text);
        transition: background-color ease-in-out 0.1s;
        
        @media(min-width: 700px){
          padding: 3rem 5rem;
        }

        @media(min-width: 1025px){
          padding: 2rem;
        }
        svg {
          color: var(--blue);
          margin-right: 1rem;
        }

        &:hover {
          background-color: ${props => props.darkMode ? '#1f1f1f' : '#f1f7fa'};
          color: ${props => props.darkMode ? 'var(--blue)' : 'var(--blue)'};
        }
      }
`;