import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import Button from "../Button";
import spinner from "../../assets/images/spinner.svg";
import { Inputs } from "./styles";
import ModalContent from "../ModalContent";
import ModalButtons from "../ModalButtons";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSubModal } from "../../context/contextSubModal";

const ChangePasswordForm = ({ formData, onSubmit, loader }) => {
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [newPasswordVisible, setNewPasswordVisible] = useState(true);
  const [verifyPasswordVisible, setVerifyPasswordVisible] = useState(true);

  function toggleVisiblePassword() {
    setPasswordVisible(!passwordVisible);
  }

  function toggleVisibleNewPassword() {
    setNewPasswordVisible(!newPasswordVisible);
  }

  function toggleVisibleVerifyPassword() {
    setVerifyPasswordVisible(!verifyPasswordVisible);
  }

  const { setIsSubModalVisible } = useSubModal();

  return (
    <Formik initialValues={formData} onSubmit={onSubmit}>
      <Form>
        <ModalContent>
          <Inputs>
            {passwordVisible ? (
              <div className="submit-line">
                <Field
                  name="password"
                  type="password"
                  placeholder="Senha atual"
                />
                <FaEyeSlash
                  className="submit-icon"
                  style={{ color: "#A0A0A0", cursor: "pointer" }}
                  onClick={toggleVisiblePassword}
                />
              </div>
            ) : (
              <div className="submit-line">
                <Field name="password" type="text" placeholder="Senha atual" />
                <FaEye
                  className="submit-icon"
                  style={{ color: "#A0A0A0", cursor: "pointer" }}
                  onClick={toggleVisiblePassword}
                />
              </div>
            )}
          </Inputs>
          <Inputs>
            {newPasswordVisible ? (
              <div className="submit-line">
                <Field
                  name="newPassword"
                  type="password"
                  placeholder="Nova senha"
                />
                <FaEyeSlash
                  className="submit-icon"
                  style={{ color: "#A0A0A0", cursor: "pointer" }}
                  onClick={toggleVisibleNewPassword}
                />
              </div>
            ) : (
              <div className="submit-line">
                <Field
                  name="newPassword"
                  type="text"
                  placeholder="Nova senha"
                />
                <FaEye
                  className="submit-icon"
                  style={{ color: "#A0A0A0", cursor: "pointer" }}
                  onClick={toggleVisibleNewPassword}
                />
              </div>
            )}
          </Inputs>
          <Inputs>
            {verifyPasswordVisible ? (
              <div className="submit-line">
                <Field
                  name="verifyPassword"
                  type="password"
                  placeholder="Confirme a nova senha"
                />
                <FaEyeSlash
                  className="submit-icon"
                  style={{ color: "#A0A0A0", cursor: "pointer" }}
                  onClick={toggleVisibleVerifyPassword}
                />
              </div>
            ) : (
              <div className="submit-line">
                <Field
                  name="verifyPassword"
                  type="text"
                  placeholder="Confirme a nova senha"
                />
                <FaEye
                  className="submit-icon"
                  style={{ color: "#A0A0A0", cursor: "pointer" }}
                  onClick={toggleVisibleVerifyPassword}
                />
              </div>
            )}
          </Inputs>
          <h4>
            <strong>Importante:</strong> Sua senha precisa ter de 6 a 20
            caracteres e números. Precisa conter ao menos um símbolo (@#$%), uma
            letra maiúscula e uma letra minúscula.
          </h4>
        </ModalContent>
        <ModalButtons>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            type="button"
          >
            {loader ? <img src={spinner} alt="Alterar"/> : "ALTERAR"}
          </Button>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={() => setIsSubModalVisible(false)}
          >
            CANCELAR
          </Button>
        </ModalButtons>
      </Form>
    </Formik>
  );
};

export default ChangePasswordForm;
