import React from "react";
import { DivModal } from "./styles";
import { FaTimes } from "react-icons/fa";
import { useUploadModal } from "../../context/contextUploadModal";
import { useColorMode } from "../../context/contextColorMode";

const UploadModal = ({ id = "uploadmodal", onClose = () => {} }) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) return;
  };

  const {isEnabled} = useColorMode();

  const { uploadTitle, uploadContent } = useUploadModal();

  return (
    <DivModal onClick={handleOutsideClick} darkMode={isEnabled} id={id}>
      <div className="container">
        <div className="header">
          <h2 className="title">{uploadTitle}</h2>
          <button className="close" onClick={onClose}>
            <FaTimes size={25} />
          </button>
        </div>
        <div className="content">{uploadContent}</div>
      </div>
    </DivModal>
  );
};

export default UploadModal;
