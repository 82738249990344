import React, { useState } from "react";
import { 
    Grid,
    Divider,
    Button,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,    
    Paper,
    IconButton,
    } from "@material-ui/core";

import {
  FaPlus,
  FaTrash,
} from "react-icons/fa";

import { useSubModal } from "../../../context/contextSubModal";
import Dependent from "./submodals/dependent";

const Dependents = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [dataSource,setDataSource] = useState(props.data.employee.DEPENDENTES)

    const handleAddEditDependent = (index=null) => {
        setIsSubModalVisible(true);
        setSubModalContent(<Dependent data={{employee:props.data.employee}} rowToEdit={index} handleChange={ props.handleChange } setIsSubModalVisible={setIsSubModalVisible} />)
    }

    const handleRemoveDependent = (index) => {

        const newData = dataSource

        newData.splice(index, 1)

        props.handleChange(null,{DEPENDENTES:newData})

    }

    return <Grid container spacing={1}>

        <Grid item xs={12}>

            <Button size="small" variant="contained" startIcon={<FaPlus size={12} />} onClick={() => {handleAddEditDependent()}}>Incluir Dependente</Button>
            <Divider style={{margin:"20px 0"}}/>
            
            {dataSource.length > 0 && <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource.map((row,index) => (
                            <TableRow key={index}>
                                <TableCell><Link component="button" onClick={(e) => { e.preventDefault(); handleAddEditDependent(index) }}>{row.NOMEDEPEND}</Link></TableCell>
                                <TableCell width={70} align="right"><Button size="small" color="secondary" startIcon={<FaTrash size={12} />} onClick={() => { handleRemoveDependent(index) }}>Excluir</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}

            {!dataSource.length && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#000",backgroundColor:"#FFFAC9"}}>Nenhum dependente até o momento.</Paper>}
            
        </Grid>

    </Grid>

}

export default Dependents