import { Grid, TextField, Divider, Card, CardContent, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useSubModal } from "../../../../context/contextSubModal";
import Municipio from "../submodals/municipio";
import Pais from "../submodals/pais";

const Address = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [municipio,setMunicipio] = useState({
        CODIGOMUNICNASC: props.data.employee.CODIGOMUNICNASC || "",
        NOMEMUNICNASC: props.data.employee.NOMEMUNICNASC || "",
    })
    const [estado,setEstado] = useState("")
    const [pais,setPais] = useState({
        PAISORIGEM: props.data.employee.PAISORIGEM,
        NOMEPAIS: props.data.employee.NOMEPAIS,
    })

    const [deficiencies, setDeficiencies] = useState({
        NAOPOSSUI: props.data.employee.NAOPOSSUI,
        DEFFISICA: props.data.employee.DEFFISICA,
        DEFVISUAL: props.data.employee.DEFVISUAL,
        DEFAUDITIVA: props.data.employee.DEFAUDITIVA,
        DEFINTELECTUAL: props.data.employee.DEFINTELECTUAL,
        DEFMENTAL: props.data.employee.DEFMENTAL,
        DEFCOTA: props.data.employee.DEFCOTA,
        DEFREABILITADO: props.data.employee.DEFREABILITADO,
    })

    const handleDeficienciesChange = (event) => {
        if(event.target.name==="NAOPOSSUI"){
            setDeficiencies({
                NAOPOSSUI: event.target.checked,
                DEFFISICA: false,
                DEFVISUAL: false,
                DEFAUDITIVA: false,
                DEFINTELECTUAL: false,
                DEFMENTAL: false,
                DEFCOTA: false,
                DEFREABILITADO: false,
            })

            return false
        }

        setDeficiencies({ ...deficiencies, [event.target.name]: event.target.checked })
    }

    const handleChangeUF = (e) => {

        setMunicipio({
            CODIGOMUNICNASC: "",
            NOMEMUNICNASC: "",
        })

        setEstado(e.target.value)

        props.handleChange(e)

    }

    useEffect(() => {

        props.handleChange(null,municipio)

    }, [municipio])

    useEffect(() => {

        props.handleChange(null,pais)

    }, [pais])

    useEffect(() => {

        props.handleChange(null,deficiencies)

    }, [deficiencies])

    return <Grid container spacing={2}>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Informações pessoais</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth type="date" label="Data de nascimento" required defaultValue={props.data.employee.DATANASC} name="DATANASC" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } InputLabelProps={{ shrink: true }} />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField select fullWidth label="Nacionalidade" required defaultValue={props.data.employee.NACIONALIDADE} name="NACIONALIDADE" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="10">Brasileiro</option>
                                <option value="15">Brasileiro nascido no exterior</option>
                                <option value="20">Naturalizado brasileiro</option>
                                <option value="21">Argentino</option>
                                <option value="22">Boliviano</option>
                                <option value="23">Chileno</option>
                                <option value="24">Paraguaio</option>
                                <option value="25">Uruguaio</option>
                                <option value="26">Venezuelano</option>
                                <option value="27">Colombiano</option>
                                <option value="28">Peruano</option>
                                <option value="29">Equatoriano</option>
                                <option value="30">Alemão</option>
                                <option value="31">Belga</option>
                                <option value="32">Britânico</option>
                                <option value="34">Canadense</option>
                                <option value="35">Espanhol</option>
                                <option value="36">Norte-americano(EUA)</option>
                                <option value="37">Francês</option>
                                <option value="38">Suíço</option>
                                <option value="39">Italiano</option>
                                <option value="40">Haitiano</option>
                                <option value="41">Japonês</option>
                                <option value="42">Chinês</option>
                                <option value="43">Coreano</option>
                                <option value="44">Russo</option>
                                <option value="45">Português</option>
                                <option value="46">Paquistanês</option>
                                <option value="47">Indiano</option>
                                <option value="48">Outros latino-americanos</option>
                                <option value="49">Outros asiáticos</option>
                                <option value="50">Outros</option>
                                <option value="51">Outros Europeus</option>
                                <option value="60">Angolano</option>
                                <option value="61">Congolês</option>
                                <option value="62">Sul-Africano</option>
                                <option value="70">Outros Africanos</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField select fullWidth label="UF" required defaultValue={props.data.employee.SIGLAESTADONASC} name="SIGLAESTADONASC" onChange={handleChangeUF} SelectProps={{native: true}}>
                                <option value=""></option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AM">AM</option>
                                <option value="AP">AP</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="EX">EX</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MG">MG</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="PR">PR</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="RS">RS</option>
                                <option value="SC">SC</option>
                                <option value="SE">SE</option>
                                <option value="SP">SP</option>
                                <option value="TO">TO</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <input
                                name="CODIGOMUNICNASC"
                                value={municipio.CODIGOMUNICNASC}
                                hidden="true"
                            />
                            <TextField
                                style={{cursor:"pointer"}}
                                fullWidth
                                required
                                label="Município"
                                value={municipio.NOMEMUNICNASC}
                                name="NOMEMUNICNASC"
                                inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                                onFocus={() => {
                                    setIsSubModalVisible(true);
                                    setSubModalContent(<Municipio setMunicipio={setMunicipio} params={["CODIGOMUNICNASC","NOMEMUNICNASC"]} municipio={municipio} siglaEstado={estado} setIsSubModalVisible={setIsSubModalVisible} />)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <input
                                name="PAISORIGEM"
                                value={pais.PAISORIGEM}
                                hidden="true"
                            />
                            <TextField
                                style={{cursor:"pointer"}}
                                fullWidth
                                required
                                label="País de Origem"
                                value={pais.NOMEPAIS}
                                name="NOMEPAIS"
                                inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                                onFocus={() => {
                                    setIsSubModalVisible(true);
                                    setSubModalContent(<Pais setPais={setPais} params={["PAISORIGEM","NOMEPAIS"]} setIsSubModalVisible={setIsSubModalVisible} />)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField select required fullWidth label="Sexo" defaultValue={props.data.employee.SEXO} name="SEXO" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="1">Masculino</option>
                                <option value="2">Feminino</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Grau de instrução" defaultValue={props.data.employee.GRAUINSTR} name="GRAUINSTR" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="13">Ph. D.</option>
                                <option value="12">Doutorado completo</option>
                                <option value="11">Mestrado completo</option>
                                <option value="10">Especialização</option>
                                <option value="9">Educação Superior completa</option>
                                <option value="8">Educação Superior incompleta</option>
                                <option value="7">Ensino médio completo</option>
                                <option value="6">Ensino médio incompleto</option>
                                <option value="5">Ensino fundamental completo</option>
                                <option value="4">Do 6º ao 9º ano do Ensino Fundamental incompleto</option>
                                <option value="3">5º ano completo do Ensino Fundamental</option>
                                <option value="2">Até o 5º ano incompleto do Ensino Fundamental</option>
                                <option value="1">Analfabeto</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Estado civil" defaultValue={props.data.employee.ESTADOCIVIL} name="ESTADOCIVIL" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="6">Outros</option>
                                <option value="5">Concubinato</option>
                                <option value="4">Viúvo</option>
                                <option value="3">Divorciado ou Separado Judicialmente</option>
                                <option value="9">Casado - Comunhão Parcial de Bens</option>
                                <option value="8">Casado - Sem Comunhão de Bens</option>
                                <option value="7">Casado - Comunhão Total de Bens</option>
                                <option value="2">Casado</option>
                                <option value="1">Solteiro</option>
                                <option value="0">Selecione o Estado Civil</option>
                            </TextField>
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Tipos de deficiência</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.NAOPOSSUI}
                                onChange={handleDeficienciesChange}
                                name="NAOPOSSUI"
                                color="primary"
                                value={true}
                            />
                            }
                            label="Não possui"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFFISICA}
                                onChange={handleDeficienciesChange}
                                name="DEFFISICA"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="Física"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFVISUAL}
                                onChange={handleDeficienciesChange}
                                name="DEFVISUAL"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="Visual"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFAUDITIVA}
                                onChange={handleDeficienciesChange}
                                name="DEFAUDITIVA"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="Auditiva"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFINTELECTUAL}
                                onChange={handleDeficienciesChange}
                                name="DEFINTELECTUAL"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="Intelectual"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFMENTAL}
                                onChange={handleDeficienciesChange}
                                name="DEFMENTAL"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="Mental"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFCOTA}
                                onChange={handleDeficienciesChange}
                                name="DEFCOTA"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="PCD - Cotista"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={deficiencies.DEFREABILITADO}
                                onChange={handleDeficienciesChange}
                                name="DEFREABILITADO"
                                color="primary"
                                value="1"
                                disabled={deficiencies.NAOPOSSUI}
                            />
                            }
                            label="Reabilitado"
                        />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField fullWidth label="Observações" disabled={deficiencies.NAOPOSSUI} defaultValue={props.data.employee.DEFOBS} name="DEFOBS" inputProps={{ maxLength: 255 }} onChange={ props.handleChange } />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Grid>

    </Grid>

}

export default Address