import React, { useEffect, useState, useCallback } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaPlus,
  FaArrowLeft,
} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";

import api from "../../services/api";

const VacationList = ( {employee} ) => {
  const history = useHistory()
  const {user,primary} = useAuth()
  const [drafts,setDrafts] = useState([])
  const [loading, setLoading] = useState(false)
  const {isEnabled} = useColorMode();
  const [data, setData] = useState([]);
  const token = localStorage.getItem("@Atlantico:token")

  const headers = {
    headers: {
      Authorization: token,
    },
  }
  
  const loadData = async ({ skip, limit, sort }) => {
    const filter = { Filter: { CODIGOEMPRESA:employee.CODIGOEMPRESA, CODIGOFUNCCONTR:employee.CODIGOFUNCCONTR } }
  
    const params = {
      classe: "TnFpaDCReciboFerias",
      limit,
      start:skip,
      sort,
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.post(`v1/nweb${qs}`,filter,headers)
    .then((response) => {
      setData(response.data.data);
      return ({data: response.data.data, count: parseInt(response.data.records)})
    })
    .catch((err) => {
      console.error(err)
    })
  
  }

  const dataSource = useCallback(loadData, [])

  const columns = [
    { name: 'SEQRECIBOFERIAS', header: 'Seq.', width: 100},
    { name: 'DATAINICIALFERIAS', header: 'Data Inicial', defaultFlex: 1},
    { name: 'DATAFINALFERIAS', header: 'Data Final', defaultFlex: 1},
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  const handleAddClick = () => {
    let newSeq = 1;

    if(data.length){
      const lastSeq = data[data.length-1].SEQRECIBOFERIAS
      newSeq = Number(lastSeq) + 1;
    }

    history.push({pathname: '/dashboard/pessoas-aviso-ferias-inclusao', state: {modal: true},employee:{data:employee, seq:newSeq}});
  }

  return (
    <>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>
        
        {/* Lista do Cadastro de empregados

        <form noValidate>
          <div style={{fontSize:"15px !important"}}>
            <TextField required id="standard-required" label="Required" defaultValue="Hello World" />
          </div>
        </form> */}

        <Grid container>
          <Grid item xs={12}>
            <Typography>{employee.NOMEFUNC}</Typography>

            <Divider style={{margin:"20px 0"}}/>            
              <Button size="small" variant="contained" startIcon={<FaArrowLeft size={12} />} onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-ferias', state: {modal: true}, module: "ferias", type: "Empregados"}) }}>Voltar</Button>
              <Button size="small" variant="contained" startIcon={<FaPlus size={12} />} onClick={handleAddClick} style={{marginLeft:10}}>Incluir</Button>
            <Divider style={{margin:"20px 0"}}/>

            {loading && <LinearProgress />}
            
            {!loading && <ReactDataGrid
              idProperty="CODIGOFUNCCONTR"
              columns={columns}
              dataSource={dataSource}
              defaultLimit={10}
              pagination 
              theme="default-light" 
              defaultSortInfo={{ name: 'NOMEFUNC', dir: 1, type: 'text' }}
              style={{ minHeight: 350 }}
              // onRenderRow={onRenderRow}
             />}
          </Grid>
        </Grid>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default VacationList;
