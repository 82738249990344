export const addEmail = (email, setEmail) => {
    setEmail([...email, { label: "work", value: "", primary: false }]);
}

export const removeEmail = (email, setEmail, position) => {
    const updatedEmails = [...email];
    updatedEmails.splice(position, 1);
    setEmail(updatedEmails);
}

export const setEmailsValue = (email, setEmail, position, field, value) => {
    const updatedEmails = email.map((email, index) => {
      if (index === position) {
        return {
          ...email,
          [field]: value,
        };
      }
      return email;
    });
    setEmail(updatedEmails);
}