import React, { useState } from "react";
import Button from "../../../components/Button";
import StateDrop from "../../../components/StateDrop";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useNewRequest } from "../../../context/contextNewRequest";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";
import { useHistory } from "react-router";

const Company = ({  navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const { company, setCompany, companies } = useNewRequest();
  const { next } = navigation;
  const history = useHistory();

  return (
    <>
        {error && <ToastMessage variant="info" message="Preencha este campo antes de continuar!"/>}
        <ModalContent displayFlex={true}>
          <FormContact darkMode={isEnabled}>
            <h2>SELECIONE A EMPRESA QUE DESEJA ABRIR O TICKET</h2>
            <StateDrop
              isCompany
              content={companies}
              name="type"
              value={company}
              onChange={e=>setCompany(Number(e.target.value))}
            />
          </FormContact>
        </ModalContent>

        <ModalButtons>
          <Button id="button" bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={async () => {
              await validateField(company, 'text', next, setError);
              setError(false);
            }}
          >
            CONTINUAR
          </Button>
          <hr />
          <Button color="var(--gray)" border="var(--gray)" expansive={true} hollow={true} size="small" onClick={() => history.goBack()}>
            CANCELAR
          </Button>
        </ModalButtons>
    </>
  );
};

export default Company;
