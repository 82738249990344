import styled from "styled-components";

export const InputMU = styled.div`
  position: relative;

  input {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    outline: 0;
    padding: 1rem 1.5rem;
    padding-left: 3.5rem;
    background-color: var(--modal);
    color: #bbbbbb;
    border: 0;
  }

  svg {
    position: absolute;
      top: 1rem;
    left: 1.5rem;
    color: #2699fb;
  }

  @media (min-width: 700px) {
    svg {
      top: 1rem;
    }
  }

  @media (min-width: 1025px) {
    input {
      height: auto;
      padding: 1rem 1.5rem;
      padding-left: 3.5rem;
      color: #bbbbbb;
    }
    svg {
      top: 1rem;
    }
  }
`;
