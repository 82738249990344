import React, { useState } from 'react';
import { FaDownload, FaTrash } from 'react-icons/fa';
import { useAuth } from '../../context/contextAuth';
import { useFiles } from '../../context/contextFiles';
import { useSubModal } from '../../context/contextSubModal';
import api from '../../services/api';
import { docName, removeId } from '../../utils/handlePaths';
import Button from '../Button';
import spinner from "../../assets/images/spinner.svg";
import { DivFileOptions } from './styles';
import { createHash } from '../../utils/createHash';

export const OptionsFolder = ({deleteAllowed, folder, company}) => {
    const {setIsSubModalVisible} = useSubModal();
    const {user} = useAuth();
    const token = localStorage.getItem("@Atlantico:token");
    const {setSuccessDeleted} = useFiles();
    const [loader, setLoader] = useState(false);

    async function handleDelete(e, path) {
        e.preventDefault();
        return await api.delete(`v1/clients/${company}/deleteObject?path=${path}&user_id=${user._id}`,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then(() => {
            setIsSubModalVisible(false);
            setSuccessDeleted(true);
          })
          .then(() => {
            setSuccessDeleted(false);
          })
          .catch((err) => {
            console.log(err);
          });
    }

    async function downloadFolder(primaryId, folder){
      setLoader(true);
      let path = folder;
      const token = localStorage.getItem("@Atlantico:token");
      
      if(folder[folder.length - 1] === '/'){
          path = folder.substring(0, folder.length - 1);
      }
  
      await api.post(`v1/clients/${primaryId}/downloadFolder?path=${removeId(path) + '/'}`, {
        hash: createHash(16)
      }, {
        headers: {
          Authorization: token
        },
        responseType: 'blob'
      })
      .then(response => {
        const data = new Date();
        var url = window.URL.createObjectURL(response.data);
        var anchor = document.createElement("a");
        anchor.download = `AD-${data.getDate()}-${(data.getMonth() + 1)}-${data.getFullYear()}-${data.getHours()}-${data.getMinutes()}-${data.getSeconds()}.zip`;
        anchor.href = url;
        anchor.click();
      })
      .then(() => setLoader(false))
      .catch(err => console.log(err))
    }

    return (
      <DivFileOptions>
        <h3><strong style={{color: 'var(--text)'}}>{docName(folder)}</strong></h3>

        <Button bgcolor="var(--green)" color="#fff" border="var(--green)" hover="var(--green-hover)" expansive={true} hollow={false} size="small" onClick={() => downloadFolder(company, folder)}>
          {loader ? <img src={spinner} alt="Download da pasta"/> : (
          <>
            <FaDownload style={{color: "#fff", marginBottom: "0"}}/>
            DOWNLOAD
          </>
          )}
        </Button>

        {deleteAllowed && (
          <Button bgcolor="var(--red)" color="#fff" border="var(--red)" hover="var(--red-hover)" expansive={true} hollow={false} size="small" onClick={(e) => handleDelete(e, removeId(folder))}>
            <FaTrash style={{color: "#fff", marginBottom: "0"}}/>
            EXCLUIR
          </Button>
        )}

        <Button expansive={true} hollow={true} size="small" color="var(--gray)" border="var(--gray)" onClick={() => setIsSubModalVisible(false)}>
          CANCELAR
        </Button>
        
      </DivFileOptions>
    )
}