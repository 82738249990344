import React, { useState } from 'react';
import { FaDownload, FaPlayCircle, FaUser } from 'react-icons/fa';
import Button from '../../../components/Button';
import ModalButtons from '../../../components/ModalButtons';
import ModalContent from '../../../components/ModalContent';
import ToastMessage from '../../../components/ToastMessage';
import { useColorMode } from '../../../context/contextColorMode';
import { useRunrunit } from '../../../context/contextRunrunit';
import spinner from "../../../assets/images/spinner.svg";
import {FormContact, ListClients} from './styles';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router'
import { CSVLink } from 'react-csv';
import { useEffect } from 'react';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { convertName } from '../../../utils/convertName';
import api from '../../../services/api';

const RunrunitUpdate = ({task}) => {
    const {isEnabled} = useColorMode();
    const token = localStorage.getItem('@Atlantico:token');
    const [assignment, setAssignment] = useState(task ? task.assignment : null);
    const name = task ? task.name : '';
    const [title, setTitle] = useState(task ? task.title : '');
    const area = task ? task.profile : '';
    const [competence, setCompetence] = useState(task ? task.competence : 1);
    const [date, setDate] = useState(task ? task.desired_date : 1);
    const [month, setMonth] = useState(task ? task.desired_month : null);
    const history = useHistory();

    const [linkedClients, setLinkedClients] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        async function getData(){
            await api.get(`v1/linkedclients/${task._id}`, {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                setLinkedClients(response.data);
                setLoader(false);
            })
            .catch(err => {
                console.log(err)
                setLoader(false);
            })
        }

        getData()
    }, [])

    const {users, updateTask, loading, error, success} = useRunrunit();

    const data = {
        title,
        assignment,
        competence,
        desired_date: date,
        desired_month: month
    }

    const headers = [
        { label: "Referência", key: "reference" },
        { label: "Nome", key: "name" },
        { label: "Dia", key: "date" },
        { label: "Mês", key: "month" },
        { label: "Responsável", key: "assignment" },
    ];

    var orderedClients = linkedClients.sort((a,b)=> (a.reference > b.reference ? 1 : -1))

    useEffect(() => {
        console.log(task)
    }, [updateTask])

    return (
        <>

        <ModalContent>
            {success && <ToastMessage variant="success" message="Salvo com sucesso!"/>}
            {error && <ToastMessage variant="error" message="Erro ao salvar!"/>}
          <FormContact darkMode={isEnabled}>
            <div className="twoFields">
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Nome</strong></label>
                    <input value={name} disabled style={{border: 0}}/>
                </div>
                
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Área</strong></label>
                    <input value={convertName(area)} disabled style={{border: 0}}/>
                </div>
            </div>
            <div className="twoFields">
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Título</strong></label>
                    <input value={title} onChange={e => setTitle(e.target.value)} placeholder="Título"/>
                </div>
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Responsável</strong></label>
                    <select value={assignment ? assignment : ""} style={{ background: 'var(--modal)', color: 'var(--text)' }} onChange={e=> setAssignment(e.target.value)}>
                        <option style={{color: 'var(--text)'}} value="">Nenhum</option>
                        {users.map(user => <option style={{color: 'var(--text)'}} value={user.id} key={user.id}>{user.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="twoFields">
                <div className="field">
                    <label><strong style={{color: 'var(--text)'}}>Competência</strong></label>
                    <input value={competence} onChange={e=> setCompetence(e.target.value)} placeholder="Data de competência"/>
                </div>
                <div className="twoFields">
                    <div className="field">
                        <label><strong style={{color: 'var(--text)'}}>Dia Desejado</strong></label>
                        <input type="number" min={1} max={31} value={date} onChange={e=> setDate(e.target.value)} placeholder="Data desejada"/>
                    </div>
                    <div className="field">
                        <label><strong style={{color: 'var(--text)'}}>Mês Desejado</strong></label>
                        <input type="number" min={1} max={12} value={month} onChange={e=> setMonth(e.target.value)} placeholder="Mês desejado (opcional)"/>
                    </div>
                </div>
                
            </div>
          </FormContact>

            {task && (
                <>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                    <h3 style={{color: 'var(--blue)'}}><strong>CLIENTES VINCULADOS</strong></h3>
                    <Link style={{marginLeft: '1rem'}} to={{pathname: "/admin/clientes-vinculados", state: {modal: true, props: {...task, ...data}}}} >
                        <FaUser color="var(--blue)"/>
                    </Link>
                    <CSVLink data={linkedClients} headers={headers} filename={`clientes_vinculados_${task.name}.csv`}>
                        <FaDownload style={{cursor: 'pointer', marginLeft: '1rem', color: 'var(--blue)'}} />
                    </CSVLink>
                    <Link to={{pathname: "/admin/clientes-tarefas", state: {modal: true, props: {...task, ...data}}}} > 
                        <FaPlayCircle size={18} color='var(--blue)' style={{cursor: 'pointer', marginLeft: '1rem'}}/>
                    </Link>
                </div>
                {loader ? <Loader /> : (
                    linkedClients.length > 0 ? (
                        <ListClients darkMode={isEnabled}>
                            {orderedClients.map(client => (
                                <div style={{paddingRight: '0.5rem'}}>
                                    <div style={{padding: '0.3rem'}}>
                                        <h5 style={{color: 'var(--text)'}}>{client.reference} - {client.name}</h5>
                                    </div>
                                    <hr style={{borderWidth: '0.5px'}}/>
                                </div>
                            ))}
                        </ListClients>
                    ) : (
                        <Message severity="warning" message="Sem clientes vinculados à essa tarefa!"/>
                    )
                )}
                </>
            )}


        </ModalContent>

        <ModalButtons>
          <Button bgcolor="var(--blue)"  color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={() => updateTask(data, task._id)}>
            {loading ? <img src={spinner} alt="Carregando"/> : 'ATUALIZAR'}
          </Button>
          <hr />
            <Link to={{pathname: "/admin/tarefas", state: {modal: true, props: task}}} style={{textDecoration:'none'}}>
          <Button color="var(--gray)" border="var(--gray)" expansive={true} hollow={true} size="small">
            VOLTAR PARA TAREFAS
          </Button>
          </Link>
        </ModalButtons>
        </>

    )
}

export default RunrunitUpdate;