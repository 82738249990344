import styled from 'styled-components';

export const LinkUI = styled.div`
    margin-top: 1rem;
    width: 100%;
    span {
        display: flex;
        align-content: left;
        align-items: center;
        cursor: pointer;
        text-align: left;
        svg {
            margin-right: 1rem;
            margin-bottom: 0;
            color: var(--blue);
            transition: color 0.25s;
        }
        p {
            font-weight: bold;
            color: var(--text);
        }
        &:hover {
            svg {
                color: var(--blue-hover);
            }
        }
    }     
`;