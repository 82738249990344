import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import StateDrop from "../../../components/StateDrop";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useNewRequest } from "../../../context/contextNewRequest";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";

import api from "../../../services/api";
import { useHistory } from "react-router";

const Type = ({  navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const { type, setType } = useNewRequest();
  const { next } = navigation;

  const token = localStorage.getItem("@Atlantico:token");

  useEffect(() => {
    async function getData(){
        await api.get("v1/tickets/options", {
          headers: {
            Authorization: token,
          },
        })
        .then(response => setOptions(response.data));
    }

    getData();
  }, [token]);

  return (
    <>
        {error && <ToastMessage variant="info" message="Preencha este campo antes de continuar!"/>}
        <ModalContent displayFlex={true}>
          <FormContact darkMode={isEnabled}>
            <h2>SELECIONE O TIPO DA SOLICITAÇÃO</h2>
            <StateDrop
              
              content={options}
              name="type"
              value={type}
              onChange={e=>setType(e.target.value)}
            />
          </FormContact>
        </ModalContent>

        <ModalButtons>
          <Button
            id="button"
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            onClick={async () => {
              await validateField(type, 'text', next, setError);
              setError(false);
            }}
          >
            CONTINUAR
          </Button>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>
        </ModalButtons>
    </>
  );
};

export default Type;
