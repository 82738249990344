import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import axios from "axios";
import Loader from "../../components/Loader";
import List from "../../components/List";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const Youtube = () => {
  const [videos, setVideos] = useState([]);
  const [loader, setLoader] = useState();
  const [activeVideo, setActiveVideo] = useState(null);
  const screenW = window.screen.width;
  useEffect(() => {
    async function getData(){
        await axios.get("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=12&playlistId=PLB30Ly_OJ5degcxMubesNvJXrqplHPAE-&key=AIzaSyAUZa_oSd5dur2tgRT5cRmT5SZyOYX0lc8")
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setVideos(response.data.items);
        })
        .catch((err) => {
          setLoader(false);
        });
    }

    getData();
  }, []);

  const opts = {
    height: screenW > 700 ? 390 : 195,
    width: screenW > 700 ? 640 : 320,
    playerVars: {
      autoplay: 1,
    },
  }

  return (
    loader ? (
      <Loader/>
    ) : (
      <List youtube>
        {videos.map((video, index) => (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <li className="item" style={{cursor: 'pointer'}} onClick={() => activeVideo === index ? setActiveVideo(null) : setActiveVideo(index)}>
              <div className="info">
                <h4><strong>{video.snippet.title}</strong></h4>
              </div>
              {activeVideo === index ? <FaArrowUp /> : <FaArrowDown />}
            </li>

            {activeVideo === index && (
              <div style={{alignSelf: 'center'}}>
                <YouTube videoId={video.snippet.resourceId.videoId} opts={opts}/>
              </div>
            )}
          </div>
        ))}
      </List>
    )
  );
};

export default Youtube;
