import React from 'react';
import { useColorMode } from '../../context/contextColorMode';
import {LinkUI} from './styles';

export const SeeMore = ({onClick, text, icon}) => {
    const {isEnabled} = useColorMode();
    
    return (
        <LinkUI darkMode={isEnabled}>
            <span onClick={onClick}>
                {icon} <p>{text}</p>
            </span>
        </LinkUI>
    )
}