import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Button from '../../../components/Button';
import ModalButtons from '../../../components/ModalButtons';
import ModalContent from '../../../components/ModalContent';
import { useSubModal } from '../../../context/contextSubModal';
import spinner from "../../../assets/images/spinner.svg";
import ContactDetail from '../ContactDetail';
import { Inputs } from './styles';
import api from '../../../services/api';
import ToastMessage from '../../../components/ToastMessage';

export const ChangeClientPassword = ({contactId}) => {
    const {setSubModalContent} = useSubModal();

    const [newPassword, setNewPassword] = useState('');
    const [verifyNewPassword, setVerifyNewPassword] = useState('');

    const [newPasswordVisible, setNewPasswordVisible] = useState(true);
    const [verifyPasswordVisible, setVerifyPasswordVisible] = useState(true);

    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [err400, setErr400] = useState(false);
    const [err401, setErr401] = useState(false);
    const [err406, setErr406] = useState(false);
    const [err422, setErr422] = useState(false);

    function toggleVisibleNewPassword() {
        setNewPasswordVisible(!newPasswordVisible);
    }

    function toggleVisibleVerifyPassword() {
        setVerifyPasswordVisible(!verifyPasswordVisible);
    }

    async function change(){
        const token = localStorage.getItem("@Atlantico:token");
        api.put(`v1/users/${contactId}/changePassword`, {
            compare: "false",
            password: "null",
            newPassword,
            verifyPassword: verifyNewPassword
        }, {
            headers: {
                Authorization: token,
            }
        })
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setSuccess(true);
          setSubModalContent(<ContactDetail contactId={contactId}/>);
        })
        .catch((err) => {
          setLoader(false);
          if (err.response.status === 422) {
            setErr422(true);
            setErr422(false);
          } else if (err.response.status === 401) {
            setErr401(true);
            setErr401(false);
          } else if (err.response.status === 406) {
            setErr406(true);
            setErr406(false);
          } else {
            setErr400(true);
            setErr400(false);
          }
        });
    }

    return (
        <div>
            <ModalContent>
                <div>
                    {success && <ToastMessage variant="success" message="Senha alterada com sucesso!"/>}
                    {err401 && <ToastMessage variant="error" message="Senha atual inválida." />}
                    {err406 && <ToastMessage variant="error" message="A nova senha não pertence aos requisitos necessários."/>}
                    {err422 && <ToastMessage variant="error" message="Senhas não coincidem!" />}
                    {err400 && <ToastMessage variant="error" message="Ocorreu um erro ao alterar a senha. Tente novamente."/>}
                </div>
                <Inputs>
                    {newPasswordVisible ? (
                    <div className="submit-line">
                        <input name="password" type="password" placeholder="Nova senha" value={newPassword}  onChange={(e) => setNewPassword(e.target.value)} />
                        <FaEyeSlash className="submit-icon" style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleNewPassword}/>
                    </div>
                    ) : (
                    <div className="submit-line">
                        <input name="password" type="text" placeholder="Nova senha"  value={newPassword}  onChange={(e) => setNewPassword(e.target.value)} />
                        <FaEye className="submit-icon" style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleNewPassword}/>
                    </div>
                    )}
                </Inputs>

                <Inputs>
                    {verifyPasswordVisible ? (
                    <div className="submit-line">
                        <input name="password" type="password" placeholder="Confirme a nova senha" value={verifyNewPassword} onChange={(e) => setVerifyNewPassword(e.target.value)} />
                        <FaEyeSlash className="submit-icon" style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleVerifyPassword}/>
                    </div>
                    ) : (
                    <div className="submit-line">
                        <input name="password" type="text" placeholder="Confirme a nova senha" value={verifyNewPassword} onChange={(e) => setVerifyNewPassword(e.target.value)}/>
                        <FaEye className="submit-icon" style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleVerifyPassword}/>
                    </div>
                    )}
                </Inputs>

                <h4>
                    <strong>Importante:</strong> A nova senha precisa ter de 6 a 20
                    caracteres e números. Precisa conter ao menos um símbolo (@#$%), uma
                    letra maiúscula e uma letra minúscula.
                </h4>
            </ModalContent>
            <ModalButtons>
                <Button
                    bgcolor="var(--blue)"
                    color="#fff"
                    border="var(--blue)"
                    hover="var(--blue-hover)"
                    expansive={true}
                    hollow={false}
                    size="small"
                    type="button"
                    onClick={() => change()}
                >
                    {loader ? <img src={spinner} alt="Alterar"/> : "ALTERAR"}
                </Button>
                <hr />
                <Button
                    color="var(--gray)"
                    border="var(--gray)"
                    expansive={true}
                    hollow={true}
                    size="small"
                    onClick={() => setSubModalContent(<ContactDetail contactId={contactId}/>)}
                >
                    CANCELAR
                </Button>
            </ModalButtons>
        </div>
    )
}