import React, { useState, useEffect } from "react";
import spinner from "../../../assets/images/spinner.svg";
import Button from "../../../components/Button";
import ItemForm from "../../../components/ItemForm";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import { useAuth } from "../../../context/contextAuth";
import { useNewRequest } from "../../../context/contextNewRequest";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";

import api from "../../../services/api";

const Date = ({ navigation }) => {
  const { user, primary } = useAuth();
  const {isEnabled} = useColorMode();
  const { date, type, title, description, setDate, setRequestNumber, company, companies, setCompany } = useNewRequest();
  const [loader, setLoader] = useState();
  const [error, setError] = useState(false);
  const { next, previous } = navigation;
  const token = localStorage.getItem("@Atlantico:token");

  useEffect(() => {
    if(companies.length === 1){
      setCompany(primary.reference);
    } else {
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleOpenRequestSubmit() {
    await api.post(`v1/users/${user._id}/tickets`,
        {
          subject: type,
          title: title,
          description: description,
          expec_date: date,
          reference: company,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(setLoader(true))
      .then((response) => {
        setLoader(false);
        setRequestNumber(response.data.id);
        next();
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  function validateField() {
    if (date !== "") {
      return handleOpenRequestSubmit();
    } else {
      setError(true);
      return;
    }
  }

  return (
    <>
        {error ? (
          <ToastMessage
            variant="info"
            message="Preencha este campo antes de continuar!"
          />
        ) : null}
        <ModalContent>
          <FormContact darkMode={isEnabled}>
            <h2>POR ÚLTIMO INFORME UMA DATA DE EXPECTATIVA</h2>
            <ItemForm type="date" name="date" value={date} onChange={e=>setDate(e.target.value)} />
          </FormContact>
        </ModalContent>
        <ModalButtons>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            onClick={async () => {
              await validateField();
              setError(false);
            }}
          >
            {loader ? <img src={spinner} alt="" /> : "ENVIAR"}
          </Button>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={previous}
          >
            VOLTAR
          </Button>
        </ModalButtons>
    </>
  );
};

export default Date;
