import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f1f7fa;

  .textarea-div{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    h4{
      margin: 0.5rem 0;
    }

    img{
      margin-bottom: 1rem;
    }

    textarea{
      padding: 1rem;
      border-radius: 10px;
      resize: none;
      width: 100%;
    }

    @media(min-width: 700px){
      padding: 2rem 20rem;

      h4{
        margin: 1.5rem 0;
      }
    }

    @media(min-width: 1025px){
      padding: 2rem 50rem;
    }
  }
`;