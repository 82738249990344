import React, { useState } from "react";
import InputMask from 'react-input-mask';
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useNewContact } from "../../../context/contextNewContact";
import { useColorMode } from "../../../context/contextColorMode";
import { FormContact } from "../styles";

const Date = ({  navigation }) => {
  const { date, setDate } = useNewContact();
  const {isEnabled} = useColorMode();
  const [error, setError] = useState(false);
  const { next, previous } = navigation;

  return (
      <>
        {error ? (
          <ToastMessage
            variant="info"
            message="Preencha este campo antes de continuar!"
          />
        ) : null}
        <ModalContent>
          <FormContact darkMode={isEnabled}>
            <h2>INFORME A DATA DE NASCIMENTO DO CONTATO</h2>
            <InputMask
              mask="99/99/9999"
              maskChar={null}
              value={date}
              placeholder="DD/MM/AAAA"
              onChange={(e) => setDate(e.target.value)}
            />
          </FormContact>
        </ModalContent>
        <ModalButtons>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            onClick={async () => {
              await validateField(date, 'text', next, setError);
              setError(false);
            }}
          >
            CONTINUAR
          </Button>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={previous}
          >
            VOLTAR
          </Button>
        </ModalButtons>
      </>
  );
};

export default Date;
