import React, { useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaPlus,
} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Paper,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";
import PickEmployee from "./events/submodals/employee";
import { useSubModal } from "../../context/contextSubModal";
import PickEditEvent from "./events/submodals/events";

import api from "../../services/api";


const validation = (alert, data, requiredData) => {

  if(!Object.keys(data.employees).length){
    alert({
      message:<div>Adicione ao menos um <b>Empregado</b> para lançamento em folha.</div>,
      state:true,
    })

    return false
  }

  if(!Object.entries(data.employees).every(row => row[1].EVENTOS.length>0)){
    alert({
      message:<div>Vincule ao menos um <b>Evento</b> aos Empregados para lançamento em folha.</div>,
      state:true,
    })

    return false
  }

  return requiredData.every((element,index) => {

      if(!data[element[0]]){

        alert({
          state:true,
          message: <div>O campo <b>{element[1]}</b> é obrigatório.</div>,
        })

        return false
      }

      return true
    })

}

const jsonToString = (initialState,data) => {

  return Object.entries(initialState).map(row => {

    if(data[row[0]]===false){
      return 0
    }

    if(data[row[0]]===true){
      return 1
    }

    if(!data[row[0]]){
      return "~"
    }

    return data[row[0]]

  }).join(';')

}

const requiredData = [
  ["COMPET","Competência"],
]

const PeriodoCalculo = {
  CODIGOEMPRESA: "",
  CODIGOPERCALCULO: "",
  CODIGOTIPOCALC: "1",
  CODIGOEMPRESACOMPL: "",
  CODIGOPERCALCCOMPL: "",
  CODIGOEMPRESAAGRUPADOR: "",
  CODIGOPERCALCULOAGRUPADOR: "",
  CODIGOSINDDISSIDIOEMP: "",
  TIPODCTOCOMPL: "",
  TIPOINTEGRACAO: "",
  TIPOREGRATRIBCOMPL: "",
  TIPOAPURACAOANTERIOR: "",
  COMPET: "",
  DATAINICIALFOLHA: "",
  DATAFINALFOLHA: "",
  DATAPGTO: "",
  DATAPGTODIRET: "",
  SEQ: "",
  POSSUIPARCELAMENTO: "0",
  FECHADO: "0",
}

const initialState = {
  ...PeriodoCalculo,
  DESCRICAO: "",
  employees: [],
}

const Events = ({ draftId, type, compet }) => {
  const history = useHistory();
  const { primary, user } = useAuth();
  const {isEnabled} = useColorMode();
  const [state,setState] = useState(initialState)
  const {setIsSubModalVisible, setSubModalContent} = useSubModal()
  const [progressDraft, setProgressDraft] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState(false)
  const [taskId,setTaskId] = useState(0)
  const [submitError, setSubmitError] = useState(false)
  const token = localStorage.getItem("@Atlantico:token");

  const headers = {
    headers: {
      Authorization: token,
    },
  }

  const [required,setRequired] = useState({
    message:"",
    state:false,
  })

  const [success,setSuccess] = useState({
    message:"",
    state:false,
  })

  const postPeriod = (payload) => (

    api.post(`v1/nweb/import`,payload,headers)
    .then(response => {
      // console.log(response.data)
      return true
    })
    .catch(error => {

      onSubmitError(error)
      return false

    })

  )

  const onSubmitError = (error) => {
    if(error.response){
      console.log(error.response.data)
    }

    setSubmitError(true)
  }

  const handleChange = (e,obj=false) => {
    
    if(obj){

      let newObj = {}

      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key]
      })

      setState({
        ...state,
        ...newObj,
      })

      // console.log({
      //   ...state,
      //   newObj,
      // })

    }else{
      const el = e.target
      const name = el.name;
      const value = el.type === "checkbox" ? el.checked : el.value;

      setState({
        ...state,
        [name]: value
      })

      // console.log({
      //   ...state,
      //   [name]: value
      // })
    }

  }

  const onSubmit = async (e) => {
    e.preventDefault()

    setRequired({
      message:"",
      state:false,
    })
    
    const validate = validation(setRequired,state,requiredData)

    if(validate){
      handleDraftSave()

      history.push({pathname: '/dashboard/pessoas-eventos-em-folha-recibo', state: {modal: true}, data: {draft:state, _id:draftId, task: taskId}, type: "manual"})
    }
  }

  const getCompet = async (filterValue=null) => {
    let filter = { Filter: { CODIGOEMPRESA:primary.reference } }

    if(filterValue){
      filterValue.map(item => {
        if(item.value){
          filter.Filter[item.name] = item.value
        }
      })
    }
  
    const params = {
      classe: "TnFpaDCPeriodoCalculo2",
      limit:1,
      start:0,
      sort:"desc",
      orderby:2,
    }

    const qs = '?' + new URLSearchParams(params).toString()
  
    return await api.post(`v1/nweb${qs}`,filter,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(async (response) => {
      let data = response.data.data

      if(parseInt(response.data.records)){
        return Number(data[0].CODIGOPERCALCULO)
      }else{
        return 0
      }
    })
    .catch((err) => {
      console.error(err)
      return 0
    })
  }

  const getTask = async () => {
    const now = new Date();
    let payload = { 
      client: primary._id,
      taskTypes:[1683606,1689892,1689893,1951827]
     }
  
    return await api.post(`v1/tasks/apiHistory/getLastIdByClientAndTaskType`,payload,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(async (response) => {
      let data = response.data.task
      
      if (!data.length) return false;
      const taskDate = new Date(data[0].created_at);

      console.log({
        task:{
          month:taskDate.getMonth(),
          year:taskDate.getFullYear(),
        },
        now:{
          month:now.getMonth(),
          year:now.getFullYear(),
        },
        isEqualMonth:(now.getMonth())===taskDate.getMonth(),
        isEqualYear:now.getFullYear()===taskDate.getFullYear()
      })

      if(
        now.getMonth()===taskDate.getMonth()
        &&
        now.getFullYear()===taskDate.getFullYear()
        ){
          return data[0].id;
        }
      
      return false;
    })
    .catch((err) => {
      console.error(err)
      return false
    })
  }

  const loadDraft = async () => {
    return await api.get(`v1/fopag/events?_id=${draftId}`,headers)
      .then(async (response) => {
        setState({
          ...state,
          ...response.data[0].draft,
        })
        return true
      })
      .catch((err) => {
        console.error(err)
        return false
      })
  }

  const initData = async () => {
    const date = new Date()
    const taskId = await getTask();

    console.log(`Localizou a tarefa: ${taskId}`);

    if (!taskId) {
      setError(true)
      return false
    }

    setTaskId(taskId);

    if(draftId){
      const draft = await loadDraft();

      if (!draft) {
        setError(true)
        return false
      }

      setLoading(false)
      return false
    }

    console.log(`Localizou o rascunho: ${draftId}`);

    // let COMPET = `11/2022`
    let COMPET = `${('0' + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
    let CODIGOPERCALCULO = 0

    console.log(`Competência atual: ${COMPET}`);

    //Verifica se existe a competência para o mês
    const exists = await getCompet([{name:"CODIGOTIPOCALC",value:1},{name:"COMPET",value:COMPET}])

    console.log(`Competência já foi criada: ${exists}`);

    if(exists){
      CODIGOPERCALCULO = exists
    }else{

      //Verifica o último código do período de cálculo existente para o cliente
      const lastID = await getCompet()

      console.log(`Competência será criada: ${lastID}`);

      if(lastID){

        CODIGOPERCALCULO = lastID+1 //Adiciona 1 para criar o próximo código
        // console.log(`${CODIGOPERCALCULO}: criar período!`)

        const COMPETSPLIT = COMPET.split("/")
        const LASTDAYOFTHEMONTH = new Date(COMPETSPLIT[1],COMPETSPLIT[0], 0)
        const COMPETDATE = new Date(COMPETSPLIT[1],COMPETSPLIT[0]-1)

        const DATAINICIALFOLHA = `${COMPETDATE.getFullYear()}-${(COMPETDATE.getMonth()+1).toString().padStart(2, '0')}-${COMPETDATE.getDate().toString().padStart(2, '0')}`
        const DATAFINALFOLHA = `${LASTDAYOFTHEMONTH.getFullYear()}-${(LASTDAYOFTHEMONTH.getMonth()+1).toString().padStart(2, '0')}-${LASTDAYOFTHEMONTH.getDate().toString().padStart(2, '0')}`
        const DATAPGTO = DATAFINALFOLHA
        const DATAPGTODIRET = DATAFINALFOLHA

        let payload = {
          layout: "PeriodoCalculo",
          data: jsonToString(PeriodoCalculo,{
            ...state,
            CODIGOEMPRESA: primary.reference,
            CODIGOPERCALCULO,
            COMPET: DATAINICIALFOLHA,
            DATAINICIALFOLHA,
            DATAFINALFOLHA,
            DATAPGTO,
            DATAPGTODIRET,
          })
        }

        const periodoCalculo = await postPeriod(payload)

        if(!periodoCalculo){
          setError(true)
          return false
        }

      }else{
        setError(true)
        return false
      }

    }

    setState({
            ...state,
            CODIGOEMPRESA: primary.reference,
            COMPET,
            CODIGOPERCALCULO,
          })

    setLoading(false)

  }

  useEffect(() => {

    initData()
  
  },[])

  const handleDraftSave = () => {
    
    if(!Object.keys(state.employees).length){

      setRequired({
        message:"Insira ao menos um empregado.",
        state:true,
      })

      return false

    }

    setProgressDraft(true)

    const payload = {
      client: primary.reference,
      user: user._id,
      draft: state,
      task: taskId,
      compet: state.COMPET,
    }

    const baseUrl = draftId ? api.put(`v1/fopag/events/${draftId}`,payload,headers) : api.post(`v1/fopag/events`,payload,headers)

    baseUrl
    .then(response => {      
      setSuccess({
        message:<div><b>Rascunho</b> salvo com sucesso!</div>,
        state:true,
      })
    })
    .catch(error => {
      setRequired({
        message:"Ops! Não foi possível salvar o rascunho no momento, tente novamente mais tarde.",
        state:true,
      })
    })
    .then(() => {
      setProgressDraft(false)
    })
  }

  const handleDownloadCsv = async () => {
    setDownloading(true);
    await api.get(`v1/questor/downloadCsvByEmployeesAndEvents?reference=${primary.reference}`,{
      headers: {
        Authorization: token,
      },
      responseType: 'blob'
    })
    .then(async (response) => {
      var url = window.URL.createObjectURL(response.data);
      var anchor = document.createElement("a");
      anchor.download = `funcionarios-folha.csv`;
      anchor.href = url;
      anchor.click();
      setDownloading(false);
    })
    .catch((err) => {
      console.error(err)
    })
  }

  // useEffect(() => {
  //   console.log(state)
  // }, [state])

  const columns = [
    {
        name: 'NOMEFUNC',
        header: 'Empregado',
        defaultFlex: 2,
        render: ({ data }) => {
        return data[1].NOMEFUNC
        }
    },
    { name: '0', header: 'Eventos', defaultVisible: false, defaultWidth: 60, type: 'number'},
    {
        name: 'NUMEVENTOS',
        header: 'Eventos',
        defaultWidth: 80,
        render: ({ data }) => {
        return data[1].EVENTOS.length
        }
    },
    {
        name: 'OPTN',
        header: '',
        defaultWidth: 110,
        render: ({ data }) => {
        return <Button
          variant="contained"
          fullWidth
          size="small"
          startIcon={<FaPlus size={10} />}
          onClick={() => {
            setIsSubModalVisible(true);
            setSubModalContent(<PickEditEvent setState={setState} state={state} employee={data[1]} CODIGOEMPRESA={primary.reference} setIsSubModalVisible={setIsSubModalVisible} />)
          }}
          >EVENTO</Button>
        }
    },
  ]

  if(error){
    return <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível continuar sua solicitação, volte e tente novamente mais tarde. Persistindo o problema, entre em contato com nossos canais de suporte!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }}>Voltar</Button>
      </Paper>
  }

  if(loading){
    return <LinearProgress />
  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

          <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
          <Grid container>
            <Grid item xs={12} style={{padding:1}}>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                  select
                  fullWidth
                  label="Empresa"
                  name="CODIGOEMPRESA"
                  defaultValue={state.CODIGOEMPRESA}
                  disabled
                  SelectProps={{
                    native: true,
                  }}
                  >
                    <option key={primary.reference} value={primary.reference}>{primary.name}</option>
                  </TextField>
                </Grid>
                
                <Grid item xs={12} lg={6}>
                  <TextField fullWidth id="COMPET" label="Competência" disabled defaultValue={state.COMPET} name="COMPET" />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField fullWidth multiline rows={3} label="Informações adicionais" name="DESCRICAO" defaultValue={state.DESCRICAO} onChange={handleChange} />
                </Grid>
              </Grid>

              <Card style={{marginTop:20}}>
                <CardContent>

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}><Typography variant="overline" display="block" gutterBottom>Empregados/Eventos</Typography></Grid>
                    {type==="manual" && <Grid item xs={12} lg={6} style={{ textAlign:"right" }}>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<FaPlus size={15} />}
                        onClick={() => {
                            setIsSubModalVisible(true);
                            setSubModalContent(<PickEmployee setState={setState} state={state} CODIGOEMPRESA={primary.reference} setIsSubModalVisible={setIsSubModalVisible} />)
                        }}
                        >Empregado</Button>
                    </Grid>}
                  </Grid>
                  <Divider style={{marginBottom:20}} />

                  {type==="manual" && <div>
                    <ReactDataGrid
                      idProperty="CODIGOFUNCPESSOA"
                      columns={columns}
                      dataSource={Object.entries(state.employees)}
                      theme="default-light"
                      style={{ minHeight: 350 }}
                    />         
                  </div>}

                  {type==="xls" && <div>
                  <Typography variant="body1">
                    Clique no botão abaixo para realizar o donwload da planilha contendo os empregados e eventos relacionados a sua empresa. Abra a planilha no excel, remova as linhas dos empregados que não faram parte deste lançamento e preencha os campos dos eventos. Por último, salve a planilha e suba novamente ela aqui.
                  </Typography>

                  <Grid container spacing={2} style={{marginTop:20}}>
                    <Grid item xs={12} lg={6}>
                      <Button variant="contained" fullWidth color="primary" onClick={handleDownloadCsv}>{downloading ? <CircularProgress color="secondary" size={20} /> : "Donwload da planilha"}</Button>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <input
                        id="contained-button-file"
                        multiple
                        type="file"
                        style={{display:"none"}}
                      />
                      <label htmlFor="contained-button-file">
                        <Button variant="contained" color="default" fullWidth component="span">
                          Selecionar planilha
                        </Button>
                      </label>

                    </Grid>
                  </Grid>
                  </div>}
                  
                </CardContent>
              </Card>
                
            </Grid>
          </Grid>
          </div>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
          <Divider style={{marginTop:10}} />

          {required.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#FC5757",backgroundColor:"#FEDDDE"}}>{required.message}</Paper>}
          {success.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#2D3D0F",backgroundColor:"#E0EFC1"}}>{success.message}</Paper>}

          <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
              <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }}>Voltar</Button>
              {type==="manual" && <Button onClick={handleDraftSave}>{progressDraft ? <CircularProgress size={20} /> : "Salvar Rascunho"}</Button>}
              <Button color="secondary" type="submit">Enviar</Button>
          </ButtonGroup>
      </ModalButtons>
    </ form>
  );
};

export default Events;