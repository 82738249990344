import styled from 'styled-components';

export const DivNps = styled.div`

    .button-and-search{
        display: ${props => props.isAdmin ? 'grid' : 'flex'};
        grid-template-columns: ${props => props.isAdmin ? '1fr 1fr 1fr 1fr' : ''};
        grid-template-areas: ${props => props.isAdmin ? "'button search search search'" : ''};
        grid-gap: ${props => props.isAdmin ? '4rem' : ''};

        #button{
            grid-area: button;
            align-self: center;
        }

        #search{
            grid-area: search;
            width: 100%;
        }
    }

    hr{
        border: 1px solid var(--divisor);
        margin: 2rem 0;
    }

    .survey-list{
        h3{
            text-transform: uppercase;
            color: var(--atlantico);
            margin-bottom: 1rem;
        }
    }
    
`;

export const Search = styled.div`
  position: relative;

  input {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    outline: 0;
    padding: 1.1rem 2rem;
    padding-left: 4rem;
    background-color: ${props => props.darkMode ? '#121212' : '#F4F4F4'};
    color: #bbbbbb;
    border: 0;
  }

  svg {
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    color: #2699fb;
  }

  @media (min-width: 700px) {
    svg {
      top: 1rem;
    }
  }

  @media (min-width: 1025px) {
    input {
      height: auto;
      padding: 1.5rem 2rem;
      padding-left: 5rem;
      color: #bbbbbb;
    }
    svg {
      top: 1.7rem;
    }
  }
`;