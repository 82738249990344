import React, { useState } from "react";
import ModalContent from "../../components/ModalContent";
import ChangePasswordForm from "../../components/ChangePasswordForm";
import ToastMessage from "../../components/ToastMessage";
import { useAuth } from "../../context/contextAuth";
import { useSubModal } from "../../context/contextSubModal";

import api from "../../services/api";

const ChangePassword = () => {
  const { setIsSubModalVisible } = useSubModal();

  const { user } = useAuth();

  const [loader, setLoader] = useState();

  const [success, setSuccess] = useState(false);
  const [err400, setErr400] = useState(false);
  const [err401, setErr401] = useState(false);
  const [err406, setErr406] = useState(false);
  const [err422, setErr422] = useState(false);

  const formData = {
    password: "",
    newPassword: "",
    verifyPassword: "",
  }

  async function handleChangePassword(values){
    const token = localStorage.getItem("@Atlantico:token");
    return await api.put(`v1/users/${user._id}/changePassword`,
        {
          password: values.password,
          newPassword: values.newPassword,
          verifyPassword: values.verifyPassword,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(setLoader(true))
      .then((response) => {
        setLoader(false);
        setSuccess(true);
      })
      .then(() => {
        setTimeout(function () {
          setIsSubModalVisible(false);
        }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.status === 422) {
          setErr422(true);
          setErr422(false);
        } else if (err.response.status === 401) {
          setErr401(true);
          setErr401(false);
        } else if (err.response.status === 406) {
          setErr406(true);
          setErr406(false);
        } else {
          setErr400(true);
          setErr400(false);
        }
      });
  };

  return (
    <>
        <ModalContent hasFooter={true}>
          <div>
              {success && <ToastMessage variant="success" message="Senha alterada com sucesso!"/>}
              {err401 && <ToastMessage variant="error" message="Senha atual inválida." />}
              {err406 && <ToastMessage variant="error" message="A nova senha não pertence aos requisitos necessários."/>}
              {err422 && <ToastMessage variant="error" message="Senhas não coincidem!" />}
              {err400 && <ToastMessage variant="error" message="Ocorreu um erro ao alterar a senha. Tente novamente."/>}
          </div>
          <ChangePasswordForm
            formData={formData}
            onSubmit={handleChangePassword}
            loader={loader}
          />
        </ModalContent>
    </>
  );
};

export default ChangePassword;
