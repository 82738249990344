export const formatDate = (string) => {
    const str = string.split('T');

    const day = str[0];
    let FormattedDay = day.split('-');
    FormattedDay = FormattedDay.reverse();
    FormattedDay = FormattedDay.join('/');

    const time = str[1];
    let FormattedTime = time.split(':');
    FormattedTime = FormattedTime.splice(0, 2);
    FormattedTime = FormattedTime.join(':');

    return([FormattedDay, FormattedTime]);
}