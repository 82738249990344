import React, { useState, useEffect } from "react";
import { FaFolder } from "react-icons/fa";
import { FaRegFolder, FaPlusSquare } from "react-icons/fa";
import Button from "../../components/Button";
import List from "../List";
import {SeeMore} from "../SeeMore";
import Message from "../Message";
import Loader from "../Loader";
import { useAuth } from "../../context/contextAuth";

import api from "../../services/api";
import { Link } from "react-router-dom";

const ReceivedFiles = () => {
  const { primary, user } = useAuth();

  const [tasks, setTasks] = useState([]);
  const token = localStorage.getItem("@Atlantico:token");
  const [lastTask, setLastTask] = useState({});
  const [loader, setLoader] = useState();
  const [count, setCount] = useState(0);
  const [noProjects, setNoProjects] = useState(false);

  async function getData(){
    const joinedProfile = user.profile.join(',')

    await api.get(`v1/clients/${primary._id}/tasks?project_id=${primary.runrunit_projects[0]}&limit=12&user_profile=${joinedProfile}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(setLoader(true))
    .then((response) => {
      setLoader(false);
      setLastTask(response.data.tasks[0]);
      response.data.tasks.splice(0, 1);
      setTasks(response.data.tasks);
      console.log(response.data.tasks.length);
    })
    .catch((err) => {
      setLoader(false);
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if(primary.runrunit_projects.length > 0){
        getData();
      } else {
        setNoProjects(true);
      }
    }, 100)
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary, token])

  useEffect(() => {
      const interval = setInterval(() => {
        if(primary.runrunit_projects.length > 0){
          getData()
        }else {
          setNoProjects(true);
        }
        setCount(count + 1);
      }, 600000);
      return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[count, primary._id, primary.runrunit_projects, token]);

  async function getDocuments(id) {
    return await api.get(`v1/clients/taskDocuments?id=${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        return response.data.documents;
      })
      .catch((err) => {
        return false;
      });
  }

  async function downloadZipFile(taskId) {
    const response = await getDocuments(taskId);
    const arrayDocuments = [];
    response.map((object) => arrayDocuments.push(object.id));
    const string = arrayDocuments.toString();
    await api.get(`v1/users/${user._id}/downloadTaskZipDocuments?ids=${string}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        window.open(response.data.link);
      });
  }

  return (
    <>
      <FaRegFolder size={60} color="var(--blue)" />
      {loader ? (
        <Loader/>
      ) : (
        <>
          {noProjects ? (
            <Message severity="warning" message="Nenhum projeto ativo!"/>
          ) : (
            <h5>
              <small>
                <strong style={{color: 'var(--text)'}}>
                  {lastTask ? lastTask.closed_at_formatted : ""}{" "}
                  {lastTask ? ` • ${lastTask.attachments_count} Arquivos` : ""}
                </strong>
              </small>
            </h5>
          )}
          
          <h3 style={{ textAlign: "center", color: 'var(--text)', width: '100%' }}>
            {lastTask ? (
              noProjects ? '' : lastTask.title
            ) : (
              <Message severity="warning" message="Nenhum arquivo até o momento!"/>
            )}
          </h3>
          {lastTask && !noProjects ? (
          <div style={{ width: "100%", margin: "1rem 0" }}>
              <>
                <Button
                  bgcolor="var(--green)"
                  color="#fff"
                  hover="var(--green-hover)"
                  border="var(--green)"
                  hollow={false}
                  expansive={true}
                  size="small"
                  onClick={() => downloadZipFile(lastTask.id)}
                >
                  <small>
                    <small>BAIXAR ARQUIVO ZIP</small>
                  </small>
                </Button>
                <Link to={{pathname: `/dashboard/tarefa/${lastTask.id}`, state: {modal: true, props: lastTask}}}>
                  <Button bgcolor="var(--blue)" color="#fff" hover="var(--blue-hover)" border="var(--blue)" hollow={false} expansive={true} size="small">
                    <small><small>VER DETALHES</small></small>
                  </Button>
                </Link>
              </>
          </div>
          ) : null}
        </>
      )}

      {loader ? (
        <Loader/>
      ) : (
        <>
          {noProjects ? (
            ''
          ) : (
          <List>
              {tasks.map((task) => {
                return (
                  <li className="documents" key={task.id}>
                      <FaFolder size={32} />
                      <div className="info">
                        <Link to={{pathname: `/dashboard/tarefa/${task.id}`, state: {modal: true, props: task}}}>
                          <strong title={task.title}> {task.title}</strong>
                        </Link>
                        <span><small>Modificado em {task.closed_at_formatted}</small></span>
                      </div>
                  </li>
                );
              })}
          </List>
          )}
          {lastTask  && !noProjects ? (
            <Link style={{width: '100%', textDecoration: 'none'}} to={{pathname: "/dashboard/arquivos-recebidos", state: {modal: true}}}>
              <SeeMore icon={<FaPlusSquare />} text="Ver Arquivos Recebidos" />
            </Link>
          ) : null}
          
        </>
      )}
    </>
  );
};

export default ReceivedFiles;
