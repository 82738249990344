import React, { useEffect, useState } from 'react';
import neutral from '../../../assets/images/neutralicon.svg';
import happy from '../../../assets/images/happyicon.svg';
import angry from '../../../assets/images/angryicon.svg';
import List from '../../../components/List';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import SurveyButtons from '../../../components/SurveyButtons';
import Message from '../../../components/Message';
import InsatisfiedClients from '../InsatisfiedClients';
import { handleNpsIcon } from '../../../utils/handleNpsIcon';
import { formatDate } from '../../../utils/formatDate';
import { useSubModal } from '../../../context/contextSubModal';
import { useColorMode } from '../../../context/contextColorMode';
import { useSurvey } from '../../../context/contextSurvey';

import {Div, CommentsDiv, CustomFilter, SelectQuestion} from './styles';

import api from '../../../services/api';
import { Link } from 'react-router-dom';

const NpsDetail = ({surveyId}) => {
    const {isEnabled} = useColorMode();
    const [loader, setLoader] = useState(false);
    const token = localStorage.getItem('@Atlantico:token');
    const [detail, setDetail] = useState();
    const [index, setIndex] = useState(0);
    const [questionsIndexes, setQuestionsIndexes] = useState();
    const {setIsSubModalVisible, setSubModalContent} = useSubModal();

    const { customDates, setDayInit, setDayEnd, filter, dayInit, dayEnd } = useSurvey();

    useEffect(() => {
        async function getData(){
           await api.get(`v1/survey/nps?_id=${surveyId}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(response => setQuestionsIndexes(response.data[0].questions))
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyId])

    useEffect(() => {
        async function getData(){
           await api.get(`v1/survey/nps/report?survey=${surveyId}&filter=${filter}&startAt=${dayInit}&endAt=${dayEnd}&index=${index}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setDetail(response.data);
            })
            .catch(err => {
                setLoader(false);
            })
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, dayInit, dayEnd, index])

    function handleColor(value){
        if((value >= 0 && value <= 49) || value < 0){
            return '#fd6643'
        } else if (value >= 50 && value <= 74){
            return '#fed940'
        } else {
            return '#3fb653'
        }
    }

    return (
        <Div darkMode={isEnabled}>
            <div className="buttons">
                <Link style={{textDecoration: 'none'}} to={{pathname: `/admin/salvar-pesquisa`, state: {modal: true, props: surveyId}}}>
                    <Button id="button1" bgcolor="var(--blue)" color="white" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small"  noMargin={true}>
                        Editar
                    </Button>
                </Link>
                
                {questionsIndexes && (
                    <SelectQuestion darkMode={isEnabled} value={index} onChange={(e) => setIndex(e.target.value)}>
                        {questionsIndexes.map((question, index) => (
                            <option value={index} key={index}>{question}</option>
                        ))}
                    </SelectQuestion>
                )}
                
            </div>

            <hr />
            
            <div className="filters">
                <SurveyButtons/>
            </div>
          
            {customDates && (
                <CustomFilter darkMode={isEnabled}>
                    <div className="title_input">
                      <h3><strong>Inicio:</strong></h3>
                      <input type="date" onChange={(e) => setDayInit(e.target.value)}/>
                    </div>
                    <div className="title_input">
                      <h3><strong>Fim:</strong></h3>
                      <input type="date" onChange={(e) => setDayEnd(e.target.value)}/>
                    </div>
                </CustomFilter>
            )}

            {loader ? <Loader /> : (
                detail && (
                    detail.total > 0 ? (
                        <div style={{display: 'flex', width: '100%'}}>
                            <div className="info">
                                <div className="score">
                                    <div className="categories">
                                        <div id="detractors">
                                            <h5><strong style={{color: 'var(--gray)'}}>DETRATORES</strong></h5>
                                            <div className="div-area">
                                                <div className="area"/>
                                            </div>             
                                            <div className="total">
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.zero}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>0</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.one}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>1</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.two}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>2</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.three}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>3</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.four}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>4</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.five}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>5</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FD6643'}}><strong>{detail.six}</strong></h5>
                                                    <img src={angry} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>6</strong></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="passives">
                                            <h5><strong style={{color: 'var(--gray)'}}>PASSIVOS</strong></h5>
                                            <div className="div-area">
                                                <div className="area"/>
                                            </div>
                                            <div className="total">
                                                <div>
                                                    <h5 style={{color: '#FED940'}}><strong>{detail.seven}</strong></h5>
                                                    <img src={neutral} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>7</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: '#FED940'}}><strong>{detail.eight}</strong></h5>
                                                    <img src={neutral} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>8</strong></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="promoters">
                                            <h5><strong style={{color: 'var(--gray)'}}>PROMOTORES</strong></h5>
                                            <div className="div-area">
                                                <div className="area"/>
                                            </div>
                                            <div className="total">
                                                <div>
                                                    <h5 style={{color: 'var(--green)'}}><strong>{detail.nine}</strong></h5>
                                                    <img src={happy} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>9</strong></h5>
                                                </div>
                                                <div>
                                                    <h5 style={{color: 'var(--green)'}}><strong>{detail.ten}</strong></h5>
                                                    <img src={happy} alt="Expressão conforme a nota"/>
                                                    <h5 className="note"><strong>10</strong></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="totalScore">
                                    <div className="circle"  style={{backgroundColor: handleColor(detail.score)}}>
                                        <h1>{detail.score}</h1>
                                        <h4>NPS SCORE</h4>
                                    </div>
                                    <div className="listTotal">
                                        <div className="itemList">
                                            <img src={happy} alt="Expressão conforme a nota"/>
                                            <div className="info-item">
                                                <span><strong style={{color: 'var(--gray)'}}>{detail.promoters.length} PROMOTORES</strong></span>
                                                <span><strong style={{color: 'var(--green)'}}>{Math.floor(detail.promoters.length/detail.total*100)}%</strong></span>
                                            </div>
                                        </div>
                                        <div className="itemList" style={{cursor: 'pointer'}} onClick={() => {
                                            setIsSubModalVisible(true);
                                            setSubModalContent(<InsatisfiedClients title="PASSIVOS" users={detail.passives}/>);
                                        }}>
                                            <img src={neutral} alt="Expressão conforme a nota"/>
                                            <div className="info-item">
                                                <span><strong style={{color: 'var(--gray)'}}>{detail.passives.length} PASSIVOS</strong></span>
                                                <span><strong style={{color: '#FED940'}}>{Math.floor(detail.passives.length/detail.total*100)}%</strong></span>
                                            </div>
                                        </div>
                                        <div className="itemList" style={{cursor: 'pointer'}} onClick={() => {
                                            setIsSubModalVisible(true);
                                            setSubModalContent(<InsatisfiedClients title="DETRATORES" users={detail.detractors}/>);
                                        }}>
                                            <img src={angry} alt="Expressão conforme a nota"/>
                                            <div className="info-item">
                                                <span><strong style={{color: 'var(--gray)'}}>{detail.detractors.length} DETRATORES</strong></span>
                                                <span><strong style={{color: '#FD6643'}}>{Math.floor(detail.detractors.length/detail.total*100)}%</strong></span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '40%'}}>
                                <CommentsDiv>
                                    <h3>Comentários</h3>
                                    {detail.comments.length !== 0 ? (
                                        <List darkMode={isEnabled}>
                                            {detail.comments.map((c, index) => {
                                                return (
                                                    <li className="comments" key={index}>
                                                        <div style={{display: "flex"}}>
                                                            <img src={handleNpsIcon(c.answer)} style={{width: 32, marginRight: '1rem'}} alt=""/>
                                                            <div>
                                                                <h4><strong>{c.user}</strong></h4>
                                                                <h5 style={{fontWeight: 'normal'}}>{formatDate(c.data)[0]} - {formatDate(c.data)[1]}</h5>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p>{c.comment}</p>
                                                    </li>
                                                )
                                            })}
                                        </List>
                                    ) : <Message severity="warning" message="Não existem comentários para esse filtro!"/>}
                                    
                                </CommentsDiv>
                            </div>
                            
                        </div>
                    ) : (
                        <Message severity="warning" message="Não existem dados para esse filtro!"/>
                    )
                
                )
            )}
            
            
        </Div>
    )
}

export default NpsDetail;