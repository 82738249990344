import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Divider,
  Grid,
  TextField,
  Paper,
  CircularProgress,
  LinearProgress,
  Typography,
  Backdrop,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import PeriodoAquisitivo from "./employee/submodals/periodoAquisitivo";
import { useSubModal } from "../../context/contextSubModal";

import { DivManagementPeople } from "./styles";
import { FaCheckCircle } from "react-icons/fa";
import { rr } from "../../services/runrunit";
import api from "../../services/api";

import MotivoDemissao from "./employee/submodals/motivoDemissao";

const validation = (alert, data, requiredData) => {

  return requiredData.every((element,index) => {

      if(!data[element[0]]){

        alert({
          state:true,
          message: <div>O campo <b>{element[1]}</b> é obrigatório.</div>,
        })

        return false
      }

      return true
    })

}

const jsonToString = (initialState,data) => {

  return Object.entries(initialState).map(row => {

    if(data[row[0]]===false){
      return 0
    }

    if(data[row[0]]===true){
      return 1
    }

    if(!data[row[0]]){
      return "~"
    }

    return data[row[0]]

  }).join(';')

}

const requiredData = [
  ["AVISOPREVIO","Tipo Aviso Prévio"],
  ["DESCRMOTDEM","Motivo de Demissão"],
  ["DATAAVPREVIO","Data do Aviso Prévio"],
]

const funcRescicao = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  COMPLEMENTAR: 1,
  TIPORESCISAO: 1,
  CODIGOSINDDISSIDIOEMP: "",
  CODIGOPERCALCULO: "",
  CONSIDERARHISTSAL: "0",
  AVISOPREVIO: "",
  CODIGOCAUSA: "1",
  CODIGOSAQUE: "0",
  TIPOAFASTSEFIP: "",
  TIPOAFASTCAGED: "",
  TIPOAFASTRAIS: "",
  CODIGOMOTDEM: "",
  DATAAVPREVIO: "",
  INICIOCONTAGEM: "1",
  REPOSICAOVAGA: "0",
  NOVOEMPREGOAVPREVIO: "0",
  DISPENSAAVPREVIO: "0",
  CUMPRIUJORNADAINTEGRALSEMAFAST: "0",
  JORNADATRABSABCOMPENSEMAFAST: "0",
  DIASAVISOTRAB: "",
  DIASAVINDEN: "",
  DIASAVREAV: "",
  DATARESC: "",
  DATAPGTORESC: "",
  DATAPAGTOREVISADO: "",
  DATAHOMOLOGRESC: "",
  LOCALHOMOLOGRESC: "",
  DIASTRAB: "30",
  DIASAVPROP: "",
  AVISOPREPROPFERIAS: "",
  AVISOPREPROP13: "",
  DIASESTABIL: "",
  DIASGRATIF: "",
  SALFINAL: "0",
  FGTSMESANT: "1",
  SALDOFGTS: "0",
  OBSERVACAO: "",
  REMMESANT: "0",
  REMMESANT13: "0",
  VALORMESANT: "0",
  REMMESANTRECOL: "0",
  REMMESANT13RECOL: "0",
  VALORMESANTRECOL: "0",
  REMMES: "0",
  REMMES13: "0",
  VALORMES: "0",
  REMMESRECOL: "0",
  REMMES13RECOL: "0",
  VALORMESRECOL: "0",
  REMINDEN: "0",
  REMINDEN13: "0",
  VALORINDEN: "0",
  REMINDENRECOL: "0",
  REMINDEN13RECOL: "0",
  VALORINDENRECOL: "0",
  REMMULTA: "0",
  VALORMULTA: "0",
  REMMULTARECOL: "0",
  VALORMULTARECOL: "0",
  BASEINSS: "0",
  VALORINSS: "0",
  DEDUCAO: "0",
  SALFAMILIA: "0",
  AVOSFERIASPROP: "0",
  AVOSFERIASIND: "",
  CODIGOOUTEMP: "",
  AVOS13PROP: "0",
  CODIGOFPAS: "",
  FGTSRECOL: "0",
  REMUNRESCISAO: "0",
  REMUNSEGDESEMPANT: "0",
  REMUNSEGDESEMPPEN: "0",
  REMUNSEGDESEMPULT: "0",
  REMUNULT6MESES: "0",
  DATACALCATRASOGRFC: "",
  JAMMESANT: "0",
  JAMMES: "0",
  JAMINDEN: "0",
  JAMMULTA: "0",
  CODIGOUSUARIO: "",
  DATAHORALCTO: "",
  ORIGEMDADO: "",
  DIASSALDOSALARIO: "",
  MOTIVODESLIGAMENTODIRETOR: "",
  PERCPENSAOJUD: "",
  NUMCERTOBITO: "",
  NUMPROCTRAB: "",
  QUARENTENAREMUNERADA: "",
  DATAQUARENTENA: "",
  INDCUMPPARCIALAVISOPREVIO: "",
  TIPOALTERACAOESOCIAL: "0",
}

const initialState = {
  ...funcRescicao,
  DESCRMOTDEM: "",
}

const Termination = ( { employee } ) => {
  const token = localStorage.getItem("@Atlantico:token");
  const {setIsSubModalVisible, setSubModalContent} = useSubModal()
  const [tab, setTab] = useState("general")
  const [state,setState] = useState(initialState)
  const history = useHistory();
  const { primary, user } = useAuth();
  const {isEnabled} = useColorMode();
  const [submitError, setSubmitError] = useState(false)
  const [progress,setProgress] = useState({
    state: false,
    step: 0,
    progress:0,
  })

  const headers = {
    headers: {
      Authorization: token,
    },
  }

  const [required,setRequired] = useState({
    message:"",
    state:false,
  })

  const [success,setSuccess] = useState({
    message:"",
    state:false,
  })

  const postData = (payload) => (

    api.post(`v1/questor/rescisao`,payload,headers)
    .then(response => {
      // console.log(response.data)
      return true
    })
    .catch(error => {

      onSubmitError(error)
      return false

    })

  )

  const onSubmitError = (error) => {
    if(error.response){
      console.log(error.response.data)
    }

    setSubmitError(true)
  }

  const handleTaskCreate = async () => {

    let blocks = ""

    blocks = blocks + `<br /><br /><h2>Informações enviadas:</h2>`
    
    requiredData.map((item) => {

        let value = state[item[0]]
        
        if(item[0]=="DATAINICIALFERIAS"){
            let date = value.split("-")
            date = new Date(date[0],date[1]-1,date[2])
            value = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth()+1).toString().padStart(2, '0')}/${date.getFullYear()}`
        }

        blocks = blocks + `<div><b>${item[1]}:</b> ${value}</div>`
    })

    return rr.post('tasks', {
      task: {
        title: `${primary.reference} - AVISO RESCISÃO - EMPREGADO - ${employee.NOMEFUNC}`,
        on_going: false,
        project_id: primary.runrunit_projects[0],
        type_id: 1690017,
        board_id: 486584,
        desired_date: null,
        description: blocks,
      }
    }, {
        headers: {
            "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
            "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
        }
    })
    .then(response => response.data.id)
    .catch(err => false)
  
  }

  const sendEmail = async (id) => {
    return api.post(`v1/mailer/send`,{
      recipients: user.email.map(email => ({email: email.value,name: user.name})),
      subject: "Novo aviso de rescisão registrado",
      template_id: "neqvygmn9rd40p7w",
      data: {
        id,
        name: user.nickname,
        company: primary.name,
        employee: employee.NOMEFUNC,
      }
    },headers).then((response) => console.log(response)).catch((error) => console.log(error))
  }

  const handleChange = (e,obj=false) => {
    
    if(obj){

      let newObj = {}

      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key]
      })

      setState({
        ...state,
        ...newObj,
      })

      // console.log({
      //   ...state,
      //   newObj,
      // })

    }else{
      const el = e.target
      const name = el.name;
      const value = el.type === "checkbox" ? el.checked : el.value;

      setState({
        ...state,
        [name]: value
      })

      // console.log({
      //   ...state,
      //   [name]: value
      // })
    }

  }

  const onSubmit = async (e) => {
    e.preventDefault()

    setRequired({
      message:"",
      state:false,
    })
    
    const validate = validation(setRequired,state,requiredData)

    if(validate){

      const DATAAVPREVIO = state.DATAAVPREVIO.split("-")
      let DATARESC = state.DATAAVPREVIO

      if(state.AVISOPREVIO==="4"){
        DATARESC = DATARESC.split("-")
        DATARESC = new Date(DATARESC[0],DATARESC[1]-1,DATARESC[2])
        DATARESC.setDate(DATARESC.getDate()+29)
        DATARESC = `${DATARESC.getFullYear()}-${(DATARESC.getMonth()+1).toString().padStart(2, '0')}-${DATARESC.getDate().toString().padStart(2, '0')}`
      }
      
      let DATAPGTORESC = new Date(DATAAVPREVIO[0],DATAAVPREVIO[1]-1,DATAAVPREVIO[2])
      DATAPGTORESC.setDate(DATAPGTORESC.getDate()+10)
  
      if(DATAPGTORESC.getDay() === 6){
        DATAPGTORESC.setDate(DATAPGTORESC.getDate()-1)
      }
      else if(DATAPGTORESC.getDay() === 0){
        DATAPGTORESC.setDate(DATAPGTORESC.getDate()-2)
      }
  
      DATAPGTORESC = `${DATAPGTORESC.getFullYear()}-${(DATAPGTORESC.getMonth()+1).toString().padStart(2, '0')}-${DATAPGTORESC.getDate().toString().padStart(2, '0')}`

      let payload = {
        ...state,
        DATARESC,
        DATAPGTORESC,
      }

      console.log(payload);

      setProgress({
        state:true,
        step:0,
        progress:33,
      })

      const task = await handleTaskCreate()
      if(!task) return false

      setProgress({
        state:true,
        step:1,
        progress:66,
      })

      // if(!await postData(payload)) return false

      await sendEmail(task);

      setProgress({
        state:true,
        step:2,
        progress:100,
      })

    }

  }

  useEffect(() => {
    
    setState({
      ...state,
      CODIGOEMPRESA: primary.reference,
      CODIGOFUNCCONTR: employee.CODIGOFUNCCONTR,
    })

  },[])

  const backdrop = () => {

    if(submitError) {

      return <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível continuar sua solicitação, volte e tente novamente mais tarde. Persistindo o problema, entre em contato com nossos canais de suporte!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-rescisao', state: {modal: true}, module: "rescisao", type: "Empregados"}) }}>Voltar</Button>
      </Paper>
    }

    return (
      <div>

        <LinearProgress variant="determinate" value={progress.progress} />

        <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          {progress.step < 2 && <CircularProgress size={50} style={{marginBottom:20}} />}
          {progress.step===2 && <FaCheckCircle size={100} color="#3cd56b" style={{marginBottom:20}} />}
                
          {progress.step===0 && <Typography variant="h5">Criando tarefa...</Typography>}
          {progress.step===1 && <Typography variant="h5">Enviando informações...</Typography>}
          {progress.step===2 && <div><Typography variant="h5">Solicitação enviada com sucesso!</Typography><Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-rescisao', state: {modal: true}, module: "rescisao", type: "Empregados"}) }}>Voltar</Button></div>}
        </Paper>

      </div>
    )

  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

        <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
        <Grid container>
          <Grid item xs={12} style={{padding:1}}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                  select
                  fullWidth
                  label="Empresa"
                  name="CODIGOEMPRESA"
                  defaultValue={employee.CODIGOEMPRESA}
                  disabled
                  SelectProps={{
                    native: true,
                  }}
                  >
                    <option key={primary.reference} value={primary.reference}>{primary.name}</option>
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField required fullWidth id="NOMEFUNC" disabled label="Empregado" aria-label="Empregado" defaultValue={employee.NOMEFUNC} name="NOMEFUNC" inputProps={{ maxLength: 70 }} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <TextField select fullWidth required label="Tipo Aviso Prévio" defaultValue="" name="AVISOPREVIO" id="AVISOPREVIO" SelectProps={{native: true}} onChange={handleChange}>
                        <option value=""></option>
                        <option value="2">Indenizado</option>
                        <option value="3">Ausência/Dispensa</option>
                        <option value="4">Trabalhado</option>
                    </TextField>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <input
                      name="CODIGOMOTDEM"
                      value={state.CODIGOMOTDEM}
                      hidden="true"
                  />
                  <TextField
                      style={{cursor:"pointer"}}
                      fullWidth
                      required
                      label="Motivo de demissão"
                      value={state.DESCRMOTDEM}
                      name="DESCRMOTDEM"
                      inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                      onClick={() => {
                          setIsSubModalVisible(true);
                          setSubModalContent(<MotivoDemissao setState={setState} state={state} employee={employee} setIsSubModalVisible={setIsSubModalVisible} />)
                      }}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField required fullWidth id="DATAAVPREVIO" label="Data do Aviso Prévio" defaultValue="" name="DATAAVPREVIO" onChange={handleChange} type="date" inputProps={{ maxLength: 10 }} InputLabelProps={{ shrink: true }} />
                </Grid>

                <Grid item xs={12}>
                  <TextField fullWidth id="OBSERVACAO" label="Observação" defaultValue="" name="OBSERVACAO" onChange={handleChange} />
                </Grid>
                
              </Grid>
              
          </Grid>
        </Grid>
        </div>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
          <Divider style={{marginTop:10}} />

          {required.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#FC5757",backgroundColor:"#FEDDDE"}}>{required.message}</Paper>}
          {success.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#2D3D0F",backgroundColor:"#E0EFC1"}}>{success.message}</Paper>}

          <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
              <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-rescisao', state: {modal: true}, module: "rescisao", type: "Empregados"}) }}>Voltar</Button>
              <Button color="secondary" type="submit">Enviar</Button>
          </ButtonGroup>
      </ModalButtons>

      <Backdrop open={progress.state} style={{zIndex:1000}}>
        {backdrop()}
      </Backdrop>
    </form>
  );
};

export default Termination;
