import React, { Component } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";
import FileList from "../../components/FileList";
import Upload from "../../components/Upload";

import { Div } from "./styles";

import api from "../../services/api";

class UploadFiles extends Component {
  state = {
    uploadedFiles: [],
    maxFilesError: false,
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleUpload = (files) => {
    const uploadedFilesObject = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFilesObject),
    });

    uploadedFilesObject.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = async (uploadedFile) => {
    const base64 = await this.toBase64(uploadedFile.file);
    const base64legible = base64.split("base64,");
    const name = uploadedFile.name;
    await api.post(`v1/clients/${this.props.primary}/upload?path=${this.props.path}&user_id=${this.props.user}`,
        {
          file_name: name,
          base64data: base64legible[1],
        },
        {
          onUploadProgress: (e) => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));

            this.updateFile(uploadedFile.id, { progress });
          },
          headers: {
            Authorization: this.props.token
          }
        }
      )
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  render() {
    const { uploadedFiles } = this.state;

    return (
      <>
        <Div>
          <div className="contentUpload">
            <div className="folder">{this.props.path}</div>
            <Upload onUpload={this.handleUpload} />
                {/* <p style={{marginTop: '1rem', border: maxFilesError ? '1px dashed red' : '0px solid red', padding: maxFilesError ? '1rem' : '0rem', borderRadius: '1rem'}}>
                  <strong><small>
                    <span style={{color: 'red'}}>Atenção: </span>É permitido enviar no máximo 10 arquivos por vez. Caso houver a necessidade de enviar muitos arquivos,
                    pedimos a gentileza de compactar os arquivos antes do envio. <a style={{textDecoration: 'none', color: 'var(--blue)'}} target="_blank" href="https://www.win-rar.com/download.html?&L=0">Clique aqui</a> e baixe o WinRar gratuitamente para compactar os
                    arquivos caso não possua instalado em sua máquina.
                  </small></strong>
                </p> */}

                {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
          </div>
        </Div>
      </>
    );
  }
}

export default UploadFiles;
