import React, { useState } from "react";
import Button from "../../../components/Button";
import ItemForm from "../../../components/ItemForm";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useNewRequest } from "../../../context/contextNewRequest";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";

const Description = ({ navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const { description, setDescription } = useNewRequest();
  const { next, previous } = navigation;

  return (
    <>
        {error ? (
          <ToastMessage
            variant="info"
            message="Preencha este campo antes de continuar!"
          />
        ) : null}
        <ModalContent>
          <FormContact darkMode={isEnabled}>
            <h2>DESCRIÇÃO DA SOLICITAÇÃO</h2>
            <ItemForm
              placeholder="Digite aqui…"
              name="description"
              type="text"
              value={description}
              onChange={e=>setDescription(e.target.value)}
            />
          </FormContact>
        </ModalContent>
        <ModalButtons>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            onClick={async () => {
              await validateField(description, 'text', next, setError);
              setError(false);
            }}
          >
            CONTINUAR
          </Button>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={previous}
          >
            VOLTAR
          </Button>
        </ModalButtons>
    </>
  );
};

export default Description;
