import { Grid, TextField, Divider, Card, CardContent, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useSubModal } from "../../../../context/contextSubModal";
import OrgaoEmissor from "../submodals/orgaoEmissor";

const Documents = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [orgaoEmissor,setOrgaoEmissor] = useState({
        CODIGOORGAOEMISSORRG: props.data.employee.CODIGOORGAOEMISSORRG || "",
        SIGLAORGAOEMISSOR: props.data.employee.SIGLAORGAOEMISSOR || "",
    })

    useEffect(() => {

        props.handleChange(null,orgaoEmissor)

    }, [orgaoEmissor])

    return <Grid container spacing={2}>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>RG - Registro Geral</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Número" defaultValue={props.data.employee.NUMERORG} name="NUMERORG" inputProps={{ maxLength: 20 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <input
                                name="CODIGOORGAOEMISSORRG"
                                value={orgaoEmissor.CODIGOORGAOEMISSORRG}
                                hidden="true"
                            />
                            <TextField
                                style={{cursor:"pointer"}}
                                fullWidth
                                label="Orgão Emissor RG"
                                value={orgaoEmissor.SIGLAORGAOEMISSOR}
                                name="SIGLAORGAOEMISSOR"
                                inputProps={{ maxLength: 30, autoComplete: 'new-password', readOnly: true }}
                                onFocus={() => {
                                    setIsSubModalVisible(true);
                                    setSubModalContent(<OrgaoEmissor setOrgaoEmissor={setOrgaoEmissor} params={["CODIGOORGAOEMISSORRG","SIGLAORGAOEMISSOR"]} setIsSubModalVisible={setIsSubModalVisible} />)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth label="UF" defaultValue={props.data.employee.SIGLAESTADORG} name="SIGLAESTADORG" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AM">AM</option>
                                <option value="AP">AP</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="EX">EX</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MG">MG</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="PR">PR</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="RS">RS</option>
                                <option value="SC">SC</option>
                                <option value="SE">SE</option>
                                <option value="SP">SP</option>
                                <option value="TO">TO</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth type="date" label="Data de emissão" defaultValue={props.data.employee.EMISSRG} name="EMISSRG" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } InputLabelProps={{ shrink: true }} />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Título eleitoral</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Número" defaultValue={props.data.employee.TITELEIT} name="TITELEIT" inputProps={{ maxLength: 13 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Zona" defaultValue={props.data.employee.ZONATITELEIT} name="ZONATITELEIT" inputProps={{ maxLength: 5 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Seção" defaultValue={props.data.employee.SECAOTITELEIT} name="SECAOTITELEIT" inputProps={{ maxLength: 5 }} onChange={ props.handleChange } />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Carteira de reservista</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Número" defaultValue={props.data.employee.CARTRESERV} name="CARTRESERV" inputProps={{ maxLength: 20 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Categoria" defaultValue={props.data.employee.CATEGCARTRESERV} name="CATEGCARTRESERV" inputProps={{ maxLength: 3 }} onChange={ props.handleChange } />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Carteira de motorista</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Número" defaultValue={props.data.employee.CNHFUNC} name="CNHFUNC" inputProps={{ maxLength: 15 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Categoria" defaultValue={props.data.employee.CATEGCNH} name="CATEGCNH" inputProps={{ maxLength: 3 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth type="date" label="Vencimento" defaultValue={props.data.employee.VALIDCNH} name="VALIDCNH" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } InputLabelProps={{ shrink: true }} />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Grid>

    </Grid>

}

export default Documents