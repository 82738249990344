export const FuncPessoa = {
  CODIGOFUNCPESSOA: "4179",
  NOMEFUNC: "",
  NOMESOCIAL: "",
  CPFFUNC: "",
  DATANASC: "",
  PISFUNC: "",  //Validado
  DATACADPIS: "",
  FOTOFUNC: "",
  APELIDOFUNC: "",
  CEPFUNC: "",
  CODIGOTIPOLOGRAD: "",
  ENDERFUNC: "",
  NUMEROENDER: "",
  COMPLENDER: "",
  BAIRROFUNC: "",
  SIGLAESTADO: "",
  CODIGOMUNIC: "",
  CODIGOPOSTAL: "",
  DDDFONE: "",
  NUMEROFONE: "",
  RAMALFONE: "",
  DDDCELULAR: "",
  NUMEROCELULAR: "",
  ENDERELETR: "",
  SIGLAESTADONASC: "",
  CODIGOMUNICNASC: "",
  SEXO: "",
  RACACOR: "9",
  DEFFISICA: false,
  DEFVISUAL: false,
  DEFAUDITIVA: false,
  DEFMENTAL: false,
  DEFINTELECTUAL: false,
  DEFREABILITADO: false,
  DEFCOTA: false,
  DEFOBS: "",
  BRPDHPPP: "3",
  ESTADOCIVIL: "",
  GRAUINSTR: "",
  NACIONALIDADE: "",
  PAISORIGEM: "1058",
  DATANATURAL: "",
  TMPRESID: "",
  TIPOVISTO: "",
  DATACHEGADA: "",
  CASADOBR: "",
  FILHOSBR: "",
  CARTRNE: "",
  CODIGOORGAOEMISSORRNE: "",
  EXPEDICAORNE: "",
  VALIDRNE: "",
  PORTNATNUMERO: "",
  PORTNATDATA: "",
  NUMERORG: "",
  COMPLRG: "",
  CODIGOORGAOEMISSORRG: "",
  SIGLAESTADORG: "",
  EMISSRG: "",
  TITELEIT: "",
  ZONATITELEIT: "",
  SECAOTITELEIT: "",
  CNHFUNC: "",
  CATEGCNH: "",
  EXPEDICAOCNH: "",
  VALIDCNH: "",
  CODIGOORGAOEMISSORCNH: "",
  UFCNH: "",
  PRIMHABCNH: "",
  CARTRESERV: "",
  CATEGCARTRESERV: "",
  CODIGOCONSELHO: "",
  NUMEROREGIST: "",
  DATAREGIST: "",
  VALIDADEREGIST: "",
  NUMEROCNS: "",
  CERTCIVILTIPO: "",
  CERTCIVILEMISSAO: "",
  CERTCIVILTERMO: "",
  CERTCIVILLIVRO: "",
  CERTCIVILFOLHA: "",
  CERTCIVILCARTORIO: "",
  CERTCIVILSIGLAESTADO: "",
  CERTCIVILMUNIC: "",
  CODIGOORGAOEMISSORPASPORT: "",
  PASSAPNUMERO: "",
  PASSAPEMISSAO: "",
  PASSAPVALIDADE: "",
  PASSAPPAISEMISSAO: "",
  PASSAPSIGLAESTADO: "",
  RICNUMERO: "",
  CODIGOORGAOEMISSORRIC: "",
  RICSIGLAESTADO: "",
  RICMUNIC: "",
  RICDATAEXPED: "",
  OBSERV: "",
  TIPOALTERACAOESOCIAL: "0",
  NAOPOSSUI: "",
}

export const FuncContrato = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  MATRICULAESOCIAL: "",
  CODIGOFUNCPESSOA: "",
  ESOCIALSITUACAO: "",
  CODIGOTIPOCONTR: "",
  DATAADM: "",
  OPTANTEFGTS: "1",
  DATAOPCAOFGTS: "",
  CONTAFGTS: "",
  DATAAPOSENT: "",
  TIPOVINCULO: "",
  RECEBSEGURODESEMPREGO: "",
  SIGLAESTADOMINREG: "",
  CODIGOCATEG: "",
  CATEGORIA: "",
  CATEGESOCIAL: "",
  DIACONTREXP: "",
  DIAPRORROGCONTREXP: "",
  CODIGOSIT: "",
  JUSTPRORROG: "",
  CODIGOTIPOFATO: "",
  CLAUSULAASSEGRESC: "",
  ANTECIPDECIMOTERCEIROEFERIAS: "1",
  CATEGORIG: "",
  TPINSCR: "",
  CNPJORIGEM: "",
  DATAADMORIG: "",
  MATRICORIG: "",
  CATEGORIGCEDIDO: "",
  CNPJCEDNT: "",
  MATRICCED: "",
  DTADMCED: "",
  INFONUS: "",
  NATUREZAESTAGIO: "",
  NIVELESTAGIO: "",
  AREAATUACAO: "",
  INSTITUICAOENSINO: "",
  AGENTEINTEGRACAO: "",
  CODIGOSUPERVISORESTAGIO: "",
  RECEBE13SAL: "1",
  RECEBEADTO: "",
  PERCADTO: "40",
  FORMACALCULO: "1",
  PERPGTO: "1",
  MODOPGTO: "",
  TIPOCONTA: "",
  CODIGOBANCO: "",
  NUMEROAGENCIA: "",
  NUMEROCONTA: "",
  DIGITOCONTA: "",
  INDNUMEROIDENTFISCAL: "",
  NUMEROIDENTFISCAL: "",
  NUMEROCARTAOVT: "",
  PREVTERMAFASTVT: "0",
  EMITECARTAO: "0",
  CHAPEIRA: "",
  PARTICPAT: "0",
  POSSUIALVARA: "0",
  CODIGOPROCESSOJUDADM: "",
  DOCUMENTOSCOMPLETOS: "",
  UTILIZAQCOLABORE: "0",
  PERFILTAREFFA: "",
  NUMEROINSCRINSS: "",
  EMITIRCARNE: "",
  CODIGOIMPOSTO: "",
  VARIACAOIMPOSTO: "",
  CODIGOIMPOSTO13: "",
  VARIACAOIMPOSTO13: "",
  INSSFOLHA: "",
  DATADEM: "",
  CODIGOUSUARIO: "",
  DATAHORALCTO: "",
  ORIGEMDADO: "",
  TIPOINSCRSEFIP: "",
  INDICATPRIMEMP: "",
  MOTIVOCONTRAT: "",
  JUSTCONTR: "",
  TPINCLCONTR: "",
  CODIGOEMPRESASUBST: "",
  CODIGOFUNCCONTRSUBST: "",
  REGIMEPREV: "",
  REGIMETRAB: "",
  CODIGOESOCIALTRANSACAO: "",
  CODIGOEMPCONTRATAAPRENDIZ: "",
  EMPCONTRATAPRENDIZ: "",
  CODIGOCURSOFORM: "",
  ORDEMCALCULO: "",
  ENVIOUPRORROGESOCIAL: "",
  TIPOALTERACAOESOCIAL: "0",
  STATUSSINCZEN: "0",
}

export const FuncSalario = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  DATAINICIAL: "", //Data Alteração Data Inicial do Histórico deve ser maior que a data de admissão do funcionário!
  SEQ: 1,
  CODIGOESTRUTSAL: "",
  CODIGOTABSAL: "",
  CODIGOMOTIVO: "1",
  DATAEFEITO: "",
  TIPOSALARIO: "",
  VALORSAL: "",
  PERCAUMENTO: "",
  COMPLSAL: "0,00",
  ANTECSAL: "0,00",
  SALCARNE: "0,00",
  DESCRSAL: "",
  TIPOALTERACAOESOCIAL: "0",
  CODIGOSINDDISSIDIOEMP: "",
}

export const FuncCargo = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  DATAINICIAL: "",
  CODIGOCARGO: "",
  CODIGOMOTIVO: "1",
  MOTIVOALTERACAO: "",
  CODIGOFUNCAO: "",
  TIPOALTERACAOESOCIAL: "0",
}

export const FuncEscala = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  DATAINICIAL: "",
  CODIGOESCALA: "",
  CODIGOMOTIVO: "1",
  REGIMEJOR: "1",
  TIPOJORNADA: "0",
  DESCRTIPOJORNADA: "",
  TEMPOPARCIAL: "0",
  OBSERVACAO: "",
  TIPOALTERACAOESOCIAL: "0",
}

export const FuncDependentes = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  CODIGODEPEND: "",
  NOMEDEPEND: "",
  DATANASC: "",
  PARENTESCO: "",
  CPFDEPEND: "",
  SEXO: "",
  ESTADOCIVIL: "",
  GRAUINSTR: "",
  IDADEMINCRECHE: "",
  IDADELIMCRECHE: "",
  IDADELIMSALFAM: "",
  IDADELIMIR: "",
  INCAPAZTRABALHAR: "",
  FORMACONTROLEIR: "",
  CONTROLEVACIN: "",
  DATAVACIN: "",
  SIGLAESTADO: "",
  CODIGOMUNIC: "",
  NACIONALIDADE: "",
  CARTORIO: "",
  LIVROREG: "",
  FOLHALIVROREG: "",
  NUMEROREG: "",
  MATRICULA: "",
  NUMDECLARNASCVIVO: "",
  NUMEROCONVENIO: "",
  FUMANTE: "",
  CONSOMEALCOOL: "",
  ENTREGACERT: "",
  DATABAIXA: "",
  TIPOALTERACAOESOCIAL: "0",
  EHDEPENDENTEIR: "",
}

export const initialState = {
    ...FuncPessoa,
    ...FuncContrato,
    ...FuncSalario,
    ...FuncCargo,
    ...FuncEscala,
    
    NOMEPAIS:"Brasil",
    DEPENDENTES: [],
  }

export const requiredData = [
  ["NOMEFUNC","Nome"],
  ["CPFFUNC","CPF"],
  ["CODIGOTIPOCONTR","Tipo do contrato"],
  ["MATRICULAESOCIAL","Matrícula eSocial"],
  ["CODIGOCARGO","Cargo"],
  ["CODIGOFUNCAO","Função"],
  ["DESCRSIT","Situação"],
  ["TIPOVINCULO","Tipo de vínculo"],
  ["CATEGORIA","Categoria do contrato"],
  ["DATAADM","Data de admissão"],
  ["CATEGESOCIAL","Categoria do eSocial"],
  ["INDICATPRIMEMP","Primeiro emprego"],
  ["TIPOSALARIO","Tipo de salário"],
  ["RECEBEADTO","Recebe adiantamento de salário"],
  ["VALORSAL","Valor do salário"],
  ["CODIGOESCALA","Escala"],
  ["PISFUNC","Número do PIS"],
  ["MODOPGTO","Modo de pagamento"],
  ["DATANASC","Data de nascimento"],
  ["NACIONALIDADE","Nacionalidade"],
  ["SIGLAESTADONASC","Estado do nascimento"],
  ["NOMEMUNICNASC","Município do nascimento"],
  ["NOMEPAIS","País de origem"],
  ["SEXO","Sexo"],
  ["GRAUINSTR","Grau de instrução"],
  ["ESTADOCIVIL","Estado Civil"],
]