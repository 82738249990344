import React, { useState, useEffect } from "react";
import { FaFolder, FaPlusSquare } from "react-icons/fa";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import ModalContent from "../../components/ModalContent";
import {SeeMore} from "../../components/SeeMore";
import List from "../../components/List";
import { useAuth } from "../../context/contextAuth";

import api from "../../services/api";
import { Link } from "react-router-dom";

const ReceivedFilesModal = () => {
  const { user, primary } = useAuth();

  const [tasks, setTasks] = useState([]);
  const token = localStorage.getItem("@Atlantico:token");
  const [loader, setLoader] = useState(true);
  const [limit, setLimit] = useState(10);
  const [noProjects, setNoProjects] = useState(false);
  const joinedProfile = user.profile.join(',');

  async function getData(){
    await api.get(`v1/clients/${primary._id}/tasks?project_id=${primary.runrunit_projects[0]}&limit=${limit}&user_profile=${joinedProfile}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      setLoader(false);
      setTasks(response.data.tasks);
    })
    .catch((err) => {
      setLoader(false);
      console.log(err);
    });
  }

  useEffect(() => {
    if(primary.runrunit_projects.length > 0){
      getData();
    } else {
      setLoader(false);
      setNoProjects(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchMore(){
    return await api.get(`v1/clients/${primary._id}/tasks?project_id=${primary.runrunit_projects[0]}&limit=${limit}&user_profile=${joinedProfile}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) =>  setTasks(response.data.tasks))
    .catch((err) =>  console.log(err));
  }

  useEffect(() => {
    if(primary.runrunit_projects.length > 0){
      fetchMore();
    } else {
      setLoader(false);
      setNoProjects(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary._id, primary.runrunit_projects, token, limit])

  return (
      <ModalContent hasFooter={false}>
        {loader ? (
          <Loader/>
        ) : (
          <>
          <List completeLine={true}>
            {noProjects ? (
              <Message severity="warning" message="Nenhum projeto ativo!"/>
            ) : (
              <>
              {tasks.map(task => {
                return (
                  <li key={task.id} className="documents">
                    <FaFolder size={32} />
                      <div className="info">
                        <Link Link to={{pathname: `/dashboard/tarefa/${task.id}`, state: {modal: true, props: task}}}>
                          <strong title={task.title}> {task.title}</strong>
                        </Link>
                        <span><small>Modificado em {task.closed_at_formatted}</small></span>
                      </div>
                  </li>
                )
              })}
              
              {tasks.length === 0 ? (
                <Message severity="warning" message="Nenhum arquivo até o momento!"/>
              ) : null}
              </>
            )}

            {!noProjects && <SeeMore text="Ver Mais" icon={<FaPlusSquare />} onClick={() => setLimit(limit+10)}/>}

          </List>
          
          </>
        )}
      </ModalContent>
  );
};
export default ReceivedFilesModal;