import React from "react";
import logo from "../../assets/images/logo.svg";
import logoDarkMode from '../../assets/images/logo-dark-mode.svg';

import {
  FaUser,
  FaFileAlt,
  FaSignOutAlt,
  FaPen,
  FaChartBar,
  FaFileSignature,
  FaTasks,
} from "react-icons/fa";
import {ConfirmLogout} from "../../modals/ConfirmLogout";
import { useSubModal } from "../../context/contextSubModal";
import { useColorMode } from "../../context/contextColorMode";
import { useAuth } from "../../context/contextAuth";
import ListClients from "../../modals/Admin/ListClients";

import { DrawerUI, ItemNavigator, PageHeader } from "./styles";
import { Link } from "react-router-dom";

const NavigatorAdmin = (props) => {
  const categorias = [
    {
      id: "Clientes",
      icon: <FaUser />,
      path: 'clientes',
      modalContent: <ListClients />,
      hasFooter: true,
    },
    {
      id: "Usuários",
      icon: <FaFileAlt />,
      path: 'usuarios',
      hasFooter: false,
    },
    {
      id: "Pesquisa de Satisfação",
      icon: <FaChartBar />,
      path: 'pesquisa-satisfacao',
      hasFooter: false,
    },
    {
      id: "Pesquisas NPS",
      icon: <FaFileSignature />,
      path: 'nps',
      hasFooter: false,
    },
    {
      id: "Tarefas RR",
      icon: <FaTasks />,
      path: 'tarefas',
      hasFooter: false,
    }
  ];

  const { ...other } = props;
  const { user, setUser } = useAuth();
  const {isEnabled} = useColorMode();

  function storagedUser() {
    const storagedUser = localStorage.getItem("@Atlantico:user");
    setUser(JSON.parse(storagedUser));
  }

  const { setIsSubModalVisible, setSubModalContent } = useSubModal();

  return (
    <DrawerUI anchor={props.anchor} variant="permanent" {...other} darkMode={isEnabled}>
      <PageHeader darkMode={isEnabled}>
          <div className="logo">
              <img src={isEnabled ? logoDarkMode : logo} width="120" alt="Atlântico Digital" />
          </div>
          <div className="items">
            <div className="name-user">
              <h3><strong>Olá, {user.nickname}!</strong></h3>
            </div>
            <div className="buttons">
              <Link id="icon-edit" to={{ pathname: "/admin/perfil", state: {modal: true} }} onClick={() => storagedUser()}>
                <FaPen />
              </Link>

              <a id="icon-logout" href="#!" onClick={() => {
                setIsSubModalVisible(true);
                setSubModalContent(<ConfirmLogout />)
              }}>
                <FaSignOutAlt />
              </a>
            </div>
          </div>
      </PageHeader>
      <ul>
        {categorias.map((categoria) => {
            return (
              <ItemNavigator key={categoria.id} darkMode={isEnabled} to={{pathname: `/admin/${categoria.path}`, state: {modal: true}}}>
                <li>
                  {categoria.icon}
                  {categoria.id}
                </li>
              </ItemNavigator>
            );
        })}
      </ul>
    </DrawerUI>
  );
};

export default NavigatorAdmin;
