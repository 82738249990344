import React, { useEffect, useState } from 'react';

import { FaEdit, FaPlusSquare } from 'react-icons/fa';
import SubModal from '../../../components/SubModal';
import List from '../../../components/List';
import {SeeMore} from '../../../components/SeeMore';
import { useUploadModal } from '../../../context/contextUploadModal';
import { useSubModal } from '../../../context/contextSubModal';
import { useAuth } from '../../../context/contextAuth';
import EditUser from '../EditUser';
import CreateNewUser from '../CreateNewUser';
import Loader from '../../../components/Loader';

import api from '../../../services/api';

const ListUsers = () => {
    const {isSubModalVisible, setIsSubModalVisible, setSubModalContent} = useSubModal();
    const {att} = useUploadModal();
    const token = localStorage.getItem('@Atlantico:token');
    const [users, setUsers] = useState([]);
    const {user} = useAuth();
    const [loader, setLoader] = useState();

    useEffect(() => {
        async function getData(){
           await api.get('v1/users', {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setUsers(response.data);
            })
            .catch(err => {
                setLoader(false);
            })
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, att]);

    return(
        <div>
            {isSubModalVisible ? (
                <SubModal onClose={() => setIsSubModalVisible(false)} />
            ) : null}
            {loader ? (
                <Loader/>
            ) : (
                <List completeLine={true}>
                    {users.map(usuario => {
                            return (
                                usuario.is_admin &&
                                <li className="item">
                                    {usuario.name}
                                    {user.profile.includes('ADMIN') && (
                                        <FaEdit style={{cursor: 'pointer'}} color="var(--blue)" onClick={() => {
                                            setIsSubModalVisible(true);
                                            setSubModalContent(<EditUser user={usuario}/>);
                                        }}/>
                                    )}
                                </li>
                            )
                    })}
                </List>
            )}
            
            {user.profile.includes('ADMIN') && (
                <SeeMore text="Adicionar novo usuário" icon={<FaPlusSquare />} onClick={() => {
                    setIsSubModalVisible(true);
                    setSubModalContent(<CreateNewUser/>);
                }}/>
            )}
            
        </div>
    )
}   

export default ListUsers;
