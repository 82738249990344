export const docName = (string, position) => {
    const str1 = string;
    const str2 = str1.split("/");
    if (position){
        return str2[position];
    } else {
        return str2[str2.length - 2];
    }
}

export const removeId = (string) => {
    const str1 = string;
    const str2 = str1.split("/");
    str2.splice(0, 1);
    const newpath = str2.join("/");
    return newpath;
}


export const goForward = (path) => {
    const actualPath = path.split("/");
    actualPath.splice(0, 1);
    actualPath.splice(-1, 1);
    const newPath = actualPath.join("/");
    return newPath;
}

export const pathUrl = (path) => {
    const actualPath = path.split("/");
    actualPath.splice(0, 1);
    actualPath.splice(-1, 1);
    const newPath = actualPath.join("-");
    return newPath;
}

