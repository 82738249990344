import styled from 'styled-components';

export const ClientDetailsDiv = styled.div`
    max-height: 40em;
    overflow: auto;
    padding-right: 1rem;

    @media (max-height: 800px) and (max-width: 600px) {
        max-height: 32em;
    }

    @media (min-height: 600px) and (max-height: 700px) {
        max-height: 30em;
    }
    @media(min-width: 1024px){
        overflow: unset;
        padding-right: 0;
    }

    .buttons{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "button1" "button2"/*  "button3" */ "button4";
        height: auto;
        margin-bottom: 2rem;
        #button1{
            grid-area: button1;
        }
        #button2{
            grid-area: button2;
        }
        /* #button3{
            grid-area: button3;
        } */
        #button4{
            grid-area: button4;
        }
        button{
            width: 100%;
        }
        &:last-child{
            margin: 0;
        }

        @media(min-width: 700px){
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "button1" "button2" "button4";
            grid-gap: 1rem;
        }

        @media(min-width: 1024px){
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: "button1 button2 button4";
            grid-gap: 1rem;
        }
    }

    .client-content{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:"info" "tasks" "contacts";
        grid-gap:1rem;

        @media(min-width:1024px){
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: "info tasks contacts";
            grid-gap: 2rem;
        }

        .info{
            grid-area: info;
            h2{
                color: var(--blue);
                margin-bottom: 2rem;
            }
            .pj{
                .pj-info{
                    margin: 2rem 0;
                    .pj-infotitle{
                        color: var(--atlantico);
                    }
                }
                
            }
        }
        .tasks{
            grid-area: tasks;
            .title{
                color: var(--atlantico);
                margin-bottom: 1rem;
            }
        
    }
        .contacts{
            .title{
                color: var(--atlantico);
                margin-bottom: 1rem;
            }
            grid-area: contacts;
        }   
    }
`;