import React, { useEffect } from "react";
import { FaExclamationCircle, FaCheck, FaTimes, FaPlusSquare } from "react-icons/fa";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import SubModal from "../../components/SubModal";
import List from "../../components/List";
import {SeeMore} from "../../components/SeeMore";
import ModalContent from "../../components/ModalContent";
import InvoiceDetails from "../InvoiceDetails";
import { useState } from "react";
import { useAuth } from "../../context/contextAuth";
import { useSubModal } from "../../context/contextSubModal";
import { useColorMode } from "../../context/contextColorMode";

import api from "../../services/api";

const Invoices = () => {
  const {
    setIsSubModalVisible,
    setSubModalContent,
    isSubModalVisible,
  } = useSubModal();

  const { primary } = useAuth();
  const [invoices, setInvoices] = useState([]);
  const [loader, setLoader] = useState();
  const {isEnabled} = useColorMode();
  const token = localStorage.getItem("@Atlantico:token");
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    async function getData(){
        await api.get(`v1/clients/${primary._id}/invoices?limit=10`, {
          headers: {
            Authorization: token,
          },
        })
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setInvoices(response.data.invoices);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary]);

  useEffect(() => {
    async function getData(){
        await api.get(`v1/clients/${primary._id}/invoices?limit=${limit}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setInvoices(response.data.invoices);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  async function getInvoiceData(id) {
    return await api.get(`v1/clients/invoice?id=${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return false;
      });
  }

  return (
    <>
      <ModalContent hasFooter={false}>
        {loader ? (
          <Loader/>
        ) : (
          <>
            {isSubModalVisible ? (
              <SubModal
                onClose={() => {
                  setIsSubModalVisible(false);
                }}
              />
            ) : null}

              {invoices.length === 0 ? (
                <Message severity="warning" message="Nenhuma fatura até o momento!"/>
              ) : (
                <List darkMode={isEnabled}>
                  {invoices.map((invoice) => {
                    return (
                      <li
                        key={invoice.invoice_id}
                        className="invoices"
                        onClick={async () => {
                          setIsSubModalVisible(true);
                          await setSubModalContent(
                            <Loader/>
                          );
                          setSubModalContent(
                            <InvoiceDetails
                              object={await getInvoiceData(invoice.invoice_id)}
                            />
                          );
                        }}
                      >
                        <div className="icon-info">
                          <div className="icon">
                            {invoice.status === "pending" ? (
                              <FaExclamationCircle color="var(--blue)" />
                            ) : null}
                            {invoice.status === "paid" ? (
                              <FaCheck color="var(--green)" />
                            ) : null}
                            {invoice.status === "partially_paid" ? (
                              <FaCheck color="var(--green)" />
                            ) : null}
                            {invoice.status === "refunded" ? (
                              <FaCheck color="var(--green)" />
                            ) : null}
                            {invoice.status === "authorized" ? (
                              <FaCheck color="var(--green)" />
                            ) : null}
                            {invoice.status === "canceled" ? (
                              <FaTimes color="var(--red)" />
                            ) : null}
                            {invoice.status === "expired" ? (
                              <FaTimes color="var(--red)" />
                            ) : null}
                          </div>
                          <div className="info">
                            <h4><strong>{invoice.total}</strong></h4>
                            {invoice.items.map((item) => {
                              return (
                                <h5 key={item} style={{ width: "100%" }}>
                                  {item}
                                </h5>
                              );
                            })}
                          </div>
                        </div>
                        
                        <h5 className="status">
                            {invoice.status === "pending" ? "Pendente" : null}
                            {invoice.status === "paid" ? "Pago" : null}
                            {invoice.status === "partially_paid"
                              ? "Parcialmente Pago"
                              : null}
                            {invoice.status === "refunded" ? "Reembolsado" : null}
                            {invoice.status === "authorized" ? "Autorizado" : null}
                            {invoice.status === "canceled" ? "Cancelado" : null}
                            {invoice.status === "expired" ? "Expirado" : null}
                        </h5>
                      </li>
                    );
                  })}

                  <SeeMore onClick={() => setLimit(limit+10)} icon={<FaPlusSquare/>}  text="Ver Mais"/>
                </List>
              )}
            
          </>
        )}
      </ModalContent>
    </>
  );
};

export default Invoices;
