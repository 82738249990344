import styled from 'styled-components';

export const LoaderDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: ${(props) => (props.infinitescroll ? "-5rem" : null)};

    img{
        width: ${(props) => (props.infinitescroll ? '2rem' : '3rem')};
    }
`;