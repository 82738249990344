import React, { useCallback, useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaArrowRight,
  FaMinus,

} from "react-icons/fa";
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  Grid,
  Typography,
  Link,
  Checkbox,
  LinearProgress,
  TextField,
  Divider,
  ButtonGroup,
} from '@material-ui/core'
import InputMask from 'react-input-mask';
import { NumericFormat } from "react-number-format";

import api from "../../../../services/api";

function sortObjectEntries(obj){
  return  obj.sort((a, b) => (a.DESCREVENTO > b.DESCREVENTO) ? 1 : -1)
}
// REFEREVENTO
// Hora=1
// Valor=2
// Percentual=3
// Dia=4
// Unidade=5
// Média=6
// Imposto=7

const allowedEventsCode = [
  1,
  29,
  35,
  36,
  39,
  41,
  43,
  49,
  69,
  90,
  347,
  417,
  444,
  476,
  505,
  508,
  509,
  529,
  533,
  536,
  537,
  545,
  559,
  563,
  567,
  587,
  593,
  608,
  663,
  689,
  692,
  693,
  694,
  697,
  698,
  699,
  706,
  716,
  747,
  749,
  806,
  814,
  815,
  829,
  833,
  834,
  841,
  842,
  870,
  924,
  926,
  1862,
  4013,
  4015,
  4017,
]

const PickEditEvent = (props) => {
  const token = localStorage.getItem("@Atlantico:token")
  const [selected, setSelected] = useState(props.state.employees)
  const initialState = [{
    CODIGOEVENTO: "",
    VALOREVENTO: "",
    REFEREVENTO: "",
    CODIGOREF: "",
    DESCRIPTION: "",
    }]
  const [dataSource, setDataSource] = useState(props.employee.EVENTOS.length ? props.employee.EVENTOS : initialState)
  const [events,setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected)
  }, [])
  
  const loadData = async () => {
  
    const params = {
      reference: props.CODIGOEMPRESA,
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    await api.get(`v1/questor/eventsByCompany${qs}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      const data = response.data.data.filter(row => allowedEventsCode.includes(Number(row.CODIGOEVENTO)))
      setLoading(false)
      setEvents(data)
    })
    .catch((err) => {
      setLoading(false)
      console.log(err.response.data)
    })
  
  }

  useEffect(() => {
    loadData()
  },[])

  const onSubmit = () => {
    props.setState({...props.state, employees: selected})
    props.setIsSubModalVisible(false)
  }

  const handleAddEvent = () => {

    setDataSource([...dataSource, ...initialState])

  }

  const handleMinusEvent = (index) => {

    let newData = dataSource

    if(index === 0 && newData.length === 1) {
      setDataSource([...initialState])
      return false
    }

    newData.splice(index, 1)
    
    setDataSource([...newData])

  }

  const isReference = (ref) => {
    switch (ref) {
      case "1":
      case "3":
      case "4":
      case 1:
      case 3:
      case 4:
        return true
      default:
        return false
    }
  }

  const handleSelectChange = (e,index) => {
    let newData = dataSource

    const event = events.filter(row => row.CODIGOEVENTO === Number(e.target.value))
    newData[index]["CODIGOREF"] = event[0]["REFEREVENTO"]
    newData[index]["DESCRIPTION"] = event[0]["DESCREVENTO"]

    newData[index][e.target.name] = e.target.value
    setDataSource([...newData])
  }

  const handleChange = (e,index) => {
    let newData = dataSource

    if (isReference(dataSource[index]["CODIGOREF"])) {
      newData[index]["REFEREVENTO"] = e.target.value
    } else {
      newData[index][e.target.name] = e.target.value
    }

    setDataSource([...newData])
  }

  useEffect(() => {
    console.log(dataSource);

    const state = dataSource.filter(row => row.CODIGOEVENTO != "" && (row.VALOREVENTO != "" || row.REFEREVENTO != ""))

    props.setState({
      ...props.state,
      employees: {
        ...props.state.employees,
        [props.employee.CODIGOFUNCPESSOA]: {
          ...props.employee,
          EVENTOS: state,
        }
      }
    })

  }, [dataSource])

  if(loading){
    return <LinearProgress color="primary" />
  }

  return (
    <>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
              <Typography variant="h6" style={{marginBottom:"20px"}}>Eventos - {props.employee.NOMEFUNC}</Typography>

              {dataSource.map((row, index) => (<div>
              <Grid container spacing={2} alignItems="center" style={{marginTop:3}}>
                <Grid item xs>{index+1}.</Grid>
                <Grid item xs={12} lg={5}>
                  <TextField select fullWidth label="Evento" variant="outlined" size="small" value={`${row.CODIGOEVENTO}`} name="CODIGOEVENTO" onChange={(e) => { handleSelectChange(e,index) }} SelectProps={{native: true}}>
                      <option value=""></option>
                      {events.map(row => <option value={`${row.CODIGOEVENTO}`}>{row.DESCREVENTO}</option>)}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <NumericFormat
                  defaultValue={row.VALOREVENTO || row.REFEREVENTO}
                  decimalSeparator=","
                  onChange={(e) => { handleChange(e,index) }}
                  placeholder="0,00"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Valor/Referência"
                  customInput={TextField}
                  name="VALOREVENTO"
                  />
                </Grid>
                <Grid item xs><Button size="small" fullWidth color="secondary" onClick={() => { handleMinusEvent(index) }}><FaMinus /></Button></Grid>
              </Grid>
              <Divider style={{marginTop:10}} />
              </div>))}
            
            </div>
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons>
        <Button color="primary" variant="outlined" style={{marginTop:20}} fullWidth onClick={handleAddEvent}>ADD EVENTO</Button>
          <Divider style={{marginTop:10}} />
          <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
              <Button color="secondary" onClick={() => { props.setIsSubModalVisible(false) }}>Continuar</Button>
          </ButtonGroup>
      </ModalButtons>
    </>
  );
};

export default PickEditEvent;
