import styled from "styled-components";

export const DivModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1201 !important;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background-color: ${props => props.noBackground ? '#fff' : 'var(--modal)'};
    width: 95%;
    color: #000;
    border-radius: 0.5rem;
    padding: 1rem;
    height: auto;
    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      .title {
        text-transform: uppercase;
        color: var(--atlantico);
      }
      .close {
        background: none;
        border: 0;
        cursor: pointer;
        color: var(--text);
      }
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media (min-width: 700px) {
    .container {
      width: 70%;
      height: auto;
      padding: 2rem;
    }
  }
`;
