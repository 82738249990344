import styled from "styled-components";

export const ListDiv = styled.ul`
  width: 100%;
  list-style: none;
  max-height: ${props=> props.dashboard ? '15em' : (props.youtube ? '30em' : '23em')};
  ${props=> props.overflow && 'overflow: auto;'};
  ${props=> !props.overflow && 'max-height: initial;'};
  

  li, a{
    width: 99%;
    text-decoration: none
  }

  .documents{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 0;
    cursor: pointer;
    line-height: 1rem;
    border-bottom: 1px solid var(--divisor);
    margin-right: 0.5rem;
    svg{
      color: #aab7c3;
      margin-right: 1rem;
      margin-bottom: 0;
    }

    a{
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .info{
      display: flex;
      flex-direction: column;

      a, h4{
        white-space:nowrap;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        max-width: 16em;
        color: var(--blue);
        transition: color 0.25s ease-in;
      }

      small{
        color: var(--text);
      }

    }
    &:hover{
      a{
        color: var(--blue-hover);
      }
    }
    
    @media(min-width: 1024px){
      line-height:1rem;
      padding: 1rem 0;
      .info{
        a, h4{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: ${props => props.completeLine ? '100em' : '12em'};
        }
      }
    }
  }

  .invoices{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--item-list);
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    cursor: pointer;

    .icon-info{
      display: flex;
      align-items: center;
      .icon{
        margin-right: 1rem;
      }
      .info{
        display: flex;
        flex-direction: column;
        color: var(--text);
      }
    }

    .status{
      color: var(--text);
    }

    @media(min-width: 1024px){
      padding: 1.5rem;
    }
  }

  .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--item-list);
    padding: .5rem 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    color: var(--text);

    svg{
      color: var(--blue);
    }

    .info{
      display: flex;
      flex-direction: column;
      color: var(--text);
    }

    @media(min-width: 700px){
      padding: .5rem 1rem;
    }
  }

  .notification{
    padding-right: 0.5rem;
    cursor: pointer;

    .header-notification{
      display: flex;
      justify-content: space-between;
      .title{
        display: flex;
        align-items: center;
        h3{
          white-space: nowrap;
          width: 12em;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--text);

          @media (min-width: 700px) {
            width: 100%;
          }
        }
        svg {
          margin-right: 1rem;
        }
      }

      h5{
        color: var(--text);
      } 
    }

    .description-notification{
      padding: 0.5rem 0;
      border-left: 1px solid gray;
      margin: 0.5rem 0;
      margin-left: 0.5rem;
      padding-left: 1rem;
      margin-bottom: 1rem;
      color: var(--text);
    }
  }

  .comments{
    background-color: var(--item-list);
    padding: 2rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    border-radius: 10px;

    h4,h5,p{
      color: var(--text);
    }
  }

  .checkbox{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--item-list);
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

    @media(min-width: 700px){
      padding: .5rem 1rem;
      margin-bottom: .5rem;
      border-radius: 1rem;
    }

    input{
      width: auto;
      margin-right: 1rem;
    }

    label{
      display: flex;
      flex-direction: column;
    }

    h4, h5{
      cursor: pointer;
      color: var(--text);
    }
  }

  .task {
    padding-right: 0.5rem;
    width: 100%;
    
    .task-div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      width: 100%;

      h4, h5{
        max-width: 70%;
      }

      @media(min-width: 700px){
        padding: 1rem;
        h4, h5{
          max-width: 100%;
        }
      }
  
      .title{
        color: var(--blue);
        text-transform: uppercase;
        cursor: pointer;
      }
  
      .infos{
        color: var(--gray)
      }
    }

    hr {
      border-color: ${props => props.darkMode ? '#3d3d3d' : '#BFE6FF'};
      border-width: 0.5px; 
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;

      @media(min-width: 700px){
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }
  }

  .linkedClientsTable{
    width: 100%;
    border: 0;
    outline: 0;

    th {
      text-align: left;
    }

    tr, td, th{
      padding: 0.3rem;
    }

    input, select{
      border: 0;
      background-color: transparent;
      width: 100%;
      color: var(--text);

      option{
        background-color: var(--modal);
      }
    }

    @media(min-width: 700px){
      tr, td, th{
        padding: 1rem;
        color: var(--text); 
      }
    }

    tr:nth-child(even) {
      background-color: ${props => props.darkMode ? '#121212' : '#F4F4F4'};
    }
  }
`;
