import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import ModalButtons from "../../components/ModalButtons";
import ModalContent from "../../components/ModalContent";
import { useSubModal } from "../../context/contextSubModal";
import { useAuth } from "../../context/contextAuth";
import { useSearch } from "../../context/contextSearch";

export const ConfirmLogout = () => {
  const { setIsSubModalVisible } = useSubModal();
  const {setSearch, setResult} = useSearch();
  const { signOut } = useAuth();
  const history = useHistory();

  function handleSignOut() {
    setIsSubModalVisible(false);
    setResult([]);
    setSearch('');
    signOut();
    history.push("/auth");
  }

  return (
    <>
      <ModalContent hasFooter={true}>
        <h3 style={{ textAlign: "center", marginBottom: "2rem" }}>
          <strong style={{color: 'var(--text)'}}>Tem certeza de que deseja sair?</strong>
        </h3>
      </ModalContent>
      <ModalButtons>
        <Button
          hollow={false}
          expansive={true}
          size="small"
          color="#fff"
          border="var(--blue)"
          bgcolor="var(--blue)"
          hover="var(--blue-hover)"
          onClick={handleSignOut}
        >
          Sair
        </Button>
        <hr />
        <Button
          expansive={true}
          size="small"
          color="var(--blue)"
          hollow={true}
          border="var(--blue)"
          onClick={() => {
            setIsSubModalVisible(false);
          }}
        >
          Cancelar
        </Button>
      </ModalButtons>
    </>
  );
};