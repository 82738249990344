import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import List from "../../../components/List";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useAuth } from "../../../context/contextAuth";
import { useNewContact } from "../../../context/contextNewContact";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";

import api from "../../../services/api";

const Companies = ({ navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const { primary } = useAuth();
  const [group, setGroup] = useState([]);
  const token = localStorage.getItem("@Atlantico:token");

  useEffect(() => {
    async function getData(){
      api.get(`v1/clients/${primary._id}/group`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setGroup(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getData();
  }, [primary._id, token]);

  const { companies} = useNewContact();
  const { next, previous } = navigation;

  function handleInputChange(e, item){
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (value === true) {
      value = target.checked;
      if (!companies.includes(item)) {
        companies.push(item);
      }
    } else {
      value = target.checked;
      if (companies.includes(item)) {
        companies.splice(companies.indexOf(item), 1);
      }
    }
  }

  return (
    <>
      {error ? (
        <ToastMessage
          variant="info"
          message="Preencha este campo antes de continuar!"
        />
      ) : null}
      <ModalContent displayFlex={true} hasFooter={true}>
        <FormContact style={{ padding: "0" }} darkMode={isEnabled}>
          <h2>SELECIONE AS EMPRESAS AS QUAIS ESTE CONTATO TERÁ ACESSO</h2>
          <List>
            {group.map((company) => {
              return (
                  <li key={company.document} className="checkbox">
                    <input
                      type="checkbox"
                      id={company.document}
                      value={company.reference}
                      name={company._id}
                      onClick={(e) => {
                        handleInputChange(e, company.reference)
                      }}
                    />
                    <label htmlFor={company.document}>
                      <h4>{company.document}</h4>
                      <h5>{company.name}</h5>
                    </label>
                  </li>
              );
            })}
          </List>
        </FormContact>
      </ModalContent>
      <ModalButtons>
        <Button
          bgcolor="var(--blue)"
          color="#fff"
          border="var(--blue)"
          hover="var(--blue-hover)"
          expansive={true}
          hollow={false}
          size="small"
          onClick={async () => {
            await validateField(group.length, 'number', next, setError);
            setError(false);
          }}
        >
          CONTINUAR
        </Button>
        <hr />
        <Button
          color="var(--gray)"
          border="var(--gray)"
          expansive={true}
          hollow={true}
          size="small"
          onClick={previous}
        >
          VOLTAR
        </Button>
      </ModalButtons>
    </>
  );
};

export default Companies;
