import React from "react";
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import grupoImg from "../../../assets/images/grupo.svg";
import { useNewContact } from "../../../context/contextNewContact";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";
import { useHistory } from "react-router";

const Submit = () => {
  const history = useHistory()
  const {isEnabled} = useColorMode();
  const {setCep, setCompanies, setCpf, setDate, setEmail, setName, setNumber, setPhone, setSector} = useNewContact();
  return (
    <>
      <ModalContent displayFlex={true}>
        <FormContact style={{ padding: "5rem 0" }} darkMode={isEnabled}>
          <h2 style={{ textAlign: "center", textTransform: "uppercase", color: isEnabled ? '#fff' : '#000' }}>
            SOLICITAÇÃO DE NOVO CONTATO ENVIADA COM SUCESSO!
          </h2>
          <h5 style={{ textAlign: "center", marginBottom: "2rem", color: isEnabled ? '#fff' : '#000' }}>
            Sua solicitação foi enviada e em até 24h o novo contato receberá um
            e-mail com as instruções para acesso.
          </h5>
          <img src={grupoImg} alt="" />
        </FormContact>
      </ModalContent>
      <ModalButtons>
        <Button
          color="var(--gray)"
          border="var(--gray)"
          expansive={true}
          hollow={true}
          size="small"
          onClick={() => {
            history.goBack()
            setCep('');
            setCompanies([]);
            setCpf('');
            setDate('');
            setEmail('');
            setName('');
            setNumber('');
            setPhone('');
            setSector([]);
          }}
        >
          FECHAR
        </Button>
      </ModalButtons>
    </>
  );
};

export default Submit;
