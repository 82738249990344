import angry from '../assets/images/angry.svg';
import neutral from '../assets/images/neutral.svg';
import bigsmile from '../assets/images/bigsmile.svg';

export const handleNpsIcon = (value) => {
    if(value >= 0 && value <= 6){
      return angry;
    } else if (value >= 7 && value <= 8){
      return neutral;
    } else if (value >= 9 && value <= 10){
      return bigsmile;
    }
}