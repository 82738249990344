import React, { useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import Button from "../../components/Button";
import ModalContent from "../../components/ModalContent";
import ToastMessage from "../../components/ToastMessage";
import ModalButtons from "../../components/ModalButtons";
import { useColorMode } from "../../context/contextColorMode";

import { InvoiceDetail, BarsCode } from "./styles";

const InvoiceDetails = ({ object }) => {
  const [success, setSuccess] = useState();
  const {isEnabled} = useColorMode();

  function statusInvoice(invoice) {
    if (invoice === "paid") {
      return "A fatura já está paga!";
    } else if (invoice === "canceled") {
      return "A fatura foi cancelada!";
    } else if (invoice === "expired") {
      return "A fatura está expirada!";
    }
  }

  if (object === false) {
    return <h1>Deu errado</h1>;
  } else {
    if (object.bank_slip === null) {
      return (
        <>
            {success ? (
              <ToastMessage
                variant="success"
                message="Copiado para a área de transferência!"
              />
            ) : null}
            <ModalContent hasFooter={true}>
              <InvoiceDetail>
                <FaFileAlt size={48}/>
                <h3>
                  <strong>{object.total}</strong>
                </h3>
                {object.items.map((item) => (
                  <h4 key={item}>{item}</h4>
                ))}
                <h4 style={{ margin: "2rem 0" }}>
                  <strong>{statusInvoice(object.status)}</strong>
                </h4>
              </InvoiceDetail>
            </ModalContent>
            <ModalButtons>
              <Button
                bgcolor="var(--blue)"
                color="#fff"
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={false}
                size="small"
                onClick={() => {
                  window.open(object.url);
                }}
              >
                VER FATURA
              </Button>
            </ModalButtons>
        </>
      );
    } else {
      return (
        <>
            {success ? (
              <ToastMessage
                variant="success"
                message="Copiado para a área de transferência!"
              />
            ) : null}
            <ModalContent hasFooter={true}>
              <InvoiceDetail darkMode={isEnabled}>
                <FaFileAlt size={48} color='var(--file)'/>
                <h3 style={{margin: '1rem 0', color: 'var(--text)'}}>
                  <strong>{object.total}</strong>
                </h3>
                {object.items.map((item) => (
                  <h4 key={item}>{item}</h4>
                ))}
                <h5>Venc. em {object.due_date}</h5>

                <p>
                  Utilize o código de barras abaixo para realizar o pagamento:
                </p>

                <BarsCode>
                  <h4>
                    <strong id="barsCode">
                      {object.bank_slip.digitable_line}
                    </strong>
                  </h4>
                </BarsCode>
              </InvoiceDetail>
            </ModalContent>
            <ModalButtons>
              <Button
                bgcolor="var(--green)"
                color="#fff"
                border="var(--green)"
                hover="var(--green-hover)"
                expansive={true}
                hollow={false}
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(
                    object.bank_slip.digitable_line
                  );
                  setSuccess(true);
                }}
              >
                COPIAR
              </Button>
              <hr />
              <Button
                bgcolor="var(--blue)"
                color="#fff"
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={false}
                size="small"
                onClick={() => {
                  window.open(object.url);
                }}
              >
                VER FATURA
              </Button>
            </ModalButtons>
        </>
      );
    }
  }
};

export default InvoiceDetails;
