import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaFileAlt, FaFileDownload, FaFolder } from 'react-icons/fa';
import Button from '../../components/Button';
import List from '../../components/List';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import ModalButtons from '../../components/ModalButtons';
import ModalContent from '../../components/ModalContent';
import { useAuth } from '../../context/contextAuth';
import { useColorMode } from '../../context/contextColorMode';
import api from '../../services/api';

const Legacy = () => {
    const {primary} = useAuth();
    const token = localStorage.getItem('@Atlantico:token');
    const {isEnabled} = useColorMode();
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [paths, setPaths] = useState([]);
    const [activePath, setActivePath] = useState('');
    const [loader, setLoader] = useState();
    const [filesToDownload, setFilesToDownload] = useState([]);

    useEffect(() => {
        async function getData(){
            await api.get(`v1/clients/${primary._id}/LegacyList?itemId=${activePath}`, {
                headers: {
                    Authorization: token,
                }
            }).then(setLoader(true))
            .then(response => {
                setLoader(false);
                setFolders(response.data.folders);
                setFiles(response.data.files);
                setPaths([...paths, response.data.folders.length > 0 ? response.data.folders[0].parentid : response.data.files[0].parentid]);
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
            });
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    async function downloadMultipleFiles(array){
        array.map(file => {
          return window.open(file);
        });
        setFilesToDownload([]);
    }
    
    async function removeFileToDownload(file){
        const updatedDownload = filesToDownload.filter(arq => arq.name !== file.name);
        setFilesToDownload(updatedDownload);
    }

    async function handleBack(){
        setFilesToDownload([]);
        setActivePath(paths[paths.length - 2]);
        let updatedPaths = [...paths];
        paths.map((path, index) => {
            if(paths.length - 1 === index){
                updatedPaths.splice(index, 1);
            }
            return updatedPaths; 
        });
        setPaths(updatedPaths);

        await api.get(`v1/clients/${primary._id}/LegacyList?itemId=${paths[paths.length - 2]}`, {
            headers: {
                Authorization: token,
            }
        }).then(setLoader(true))
        .then(response => {
            setLoader(false);
            setFolders(response.data.folders);
            setFiles(response.data.files);
        })
        .catch(err => {
            console.log(err);
            setLoader(false);
        });
    }

    async function handleForward(path){
        setFilesToDownload([]);
        setActivePath(path);
        setPaths([...paths, path]);

        await api.get(`v1/clients/${primary._id}/LegacyList?itemId=${path}`, {
            headers: {
                Authorization: token,
            }
        }).then(setLoader(true))
        .then(response => {
            setLoader(false);
            setFolders(response.data.folders);
            setFiles(response.data.files);
        })
        .catch(err => {
            console.log(err);
            setLoader(false);
        });
    }

    return (
        <>
        <ModalContent hasFooter={true}>
            {loader ? <Loader/> : (
                <>
                {paths.length > 1 && (
                  <h3 onClick={() => handleBack()} style={{ display: "flex", alignItems: "center", cursor: "pointer", padding: "1rem 0" }}>
                    <FaArrowLeft size={16} style={{ marginRight: "1rem" }} color='var(--text)' />
                    <strong style={{color:'var(--text)'}}>Voltar</strong>
                  </h3>
                )}
                <List darkMode={isEnabled} completeLine={true}>
                    {folders.length !== 0 && folders.map((folder, index) => {
                        return (
                            <li key={index} className="documents" onClick={() => handleForward(folder.id)}>
                                <FaFolder size={32} style={{ color: "var(--folder)" }}/>
                                <div className="info">
                                    <h4>
                                        <strong style={{ color: "var(--folder)" }}>
                                            {folder.name}
                                        </strong>
                                    </h4>
                                </div>
                            </li>
                        );
                    })}

                    {files.length !== 0 && files.map((file, index) => {
                        return (
                            <li key={index} className="documents" onClick={() => {
                                filesToDownload.includes(file.download) ? removeFileToDownload(file.download) : setFilesToDownload([...filesToDownload, file.download]);
                              }}>
                                {filesToDownload.includes(file.download) && <input type="checkbox" style={{marginRight: 10}} checked/>}
                                <FaFileAlt size={32} style={{ color: 'var(--file)' }} />
                                <div className="info">
                                    <h4>
                                        <strong style={{ color: 'var(--file)' }}>
                                            {file.name}
                                        </strong>
                                    </h4>
                                </div>
                            </li>
                        );
                    })}

                    {(folders.length === 0 && files.length === 0) && <Message  severity="warning" message="Esta pasta está vazia!"/>}
                </List>
                </>
            )}
        </ModalContent>
        {filesToDownload.length > 0 && (
            <ModalButtons>
                <Button
                    type="submit"
                    bgcolor="var(--green)"
                    color="#fff"
                    border="var(--green)"
                    hover="var(--green-hover)"
                    expansive={true}
                    hollow={false}
                    size="small"
                    onClick={() => downloadMultipleFiles(filesToDownload)}
                >
                    <FaFileDownload size={18} />
                    BAIXAR
                </Button>
            </ModalButtons>
        )}
      </>
    );
}

export default Legacy;