import styled from 'styled-components';

export const Buttons = styled.div`

    display: flex;
    flex-direction: column;

    button{
        padding: 0.2rem;
        background-color: var(--blue);
        border: 0;
        outline: 0;
        color: white;
        border-radius: 0.25rem;
        width: 8rem;
        height: 2rem;
    }

    button:last-child{
        margin-top: 0.5rem
    }

    @media (min-width: 700px) {
        flex-direction: row;

        button{
            padding: 1rem;
            background-color: var(--blue);
            border: 0;
            outline: 0;
            color: white;
            border-radius: 0.5rem;
            width: auto;
            height: auto;
        }

        button:last-child{
            margin-top: 0;
            margin-left: 1rem
        }
    }

    @media (min-width: 1025px) {
        flex-direction: row;
    }
`;