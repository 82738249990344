import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

import logo from "../../../assets/images/logo.svg";

import { RecoverPage, PageHeader } from "../styles";

const ErrorRecoverPassword = () => {
  return (
    <RecoverPage className="container">
      <PageHeader>
        <div id="top-bar-container"></div>
        <div id="header-content">
          <img src={logo} width="200" alt="Atlântico Digital" />
        </div>
      </PageHeader>
      <main>
        <div className="text">
          <h2>
            <strong>
              Ops!
              <br />
              No momento não foi possível alterar sua senha
            </strong>
          </h2>
          <h3>
            O prazo pode ter expirado ou a requisição é inválida. Tente
            novamente mais tarde ou refaça a solicitação de recuperação.
          </h3>
        </div>
        <Link to="/auth" style={{ textDecoration: "none" }}>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            size="medium"
            hollow={false}
            expansive={true}
          >
            Voltar ao login
          </Button>
        </Link>
      </main>
    </RecoverPage>
  );
};

export default ErrorRecoverPassword;
