import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Container, FileInfo } from "./styles";
import {CircularProgressbar} from 'react-circular-progressbar';

const FileList = ({ files }) => (
  <Container>
    {files.length !== 0
      ? files.map((uploadedFile) => (
          <li key={uploadedFile.id}>
            <FileInfo>
              <div>
                <strong>{uploadedFile.name}</strong>
                <span>{uploadedFile.readableSize} </span>
              </div>
            </FileInfo>

            <div>
              {!uploadedFile.uploaded && !uploadedFile.error && (

                <CircularProgressbar
                styles={{
                  root: {width: 24},
                  path: {stroke: "var(--blue)"},
                  trail: {stroke: "#d6d6d6"}
                }}
                strokeWidth={10}
                value={uploadedFile.progress} />

              )}

              {uploadedFile.uploaded && (
                <FaCheckCircle size={24} color="#78e5d5" />
              )}
              {uploadedFile.error && (
                <FaTimesCircle size={24} color="#e57878" />
              )}
            </div>
          </li>
        ))
      : null}
  </Container>
);

export default FileList;
