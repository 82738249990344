import styled from "styled-components";

export const Title = styled.h3`
  margin-top: 1.5rem;
  margin-bottom: 0.6rem;
  color: var(--blue);
`;

export const Content = styled.div`
  background-color: var(--modal);
  border-radius: 5px;
  padding: 1rem;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 20px;
  }

  @media (min-width: 700px) {
    padding: 2rem;
  }

  @media (min-width: 1025px) {
    padding: 2rem;
  }

  .content-envios {
    width: 100%;
    margin-bottom: 1rem;

    .envios_name {
      margin-bottom: 1rem;
      color: #808080;
    }

    .info_arq {
      display: flex;
      width: 100%;
      justify-content: space-between;
      div {
        width: 40%;
        justify-content: space-around;
        align-items: center;
        display: flex;
        p {
          line-height: 1.5rem;
          .texto {
            text-align: center;
          }
        }
      }
    }

    @media (min-width: 700px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      .envios_name {
        width: 100%;
      }

      .info_arq {
        width: 100%;
        padding: 0;
        div {
          width: 100%;
          justify-content: space-around;
          align-items: center;
          display: flex;
          p {
            line-height: 2rem;
            .texto {
              text-align: center;
            }
          }
        }
      }

      @media (min-width: 1025px) {
        .envios_name {
          width: 100%;
        }

        .info_arq {
          width: 100%;
          padding: 0;
          div {
            width: 100%;
          }
        }
      }
    }
  }

  .name_pj {
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
    color: #808080;
  }

  .info_pj {
    width: 100%;
    padding: 1rem 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid var(--divisor);
    svg {
      margin: 0;
      margin-right: 1rem;
    }
    h5 {
      display: flex;
      width: 100%;
      align-items: center;
      line-height: 1rem;
      color: var(--pink);
      margin: .5rem 0;
    }

    @media (min-width: 1025px) {
      flex-direction: row;
      h5 {
        line-height: 1rem;
      }
    }
  }

  .notifyClient{
    width: 100%;
    .input{
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 1rem 0;
      input{
        margin-right: 1rem;
        border: 0;
        border-bottom: 1px solid var(--divisor);
        color: var(--input);
        width: 100%;
        padding-top: 1rem;
        outline: 0;
        background-color: var(--modal);
      } 
      @media (min-width: 700px){
        flex-direction: row;
          button{
            width: 100px;
          }
      } 
    }
  }
`;
