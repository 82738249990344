import React from "react";
import grupoImg from "../../../assets/images/grupo.svg";
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import { useNewRequest } from "../../../context/contextNewRequest";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";
import { useHistory } from "react-router";

const Submit = () => {
  const history = useHistory();
  const {isEnabled} = useColorMode();
  const {setDescription, setDate, setTitle, setType, requestNumber, setRequestNumber} = useNewRequest();
  return (
    <>
      <ModalContent>
        <FormContact style={{ padding: "5rem 0", alignItems: "center" }} darkMode={isEnabled}>
          <h2>
            SOLICITAÇÃO #{requestNumber} ENVIADA COM SUCESSO!
          </h2>
          <img src={grupoImg} alt="" />
        </FormContact>
      </ModalContent>
      <ModalButtons>
        <Button
          color="var(--gray)"
          border="var(--gray)"
          expansive={true}
          hollow={true}
          size="small"
          onClick={() => {
            history.goBack()
            setDate('');
            setDescription('');
            setTitle('');
            setType('');
            setRequestNumber();
          }}
        >
          Fechar
        </Button>
      </ModalButtons>
    </>
  );
};

export default Submit;
