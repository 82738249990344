import React, {useEffect} from "react";
import { useStep } from "react-hooks-helper";

import Company from './Fields/Company';
import Type from "./Fields/Type";
import Title from "./Fields/Title";
import Description from "./Fields/Description";
import Date from "./Fields/Date";
import Submit from "./Fields/Submit";
import { useNewRequest } from "../../context/contextNewRequest";
import api from "../../services/api";
import { useAuth } from "../../context/contextAuth";
import { useState } from "react";
import Loader from "../../components/Loader";

const OpenRequest = () => {
  let steps = [];
  const {user} = useAuth();
  const {companies, setCompanies} = useNewRequest();
  const token = localStorage.getItem("@Atlantico:token");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    async function getData(){
        await api.get(`v1/users/${user._id}/clients`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setLoader(false);
          setCompanies(response.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
     getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(companies.length === 1){
    steps = [
      { id: "type" },
      { id: "title" },
      { id: "description" },
      { id: "date" },
      { id: "submit" },
    ]
  } else {
    steps = [
      { id: "company" },
      { id: "type" },
      { id: "title" },
      { id: "description" },
      { id: "date" },
      { id: "submit" },
    ]
  }

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { navigation };

  if(loader){
    return (
      <div>
        <Loader/>
      </div>
    )
  } else {
    switch (id) {
      case "company":
        return <Company {...props}/>;
      case "type":
        return <Type {...props} />;
      case "title":
        return <Title {...props} />;
      case "description":
        return <Description {...props} />;
      case "date":
        return <Date {...props} />;
      case "submit":
        return <Submit {...props} />;
      default:
        return null;
    }
  }
};

export default OpenRequest;
