import styled from "styled-components";


export const DashboardPage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);
  
  .subheader-mobile {
    text-align: center;
    margin-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 2.5rem;
  }

  .search {
    width: 100%;
    margin: 0 auto;
    height: auto;
    padding: 0 1rem;
    margin-top: 1rem;
  }

  .div-search {
    position: relative;
    margin: 0 2rem;

    .resultSet {
      height: auto;
      background: ${props => props.darkMode ? '#1c1c1c' : '#fff'};
      padding: 0.5rem 2rem;
      border-radius: 1rem;
      z-index: 99999 !important;
      position: absolute;
      max-height: 10em;
      overflow: auto;
      width: 100%;
      -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);
      -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.21);

      p{
        color: var(--text);
        margin: 1rem 0;
      }

      ul {
        list-style: none;
        li {
          padding: .5rem 0;
          cursor: pointer;
          color: var(--text);
          &:hover {
            background-color:${props => props.darkMode ? '#121212' : '#f1f7fa'};
            color: var(--blue);
          }
          a{
            text-decoration: none;
            color: ${props => props.darkMode ? '#666666' : '#000'};
          }
        }
      }
    }
    @media (min-width: 700px) {
      margin: 0 15rem;
    }
    @media (min-width: 1024px) {
      margin: 0;
    }
  }

  .buttons {
    padding: 0 2rem;
    z-index: 1 !important;
    a{
      text-decoration: none;
    }
  }

  #icons {
    display: flex;
    justify-content: space-evenly;
    padding-top: 2.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    svg {
      color: var(--blue);
      &:hover {
        color: var(--blue-hover);
      }
      &#circle-notification {
        color: var(--red);
        position: absolute;
        width: 8px;
        left: 4.5rem;
        top: 18rem;
      }
    }
  }

  .blocks{
    padding: 0 2rem;

    &#block3{
      padding: 2rem 2rem;
    }
  }

  @media (min-width: 700px) {
    .subheader-mobile {
      margin-top: 4rem;
    }

    .search {
      width: 100%;
      margin: 0 auto;
      height: auto;
      padding: 0 15rem;
      margin-top: 1rem;
    }

    #icons {
      padding: 3rem 25rem;
      svg {
        &#circle-notification {
          top: 26rem;
          left: 30rem;
        }
      }
    }
    .buttons {
      padding: 0 15rem;
    }

    .blocks {
      padding: 1rem 15rem;
      &#block3{
        padding: 1rem 15rem;
        padding-bottom: 4rem;
      }
    }
  }

  @media (min-width: 1025px) {
    display: grid;
    padding: 2rem;
    padding-left: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3rem;
    grid-template-areas:
      "navbar button1 button2 button3"
      "navbar input input input"
      "navbar res res res"
      "navbar entrada entrada entrada"
      "navbar envios envios envios"
      "navbar pj pj pj";
    nav {
      width: 100%;
      .sidebar-desktop {
        grid-area: navbar;
      }
    }

    .div-search {
      width: 100%;
      grid-area: res;
      margin-top: -4rem;
    }

    .subheader-mobile {
      display: none;
    }

    .search {
      width: 100%;
      margin: 0 auto;
      height: auto;
      padding: 0;
      grid-area: input;
    }

    .buttons {
      padding: 0;
      
    }
    #button1 {
      grid-area: button1;
    }
    #button2 {
      grid-area: button2;
    }
    #button3 {
      grid-area: button3;
    }

    .blocks {
      padding: 0;
      &#block3{
        padding: 0;
      }
    }
    #block1 {
      grid-area: entrada;
      margin-top: -4rem;
    }
    #block2 {
      grid-area: envios;
      margin-top: -4rem;
    }
    #block3 {
      grid-area: pj;
      margin-top: -4rem;
    }
  }

  @media (min-width: 1346px) {
    grid-template-areas:
      "navbar button1 button2 button3"
      "navbar input input input"
      "navbar res res res"
      "navbar entrada envios envios"
      "navbar entrada pj pj";
  }

  @media (max-width: 1025px) {
    nav {
      .sidebar-desktop {
        display: none;
      }
    }
  }
`;

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: ${props => props.darkMode ? '#1f1f1f' : 'var(--atlantico)'};

  img {
    align-self: center;
  }

  svg{
    color: #fff;
  }

  @media(min-width: 1025px){
    display: none;
  }
`;

export const DivFileOptions = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    color: var(--blue);
  }
  h3 {
    margin-bottom: 2rem;
    text-align: center;
  }
`;