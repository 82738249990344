import React, { useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaPlus,
  FaCheckCircle,
} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Paper,
  Backdrop,
  LinearProgress,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";
import PickEmployee from "./events/submodals/employee";
import { useSubModal } from "../../context/contextSubModal";
import PickEditEvent from "./events/submodals/events";

import api from "../../services/api";
import { rr } from "../../services/runrunit";

const token = localStorage.getItem("@Atlantico:token");

const Events = ({ data, type }) => {
  const history = useHistory();
  const { primary, user } = useAuth();
  const {isEnabled} = useColorMode();
  const [state,setState] = useState(data.draft)
  const {setIsSubModalVisible, setSubModalContent} = useSubModal()
  const [submitError, setSubmitError] = useState(false)
  const [progressDraft, setProgressDraft] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(false)
  const [taskId,setTaskId] = useState(data.task)
  const [progress,setProgress] = useState({
    state: false,
    step: 0,
    progress:0,
  })

  const headers = {
    headers: {
      Authorization: token,
    },
  }

  const postData = (payload) => (

    api.post(`v1/questor/events`,payload,headers)
    .then(response => {
      return true
    })
    .catch(error => {

      onSubmitError(error)
      return false

    })

  )

  const onSubmitError = (error) => {
    if(error.response){
      console.log(error.response.data)
    }

    setSubmitError(true)
  }

  const getLastSeq = async () => {
    return api.get(`v1/questor/eventsByPeriod?reference=${primary.reference}&period=${state.CODIGOPERCALCULO}`,headers)
    .then(response => {
      const { data, count } = response.data

      if(!count) return 0;
      
      const last = Number(data.SEQ)
      return last
    })
    .catch(error => {
      return 0;
    })

  }

  const sendEmail = async (id) => {
    return api.post(`v1/mailer/send`,{
      recipients: user.email.map(email => ({email: email.value,name: user.name})),
      subject: "Novo lançamento de folha registrado",
      template_id: "z3m5jgr9q8xgdpyo",
      data: {
        id,
        name: user.nickname,
        company: primary.name,
        compet: state.COMPET,
      }
    },headers).then((response) => console.log(response)).catch((error) => console.log(error))
  }

  const handleTaskStatus = async () => {

    return rr.post(`/tasks/${taskId}/change_status`, {
        task_status_id: 1948744
    }, {
        headers: {
            'Content-Type': 'application/json',
            "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
            "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
        }
    })
    .then(response => true)
    .catch(err => false)
  
  }

  const handleTaskComment = async () => {

    let blocks = ""

    blocks = blocks + `════ ⋆★⋆ ════\n\n`
    
    blocks = blocks + `<b>Competência:</b> ${state.COMPET}\n`

    if(state.DESCRICAO){
      blocks = blocks + `\n<b>Informação adicional:</b>\n${state.DESCRICAO}\n`
    }

    blocks = blocks + `\n<b>════ EMPREGADOS ════</b>\n\n`
    
    Object.entries(state.employees).map(row => {
      blocks = blocks + `<b>[${row[1].NOMEFUNC}]</b>\n`

      row[1].EVENTOS.map(row => {
        blocks = blocks + `<b>[${row.CODIGOEVENTO}] ${row.DESCRIPTION}</b>: ${row.VALOREVENTO || row.REFEREVENTO}\n`
      })

      blocks = blocks + `\n`
    })

    return rr.post(`/comments`, {
        task_id: taskId,
        text: blocks,
    }, {
        headers: {
            'Content-Type': 'application/json',
            "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
            "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
        }
    })
    .then(response => true)
    .catch(err => false)
  
  }

  const onSubmit = async () => {
    setProgress({
      state:true,
      step:0,
      progress:33,
    })

    const task = await handleTaskComment()
    const taskStatus = await handleTaskStatus()
    if(!task||!taskStatus) {
      setError(true)
      return false
    }

    setProgress({
      state:true,
      step:1,
      progress:66,
    })

    let lastSeq = await getLastSeq()
    let payload = []

    Object.entries(state.employees).map(employee => {      
      var seq = lastSeq;

      employee[1].EVENTOS.map(evento => {
        seq ++;

        payload.push({
          CODIGOEMPRESA: employee[1].CODIGOEMPRESA,
          CODIGOPERCALCULO: state.CODIGOPERCALCULO,
          SEQ: seq,
          CODIGOFUNCCONTR: employee[1].CODIGOFUNCCONTR,
          CODIGOEVENTO: evento.CODIGOEVENTO,
          VALOREVENTO: evento.VALOREVENTO || 0,
          REFEREVENTO: evento.REFEREVENTO || 0,
        })

        return false;
      })

      return false;
    })

    if(!await postData(payload)) return false

    api.put(`v1/fopag/events/${data._id}`,{state:true},headers)

    await sendEmail(taskId);

    setProgress({
      state:true,
      step:2,
      progress:100,
    })

  }

  const backdrop = () => {

    if(submitError) {

      return <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível continuar sua solicitação, volte e tente novamente mais tarde. Persistindo o problema, entre em contato com nossos canais de suporte!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }}>Voltar</Button>
      </Paper>
    }

    return (
      <div>

        <LinearProgress variant="determinate" value={progress.progress} />

        <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          {progress.step < 2 && <CircularProgress size={50} style={{marginBottom:20}} />}
          {progress.step===2 && <FaCheckCircle size={100} color="#3cd56b" style={{marginBottom:20}} />}
                
          {progress.step===0 && <Typography variant="h5">Criando tarefa...</Typography>}
          {progress.step===1 && <Typography variant="h5">Enviando informações...</Typography>}
          {progress.step===2 && <div><Typography variant="h5">Solicitação enviada com sucesso!</Typography><Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }}>Voltar</Button></div>}
        </Paper>

      </div>
    )

  }

  if(error){
    return <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível continuar sua solicitação, volte e tente novamente mais tarde. Persistindo o problema, entre em contato com nossos canais de suporte!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }}>Voltar</Button>
      </Paper>
  }

  if(loading){
    return <LinearProgress />
  }

  return (
    <>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

          <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
          <Grid container>
            <Grid item xs={12} style={{padding:1}}>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography variant="overline" display="block" gutterBottom>Organização</Typography>
                  <Divider style={{marginBottom:20}} />
                  {primary.name}
                </Grid>
                
                <Grid item xs={12} lg={6}>
                  <Typography variant="overline" display="block" gutterBottom>Competência</Typography>
                  <Divider style={{marginBottom:20}} />
                  {state.COMPET}
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="overline" display="block" gutterBottom>Informações adicionais</Typography>
                  <Divider style={{marginBottom:20}} />
                  {state.DESCRICAO}
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="overline" display="block" gutterBottom>Empregados/Eventos</Typography>
                  <Divider style={{marginBottom:20}} />

                  <Grid container spacing={2}>
                    {Object.values(data.draft.employees).map((row, index) => (
                      <Grid item key={index} xs={12} lg>
                        <Card variant="outlined">
                          <CardContent style={{ paddingBottom: 0 }}>
                            <Typography variant="overline" display="block" gutterBottom>{row.NOMEFUNC}</Typography>
                            <Divider style={{marginBottom:5}} />

                            {row.EVENTOS.map((event,index) => (
                              <div key={index}>
                              <Typography variant="body2" display="block" gutterBottom>{event.DESCRIPTION}: <b>{event.VALOREVENTO || event.REFEREVENTO}</b></Typography>
                              <Divider style={{marginBottom:10}} />
                              </div>
                            ))}

                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid> 
                
            </Grid>
          </Grid>
          </div>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
          {type !== "readonly" && <Typography variant="h6" component="p" color="secondary" style={{marginTop: 10, textAlign: "center" }}>Por favor, confira as informações de folha antes de confirmar o envio.</Typography>}

          {type !== "readonly" && 
          <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
              <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha-inclusao', state: {modal: true}, draftId: data._id, type: "manual"}) }}>Voltar</Button>
              <Button color="secondary" onClick={() => { setConfirm(true) }}>Confirmar</Button>
          </ButtonGroup>}

          {type === "readonly" && <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }} fullWidth variant="outlined" style={{ marginTop: 20 }}>Voltar</Button>}
      </ModalButtons>

      <Backdrop open={confirm} style={{zIndex:1000}}>
        <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
            <Typography variant="h5" component="p" style={{marginBottom:20}}>Deseja realmente enviar os eventos para folha?</Typography>

              <ButtonGroup style={{marginTop:20}} fullWidth>
              <Button variant="contained" color="secondary" onClick={() => { setConfirm(false) }}>Não</Button>
              <Button variant="contained" color="primary" onClick={() => { onSubmit() }}>Sim</Button>
            </ButtonGroup>
        </Paper>
      </Backdrop>

      <Backdrop open={progress.state} style={{zIndex:1000}}>
        {backdrop()}
      </Backdrop>
    </>
  );
};

export default Events;