import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaFolder } from "react-icons/fa";
import { useAdminCompany } from "../../../context/contextAdminCompany";
import ModalContent from "../../../components/ModalContent";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import List from "../../../components/List";

import api from "../../../services/api";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const Tasks = () => {
  const {company} = useAdminCompany();

  const [tasks, setTasks] = useState([]);
  const token = localStorage.getItem("@Atlantico:token");
  const [loader, setLoader] = useState();
  const history = useHistory();

  useEffect(() => {
    async function getData(){
     await api.get(`v1/clients/${company._id}/tasks?project_id=${company.runrunit_projects[0]}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setTasks(response.data.tasks);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
  }, [company, token]);

  return (
    <>
      <ModalContent hasFooter={false}>
        {loader ? (
          <Loader />
        ) : (
          <>  
            <h3 onClick={() => history.goBack()} style={{display: "flex",alignItems: "center", cursor: "pointer",padding: "1rem 0"}}>
              <FaArrowLeft size={16} style={{ marginRight: "1rem", color: 'var(--text)' }} />
              <strong style={{color: 'var(--text)'}}>Voltar</strong>
            </h3>
            <hr style={{ margin: "1rem 0", color: 'var(--divisor)' }} />
          <List completeLine>
              {tasks.map((task) => {
                return (
                  <Link to={{pathname: `/admin/${company._id}/tarefa/${task.id}`, state: {modal: true, props: task}}}>
                    <li key={task.id} className="documents" >
                      <FaFolder size={32} />
                      <div className="info">
                        <a href="#!">
                          <strong title={task.title}> {task.title}</strong>
                        </a>
                        <span><small>Modificado em {task.closed_at_formatted}</small></span>
                      </div>
                    </li>
                  </Link>
                );
              })}
              {tasks.length === 0 && <Message severity="warning" message="Nenhum arquivo até o momento!"/>}
          </List>
          </>
        )}
      </ModalContent>
    </>
  );
};

export default Tasks;