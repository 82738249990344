import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  LinearProgress,
  CircularProgress,
  Paper,
  Typography,
  Backdrop,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";

const PeopleEmployeeDetail = ({ employee, type }) => {
  const [tab, setTab] = useState("general")
  const history = useHistory();
  const {user, primary} = useAuth();
  const {isEnabled} = useColorMode();
  const token = localStorage.getItem("@Atlantico:token")
  const [loading, setLoading] =  useState(false)

  console.log(employee)

  useEffect(() => {

    // const cargosRequest = api.post(`v1/nweb?classe=TnFpaDCCargoEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
    // const funcoesRequest = api.post(`v1/nweb?classe=TnFpaDCFuncaoEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
    // const escalasRequest = api.post(`v1/nweb?classe=TnFpaDCEscalaEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
    // const tiposContratoRequest = api.post(`v1/nweb?classe=TnFpaDCTipoContratoEmpresa&limit=100`,{ Filter: {CODIGOEMPRESA:primary.reference} },headers)
  
    // const requests = [cargosRequest,funcoesRequest,escalasRequest,tiposContratoRequest]
  
    // if(id){
    //   const draft = api.get(`v1/fopag/employee?_id=${id}`,headers)
    //   requests.push(draft)
    // }
    
    // setEmployee({
    //   ...employee,
    //   CODIGOEMPRESA: primary.reference
    // })

    // axios.all(requests)
    // .then(
    //   axios.spread((...responses) => {
    //     const responseCargos = responses[0]
    //     const responseFuncoes = responses[1]
    //     const responseEscalas = responses[2]
    //     const responseTiposContrato = responses[3]

    //     setCargos(responseCargos.data.data)
    //     setFuncoes(responseFuncoes.data.data)
    //     setEscalas(responseEscalas.data.data)
    //     setTiposContrato(responseTiposContrato.data.data)

    //     if(id!=undefined){
    //       const responseDraft = responses[4]
    //       setEmployee({
    //         ...employee,
    //         ...responseDraft.data[0].draft,
    //       })

    //       setDraft(responseDraft.data[0])
    //     }

    //     setLoading(false)
    //   })
    // )
    // .catch(error => {
    //   console.log(error)
    // })

  },[])

  if(loading){
    return (<LinearProgress />)
  }

  return (
    <div>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

        <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
        <Grid container>
          <Grid item xs={12} style={{padding:1}}>
              <Typography variant="h4" style={{marginBottom:20}}>{employee.NOMEFUNC}</Typography>

              {/* <ButtonGroup variant="outlined" style={{marginTop:30}} fullWidth>
                  <Button variant="contained" color={tab=="general"?"primary":"default"} onClick={()=>{setTab("general")}}>Geral</Button>
                  <Button variant="contained" color={tab=="professionals"?"primary":"default"} onClick={()=>{setTab("professionals")}}>Profissionais</Button>
                  <Button variant="contained" color={tab=="personal"?"primary":"default"} onClick={()=>{setTab("personal")}}>Pessoais</Button>
                  <Button variant="contained" color={tab=="dependents"?"primary":"default"} onClick={()=>{setTab("dependents")}}>Dependentes</Button>
                  <Button variant="contained" color={tab=="attachments"?"primary":"default"} onClick={()=>{setTab("attachments")}}>Anexos</Button>
              </ButtonGroup> */}

              <Card>
                <CardContent>

                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell width={200} style={{fontWeight:300}}>Categoria:</TableCell>
                          <TableCell>{employee.CATEGORIA}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{fontWeight:300}}>Data de admissão:</TableCell>
                          <TableCell>{employee.DATAADM}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{fontWeight:300}}>Situação:</TableCell>
                          <TableCell>{employee.DESCRSIT}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{fontWeight:300}}>Tipo de vínculo:</TableCell>
                          <TableCell>{employee.TIPOVINCULO}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* {tab==="general"&&<General data={{cargos,sindicatos,funcoes,escalas,tiposContrato,employee}} handleChange={ handleChange } />}
                  {tab==="professionals"&&<Professionals data={{employee}} handleChange={ handleChange } />}
                  {tab==="personal"&&<Personal data={{employee}} handleChange={ handleChange } />}
                  {tab==="attachments"&&<Attachments handleChange={ handleChange } draft={draft} alert={setRequired} success={setSuccess} />}
                  {tab==="dependents"&&<Dependents data={{employee}} handleChange={ handleChange } />} */}
                </CardContent>
              </Card>
              
          </Grid>
        </Grid>
        </div>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
              <Divider style={{marginTop:10}} />

              <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
                  <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type}) }}>Voltar</Button>
              </ButtonGroup>
      </ModalButtons>
    </div>
  );
};

export default PeopleEmployeeDetail;
