import React, {useCallback, useEffect, useState} from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaPlus,
  FaTrash,
  FaArrowRight,
} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";
import api from "../../services/api";
import { Alert } from "@material-ui/lab";

import { rr } from "../../services/runrunit";

const EventsList = () => {
  const history = useHistory();
  const date = new Date()
  const compet = `${('0'+(date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
  const {user,primary} = useAuth();
  const [drafts,setDrafts] = useState([])
  const [loading, setLoading] = useState(true)
  const [isMonthDone,setIsMonthDone] = useState(false)
  const {isEnabled} = useColorMode();
  const token = localStorage.getItem("@Atlantico:token")
  const [reopenProgress, setReopenProgress] = useState(false)
  const [reopenSuccess, setReopenSuccess] = useState(false)

  const headers = {
    headers: {
      Authorization: token,
    },
  }

  const checkCurrentMonth = () => {
    api.get(`v1/fopag/events?client=${primary.reference}&state=true&compet=${compet}`,headers)
      .then(response => {
        if(response.status===200&&response.data.length>0){
          setIsMonthDone(true)
        }
      })
      .then(() => {
        setLoading(false)
      })
  }

  const loadDrafts = () => {

    api.get(`v1/fopag/events?client=${primary.reference}`,headers)
      .then(response => {
        setDrafts(response.data)
      })
      .then(() => {
        setLoading(false)
      })

  }

  const handleDraftDelete = (id) => {

    api.delete(`v1/fopag/events/${id}`,headers)
      .then(response => {
        loadDrafts()
        setLoading(true)
      })
      .catch(error => {
        console.log(error)
      })

  }

  const handleReopen = (data) => {
    setReopenProgress(true)

    rr.post(`/comments`, {
        task_id: data.task,
        text: `<b>${user.nickname}</b>, solicitou a reabertura da competência pelo AD. ${data._id}`,
    }, {
        headers: {
            'Content-Type': 'application/json',
            "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
            "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
        }
    })
    .then(response => {
      setReopenSuccess(true)
      setReopenProgress(false)
    })
    .catch(err => false)

  }

  const columnsDrafts = [
    {
      name: 'CODIGOPERCALCULO',
      header: 'Cód.',
      maxWidth: 120,
      render: ({ data }) => {
        return data.draft.CODIGOPERCALCULO
      }
    },
    { 
      name: 'COMPET',
      header: 'Competência',
      defaultFlex: 1,
      render: ({ data }) => {
        const to = data.state ? {pathname: `/dashboard/pessoas-eventos-em-folha-recibo`, state: {modal: true}, data, type: "readonly"} : {pathname: `/dashboard/pessoas-eventos-em-folha-inclusao`, state: {modal: true}, draftId: data._id, type: "manual"}

        return <Link to={to}>{data.draft.COMPET}</Link>
      }
    },
    { name: 'DESCRTIPOCALC', header: 'Tipo', defaultFlex: 1, render: ({ data }) => "Mensal"},
    {
      name: 'OPT',
      header: '',
      defaultFlex: 1,
      render: ({ data }) => {
        return (
            <>
            {!data.state && 
              <ButtonGroup>
              <Button size="small" fullWidth color="primary" startIcon={<FaArrowRight size={12} />} onClick={() => { history.push({pathname: `/dashboard/pessoas-eventos-em-folha-inclusao`, state: {modal: true}, draftId: data._id, type: "manual"}) }}>Continuar</Button>
              <Button size="small" fullWidth color="secondary" startIcon={<FaTrash size={12} />} onClick={() => { handleDraftDelete(data._id) }}>Excluir</Button>
              </ButtonGroup>
            }
            {data.state && reopenSuccess && "Solicitação enviada!"}
            {data.state && !reopenSuccess && <Button size="small" fullWidth variant="outlined" color="primary" startIcon={reopenProgress ? <CircularProgress size={12} /> : <FaArrowRight size={12} />} onClick={() => { handleReopen(data) }}>{!reopenProgress && "Solicitar Reabertura"}{reopenProgress && "Enviando..."}{reopenSuccess && "Solicitação enviada!"}</Button>}
            </>
        )
        
      }
    },
  ]

  useEffect(() => {
    checkCurrentMonth()
    loadDrafts()
  },[])

  return (
    <>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

        <Grid container>
          <Grid item xs={12}>
            {isMonthDone && <Alert style={{marginBottom:"20px"}}>Os lançamentos da competência {compet} já foram enviados. Volte no próximo mês.</Alert>}
            {reopenSuccess && <Alert severity="success" style={{marginBottom:"20px"}}>Solicitação enviada com sucesso. Aguarde nossa equipe avaliar e liberar para você.</Alert>}
            {!isMonthDone && <Button size="small" variant="contained" startIcon={<FaPlus size={12} />} onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha-inclusao', state: {modal: true}, type: 'manual'}) }}>Incluir Manual</Button>}
            {/* {!isMonthDone && <Button size="small" variant="contained" startIcon={<FaPlus size={12} />} onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha-inclusao', state: {modal: true}, type: 'xls'}) }} style={{marginLeft:10}}>Incluir via XLS</Button>} */}
            {!isMonthDone && <Divider style={{margin:"20px 0"}}/>}

            {(!loading&&drafts.length>0) && <ReactDataGrid
              idProperty="_id"
              columns={columnsDrafts}
              dataSource={drafts}
              defaultLimit={100}
              theme="default-light"
              defaultSortInfo={{ name: 'NOMEFUNC', dir: 1, type: 'text' }}
              style={{ minHeight: 450, marginBottom: 20 }}
             />}

            {!loading&&!drafts.length && <Alert severity="info" style={{marginBottom:"20px"}}>No momento não foi enviada nenhuma competência.</Alert>}

          </Grid>
        </Grid>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default EventsList;
