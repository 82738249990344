import React, { useState } from 'react';
import { FaEdit, FaArrowAltCircleUp, FaCircle, FaFileAlt, FaPause, FaPlay, FaSyncAlt, FaUser } from 'react-icons/fa';
import List from '../../../components/List';
import Button from '../../../components/Button';
import {useSubModal} from '../../../context/contextSubModal';
import {useAuth} from '../../../context/contextAuth';
import {DivLinkedClients} from './styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import api from '../../../services/api';
import { rr } from '../../../services/runrunit';
import { useRunrunit } from '../../../context/contextRunrunit';

export const RunrunitTaskClients = ({task}) => {
    const {user} = useAuth()
    const {teams} = useRunrunit()
    const {setIsSubModalVisible, setSubModalContent} = useSubModal();
    const [isPaused, setIsPaused] = useState(true);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [linkedClients, setLinkedClients] = useState([])
    const [taskGlobalDate, setTaskGlobalDate] = useState()
    const token = localStorage.getItem('@Atlantico:token');
    const [loader, setLoader] = useState(true);

    const teamsMask = (id) => {    
        const team = teams.filter(team => team.id === Number(id))[0]

        if (!team) return ""

        return team.name;
    }

    useEffect(() =>  {
        setTaskGlobalDate(dateTime(task.desired_date))

        setLinkedClients([]);
        async function getData(){
            await api.get(`v1/linkedclients/${task._id}`, {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                response.data.map(res => {
                    setLinkedClients(old => [...old, {...res, status: null, task: '-', response: null}])
                })
                setLoader(false);
            })
            .catch(err => {
                console.log(err);
                setLoader(false)
            })
        }

        getData()
    }, [])

    function statusToIcon(status){
        switch (status) {
            case 'success': return <FaCircle size={10} color="#08ff4a" />;
            case 'error': return <FaCircle size={10} color="#ff0000"/>;
            case 'syncing': return <FaArrowAltCircleUp size={16} color="var(--blue)"/>;
            default: return <FaCircle size={10} color="var(--gray)"/>;
        }
    }

    async function handleChange(position, values){
        let clients = [...linkedClients];

            clients[position].status = values.status;
            clients[position].task = values.task;
            clients[position].response = values.response;

        setLinkedClients(clients);
    }

    function handleLoading(position){
        let clients = [...linkedClients];

            clients[position].status = 'syncing';

        setLinkedClients(clients);
    }

    function dateTime(date, month = null){
        const today = new Date();
        // console.log(today)
        // console.log(today.getMonth())

        if(today.getDate() > parseInt(date)&&month==null){
            month = today.getMonth()+1
        }

        month = month ? month : today.getMonth()

        var desiredDate = new Date(today.getFullYear(), month, date, 18)

        if(desiredDate.getDay() === 6){
            desiredDate.setDate(desiredDate.getDate()-1)
        }

        if(desiredDate.getDay() === 0){
            desiredDate.setDate(desiredDate.getDate()-2)
        }

        return desiredDate;
    }

    function taskDateIsEqualToToday(taskDate){
        const today = new Date();
        const aux = taskDate.split('T');

        if(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate() === aux[0]){
            return true
        } else {
            return false
        }
    }

    async function sync(position) {
        return await Promise.all(linkedClients.map(async (client, index) => {
            if(client.status === 'success') return {...client}

            if(position === index){   
                handleLoading(position)

                // await api.post(`v1/tasks/apiHistory/check`, {
                //     client: client._id,
                //     task: task._id,
                //     desired_month: taskGlobalDate.getMonth(),
                //     }, {
                //     headers: {
                //         Authorization: token,
                //     }
                // }).then(async response => {
                //     if(response.status === 200){
                //         handleChange(position, {
                //             status: "success",
                //             task: response.data.task.id,
                //             response: response.data.task.response.data
                //         })
                    // } else if (response.status === 202) {
                        var title = ('0000'+client.reference).slice(-4)
                            title += task.title ? ` - ${task.title.toUpperCase()}` : ` - ${task.name.toUpperCase()}`

                            if(task.competence){
                                title += ` - ${task.competence.toUpperCase()}`
                            }

                        const data = {
                            task: {
                                desired_date_with_time: dateTime(client.date&&client.date>0 ? client.date : task.desired_date),
                                on_going: false,
                                project_id: client.runrunit_projects[0],
                                description: task.description ? task.description : "",
                                scheduled_start_time: null,
                                board_id: 486584,
                                title,
                                type_id: task.runrunit_id,
                                assignments: [
                                    {
                                      assignee_id: client.assignment ? client.assignment : task.assignment,
                                      team_id: client.team_id ? client.team_id : task.runrunit_team_id,
                                    }
                                ]
                            }
                        }
                    
                        await rr.post('tasks', data, {
                            headers: {
                                "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
                                "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
                            }
                        })
                        .then(async response => {
                            handleChange(position, {
                                status: "success",
                                task: response.data.id,
                                response: response.data
                            })

                            await api.post('v1/tasks/apiHistory', {
                                id: response.data.id,
                                client: client._id,
                                user: user._id,
                                task_type: task._id,
                                response,
                                created_at:dateTime(client.date&&client.date>0 ? client.date : task.desired_date),
                            }, {
                                headers: {
                                    Authorization: token,
                                }
                            })
                        })
                        .catch(err => {
                            handleChange(position, {
                                status: "error",
                                response: {
                                    error: 'Ocorreu um erro ao criar a tarefa. Tente novamente.'
                                }
                            })
                        })
                    // }
                // }).catch(error => {
                //     handleChange(position, {
                //         status: "error",
                //         response: {
                //             error: 'Ocorreu um erro ao verificar se a tarefa já foi criada.'
                //         }
                //     })
                // })
            } else return {...client}

        }))
    }

    useEffect(() => {
        if(activeIndex >= linkedClients.length){
            setIsPaused(true);
            return
        } else {
            if(isPaused){
                return;
            } else {
                // if(activeIndex  === 0){
                //     return;
                // } else {
                    sync(activeIndex)
                    .then(() => setActiveIndex(activeIndex + 1))
                // }
            }
        }
    }, [activeIndex, isPaused])

    return (
        <DivLinkedClients>
            {loader ? <Loader /> : (
            linkedClients.length > 0 ? (
                <>
                <div className="icons">
                    <Link to={{pathname: "/admin/atualizar-tarefa", state: {modal: true, props: task}}}>
                        <FaEdit size={18} color='var(--blue)' style={{cursor: 'pointer', marginRight: '1rem'}}/>
                    </Link>
                    <Link to={{pathname: "/admin/clientes-vinculados", state: {modal: true, props: task}}} >
                    <FaUser color='var(--blue)' style={{marginRight: '0.5rem'}}/>
                    </Link>
                    <h4><strong>{linkedClients.length}</strong></h4>
                </div>

                <div className="buttons">
                    {isPaused && (
                        <Button onClick={async () => {
                            setIsPaused(false);
                            setActiveIndex(0);
                        }} bgcolor="var(--blue)" color="white" border="var(--blue)" hover="var(--blue-hover)" expansive hollow={false} size="medium">
                            <FaPlay/>
                        </Button>
                    )}

                    <Button color="var(--gray)" border="var(--gray)" expansive hollow size="medium" onClick={() => setIsPaused('true')}>
                        <FaPause/>
                    </Button>
                </div>

                <hr />
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width: "50%"}}><b>Título da tarefa a subir:</b><br />REF. - {(task.title ? task.title.toUpperCase() : task.name.toUpperCase())} {(task.competence ? " - "+task.competence.toUpperCase() : "")}</div>
                    <div style={{width: "50%"}}><b>Data desejada (Global):</b><br />{`${('0'+taskGlobalDate.getDate()).slice(-2)}/${('0'+(taskGlobalDate.getMonth()+1)).slice(-2)}/${taskGlobalDate.getFullYear()}`} 18:00:00</div>
                </div>
                <hr />

                <List completeLine>
                    <table className='linkedClientsTable'>
                        <colgroup>
                            <col style={{width: "5%"}}/>
                            <col style={{width: "10%"}}/>
                            <col style={{width: "20%"}}/>
                            <col style={{width: "5%"}}/>
                            <col style={{width: "15%"}}/>
                            <col style={{width: "15%"}}/>
                            <col style={{width: "15%"}}/>
                            <col style={{width: "10%"}}/>
                        </colgroup>  
                        <tr>
                            <th></th>
                            <th>REF.</th>
                            <th>NOME</th>
                            <th>DIA</th>
                            <th>RESPONSÁVEL</th>
                            <th>TIME</th>
                            <th># TAREFA</th>
                            <th style={{textAlign: 'center'}}>•••</th>
                        </tr>
                        {linkedClients.map((client, index) => (
                            <tr>
                                <td style={{textAlign: 'center'}}>{client.status ? statusToIcon(client.status) : statusToIcon('')}</td>
                                <td>{client.reference}</td>
                                <td>{client.name}</td>
                                <td>{client.date&&client.date>0 ? client.date : task.desired_date}</td>
                                <td>{client.assignment ? client.assignment : task.assignment}</td>
                                <td>{client.team_id ? teamsMask(client.team_id) : teamsMask(task.team_id)}</td>
                                <td><strong>{client.task ? (client.error ? 'Ops!' : client.task) : '-'}</strong></td>
                                <td style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                    {(!client.status || client.status === 'error') && <FaSyncAlt onClick={() => sync(index)} size={16} color="var(--blue)" style={{cursor: 'pointer'}}/>}
                                    {(client.status === 'error' || client.status === 'success') && <FaFileAlt size={16} color="var(--blue)" style={{cursor: 'pointer'}} onClick={() => {
                                        setIsSubModalVisible(true);
                                        setSubModalContent(client.response ? (
                                            <div>
                                                {client.response && (
                                                    <>
                                                        {client.response.id && <h3><strong>ID da tarefa:</strong> {client.response.id}</h3>}
                                                        {client.response.title && <h3><strong>Título:</strong> {client.response.title}</h3>}
                                                        {client.response.project_id && <h3><strong>ID do projeto:</strong> {client.response.project_id}</h3>}
                                                        {client.response.desired_date_with_time && <h3><strong>Data desejada:</strong> {client.response.desired_date_with_time}</h3>}
                                                        {client.response.client_name && <h3><strong>Nome do Cliente:</strong> {client.response.client_name}</h3>}
                                                        {client.response.type_name && <h3><strong>Tipo da tarefa:</strong> {client.response.type_name}</h3>}
                                                        {client.response.assignments && <h3><strong>Responsável:</strong> {client.response.assignments[0].assignee_name}</h3>}
                                                        
                                                        {client.response.error && <h3><strong>Erro:</strong> {client.response.error}</h3>}
                                                    </>
                                                )}
                                            </div>
                                        ) : '')
                                    }}/>}
                                </td>
                            </tr>
                        ))}
                    </table>
                </List>
                </>
            ) : (
                <Message severity="warning" message="Sem clientes vinculados à essa tarefa!"/>
            ))}

        </DivLinkedClients>
    )
}