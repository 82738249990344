import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaPhone,
  FaMinusCircle,
  FaPlusCircle,
  FaEnvelope,
  FaUserLock,
  FaMoon,
  FaSun,
} from "react-icons/fa";
import spinner from "../../assets/images/spinner.svg";
import InputMask from 'react-input-mask';
import Switch from 'react-switch';
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import ToastMessage from "../../components/ToastMessage";
import Button from "../../components/Button";
import Avatar from "../../components/Avatar";
import SubModal from "../../components/SubModal";
import {addEmail, setEmailsValue, removeEmail} from '../../utils/email';
import {addPhone, setPhonesValue, removePhone} from '../../utils/phones';
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";
import { useSubModal } from "../../context/contextSubModal";
import ChangePassword from "../ChangePassword";

import { DivInputs, ProfileDiv, Division } from "./styles";

import api from "../../services/api";
import { useHistory } from "react-router";

const ProfileEdit = () => {
  const { isEnabled, setIsEnabled} = useColorMode();
  const { user } = useAuth();
  const [loader, setLoader] = useState();
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const {
    isSubModalVisible,
    setIsSubModalVisible,
    setSubModalContent,
  } = useSubModal();

  const [userName, setUserName] = useState(user.name);
  const [email, setEmail] = useState(user.email.length !== 0 ? user.email : [{label: "work", value: "", primary: true}]);
  const [phone, setPhone] = useState(user.phone.length !== 0 ? user.phone : [{label: "work", value: "", primary: true}]);

  const actualUserName = user.name;
  const actualEmails = user.email.length !== 0 ? user.email : [{label: "work", value: "", primary: true}];
  const actualPhones = user.phone.length !== 0 ? user.phone : [{label: "work", value: "", primary: true}];

  const [hasChange, setHasChange] = useState(false);

  const toggleColorMode = () => {
    setIsEnabled(!isEnabled);
    localStorage.setItem('@Atlantico:color', JSON.stringify(!isEnabled)); 
  } 

  async function handleSubmitUpdate() {
    const token = localStorage.getItem("@Atlantico:token");
    return await api.put(`v1/users/${user._id}`,
        {
          propagate: true,
          name: userName,
          email,
          phone,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(setLoader(true))
      .then((response) => {
        console.log(response.data);
        setLoader(false);
        localStorage.removeItem("@Atlantico:user");
        const data = JSON.stringify({ ...user, name: userName, phone, email });
        localStorage.setItem("@Atlantico:user", data);
      })
      .then(async (response) => {
        await api.get(`v1/users/${user._id}/notifyUserUpdate`, {
          headers: {
            Authorization: token
          }
        })
        setSuccess(true);
      })
      .then((response) => {
        setSuccess(false);
        history.goBack()
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  const beforeMaskedValueChange = (newState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  useEffect(() => {
    if(JSON.stringify(email) === JSON.stringify(actualEmails) && JSON.stringify(phone) === JSON.stringify(actualPhones) && userName === actualUserName) {
      setHasChange(false)
      return;
    } else {
      setHasChange(true)
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, email, phone])

  return (
    <>
        <ModalContent hasFooter={true}>
          {isSubModalVisible && <SubModal onClose={() => setIsSubModalVisible(false)} />}
          {success && <ToastMessage variant="success" message="Perfil atualizado com sucesso!"/>}
          <ProfileDiv darkMode={isEnabled}>
            <div className="avatar">
              <Avatar>
                <FaUser size={20} color="var(--atlantico)" />
              </Avatar>
            </div>

            <div className="container-changepassword">
              <button
                className="change-password"
                onClick={() => {
                  setIsSubModalVisible(true);
                  setSubModalContent(<ChangePassword />);
                }}
              >
                <FaUserLock style={{ marginRight: "1.5rem" }} /> Alterar minha senha
              </button>
              <Switch 
                onChange={toggleColorMode} 
                checked={isEnabled}
                checkedIcon={false}
                uncheckedIcon={false}
                uncheckedHandleIcon={<FaSun color="black"/>}
                checkedHandleIcon={<FaMoon color="white" />}
                height={20}
                handleDiameter={25}
                onHandleColor='#001275'
                offHandleColor='#ffd36e'
                offColor='#000'
                onColor='#fff'
                width={40}
              />
            </div>

            <DivInputs darkMode={isEnabled}>
              <div className="input">
                <FaUser className="icon"/>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    defaultValue={user.name}
                    placeholder="Nome Completo"
                    onChange={(e) => setUserName(e.target.value)}
                  />
              </div>
            </DivInputs>
            <Division>
            <DivInputs darkMode={isEnabled}>
              {phone.map((fone, index) => {
                return (
                  <div className="input" key={index}>
                    <FaPhone className="icon" />
                    <InputMask
                    mask="(99) 9999-99999"
                    maskChar={null}
                    placeholder="(00) 00000-0000"
                    value={fone.value}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    onChange={(e) =>
                      setPhonesValue(phone, setPhone, index, "value", e.target.value)
                    }
                  />
                    {index !== 0 ? (
                      <FaMinusCircle
                        onClick={() => removePhone(phone, setPhone, index)}
                        size={15}
                        className="remove"
                      />
                    ) : null}
                  </div>
                );
              })}
              <FaPlusCircle
                onClick={() => addPhone(phone, setPhone)}
                size={15}
                className="add"
              />
            </DivInputs>
            
            <DivInputs darkMode={isEnabled}>
              {email.map((mail, index) => {
                return (
                  <div className="input" key={index}>
                    <FaEnvelope className="icon"/>
                    <input
                      id="text"
                      type="mail"
                      value={mail.value}
                      name="email"
                      placeholder="Digite aqui seu e-mail"
                      onChange={(e) =>
                        setEmailsValue(email, setEmail, index, "value", e.target.value)
                      }
                    />
                    {index !== 0 ? (
                      <FaMinusCircle
                        onClick={() => removeEmail(email, setEmail, index)}
                        size={15}
                        className="remove"
                      />
                    ) : null}
                  </div>
                );
              })}
              <FaPlusCircle
                onClick={() => addEmail(email, setEmail)}
                size={15}
                className="add"
              />
            </DivInputs>
            </Division>
            
          </ProfileDiv>
        </ModalContent>
        <ModalButtons>
          {hasChange && (
            <Button
              hollow={false}
              expansive={true}
              bgcolor="var(--blue)"
              border="var(--blue)"
              size="small"
              color="#fff"
              hover="var(--blue-hover)"
              onClick={handleSubmitUpdate}
            >
              {loader ? <img src={spinner} alt="Salvar"/> : "SALVAR"}
            </Button>
          )}
          <hr />
          <Button
            hollow={true}
            expansive={true}
            size="small"
            color="var(--gray)"
            border="var(--gray)"
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
        </ModalButtons>
    </>
  );
};
export default ProfileEdit;
