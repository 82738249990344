import styled from 'styled-components';

export const ContactDetails = styled.div`
max-height: 40em;
overflow: auto;
    h2{
        text-transform: uppercase;
        color: var(--blue);
        margin-bottom: 1rem;
    }
    h5{
        color: var(--atlantico);
    }
    h4{
        text-transform: uppercase;
        color: var(--atlantico);
    }

    .block{
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 1rem;

        ul{
            list-style: none;
            li{
                background: var(--item-list);
                border-radius: 1rem;
                padding: 1rem;
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;   
                color: var(--text);
            }
        }
    }
`;