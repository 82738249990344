import styled from "styled-components";

export const Inputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    input {
      border: 0;
      width: 100%;
      height: 3rem;
      border-radius: 0;
      padding: 0;
    }
  }
  .submit-line {
    position: relative;
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
    input {
      width: 100%;
      padding: 0;
    }
    .submit-icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      margin-right: 1rem;
      margin-top: 1rem;
    }
    @media (min-width: 700px) {
      .submit-icon {
        margin-top: 0.5rem;
      }
    }
  }
`;
