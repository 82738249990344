import styled from "styled-components";

export const FormContact = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin-bottom: 2rem;
    color: var(--text);
  }
  ul {
    width: 100%;
    height: 80%;
    max-height: 80%;
    overflow: auto;
    li {
      .checkbox-item {
        display: flex;
        padding: 0.5rem 0;
        width: 100%;
        margin-bottom: 1rem;
        background-color: var(--background);
        border-radius: 10px;
        align-items: center;
      }
    }
  }

  select,
  input {
    border: 0;
    border-bottom: 1px solid var(--text);
    background-color: var(--modal);
    color: var(--text);
    width: 100%;
    padding: 1rem 0;
    font-size: 1.2rem;
  }

  img{
    max-width: 300px; 
  }

  @media (max-height: 700px) {
    padding: 0;
  }

  @media (min-width: 700px) {
    padding: 5rem 0;
    select,
    input {
      font-size: 1.3rem;
    }
  }
`;
