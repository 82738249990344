import styled from "styled-components";

export const Inputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    input {
      border: 0;
      width: 100%;
      height: 3rem;
      border-radius: 0;
      padding: 0;
    }
  }
  .submit-line {
    position: relative;
    border-bottom: 1px solid var(--divisor); 
    margin-bottom: 1rem;
    input {
      width: 100%;
      padding-left: 2rem;
      background-color: var(--modal); 
      color: var(--text); 
    }
    .submit-icon {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      margin-right: 1rem;
      margin-top: 1rem;
      color: var(--gray);
    }
    @media (min-width: 700px) {
      .submit-icon {
        margin-top: 0.5rem;
      }
      input {
        padding-left: 3rem;
      }
    }
  }
`;

export const ProfileDiv = styled.div`
  max-height: 35em;
  overflow: auto;
  padding-right: 0.5rem;
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-changepassword {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    .change-password {
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      text-decoration: none;
      justify-content: center;
      color: var(--blue);
      cursor: pointer;
    }
  }

  @media (max-width: 540px) {
    max-height: 28em;
  }
  @media (max-height: 700px) {
    max-height: 22em;
  }
`;
