import React, { useEffect, useState } from 'react';
import spinner from '../../../assets/images/spinner.svg';
import Switch from 'react-switch';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import StateDrop from '../../../components/StateDrop';
import Button from '../../../components/Button';
import ToastMessage from '../../../components/ToastMessage';
import ModalContent from '../../../components/ModalContent';
import ModalButtons from '../../../components/ModalButtons';
import Loader from '../../../components/Loader';
import {addQuestion, removeQuestion, setQuestionValue} from '../../../utils/questions';
import { useColorMode } from '../../../context/contextColorMode';

import { FormContact, Switches } from './styles';

import api from '../../../services/api';
import { useHistory } from 'react-router';

const SaveSurvey = ({surveyId}) => {
    const {isEnabled} = useColorMode();
    const [saveLoader, setSaveLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dayInit, setDayInit] = useState('');
    const [dayEnd, setDayEnd] = useState('');
    const [active, setActive] = useState(true);
    const [priority, setPriority] = useState(false);
    const token = localStorage.getItem('@Atlantico:token');
    const history = useHistory()
    const options = [{option: 'Link de Compartilhamento'}, {option: 'App e Webapp'}];

    const [questions, setQuestions] = useState(['']);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        async function getData(){
            await api.get(`v1/survey/nps/${surveyId}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setTitle(response.data.title);
                setQuestions(response.data.questions);
                setType(response.data.option);
                setDayInit(response.data.published_up.split('T')[0]);
                setDayEnd(response.data.published_down.split('T')[0]);
                setActive(response.data.active);
                setPriority(response.data.priority);
            })
            .catch(err => setLoader(false))
        }
        if(!surveyId){
            return;
        } else {
            getData();
        }  
    }, [surveyId, token])

    async function saveSurvey(){
        if(!surveyId){
            return await api.post('v1/survey/nps', {
                title,
                option: type,
                questions,
                published_up: dayInit,
                published_down: dayEnd,
                active,
                priority,
            }, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setSaveLoader(true))
            .then(response => {
                setSaveLoader(false);
                setSuccess(true);
                setSuccess(false);
                history.goBack()
            })
            .catch(err => {
                setSaveLoader(false);
                setError(true);
                setError(false);
            })          
        } else {
            await api.put(`v1/survey/nps/${surveyId}`, {
                title,
                option: type,
                questions,
                published_up: dayInit,
                published_down: dayEnd,
                active,
                priority,
            }, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setSaveLoader(true))
            .then(response => {
                setSaveLoader(false);
                setSuccess(true);
                setSuccess(false);
                history.goBack()
            })
            .catch(err => {
                setSaveLoader(false);
                setError(true);
                setError(false);
            })
        }
        
    }

    const toggleActive = () => {
        setActive(!active);
    } 
    
    const togglePriority = () => {
        setPriority(!priority);
    } 
    return (
        <>
            <ModalContent>
                {success && <ToastMessage variant="success" message="Sucesso ao salvar a pesquisa!"/>}
                {error &&  <ToastMessage variant="info" message="Preencha todos os campos antes de continuar!"/>}
                {loader ? <Loader /> : (
                    <FormContact darkMode={isEnabled}>
                        <div className="item-form">
                            <input type="text" placeholder="Título interno da pesquisa" onChange={e=>setTitle(e.target.value)} value={title}/>
                        </div>
                        
                        <br/>

                        <div className="item-form">
                            <StateDrop
                                content={options}
                                name="type"
                                value={type}
                                onChange={e=>setType(e.target.value)}
                            />
                        </div>

                        <Switches darkMode={isEnabled}>
                                {type === 'App e Webapp' && (
                                    <>
                                        <div>
                                            <h4 style={{color: 'var(--text)'}}>Inicio:</h4>
                                            <input value={dayInit} type="date" onChange={(e) => setDayInit(e.target.value)}/>
                                        </div>
                                        <div>
                                            <h4 style={{color: 'var(--text)'}}>Fim:</h4>
                                            <input value={dayEnd} type="date" onChange={(e) => setDayEnd(e.target.value)}/>
                                        </div>
                                    </>
                                )}
                                
                                <div>
                                    <h4 style={{color: 'var(--text)'}}>Ativo:</h4>
                                    <Switch 
                                        onChange={toggleActive} 
                                        checked={active}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        height={20}
                                        onColor='#2699fb'
                                        onHandleColor='#1180c3'
                                        offHandleColor='#1180c3'
                                        handleDiameter={25}
                                        width={40}
                                        
                                    />
                                </div>
                                <div>
                                    <h4 style={{color: 'var(--text)'}}>Prioridade:</h4>
                                    <Switch 
                                        onChange={togglePriority} 
                                        checked={priority}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        height={20}
                                        onColor='#2699fb'
                                        onHandleColor='#1180c3'
                                        offHandleColor='#1180c3'
                                        handleDiameter={25}
                                        width={40}
                                    />
                                </div>
                        </Switches>               
                        <br/>
                        <div className="questions">
                            {questions.map((quest, index) => {
                                return (
                                    <div className="item-form" key={index}>
                                        <input value={quest} type="text" placeholder="Pergunta" onChange={(e) => setQuestionValue(questions, setQuestions, index, e.target.value)}/>
                                            {index !== 0 ? (
                                            <FaMinusCircle
                                                onClick={() => removeQuestion(questions, setQuestions, index)}
                                                size={20}
                                                className="remove"
                                            />
                                            ) : null}
                                    </div>
                                )     
                            })}
                            <FaPlusCircle
                                onClick={() => addQuestion(questions, setQuestions)}
                                size={20}
                                className="add"
                            />  
                        </div>
                        
                    </FormContact>
                )}
                
            </ModalContent>
            <br/>
            <ModalButtons>
                <Button type="submit" bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={saveSurvey}>
                    {saveLoader ? <img src={spinner} alt="Logar"/> : 'SALVAR'}
                </Button>
                <hr/>
                <Button type="submit" color="var(--gray)" border="var(--gray)" hover="var(--gray)" expansive={true} hollow={true} size="small" onClick={() => history.goBack()}>
                    Cancelar
                </Button>
            </ModalButtons>
        </>
        
        
    );
}

export default SaveSurvey;