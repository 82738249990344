import React from "react";
import Dropzone from "react-dropzone";
import { DropContainer, UploadMessage } from "./styles";
import { FaCloudUploadAlt } from "react-icons/fa";

const Upload = ({ onUpload }) => {
  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <FaCloudUploadAlt size={48} />
          <p><big>Clique ou arraste arquivos aqui!</big></p>
          <p><small>( Tamanho máximo por arquivo: 100MB )</small></p>
        </UploadMessage>
      );
    }
    if (isDragReject) {
      return (
        <UploadMessage type="error">
          <FaCloudUploadAlt size={48} />
          <p><big>Arquivo não suportado!</big></p>
          <p><small>( Tamanho máximo por arquivo: 100MB )</small></p>
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        <FaCloudUploadAlt size={48} />
        <p><big>Solte os arquivos aqui.</big></p>
        <p><small>( Tamanho máximo por arquivo: 100MB )</small></p>
      </UploadMessage>
    );
  };
  return (
    <Dropzone onDropAccepted={onUpload} maxSize={104857600}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;
