import angry from '../assets/images/angry.svg';
import sad from '../assets/images/sad.svg';
import neutral from '../assets/images/neutral.svg';
import smile from '../assets/images/smile.svg';
import bigsmile from '../assets/images/bigsmile.svg';

export const handleCsatIcon = (name) => {
    if(name === 'very_dissatisfied'){
        return angry;
    } else if (name === 'dissatisfied'){
        return sad;
    } else if (name === 'neutral'){
      return neutral;
    } else if (name === 'satisfied'){
      return smile;
    } else if (name === 'very_satisfied'){
      return bigsmile;
    }
}