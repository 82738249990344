import React from "react";
import vSign from "../../assets/images/v-sign.svg";
import {
  FaCloudUploadAlt,
  FaWhatsapp,
  FaHeadset,
} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";

const People = () => {
  const history = useHistory();
  const {user} = useAuth();
  const {isEnabled} = useColorMode();

  return (
    <>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>
        <Card variant="outlined" style={{width:"100%",marginBottom:20}}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom style={{fontSize:"1.5em",fontWeight:300}}>
              Serviços
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{fontSize:'1em'}}
                  onClick={() => { history.push({pathname: '/dashboard/abrir-solicitacao', state: {modal: true}}) }}
                >
                  Abrir solicitação
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" style={{width:"100%",marginBottom:20}}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom style={{fontSize:"1.5em",fontWeight:300}}>
              Cadastros
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} lg={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{fontSize:'1em'}}
                  onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type: "Empregados"}) }}
                >
                  Empregados
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{fontSize:'1em'}}
                  onClick={() => { history.push({pathname: '/dashboard/pessoas-empregados', state: {modal: true}, type: "Contribuintes"}) }}
                >
                  Contribuintes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" style={{width:"100%"}}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom style={{fontSize:"1.5em",fontWeight:300}}>
              Solicitações
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{fontSize:'1em'}}
                  onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-ferias', state: {modal: true}, module: "ferias", type: "Empregados"}) }}
                >
                  Aviso e cálculo de férias
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{fontSize:'1em'}}
                  onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-rescisao', state: {modal: true}, module: "rescisao", type: "Empregados"}) }}
                >
                  Aviso e cálculo de rescisão
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{fontSize:'1em'}}
                  onClick={() => { history.push({pathname: '/dashboard/pessoas-eventos-em-folha', state: {modal: true}}) }}
                >
                  Eventos em Folha
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default People;
