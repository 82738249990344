import React, { useState } from "react";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import Button from "../../../components/Button";
import Avatar from "../../../components/Avatar";
import {addPhone, removePhone, setPhonesValue} from '../../../utils/phones';
import {addEmail, removeEmail, setEmailsValue} from '../../../utils/email';
import { useSubModal } from "../../../context/contextSubModal";
import { useUploadModal } from "../../../context/contextUploadModal";
import { useColorMode } from "../../../context/contextColorMode";
import InputMask from 'react-input-mask';
import spinner from '../../../assets/images/spinner.svg';
import {
  FaUser,
  FaPhone,
  FaMinusCircle,
  FaPlusCircle,
  FaEnvelope,
} from "react-icons/fa";

import { Inputs, ProfileDiv } from "./styles";

import api from "../../../services/api";

const EditUser = ({user}) => {
  const {isEnabled} = useColorMode();
  const {setAtt} = useUploadModal();
  const {setIsSubModalVisible} = useSubModal();
  const [loader, setLoader] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState(user.name);
  const [nickName, setNickName] = useState(user.nickname);
  const [email, setEmail] = useState(user.email.length !== 0 ? user.email : [{label: "work", value: "", primary: true}]);
  const [phone, setPhone] = useState(user.phone.length !== 0 ? user.phone : [{label: "work", value: "", primary: true}]);
  const [login, setLogin] = useState(user.login);


  const beforeMaskedValueChange = (newState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  async function handleSubmitUpdate() {
    const token = localStorage.getItem("@Atlantico:token");
    return await api.put(`v1/users/${user._id}`, {
          passwords: {
            dominio: {
              user: null,
              pass: null,
            },
            conta_azul: {
              user: null,
              pass: null,
            }
          },
          is_admin: true,
          email: email,
          phone: phone,
          agreed: true,
          status: true,
          name: userName,
          nickname: nickName,
          login: login,
          profile: [],
          client: []
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(setLoader(true))
      .then((response) => {
        setLoader(false);
        setSuccess(true);
        setSuccess(false);
        setAtt(true);
        setAtt(false);
        
      })
      .catch((err) => {
        setError(true);
        setError(false);
        setLoader(false);
      });
  }

  return (
    <>
        <ModalContent hasFooter={true}>
          {success ? (
            <ToastMessage
              variant="success"
              message="Alterações feitas com sucesso!"
            />
          ) : null}
          {error ? (
            <ToastMessage
              variant="error"
              message="Erro ao alterar o usuário! Tente novamente mais tarde."
            />
          ) : null}
          <ProfileDiv darkMode={isEnabled}>
            <div className="avatar">
              <Avatar>
                <FaUser size={20} color="var(--blue)" />
              </Avatar>
            </div>

            <Inputs darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaUser className="submit-icon" />
                <input
                  id="name"
                  maxLength="50"
                  type="text"
                  name="name"
                  placeholder="Nome Completo"
                  defaultValue={user.name}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </Inputs>
            <Inputs darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaUser className="submit-icon" />
                <input
                  id="nickname"
                  maxLength="20"
                  type="text"
                  name="name"
                  placeholder="Nickname"
                  defaultValue={user.nickname}
                  onChange={(e) => setNickName(e.target.value)}
                />
              </div>
            </Inputs>
            <Inputs darkMode={isEnabled}>
              {phone.map((fone, index) => {
                return (
                  <div className="submit-line" key={index}>
                    <FaPhone className="submit-icon" />
                    <InputMask
                      mask="(99) 9999-99999"
                      maskChar={null}
                      placeholder="(00) 00000-0000"
                      value={fone.value}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                      onChange={(e) =>
                        setPhonesValue(phone, setPhone, index, "value", e.target.value)
                      }
  
                    />
                    {index !== 0 ? (
                      <FaMinusCircle
                        onClick={() => removePhone(phone, setPhone, index)}
                        size={15}
                        style={{ color: "var(--red)", cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                );
              })}
            </Inputs>
            <FaPlusCircle
              onClick={() => addPhone(phone, setPhone)}
              size={15}
              style={{
                color: "var(--green)",
                float: "right",
                cursor: "pointer",
              }}
            />
            <Inputs darkMode={isEnabled}>
            {email.map((mail, index) => {
                return (
                  <div className="submit-line" key={index}>
                    <FaEnvelope className="submit-icon" />
                    <input
                      id="text"
                      type="mail"
                      value={mail.value}
                      name="email"
                      placeholder="Digite aqui seu e-mail"
                      onChange={(e) =>
                        setEmailsValue(email, setEmail, index, "value", e.target.value)
                      }
                    />
                    {index !== 0 ? (
                      <FaMinusCircle
                        onClick={() => removeEmail(email, setEmail, index)}
                        size={15}
                        style={{ color: "var(--red)", cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                );
              })}
            </Inputs>
            <FaPlusCircle
              onClick={() => addEmail(email, setEmail)}
              size={15}
              style={{
                color: "var(--green)",
                float: "right",
                cursor: "pointer",
              }}
            />
            <Inputs darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaUser className="submit-icon" />
                <input
                  type="text"
                  placeholder="Login"
                  defaultValue={user.login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </div>
            </Inputs>          
          </ProfileDiv>
        </ModalContent>
        <ModalButtons>
          <Button
            hollow={false}
            expansive={true}
            bgcolor="var(--blue)"
            border="var(--blue)"
            size="small"
            color="#fff"
            hover="var(--blue-hover)"
            onClick={() => handleSubmitUpdate()}
          >
            {loader ? <img src={spinner} alt="Alterar usuário usuário"/> : "SALVAR ALTERAÇÕES"}
          </Button>
          <hr />
          <Button
            hollow={true}
            expansive={true}
            size="small"
            color="var(--gray)"
            border="var(--gray)"
            onClick={() => {
              setIsSubModalVisible(false);
            }}
          >
            Fechar
          </Button>
        </ModalButtons>
    </>
  );
};
export default EditUser;
