import styled from "styled-components";

export const DivGraphs = styled.div`
  .filters{
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "button1" "button2" "button3" "button4" "button5";
    margin-bottom: 1rem;

    #button1{
      grid-area: button1;
    }
    #button2{
      grid-area: button2;
    }
    #button3{
      grid-area: button3;
    }
    #button4{
      grid-area: button4;
    }
    #button5{
      grid-area: button5;
    }

    @media(min-width: 1024px){
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "button1 button2 button3 button4 button5";
      grid-gap: 4rem;
    }
  }

  select {
    margin: 2rem 0;
    outline: 0;
    border: 2px solid var(--blue);
    color: var(--text);
    background-color: ${props => props.darkMode ? '#121212' : "#fff"};
    border-radius: 1rem;
    padding: 1rem;
    font-weight: bold;
    width: 100%;
  }

  .content-graph{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-height: 16em;
    overflow: auto;

    .graph{
      width: 100%;
    }

    @media(min-width: 700px){
      flex-direction: row;
      max-height: 100em;
      
      .graph{
        width: 100%;
      }
    }
  }
`;


export const CustomFilter = styled.div`
    display: flex; 
    width: 100%; 
    justify-content: center; 
    align-items: center; 
    margin-bottom: 2rem; 
    padding: 0 2rem;

    .title_input{
      display: flex;
      flex-direction: column;
      margin: 0 2rem;
      width: 100%;

      input{
        width: 100%;
        outline: 0;
        border:0;
        border-bottom: 1px solid var(--divisor);
        background-color: var(--modal);
        color: var(--text);
      }

      h3{
        color: var(--text);
      }
    }
`;

export const CommentsDiv = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    h3{
      color: var(--atlantico);
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    @media(min-width: 700px){
        width: 100%;
    }
`;