export const convertName = (name) => {
    if (name === "ADMIN") {
        return "Administrativo";
      } else if (name === "FINANC") {
        return "Financeiro";
      } else if (name === "HR") {
        return "Recursos Humanos";
      } else if (name === "FTAX") {
        return "Fiscal e Tributário";
      }
}


