import React from 'react';

import {LoaderDiv} from './styles';
import spinnerBlue from "../../assets/images/spinner-blue.svg";

const Loader = ({infinitescroll}) => {
    return (
        <LoaderDiv infinitescroll={infinitescroll}>
            <img src={spinnerBlue} alt="loading"/>
        </LoaderDiv>
    )
}

export default Loader;