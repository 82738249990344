import React, { useCallback, useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaArrowRight,
} from "react-icons/fa";
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  Grid,
  Typography,
  Link,
} from '@material-ui/core'

import api from "../../../../services/api";

const MotivoDemissao = (props) => {
  const token = localStorage.getItem("@Atlantico:token")
  
  const loadData = async ({ skip, limit, sort, filterValue }) => {
    let filter = { Filter: { } }
  
    const params = {
      classe: "TnFpaDMMotivoDemissao",
      limit,
      start:skip,
      sort,
    }

    console.log(props.employee)
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.post(`v1/nweb${qs}`,filter,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return ({data: response.data.data.filter(row => row.CODIGOMOTDEM<9997), count: parseInt(response.data.records)-3})
    })
    .catch((err) => {
      console.error(err)
    })
  
  }

  const dataSource = useCallback(loadData, [])

  const columns = [
    {
        name: 'CODIGOMOTDEM',
        header: 'Cód.',
        width: 80,
    },
    {
        name: 'DESCRMOTDEM',
        header: 'Motivo',
        defaultFlex: 1,
        render: ({ data }) => {
        return <Link component="button" onClick={() => { 
          props.setState({...props.state, CODIGOMOTDEM:data.CODIGOMOTDEM, DESCRMOTDEM:data.DESCRMOTDEM})

          props.setIsSubModalVisible(false)
          }}>{data.DESCRMOTDEM}</Link>
        }
    },
    {
        name: 'OPTN',
        header: '',
        maxWidth: 80,
        render: ({ data }) => {
            return <Button size="small" color="default" variant="contained" onClick={() => { 
                props.setState({...props.state, CODIGOMOTDEM:data.CODIGOMOTDEM, DESCRMOTDEM:data.DESCRMOTDEM})

                props.setIsSubModalVisible(false)
            }}><FaArrowRight /></Button>
        }
    }
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  return (
    <>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginBottom:"20px"}}>Selecione um motivo de demissão</Typography>
            
            <ReactDataGrid
              idProperty="CODIGOMOTDEM"
              columns={columns}
              dataSource={dataSource}
              defaultLimit={100}
              pagination 
              theme="default-light" 
              style={{ minHeight: 450 }}
              // onRenderRow={onRenderRow}
             />
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default MotivoDemissao;