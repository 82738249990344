import React from "react";
import { useColorMode } from "../../context/contextColorMode";

import { Title, Content } from "./styles";

const ContentBlock = (props) => {

  const {isEnabled} = useColorMode();

  return (
    <>
      <Title>{props.title}</Title>
      <Content darkMode={isEnabled}>{props.children}</Content>
    </>
  );
};

export default ContentBlock;
