import React, { useState } from "react";
import InputMask from 'react-input-mask';
import spinner from "../../../assets/images/spinner.svg";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import Button from "../../../components/Button";
import Avatar from "../../../components/Avatar";
import {addPhone, removePhone, setPhonesValue} from '../../../utils/phones';
import {addEmail, removeEmail, setEmailsValue} from '../../../utils/email';
import { useSubModal } from "../../../context/contextSubModal";
import { useUploadModal } from "../../../context/contextUploadModal";
import { useColorMode } from "../../../context/contextColorMode";

import {
  FaUser,
  FaPhone,
  FaMinusCircle,
  FaPlusCircle,
  FaEnvelope,
  FaLock,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";

import { Inputs, ProfileDiv,  Observation } from "./styles";

import api from "../../../services/api";
import { useEffect } from "react";

const CreateNewUser = () => {
  const token = localStorage.getItem('@Atlantico:token');
  const {isEnabled} = useColorMode();
  const {setAtt} = useUploadModal();
  const {setIsSubModalVisible} = useSubModal();
  const [loader, setLoader] = useState();
  const [userName, setUserName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState([{label: "work", value: "", primary: true}]);
  const [phone, setPhone] = useState([{label: "work", value: "", primary: true}]);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isVerifyPasswordVisible, setIsVerifyPasswordVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorPasswordsDoNotMatch, setErrorPasswordsDoNotMatch] = useState(false);
  const [errorField, setErrorField] = useState(null);

  const beforeMaskedValueChange = (newState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  useEffect(() => {
    setErrorPasswordsDoNotMatch(false);
    setErrorField(null);
  }, [errorPasswordsDoNotMatch, errorField]);

  async function handleCreateNewUser(){
    if(password !== verifyPassword){
      setErrorPasswordsDoNotMatch(true);
    } else if (userName === '') {
      setErrorField('Preencha o nome do usuário!');
    } else if (nickName === '') {
      setErrorField('Preencha o apelido do usuário!');
    } else if (email[0].value === '') {
      setErrorField('Preencha ao menos um e-mail!');
    } else if (phone[0].value === '') {
      setErrorField('Preencha ao menos um telefone!');
    } else if (login === '') {
      setErrorField('Preencha o login!');
    } else if (password === '') {
      setErrorField('Preencha a senha!');
    } else if (verifyPassword === '') {
      setErrorField('Preencha a verificação de senha!');
    } else {
      await api.post('v1/users', {
        passwords: {
          dominio: {
            user: null,
            pass: null,
          },
          conta_azul: {
            user: null,
            pass: null,
          }
        },
        is_admin: true,
        email,
        phone,
        agreed: true,
        status: true,
        name: userName,
        nickname: nickName,
        login,
        password,
        profile: [],
        client: []
      }, {
        headers: {
          Authorization: token,
        }
      })
      .then(() => setLoader(true))
      .then(response => {
        setLoader(false);
        setAtt(true);
        setAtt(false);
        setSuccess(true);
        setSuccess(false);
        setIsSubModalVisible(false);
      })
      .catch(err => {
        setLoader(false);
        setError(true);
        setError(false);
      })
    }
  }

  return (
    <>
        <ModalContent hasFooter={true}>
          {success && <ToastMessage variant="success" message="Usuário criado com sucesso!"/>}
          {error && <ToastMessage variant="error" message="Erro ao criar o usuário! Tente novamente mais tarde." />}
          {errorPasswordsDoNotMatch && <ToastMessage variant="error" message="As senhas precisam coincidir!"/>}
          {errorField && <ToastMessage variant="error" message={errorField}/>}
          <ProfileDiv darkMode={isEnabled}>
            <div className="avatar">
              <Avatar>
                <FaUser size={20} color="var(--atlantico)" />
              </Avatar>
            </div>

            <Inputs darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaUser className="submit-icon" />
                <input
                  id="name"
                  maxLength="50"
                  type="text"
                  name="name"
                  placeholder="Nome Completo"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </Inputs>
            <Inputs darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaUser className="submit-icon" />
                <input
                  id="nickname"
                  maxLength="20"
                  type="text"
                  name="name"
                  placeholder="Apelido"
                  onChange={(e) => setNickName(e.target.value)}
                />
              </div>
            </Inputs>
            <Inputs darkMode={isEnabled}>
              {phone.map((fone, index) => {
                return (
                  <div className="submit-line" key={index}>
                    <FaPhone className="submit-icon" />
                    <InputMask
                      mask="(99) 9999-99999"
                      maskChar={null}
                      placeholder="(00) 00000-0000"
                      value={fone.value}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                      onChange={(e) =>
                        setPhonesValue(phone, setPhone, index, "value", e.target.value)
                      }
  
                    />
                    {index !== 0 ? (
                      <FaMinusCircle
                        onClick={() => removePhone(phone, setPhone, index)}
                        size={15}
                        style={{ color: 'var(--red)', cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                );
              })}
            </Inputs>
            <FaPlusCircle
              onClick={() => addPhone(phone, setPhone)}
              size={15}
              style={{
                color: "var(--green)",
                float: "right",
                cursor: "pointer",
              }}
            />
            <Inputs darkMode={isEnabled}>
            {email.map((mail, index) => {
                return (
                  <div className="submit-line" key={index}>
                    <FaEnvelope className="submit-icon" />
                    <input
                      id="text"
                      type="mail"
                      value={mail.value}
                      name="email"
                      placeholder="Digite aqui seu e-mail"
                      onChange={(e) =>
                        setEmailsValue(email, setEmail, index, "value", e.target.value)
                      }
                    />
                    {index !== 0 ? (
                      <FaMinusCircle
                        onClick={() => removeEmail(email, setEmail, index)}
                        size={15}
                        style={{ color: "var(--red)", cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                );
              })}
            </Inputs>
            <FaPlusCircle
              onClick={() => addEmail(email, setEmail)}
              size={15}
              style={{
                color: "var(--green)",
                float: "right",
                cursor: "pointer",
              }}
            />
            <Inputs darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaUser className="submit-icon" />
                <input
                  type="text"
                  placeholder="Login"
                  onChange={(e) => setLogin(e.target.value)}
                />
              </div>
            </Inputs>
            <Inputs style={{flexDirection: 'row'}} darkMode={isEnabled}>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)", marginRight: '1rem' }}
              >
                <FaLock className="submit-icon" />
                {isPasswordVisible ? (
                  <>
                    <input
                      type="text"
                      value={password}
                      placeholder="Senha"
                      maxLength="20"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FaEye style={{color: "var(--blue)", cursor: 'pointer'}} onClick={() => setIsPasswordVisible(false)}/>
                  </>
                ) : (
                  <>
                    <input
                      type="password"
                      value={password}
                      placeholder="Senha"
                      maxLength="20"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FaEyeSlash style={{color: "var(--blue)", cursor: 'pointer'}} onClick={() => setIsPasswordVisible(true)}/>
                  </>
                )}
                
              </div>
              <div
                className="submit-line"
                style={{ marginBottom: "calc(1rem + 15px)" }}
              >
                <FaLock className="submit-icon" />
                {isVerifyPasswordVisible ? (
                  <>
                    <input
                      type="text"
                      value={verifyPassword}
                      placeholder="Confirme sua Senha"
                      maxLength="20"
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                    <FaEye style={{color: "var(--blue)", cursor: 'pointer'}}  onClick={() => setIsVerifyPasswordVisible(false)}/>
                  </>
                ) : (
                  <>
                    <input
                      type="password"
                      value={verifyPassword}
                      placeholder="Confirme sua Senha"
                      maxLength="20"
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                    <FaEyeSlash style={{color: "var(--blue)", cursor: 'pointer'}} onClick={() => setIsVerifyPasswordVisible(true)}/>
                  </>
                )}
                
              </div>
            </Inputs>

            <Observation darkMode={isEnabled}>
             <p><small><strong>Observação: </strong>A senha precisa ter de 6 a 20 caracteres compostos por números e letras; Precisa possuir algum caractere especial (@#$%) e ao menos uma letra maiuscula e uma minuscula.</small></p>
            </Observation>
            
          </ProfileDiv>
        </ModalContent>
        <ModalButtons>
          <Button
            hollow={false}
            expansive={true}
            bgcolor="var(--blue)"
            border="var(--blue)"
            size="small"
            color="#fff"
            hover="var(--blue-hover)"
            onClick={() => {handleCreateNewUser()}}
          >
            {loader ? <img src={spinner} alt="Criar usuário"/> : "CRIAR USUÁRIO"}
          </Button>
          <hr />
          <Button
            hollow={true}
            expansive={true}
            size="small"
            color="var(--gray)"
            border="var(--gray)"
            onClick={() => {
              setIsSubModalVisible(false);
            }}
          >
            Cancelar
          </Button>
        </ModalButtons>
    </>
  );
};
export default CreateNewUser;
