import React from "react";

import { DrawerUI, PageHeader, ItemNavigator } from "./styles";

import logo from "../../assets/images/logo.svg";
import logoDarkMode from '../../assets/images/logo-dark-mode.svg';
import {
  FaUser,
  FaFileDownload,
  FaFolder,
  FaFileAlt,
  FaBuilding,
  FaKey,
  FaQuestionCircle,
  FaRandom,
  FaSignOutAlt,
  FaBell,
  FaPen,
  FaCircle,
  FaBook,
  FaFolderOpen,
} from "react-icons/fa";
import { useNotification } from "../../context/contextNotification";
import { useTutorial } from "../../context/contextTutorial";
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";
import { Link } from "react-router-dom";
import { ConfirmLogout } from "../../modals/ConfirmLogout";
import { useSubModal } from "../../context/contextSubModal";

const Navigator = (props) => {
  const { user, primary, setUser } = useAuth();
  const {isEnabled} = useColorMode();
  const screenW = window.screen.width;
  const categorias = 
  user.passwords ? (
    [
      {
        id: "Pessoas",
        icon: <FaUser />,
        path: 'pessoas',
        hasFooter: true,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-people',
      },
      {
        id: "Arquivos Recebidos",
        icon: <FaFileDownload />,
        path: 'arquivos-recebidos',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-received-files',
      },
      {
        id: "Documentos",
        icon: <FaFolder />,
        path: 'documentos',
        hasFooter: true,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-documents',
      },
      {
        id: "Legado",
        icon: <FaFolderOpen />,
        path: 'legado',
        hasFooter: true,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-legacy',
      },
      {
        id: "Faturas e Boletos",
        icon: <FaFileAlt />,
        path: 'faturas-e-boletos',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "FTAX"],
        elementId: 'nav-invoices',
      },
      /* {
        id: "Minhas Finanças",
        icon: <FaWallet />,
        modalContent: <Finances />,
        hasFooter: false,
        permission: ["ADMIN", "FINANC"],
      }, */
      {
        id: "Minha PJ",
        icon: <FaBuilding />,
        path: 'minha-pj',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-pj',
      },
      {
        id: "Contatos de Relacionamento",
        icon: <FaKey />,
        path: 'contatos',
        hasFooter: true,
        permission: ["ADMIN"],
        elementId: 'nav-contacts',
      },
      {
        id: "Ajuda",
        icon: <FaQuestionCircle />,
        path: 'ajuda',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-help',
      }
    ]
  ) : (
    [
      {
        id: "Arquivos Recebidos",
        icon: <FaFileDownload />,
        path: 'arquivos-recebidos',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-received-files',
      },
      {
        id: "Documentos",
        icon: <FaFolder />,
        path: 'documentos',
        hasFooter: true,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-documents',
      },
      {
        id: "Legado",
        icon: <FaFolderOpen />,
        path: 'legado',
        hasFooter: true,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-legacy',
      },
      {
        id: "Faturas e Boletos",
        icon: <FaFileAlt />,
        path: 'faturas-e-boletos',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "FTAX"],
        elementId: 'nav-invoices',
      },
      /* {
        id: "Minhas Finanças",
        icon: <FaWallet />,
        modalContent: <Finances />,
        hasFooter: false,
        permission: ["ADMIN", "FINANC"],
      }, */
      {
        id: "Minha PJ",
        icon: <FaBuilding />,
        path: 'minha-pj',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-pj',
      },
      {
        id: "Contatos de Relacionamento",
        icon: <FaKey />,
        path: 'contatos',
        hasFooter: true,
        permission: ["ADMIN"],
        elementId: 'nav-contacts',
      },
      {
        id: "Ajuda",
        icon: <FaQuestionCircle />,
        path: 'ajuda',
        hasFooter: false,
        permission: ["ADMIN", "FINANC", "HR", "FTAX"],
        elementId: 'nav-help',
      }
    ]
  );
  
  const { ...other } = props;
  
  function storagedUser() {
    const storagedUser = localStorage.getItem("@Atlantico:user");
    setUser(JSON.parse(storagedUser));
  }

  const { hasNotification, setIsOpened } = useNotification();

  const {setIsTutorialActive} = useTutorial();

  const {setIsSubModalVisible, setSubModalContent} = useSubModal()

  return (
    <DrawerUI darkMode={isEnabled} anchor={props.anchor} variant="permanent" {...other}>
      <PageHeader darkMode={isEnabled}>
          <div className="logo">
              <img src={isEnabled ? logoDarkMode : logo} width="120" alt="Atlântico Digital" />
          </div>
          <div className="items">
            <div className="name-user">
              <h3><strong>Olá, {user.nickname}!</strong></h3>
            </div>
            <div className="icons">
              <Link  className="icon-notification" to={{ pathname: "/dashboard/notificacoes", state: {modal: true} }} onClick={() => setIsOpened(false)}>
                {hasNotification && <FaCircle id="circle-notification"/>}
                <FaBell/>
              </Link>

              <Link className="icon-edit" to={{ pathname: "/dashboard/perfil", state: {modal: true} }} onClick={() => storagedUser()}>
                <FaPen />
              </Link>

              <a className="icon-logout" href="#!" onClick={() => {
                setIsSubModalVisible(true);
                setSubModalContent(<ConfirmLogout />)
              }}>
                <FaSignOutAlt />
              </a>
            </div>
          </div>
      </PageHeader>
      <ul>
        <ItemNavigator className="nav-switch-company" darkMode={isEnabled} to={{pathname: "/dashboard/alternar-empresa", state: {modal: true}}}>
          <li>
            <FaRandom size={15} />
            <div>
              <h5 style={{ color: "var(--gray)" }}>Empresa</h5>
              <h3><strong title={primary.name}>{primary.name}</strong></h3>
            </div>
          </li>
        </ItemNavigator>

        {categorias.map((categoria) => {
          let a = 0;
          let i = 0;
          for (i = 0; i < categoria.permission.length; i++) {
            if (user.profile.indexOf(categoria.permission[i]) >= 0) {
              a++;
            }
            
          }

          if (a > 0) {
            return (
                <ItemNavigator key={categoria.elementId} darkMode={isEnabled} className={categoria.elementId} to={{pathname: `/dashboard/${categoria.path}`, state: {modal: true}}}>
                  <li>
                    {categoria.icon}
                    {categoria.id}
                  </li>
                </ItemNavigator>

            );
          } else {
            return null;
          }
        })}
        
        <ItemNavigator darkMode={isEnabled} className="nav-guide"
          onClick={() => {
            setIsTutorialActive(true);
            localStorage.setItem('@Atlantico:tutorial', true);
            if(screenW < 1024){
              props.setOpenedDrawerMobile(false);
            }
          }}
        >
          <li> <FaBook size={15} /> Guia </li>
        </ItemNavigator>
      </ul>
    </DrawerUI>
  );
};

export default Navigator;
