import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import { AuthPage } from "./pages/AuthPage";
import RecoverPassword from "./pages/RecoverPassword";
import ErrorRecoverPassword from "./pages/RecoverPassword/ErrorRecoverPassword";
import {Dashboard} from "./pages/Client/Dashboard";
import Admin from "./pages/Admin/Dashboard";
import Csat from './pages/Csat';
import InitialRoute from './pages';
import Modal from "./components/Modal";
import PrivacyPolicy from "./modals/PrivacyPolicy";
import Notifications from "./modals/Notifications";
import ProfileEdit from "./modals/ProfileEdit";
import {SendFiles} from "./modals/SendFiles";
import OpenRequest from "./modals/OpenRequest";
import { TaskDetails } from "./modals/TaskDetails";
import SwitchCompany from "./modals/SwitchCompany";
import People from "./modals/People/People";
import PeopleDominio from "./modals/People";
import PeopleEmployeesList from "./modals/People/employeesList";
import VacationList from "./modals/People/vacationList";
import Vacation from "./modals/People/vacation";
import EventsList from "./modals/People/eventsList";
import Events from "./modals/People/events";
import EventsConfirmation from "./modals/People/eventsConfirmation";
import Termination from "./modals/People/termination";
import PeopleEmployee from "./modals/People/employee";
import ReceivedFilesModal from "./modals/ReceivedFilesModal";
import Legacy from "./modals/Legacy";
import Invoices from "./modals/Invoices";
import PJ from "./modals/PJ";
import Contacts from "./modals/Contacts";
import Youtube from "./modals/Youtube";
import { Documents } from "./modals/Documents";
import ClientDetails from "./modals/Admin/ClientDetails";
import ListClients from "./modals/Admin/ListClients";
import ListUsers from "./modals/Admin/ListUsers";
import Graphics from "./modals/Admin/Graphics";
import {NpsAdmin} from "./modals/Admin/NpsAdmin";
import Runrunit from "./modals/Admin/Runrunit";
import { SendFilesAdmin } from "./modals/Admin/SendFilesAdmin";
import { ReceivedFilesAdmin } from "./modals/Admin/ReceivedFilesAdmin";
import { useAdminCompany } from "./context/contextAdminCompany";
import { ClientTasks } from "./modals/Admin/ClientTasks";
import Tasks from "./modals/Admin/Tasks";
import SaveSurvey from "./modals/Admin/SaveSurvey";
import NpsDetail from "./modals/Admin/NpsDetail";
import RunrunitUpdate from "./modals/Admin/RunrunitUpdate";
import RunrunitClients from "./modals/Admin/RunrunitClients";
import AddContact from "./modals/AddContact";
import Ticket from "./modals/Ticket";
import { Nps } from "./modals/Nps";
import { RunrunitTaskClients } from "./modals/Admin/RunrunitTaskClients";
import { RunrunitCreate } from "./modals/Admin/RunrunitCreate";
import PeopleEmployeeDetail from "./modals/People/employeeDetail";
import { useAuth } from "./context/contextAuth";

const testClients = [
  5,
  70,
  81,
  866,
  1008,
  1070,
  1173,
  1211,
  1278,
  1285,
  1308,
  1309,
  1316,
  1328,
  1329,
  1429,
  1389,
  1396,
  1403,
  1407,
]

export const Routes = () => {
  const location = useLocation();
  const {company} = useAdminCompany();
  const {primary} = useAuth();
  const prop = location.state && location.state.props;

  const isModal = (
    location.state &&
    location.state.modal 
  );

  const people = () => {
    if(primary && testClients.includes(primary.reference)) {
      return <People />
    }

    return <PeopleDominio />
  }

  return (
    <>
        <Switch>
          <Route path="/" exact component={InitialRoute} />
          <Route path="/auth/recover/:token" component={RecoverPassword} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/invalidtoken" component={ErrorRecoverPassword} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/admin" component={Admin} />
          <Route path="/survey/csat" component={Csat} />
          <Route path="/auth/politica-de-privacidade" children={<Modal title="Política de Privacidade" content={<PrivacyPolicy />} />} /> 

          {/* CLIENTE */}
          <Route path="/dashboard/nps" children={<Modal title="Pesquisa de Satisfação" content={<Nps survey={prop ? prop : null}/>} />} /> 
          <Route path="/dashboard/notificacoes" children={<Modal title="Notificações" content={<Notifications />} />} /> 
          <Route path="/dashboard/perfil" children={<Modal title="Meu Perfil" content={<ProfileEdit />} />} /> 
          <Route path="/dashboard/envie-arquivos/:path" children={<Modal title="Envie Arquivos" content={<SendFiles path={prop}/>} />} />
          <Route path="/dashboard/busca/:id" children={<Modal title={prop ? prop.title : ''} content={<TaskDetails taskId={prop ? prop.id : null} hasNoBack={true}/>} />} />
          <Route path="/dashboard/abrir-solicitacao" children={<Modal title="Abra uma solicitação" content={<OpenRequest />} />} /> 
          <Route path="/dashboard/alternar-empresa" children={<Modal title="Alternar Empresa" content={<SwitchCompany />} />} /> 
          <Route path="/dashboard/pessoas" children={<Modal title="Pessoas" content={people()} />} /> 
          <Route path="/dashboard/pessoas/empregados" children={<Modal title="Pessoas - Empregados" content={<PeopleEmployeesList />} />} /> 
          <Route path="/dashboard/arquivos-recebidos" children={<Modal title="Arquivos Recebidos" content={<ReceivedFilesModal />} />} /> 
          <Route path="/dashboard/documentos/:path?" children={<Modal title="Documentos" content={<Documents path={prop || null} />} />} /> 
          <Route path="/dashboard/legado" children={<Modal title="Legado" content={<Legacy />} />} />
          <Route path="/dashboard/faturas-e-boletos" children={<Modal title="Faturas e boletos" content={<Invoices />} />} />
          <Route path="/dashboard/minha-pj" children={<Modal title="Minha PJ" content={<PJ />} />} />
          <Route path="/dashboard/contatos" children={<Modal title="Contatos de Relacionamento" content={<Contacts />} />} />
          <Route path="/dashboard/ajuda" children={<Modal title="Ajuda" content={<Youtube />} />} />
          <Route path="/dashboard/tarefa/:id" children={<Modal title={prop ? prop.title : ""} content={<TaskDetails taskId={prop ? prop.id : null} hasNoBack />} />} />
          <Route path="/dashboard/ticket/:id" children={<Modal title={prop ? `Solicitação #${prop.id}` : ""} content={<Ticket ticket={prop ? prop : null} />} />} />
          <Route path="/dashboard/novo-contato" children={<Modal title="Novo Contato" content={<AddContact  />} />}/>

          {/* ADMIN */}
          <Route path="/admin/perfil" children={<Modal title="Meu Perfil" content={<ProfileEdit />} />} /> 
          <Route path="/admin/cliente/:id" children={<Modal title={ company ? `${company.reference} -  ${company.name}` : ''} content={<ClientDetails />} />} />
          <Route path="/admin/clientes" children={<Modal title="Clientes" content={<ListClients />} />} /> 
          <Route path="/admin/usuarios" children={<Modal title="Usuários" content={<ListUsers />} />} /> 
          <Route path="/admin/pesquisa-satisfacao" children={<Modal title="Pesquisa de Satisfação" content={<Graphics />} />} /> 
          <Route path="/admin/nps" children={<Modal title="Pesquisas NPS" content={<NpsAdmin />} />} /> 
          <Route path="/admin/tarefas" children={<Modal title="Tarefas - Runrun.it" content={<Runrunit />} />} /> 
          <Route path="/admin/:company/envie-arquivos/:path?" children={<Modal title="Envie Arquivos" content={<SendFilesAdmin path={prop || null}/>} />} />
          <Route path="/admin/:company/arquivos-recebidos/:path?" children={<Modal title="Arquivos Recebidos" content={<ReceivedFilesAdmin path={prop || null}/>} />} />
          <Route path="/admin/:company/rotina-de-tarefas" children={<Modal title={company ? company.name + ' - Tarefas Vinculadas' : ''} content={<ClientTasks />} />} />
          <Route path="/admin/:company/tarefa/:id" children={<Modal title={prop ? prop.title : ""} content={<TaskDetails taskId={prop ? prop.id : null} />} />} />
          <Route path="/admin/:company/tarefas" children={<Modal title={company ? company.name : ''} content={<Tasks />} />} />
          <Route path="/admin/salvar-pesquisa" children={<Modal title="Criar/Editar Pesquisa" content={<SaveSurvey surveyId={prop ? prop : null} />} />} />
          <Route path="/admin/nps-detalhes" children={<Modal title={prop ? prop.title : ''} content={<NpsDetail surveyId={prop ? prop._id : null} />} />} />
          <Route path="/admin/atualizar-tarefa" children={<Modal title={prop ? prop.name : ''} content={<RunrunitUpdate task={prop ? prop : null} />} />} />
          <Route path="/admin/criar-tarefa" children={<Modal title='Novo Tipo de Tarefa' content={<RunrunitCreate />} />} />
          <Route path="/admin/clientes-vinculados" children={<Modal title={prop ? `${prop.name} - VINCULAR CLIENTES` : ''} content={<RunrunitClients task={prop ? prop : null} />} />} />
          <Route path="/admin/clientes-tarefas" children={<Modal title={prop ? `${prop.name} - SINCRONIZAR` : ''} content={<RunrunitTaskClients task={prop ? prop : null} />} />} />
          
        </Switch>

        {isModal && <Route path="/auth/politica-de-privacidade" children={<Modal title="Política de Privacidade" content={<PrivacyPolicy />} />} /> }
        {/* CLIENTE */}
        {isModal && <Route path="/dashboard/nps" children={<Modal title="Pesquisa de Satisfação" content={<Nps survey={prop ? prop : null}/>} />} /> }
        {isModal && <Route path="/dashboard/notificacoes" children={<Modal title="Notificações" content={<Notifications />} />} />}
        {isModal && <Route path="/dashboard/perfil" children={<Modal title="Meu Perfil" content={<ProfileEdit />} />} />  }
        {isModal && <Route path="/dashboard/envie-arquivos/:path" children={<Modal title="Envie Arquivos" content={<SendFiles path={prop}/>} />} />}
        {isModal && <Route path="/dashboard/busca/:id" children={<Modal title={prop ? prop.title : ''} content={<TaskDetails taskId={prop ? prop.id : null} hasNoBack={true}/>} />} />}
        {isModal && <Route path="/dashboard/abrir-solicitacao" children={<Modal title="Abra uma solicitação" content={<OpenRequest />} />} /> }
        {isModal && <Route path="/dashboard/alternar-empresa" children={<Modal title="Alternar Empresa" content={<SwitchCompany />} />} /> }
        {isModal && <Route path="/dashboard/pessoas" children={<Modal title="Pessoas" content={people()} />} />}
        {isModal && <Route path="/dashboard/pessoas-empregado-novo" children={<Modal title={`Pessoas - ${location.type}`} content={<PeopleEmployee type={location.type} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-empregado-editar" children={<Modal title={`Pessoas - ${location.type}`} content={<PeopleEmployee type={location.type} id={location.id} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-empregado-detalhe" children={<Modal title={`Pessoas - ${location.type}`} content={<PeopleEmployeeDetail type={location.type} employee={location.employee} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-empregados" children={<Modal title={`Pessoas - ${location.type}`} content={<PeopleEmployeesList type={location.type} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-aviso-ferias" children={<Modal title="Pessoas - Aviso e Cálculo de Férias" content={<PeopleEmployeesList module={location.module} type={location.type} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-aviso-ferias-empregado" children={<Modal title="Pessoas - Aviso e Cálculo de Férias" content={<VacationList employee={location.employee} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-aviso-ferias-inclusao" children={<Modal title="Pessoas - Aviso e Cálculo de Férias" content={<Vacation employee={location.employee} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-aviso-rescisao" children={<Modal title="Pessoas - Aviso e Cálculo de Rescisão" content={<PeopleEmployeesList module={location.module} type={location.type} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-aviso-rescisao-inclusao" children={<Modal title="Pessoas - Aviso e Cálculo de Rescisão" content={<Termination employee={location.employee} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-eventos-em-folha" children={<Modal title="Pessoas - Lançamento de eventos em folha" content={<EventsList />} />} />}
        {isModal && <Route path="/dashboard/pessoas-eventos-em-folha-inclusao" children={<Modal title="Pessoas - Lançamento de eventos em folha" content={<Events draftId={location.draftId} type={location.type} compet={location.compet} />} />} />}
        {isModal && <Route path="/dashboard/pessoas-eventos-em-folha-recibo" children={<Modal title="Pessoas - Lançamento de eventos em folha" content={<EventsConfirmation data={location.data} type={location.type} />} />} />}
        {isModal && <Route path="/dashboard/arquivos-recebidos" children={<Modal title="Arquivos Recebidos" content={<ReceivedFilesModal />} />} />   }
        {isModal && <Route path="/dashboard/documentos/:path?" children={<Modal title="Documentos" content={<Documents path={prop || null} />} />} /> }
        {isModal && <Route path="/dashboard/legado" children={<Modal title="Legado" content={<Legacy />} />} />}
        {isModal && <Route path="/dashboard/faturas-e-boletos" children={<Modal title="Faturas e boletos" content={<Invoices />} />} />}
        {isModal && <Route path="/dashboard/minha-pj" children={<Modal title="Minha PJ" content={<PJ />} />} />}
        {isModal && <Route path="/dashboard/contatos" children={<Modal title="Contatos de Relacionamento" content={<Contacts />} />} />}
        {isModal && <Route path="/dashboard/ajuda" children={<Modal title="Ajuda" content={<Youtube />} />} />}
        {isModal && <Route path="/dashboard/tarefa/:id" children={<Modal title={prop ? prop.title : ""} content={<TaskDetails taskId={prop ? prop.id : null} hasNoBack />} />} />}
        {isModal && <Route path="/dashboard/novo-contato" children={<Modal title="Novo Contato" content={<AddContact  />} />}/>}
        {isModal && <Route path="/dashboard/ticket/:id" children={<Modal title={prop ? `Solicitação #${prop.id}` : ""} content={<Ticket ticket={prop ? prop : null} />} />} />}

        {/* ADMIN */}
        {isModal && <Route path="/admin/perfil" children={<Modal title="Meu Perfil" content={<ProfileEdit />} />} /> }
        {isModal && <Route path="/admin/cliente/:id" children={<Modal title={ company ? `${company.reference} -  ${company.name}` : ''} content={<ClientDetails />} />} />}
        {isModal && <Route path="/admin/clientes" children={<Modal title="Clientes" content={<ListClients />} />} /> }
        {isModal && <Route path="/admin/usuarios" children={<Modal title="Usuários" content={<ListUsers />} />} /> }
        {isModal && <Route path="/admin/pesquisa-satisfacao" children={<Modal title="Pesquisa de Satisfação" content={<Graphics />} />} /> }
        {isModal && <Route path="/admin/nps" children={<Modal title="Pesquisas NPS" content={<NpsAdmin />} />} /> }
        {isModal && <Route path="/admin/tarefas" children={<Modal title="Tarefas - Runrun.it" content={<Runrunit />} />} /> }
        {isModal && <Route path="/admin/:company/envie-arquivos/:path?" children={<Modal title="Envie Arquivos" content={<SendFilesAdmin path={prop || null}/>} />} />}
        {isModal && <Route path="/admin/:company/arquivos-recebidos/:path?" children={<Modal title="Arquivos Recebidos" content={<ReceivedFilesAdmin path={prop || null}/>} />} />}
        {isModal && <Route path="/admin/:company/rotina-de-tarefas" children={<Modal title={company ? company.name + ' - Tarefas Vinculadas' : ''} content={<ClientTasks />} />} />}
        {isModal && <Route path="/admin/:company/tarefa/:id" children={<Modal title={prop ? prop.title : ""} content={<TaskDetails taskId={prop ? prop.id : null} />} />} />}
        {isModal && <Route path="/admin/:company/tarefas" children={<Modal title={company ? company.name : ''} content={<Tasks />} />} />}
        {isModal && <Route path="/admin/salvar-pesquisa" children={<Modal title="Criar/Editar Pesquisa" content={<SaveSurvey surveyId={prop ? prop : null}/>} />} />}
        {isModal && <Route path="/admin/nps-detalhes" children={<Modal title={prop ? prop.title : ''} content={<NpsDetail surveyId={prop ? prop._id : null} />} />} />}
        {isModal && <Route path="/admin/atualizar-tarefa" children={<Modal title={prop ? prop.name : 'Nova tarefa'} content={<RunrunitUpdate task={prop ? prop : null} />} />} />}
        {isModal && <Route path="/admin/criar-tarefa" children={<Modal title='Novo Tipo de Tarefa' content={<RunrunitCreate />} />} />}
        {isModal && <Route path="/admin/clientes-vinculados" children={<Modal title={prop ? `${prop.name} - VINCULAR CLIENTES` : ''} content={<RunrunitClients task={prop ? prop : null} />} />} />}
        {isModal && <Route path="/admin/clientes-tarefas" children={<Modal title={prop ? `${prop.name} - SINCRONIZAR` : ''} content={<RunrunitTaskClients task={prop ? prop : null} />} />} />}
    </>
    
  );
};
