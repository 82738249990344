import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  ButtonGroup,
  Divider,
  Grid,
  TextField,
  Paper,
  CircularProgress,
  LinearProgress,
  Typography,
  Backdrop,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import PeriodoAquisitivo from "./employee/submodals/periodoAquisitivo";
import { useSubModal } from "../../context/contextSubModal";

import { DivManagementPeople } from "./styles";
import { FaCheckCircle } from "react-icons/fa";
import { rr } from "../../services/runrunit";
import api from "../../services/api";

const validation = (alert, data, requiredData) => {

  return requiredData.every((element,index) => {

      if(!data[element[0]]){

        alert({
          state:true,
          message: <div>O campo <b>{element[1]}</b> é obrigatório.</div>,
        })

        return false
      }

      return true
    })

}

const jsonToString = (initialState,data) => {

  return Object.entries(initialState).map(row => {

    if(data[row[0]]===false){
      return 0
    }

    if(data[row[0]]===true){
      return 1
    }

    if(!data[row[0]]){
      return "~"
    }

    return data[row[0]]

  }).join(';')

}

const requiredData = [
  ["DATAINICIAL","Data Inicial do Período Aquisitivo"],
  ["DATAINICIALFERIAS","Data Inicial das Férias"],
  ["DIASFERIAS","Dias de Férias"],
  ["DIASABONO","Dias de Abono"],
]

const initialState = {
  CODIGOEMPRESA: "",
  CODIGOFUNCCONTR: "",
  DATAINICIAL: "",
  DATAINICIALFERIAS: "",
  DATAFINALFERIAS: "",
  DIASFERIAS: "",
  SEQRECIBOFERIAS : "1",
  DIASABONO: "0",
  CONCESSAOABONO: "0",
  DATAABONO: "",
  DATARETORNOFERIAS: "",
  PAGAUMTERCO: "1",
  PAGAADTO13: "0",
  DATAPGTO: "",
  PERCENTUALRECIBOFERIAS: "0",
  DESCADTO: "0",
  DESCONTPARCELA: "0",
  NUMEROPARCELA: "0",
  COMPETINICIAL: "",
  FORMAGERACAO: "0",
  DIASREMUN: "0",
  DATAAVISO: "",
  DATASOLICABONO: "",
  DIASDOBROFERIAS: "0",
  DIASDOBROABONO: "0",
  DIASPREMIO: "0",
  DIASESTABILIDADE: "0",
  DESCTAXASIND: "",
  DESCPARVALE: "",
  CODIGOUSUARIO: "7",
  DATAHORALCTO: "",
  ORIGEMDADO: "0",
  TIPORECIBO: "1",
  ORIGEMCALCULO: "0",
}

const Vacation = ( { employee } ) => {
  const {setIsSubModalVisible, setSubModalContent} = useSubModal()
  const [tab, setTab] = useState("general")
  const [state,setState] = useState(initialState)
  const history = useHistory();
  const { primary, user } = useAuth();
  const {isEnabled} = useColorMode();
  const [submitError, setSubmitError] = useState(false)
  const [progress,setProgress] = useState({
    state: false,
    step: 0,
    progress:0,
  })

  const token = localStorage.getItem("@Atlantico:token");

  const headers = {
    headers: {
      Authorization: token,
    },
  }

  const [required,setRequired] = useState({
    message:"",
    state:false,
  })

  const [success,setSuccess] = useState({
    message:"",
    state:false,
  })

  const sendEmail = async (id) => {
    return api.post(`v1/mailer/send`,{
      recipients: user.email.map(email => ({email: email.value,name: user.name})),
      subject: "Novo aviso de férias registrado",
      template_id: "0p7kx4xkqmeg9yjr",
      data: {
        id,
        name: user.nickname,
        company: primary.name,
        employee: employee.data.NOMEFUNC,
      }
    },headers).then((response) => console.log(response)).catch((error) => console.log(error))
  }

  const postData = (payload) => (

    api.post(`v1/questor/reciboFerias`,payload,headers)
    .then(response => {
      console.log(response.data)
      return true
    })
    .catch(error => {

      onSubmitError(error)
      return false

    })

  )

  const onSubmitError = (error) => {
    if(error.response){
      console.log(error.response.data)
    }

    setSubmitError(true)
  }

  const handleTaskCreate = async () => {

    let blocks = ""

    blocks = blocks + `<br /><br /><h2>Informações enviadas:</h2>`
    
    requiredData.map((item) => {

        let value = state[item[0]]
        
        if(item[0]=="DATAINICIALFERIAS"){
            let date = value.split("-")
            date = new Date(date[0],date[1]-1,date[2])
            value = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth()+1).toString().padStart(2, '0')}/${date.getFullYear()}`
        }

        blocks = blocks + `<div><b>${item[1]}:</b> ${value}</div>`
    })

    return rr.post('tasks', {
      task: {
        title: `${primary.reference} - AVISO DE FÉRIAS - EMPREGADO - ${employee.data.NOMEFUNC}`,
        on_going: false,
        project_id: primary.runrunit_projects[0],
        type_id: 1689900,
        board_id: 486584,
        desired_date: null,
        description: blocks,
      }
    }, {
        headers: {
            "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
            "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
        }
    })
    .then(response => response.data.id)
    .catch(err => false)
  
  }

  const handleChange = (e,obj=false) => {
    
    if(obj){

      let newObj = {}

      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key]
      })

      // setState({
      //   ...state,
      //   ...newObj,
      // })

    }else{
      const el = e.target
      const name = el.name;
      const value = el.type === "checkbox" ? el.checked : el.value;

      setState({
        ...state,
        [name]: value
      })

      // console.log({
      //   ...state,
      //   [name]: value
      // })
    }

  }

  const onSubmit = async (e) => {
    e.preventDefault()

    setRequired({
      message:"",
      state:false,
    })
    
    const validate = validation(setRequired,state,requiredData)

    if(validate){

      let DATAINICIAL = state.DATAINICIAL.split("/")
      DATAINICIAL = `${DATAINICIAL[2]}-${DATAINICIAL[1]}-${DATAINICIAL[0]}`

      const DATAINICIALFERIAS = state.DATAINICIALFERIAS.split("-")
      let DATAFINALFERIAS = new Date(DATAINICIALFERIAS[0],DATAINICIALFERIAS[1]-1,DATAINICIALFERIAS[2])
      DATAFINALFERIAS.setDate(DATAFINALFERIAS.getDate()+Number(state.DIASFERIAS))
      DATAFINALFERIAS = `${DATAFINALFERIAS.getFullYear()}-${(DATAFINALFERIAS.getMonth()+1).toString().padStart(2, '0')}-${DATAFINALFERIAS.getDate().toString().padStart(2, '0')}`
  
      let DATAPGTO = new Date(DATAINICIALFERIAS[0],DATAINICIALFERIAS[1]-1,DATAINICIALFERIAS[2])
      DATAPGTO.setDate(DATAPGTO.getDate()-3)
  
      if(DATAPGTO.getDay() === 6){
        DATAPGTO.setDate(DATAPGTO.getDate()-1)
      }
      else if(DATAPGTO.getDay() === 0){
        DATAPGTO.setDate(DATAPGTO.getDate()-2)
      }
  
      DATAPGTO = `${DATAPGTO.getFullYear()}-${(DATAPGTO.getMonth()+1).toString().padStart(2, '0')}-${DATAPGTO.getDate().toString().padStart(2, '0')}`

      let payload = {
        ...state,
        DATAINICIAL,
        DATAPGTO,
        DATAFINALFERIAS,
        DIASABONO: state.DIASABONO,
      }

      setProgress({
        state:true,
        step:0,
        progress:33,
      })

      const task = await handleTaskCreate()
      if(!task) return false

      setProgress({
        state:true,
        step:1,
        progress:66,
      })

      // if(!await postData(payload)) return false

      await sendEmail(task);

      setProgress({
        state:true,
        step:2,
        progress:100,
      })

    }

  }

  useEffect(() => {
    
    setState({
      ...state,
      CODIGOEMPRESA: primary.reference,
      CODIGOFUNCCONTR: employee.data.CODIGOFUNCCONTR,
      SEQRECIBOFERIAS: employee.seq,
    })

  },[])

  const backdrop = () => {

    if(submitError) {

      return <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
          <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
          <Typography variant="body1" component="p">Não foi possível continuar sua solicitação, volte e tente novamente mais tarde. Persistindo o problema, entre em contato com nossos canais de suporte!</Typography>

          <Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-ferias-empregado', state: {modal: true}, employee: employee.data}) }}>Voltar</Button>
      </Paper>
    }

    return (
      <div>

        <LinearProgress variant="determinate" value={progress.progress} />

        <Paper style={{padding:50,textAlign:"center",width:600}} elevation={2}>
          {progress.step < 2 && <CircularProgress size={50} style={{marginBottom:20}} />}
          {progress.step===2 && <FaCheckCircle size={100} color="#3cd56b" style={{marginBottom:20}} />}
                
          {progress.step===0 && <Typography variant="h5">Criando tarefa...</Typography>}
          {progress.step===1 && <Typography variant="h5">Enviando informações...</Typography>}
          {progress.step===2 && <div><Typography variant="h5">Solicitação enviada com sucesso!</Typography><Button variant="contained" style={{marginTop:20}} onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-ferias-empregado', state: {modal: true}, employee: employee.data}) }}>Voltar</Button></div>}
        </Paper>

      </div>
    )

  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>

        <div style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"23em",width:"100%"}}>
        <Grid container>
          <Grid item xs={12} style={{padding:1}}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                  select
                  fullWidth
                  label="Empresa"
                  name="CODIGOEMPRESA"
                  defaultValue={employee.CODIGOEMPRESA}
                  disabled
                  SelectProps={{
                    native: true,
                  }}
                  >
                    <option key={primary.reference} value={primary.reference}>{primary.name}</option>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField required fullWidth id="NOMEFUNC" disabled label="Empregado" aria-label="Empregado" defaultValue={employee.data.NOMEFUNC} name="NOMEFUNC" inputProps={{ maxLength: 70 }} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField
                      style={{cursor:"pointer"}}
                      fullWidth
                      required
                      label="Data Inicial Período Aquisitivo"
                      value={state.DATAINICIAL}
                      name="DATAINICIAL"
                      id="DATAINICIAL"
                      inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                      onFocus={() => {
                          setIsSubModalVisible(true);
                          setSubModalContent(<PeriodoAquisitivo setState={setState} state={state} employee={employee.data} setIsSubModalVisible={setIsSubModalVisible} />)
                      }}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField required fullWidth id="DATAINICIALFERIAS" label="Data Inicial das Férias" defaultValue="" name="DATAINICIALFERIAS" onChange={handleChange} type="date" inputProps={{ maxLength: 10 }} InputLabelProps={{ shrink: true }} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField required fullWidth id="DIASFERIAS" label="Dias de Férias" defaultValue="" name="DIASFERIAS" onChange={handleChange} type="number" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField select fullWidth required label="Dias de Abono" defaultValue="" id="DIASABONO" name="DIASABONO" SelectProps={{native: true}} onChange={handleChange}>
                      <option value=""></option>
                      <option value="10">Sim</option>
                      <option value="0">Não</option>
                  </TextField>
                </Grid>
              </Grid>
              
          </Grid>
        </Grid>
        </div>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons>
          <Divider style={{marginTop:10}} />

          {required.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#FC5757",backgroundColor:"#FEDDDE"}}>{required.message}</Paper>}
          {success.state && <Paper style={{padding:10,textAlign:'center',marginTop:10,color:"#2D3D0F",backgroundColor:"#E0EFC1"}}>{success.message}</Paper>}

          <ButtonGroup variant="outlined" style={{marginTop:10}} fullWidth>
              <Button onClick={() => { history.push({pathname: '/dashboard/pessoas-aviso-ferias-empregado', state: {modal: true}, employee: employee.data}) }}>Voltar</Button>
              <Button color="secondary" type="submit">Enviar</Button>
          </ButtonGroup>
      </ModalButtons>

      <Backdrop open={progress.state} style={{zIndex:1000}}>
        {backdrop()}
      </Backdrop>
    </form>
  );
};

export default Vacation;
