import React, {useEffect, useState} from "react";
import {
  FaMapMarkerAlt,
  FaInfo,
  FaFileAlt,
  FaEllipsisV,
  FaTrash,
} from "react-icons/fa";
import List from "../../components/List";
import SubModal from "../../components/SubModal";
import Button from "../../components/Button";
import ToastMessage from "../../components/ToastMessage";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useAuth } from "../../context/contextAuth";
import { useSubModal } from "../../context/contextSubModal";
import { useColorMode } from "../../context/contextColorMode";

import { MinhaPJ, DivFileOptions } from "./styles";

import api from "../../services/api";

const PJ = () => {
  const {
    isSubModalVisible,
    setIsSubModalVisible,
    setSubModalContent,
  } = useSubModal();
  const { primary, user } = useAuth();
  const {isEnabled} = useColorMode();
  const [document, setDocument] = useState({});
  const token = localStorage.getItem("@Atlantico:token");
  const [loader, setLoader] = useState();
  const [documents, setDocuments] = useState([]);
  const [secLoader, setSecLoader] = useState();
  const [successDeleted, setSuccessDeleted] = useState(false);


  async function DownloadFile(path) {
    const formattedPath = path.replace('+', '%2B');
    return await api.get(`v1/clients/${primary._id}/downloadUrl?path=documents/${formattedPath}&user_id=${user._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        window.open(response.data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    async function getData(){
        await api.get(`v1/clients/${primary._id}/document`, {
          headers: {
            Authorization: token,
          },
        })
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setDocument(response.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
  }, [primary._id, token]);

  useEffect(() => {
    async function getData(){
        await api.get(`v1/clients/${primary._id}/filesList?path=documents/`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(setSecLoader(true))
        .then((response) => {
          setSecLoader(false);
          setDocuments(response.data.files);
        })
        .catch((err) => {
          setSecLoader(false);
          console.log(err);
        });
    }

    getData();
  }, [primary._id, token, successDeleted]);

  async function handleDelete(e, path) {
    e.preventDefault();
    return await api.delete(`v1/clients/${primary._id}/deleteObject?path=documents/${path}&user_id=${user._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setIsSubModalVisible(false);
        setSuccessDeleted(true);
      })
      .then((response) => {
        setSuccessDeleted(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      {isSubModalVisible ? (
        <SubModal onClose={() => setIsSubModalVisible(false)} />
      ) : null}
      {successDeleted ? (
        <ToastMessage
          variant="success"
          message="Objeto deletado com sucesso!"
        />
      ) : null}
      <MinhaPJ>
        {loader ? (
          <Loader/>
        ) : (
          <>
            <div className="name">
              <h2>
                <strong>{primary.name}</strong>
              </h2>
              <h4>{primary.document}</h4>
            </div>
            <div className="groupInfo">
              <div className="info">
                <FaMapMarkerAlt size={30} />
                <p>
                  {document.logradouro}, {document.numero}
                  {document.complemento !== ""
                    ? ", - " + document.complemento
                    : null}{" "}
                  - {document.bairro}
                  <br /> {document.municipio} - {document.uf} - {document.cep}
                </p>
              </div>
              <div className="info">
                <FaInfo size={30} />
                <p>
                  CÓDIGO DE CLIENTE ATLÂNTICO <br /> {primary.reference}
                </p>
              </div>
            </div>
          </>
        )}

        {secLoader ? (
          <Loader/>
        ) : documents.length !== 0 ? (
          <List darkMode={isEnabled} completeLine={true}>
            {documents.map((doc) => {
              return (
                <li key={doc.lastModifiedFormated}>
                  <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className="documents" onClick={() => {DownloadFile(doc.name)}}>
                      <FaFileAlt size={32} color='var(--file)'/>
                      <div className="info">
                        <h4>
                          <strong style={{ color: 'var(--file)' }}>{doc.name}</strong>{" "}
                        </h4>
                        <h5 style={{color: 'var(--file)'}}>{doc.lastModifiedFormated}</h5>
                      </div>
                    </div>

                    <a href="#!">
                        <FaEllipsisV
                        style={{
                          margin: "0",
                          color: "var(--gray)",
                          cursor: "pointer",
                        }}
                        size={20}
                        onClick={() => {
                          setIsSubModalVisible(true);
                          setSubModalContent(
                            <DivFileOptions>
                              <h3>
                                <strong>{doc.name}</strong>
                              </h3>
                              <Button
                                bgcolor="var(--red)"
                                color="#fff"
                                border="var(--red)"
                                hover="var(--red-hover)"
                                expansive={true}
                                hollow={false}
                                size="small"
                                onClick={(e) => handleDelete(e, doc.name)}
                              >
                                <FaTrash
                                  style={{
                                    color: "#fff",
                                    marginBottom: "0",
                                  }}
                                />
                                EXCLUIR
                              </Button>
                              <Button
                                expansive={true}
                                hollow={true}
                                size="small"
                                color="var(--gray)"
                                border="var(--gray)"
                                onClick={() => setIsSubModalVisible(false)}
                              >
                                CANCELAR
                              </Button>
                            </DivFileOptions>
                          );
                        }}
                      />
                    </a>
                  </div>
                </li>
              );
            })}
          </List>
        ) : (
          <Message severity="warning" message="Não há documentos!"/>
        )}
      </MinhaPJ>
    </>
  );
};

export default PJ;
