import React, { useEffect, useState } from 'react';
import { FaBan, FaCloudUploadAlt, FaFolder, FaFolderOpen, FaList, FaPlusSquare } from 'react-icons/fa';
import Button from '../../../components/Button';
import List from '../../../components/List';
import ModalContent from '../../../components/ModalContent';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import {SeeMore} from '../../../components/SeeMore';
import { convertName } from '../../../utils/convertName';
import { useSubModal } from '../../../context/contextSubModal';
import { useColorMode } from '../../../context/contextColorMode';
import { useAdminCompany } from '../../../context/contextAdminCompany';
import ContactDetail  from '../ContactDetail';

import {ClientDetailsDiv} from './styles';

import api from "../../../services/api";
import { Link } from 'react-router-dom';

const ClientDetails = () => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal();
    const token = localStorage.getItem("@Atlantico:token");
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [uf, setUf] = useState('');
    const [cep, setCep] = useState('');
    const [status, setStatus] = useState(false);
    const [errorClient, setErrorClient] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [tasks, setTasks] = useState([]);
    const {company} = useAdminCompany();
    const [loader, setLoader] = useState();
    const {isEnabled} = useColorMode();

    useEffect(() => {
        async function getData(){
            await api.get(`v1/clients/${company._id}/document`, {
              headers: {
                Authorization: token,
              }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                if(response.status === 200){
                    setStatus(true);
                    setLogradouro(response.data.logradouro);
                    setNumero(response.data.numero);
                    setMunicipio(response.data.municipio);
                    setUf(response.data.uf);
                    setCep(response.data.cep);
                }else{
                    setStatus(false);
                    return;
                }
            })
            .catch(err => {setLoader(false); console.log(err)})
        }

        getData();
    }, [company, token])

    useEffect(() => {
        async function getData(){
            await api.get(`v1/clients/${company._id}/contacts`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                console.log(response.data);
                setLoader(false);
                setContacts(response.data);
            })
            .catch(err => {
                if(err.response.data.errors[0] === 'Blocked client'){
                    setErrorClient(true);
                }
                setLoader(false)
            })
        }

        getData();
    }, [company, token])

    useEffect(() => {
        async function getData(){
            await api.get(`v1/clients/${company._id}/tasks?project_id=${company.runrunit_projects[0]}&limit=10`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setTasks(response.data.tasks);
            })
            .catch(err => {setLoader(false)})
        }

        getData();
    }, [company, token])
    
    return (
        <ModalContent>
            <ClientDetailsDiv darkMode={isEnabled}>
                <div className="buttons">

                <Link style={{textDecoration: 'none'}} to={{pathname: `/admin/${company._id}/envie-arquivos`, state: {modal: true}}}>
                    <Button id="button1" bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" size="medium" hollow={false} expansive={false}>
                        <FaCloudUploadAlt/>
                        Enviar Arquivos
                    </Button>
                </Link>
                
                <Link style={{textDecoration: 'none'}} to={{pathname: `/admin/${company._id}/arquivos-recebidos`, state: {modal: true, props: 'uploads'}}}>
                    <Button id="button2" bgcolor="var(--orange)" color="#fff" border="var(--orange)" hover="var(--orange-hover)" size="medium" hollow={false} expansive={false}>
                        <FaFolderOpen/>
                        ARQUIVOS RECEBIDOS
                    </Button>
                </Link>
                
                <Link style={{textDecoration: 'none'}} to={{pathname: `/admin/${company._id}/rotina-de-tarefas`, state: {modal: true}}}>
                    <Button id="button4" bgcolor="var(--green)" color="#fff" border="var(--green)" hover="var(--green-hover)" size="medium" hollow={false} expansive={false}>
                        <FaList />
                        Rotina de Tarefas
                    </Button>
                </Link>

                </div>
                {errorClient && <Message severity="warning" message="Cliente Inativo!" style={{marginBottom: '2rem'}}/>}
                <div className="client-content">
                    <div className="info">
                    {loader ? 
                        <Loader/>
                     : (
                        <>
                        <h2><strong>{company.name}</strong></h2>
                        <div className="pj"> 
                            <div className="pj-info">
                                <h4 className="pj-infotitle">COD. DE CLIENTE</h4>
                                <h4 style={{color: 'var(--text)'}}>{company.reference}</h4>
                            </div>
                            <div className="pj-info">
                                <h4 className="pj-infotitle">CNPJ</h4>
                                <h4 style={{color: 'var(--text)'}}>{company.document}</h4> 
                            </div>
                            {status ? (
                            <div className="pj-info">
                                <h4 className="pj-infotitle">ENDEREÇO</h4>
                                <h4 style={{color: 'var(--text)'}}>{logradouro}, {numero}<br/>{municipio} - {uf} - {cep}</h4>
                            </div>
                            ) : null}  
                            <div className="pj-info">
                                <h4 className="pj-infotitle">NOTIFICAÇÃO DE FOLHA?</h4>
                                {company.payroll ? (
                                    <h4 style={{color: 'var(--text)'}}>SIM</h4>
                                ) : (
                                    <h4 style={{color: 'var(--text)'}}>NÃO</h4>
                                )}      
                            </div>           
                        </div>
                        </>  
                        )} 
                    </div>
                    <div className="tasks">
                        {loader ? 
                            <Loader />
                         : (
                            <>
                            <h4 className="title">TAREFAS CONCLÚIDAS</h4>
                                <List>
                                    {tasks ? (
                                        tasks.length === 0 ? (
                                            <Message severity="warning" message="Não existem tarefas recentes."/>
                                        ) : (
                                            <>
                                                {tasks.map((task) => {
                                                    return (
                                                    <li key={task.id} className="documents">
                                                        <FaFolder size={32} />
                                                        <div className="info">
                                                            <Link to={{pathname: `/admin/${company._id}/tarefa/${task.id}`, state: {modal: true, props: task}}}>
                                                                <strong title={task.title}>{task.title}</strong>
                                                            </Link>
                                                            <span><small>Recebido em {task.closed_at_formatted}</small></span>
                                                        </div>
                                                    </li>
                                                    );
                                                })}

                                                <Link to={{pathname: `/admin/${company._id}/tarefas`, state: {modal: true}}}>
                                                    <SeeMore text="Ver Mais" icon={<FaPlusSquare />}/>
                                                </Link>
                                            </>
                                        )
                                    ) : (
                                        <Loader />
                                    )}
                                </List>
                            </>
                        )}
                    </div>
                    <div className="contacts">
                        {loader ? 
                        <Loader/>
                         : (
                            <>
                                <h4 className="title">CONTATOS</h4>
                                <List>
                                    {contacts.map((contact) => {
                                        return (
                                            <li key={contact.name} style={{cursor: 'pointer'}} className="item" onClick={() => {
                                                setIsSubModalVisible(true);
                                                setSubModalContent(<ContactDetail contactId={contact._id}/>)
                                            }}>
                                                <div className="info">
                                                    <h4><strong >{contact.name}</strong></h4>
                                                    {contact.profile && contact.profile.map((prof) => (
                                                        <p key={prof} style={{color: isEnabled ? '#fff' : '#000'}}>{convertName(prof)}</p>
                                                    ))}
                                                </div>
                                                {!contact.status && <FaBan color="red" />}
                                            </li>
                                        );
                                    })}
                                </List>
                            </>
                        )}
                    </div>
                </div>
            </ClientDetailsDiv>
        </ModalContent>
    )
}

export default ClientDetails;