import React, { useEffect } from "react";
import {Bar, YAxis, XAxis, BarChart, Cell, Tooltip} from 'recharts';
import SurveyButtons from "../../../components/SurveyButtons";
import Message from '../../../components/Message';
import Loader from "../../../components/Loader";
import List from "../../../components/List";
import {handleCsatIcon} from "../../../utils/handleCsatIcon";
import {useColorMode} from "../../../context/contextColorMode";
import { useSurvey } from "../../../context/contextSurvey";
import { useSubModal } from '../../../context/contextSubModal';

import {DivGraphs, CustomFilter, CommentsDiv} from './styles';

import api from '../../../services/api';
import { DetailGraphic } from "../DetailGraphic";

const Graphics = () => {
  const token = localStorage.getItem('@Atlantico:token');
  const {isEnabled} = useColorMode();
  const {data, customDates, setDayInit, setDayEnd, 
  isGraphActive,filter,dayInit, dayEnd, setLoader, setFilter, 
  handleButtons, setCustomDates, comments, totalVotes, loader,
  setQtdDissatisfied, setQtdNeutral, setQtdSatisfied, setQtdVeryDissatisfied,
  setQtdVerySatisfied, setComments, type, setType} = useSurvey();

  useEffect(() => {
      handleButtons("week");
      setFilter("week");
      setCustomDates(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setIsSubModalVisible, setSubModalContent } = useSubModal();

  useEffect(() => {
    async function getData(){
        await api.get(`v1/survey/csat?filter=${filter}&startAt=${dayInit}&endAt=${dayEnd}&type=${type}`, {
          headers: {
              Authorization: token,
          }
        })
        .then(setLoader(true))
        .then(response => {
            setQtdVeryDissatisfied(response.data.very_dissatisfied);
            setQtdDissatisfied(response.data.dissatisfied);
            setQtdNeutral(response.data.neutral);
            setQtdSatisfied(response.data.satisfied);
            setQtdVerySatisfied(response.data.very_satisfied);
            setComments(response.data.comments);
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dayInit, dayEnd, type]);

  return (
      <DivGraphs darkMode={isEnabled}>
          
          <div className="filters">
            <SurveyButtons/>
          </div>

          <select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="task">Tarefas</option>
              <option value="ticket">Tickets de Atendimento</option>
          </select>
          
            {customDates && (
                <CustomFilter darkMode={isEnabled}>
                    <div className="title_input">
                      <h3><strong>Inicio:</strong></h3>
                      <input type="date" onChange={(e) => setDayInit(e.target.value)}/>
                    </div>
                    <div className="title_input">
                      <h3><strong>Fim:</strong></h3>
                      <input type="date" onChange={(e) => setDayEnd(e.target.value)}/>
                    </div>
                </CustomFilter>
            )}
            
            {loader ? <Loader /> : (
            <>
            {totalVotes === 0 && comments.length === 0 ? (
                <Message severity="warning" message="Não existem dados para esse filtro!"/>
            ) : (
                <div className="content-graph">
                  {totalVotes !== 0 ? (
                      <div className="graph">
                          {isGraphActive && (
                              <BarChart
                                  width={700}
                                  height={400}
                                  data={data}
                                  stackOffset="sign"
                                  barCategoryGap='5%'
                                  barSize='100%'
                              >
                                  <XAxis dataKey="name" />
                                  <Tooltip/>
                                  <YAxis />
                                  <Bar dataKey="quantidade" label>
                                      {type === 'ticket' ? (
                                        data.map((entry, index) => (
                                            <Cell 
                                                key={`cell-${index-type}`} 
                                                fill={entry.color} 
                                                stroke="black"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    setIsSubModalVisible(true);
                                                    setSubModalContent(<DetailGraphic type='ticket' name={entry.name} filter={filter} startAt={dayInit} endAt={dayEnd}/>)
                                                }}
                                            />
                                        ))
                                      ) : (
                                        data.map((entry, index) => (
                                            <Cell 
                                                key={`cell-${index-type}`} 
                                                fill={entry.color} 
                                                stroke="black"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    setIsSubModalVisible(true);
                                                    setSubModalContent(<DetailGraphic type='task' name={entry.name} filter={filter} startAt={dayInit} endAt={dayEnd}/>)
                                                }}
                                            />
                                        ))
                                      )}
                                  </Bar>
                              </BarChart>
                              )}
                      </div>
                  ) : (

                      <Message severity="warning" message="Não existem dados para o filtro atual!" darkMode={isEnabled}/>
                  )}
  
                  {comments.length !== 0 ? (
                      <CommentsDiv>
                        <h3>Comentários</h3>
                        <List>
                            {comments.map(c => {
                                return (
                                    <li className="comments" key={c}>
                                        <div style={{display: "flex"}}>
                                            <img src={handleCsatIcon(c.answer)} style={{width: 32, marginRight: '1rem'}} alt=""/>
                                            {type === 'task' ? (
                                                <div>
                                                    <h4><strong>{c.user}</strong></h4>
                                                    <h5>{c.reference} - Tarefa {c.id}</h5>
                                                </div>
                                            ) : (
                                                <div>
                                                    <h4><strong>Ticket #{c.id}</strong></h4>
                                                </div>
                                            )}
                                            
                                        </div>
                                        <br/>
                                        <p>{c.comment}</p>
                                    </li>
                                )
                            })}
                        </List>
                      </CommentsDiv>
                  ) : (
                      <Message severity="warning" message="Não existem comentários para o filtro atual!" darkMode={isEnabled}/>
                  )}
              </div>
            )}
            </>
          )}
          
      </DivGraphs>
        
  );
};
export default Graphics;
