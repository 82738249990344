import { validarCPF } from "../../../utils/validaCPF"
import { validarPIS } from "../../../utils/validaPIS"
import { isValidDate } from "../../../utils/isValidDate"

export const validation = (alert, data, requiredData, setTab) => {

    if(!validarCPF(data.CPFFUNC)){
      alert({
        message:<div>O <b>CPF</b> informado é inválido, por favor, confira o número informado.</div>,
        state:true,
      })

      return false
    }
      
    if(!validarPIS(data.PISFUNC)){
      alert({
        message:<div>O <b>Número do PIS</b> informado é inválido, por favor, confira o número informado.</div>,
        state:true,
      })

      return false
    }
      
    if(!isValidDate(data.DATAADM)){
      alert({
        message:<div>O formato da <b>Data de Admissão</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.DATACADPIS && !isValidDate(data.DATACADPIS)){
      alert({
        message:<div>O formato da <b>Data de Cadastro do PIS</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(!isValidDate(data.DATANASC)){
      alert({
        message:<div>O formato da <b>Data de Nascimento</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.EMISSRG && !isValidDate(data.EMISSRG)){
      alert({
        message:<div>O formato da <b>Data de Emissão do RG</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.VALIDCNH && !isValidDate(data.VALIDCNH)){
      alert({
        message:<div>O formato da <b>Data de Vencimento da Carteira de Motorista</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.DATANATURAL && !isValidDate(data.DATANATURAL)){
      alert({
        message:<div>O formato da <b>Data de Naturalização</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.DATACHEGADA && !isValidDate(data.DATACHEGADA)){
      alert({
        message:<div>O formato da <b>Data de Chegada</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.EXPEDICAORNE && !isValidDate(data.EXPEDICAORNE)){
      alert({
        message:<div>O formato da <b>Data de Expedição do RNE</b> está incorreto.</div>,
        state:true,
      })

      return false
    }
      
    if(data.VALIDRNE && !isValidDate(data.VALIDRNE)){
      alert({
        message:<div>O formato da <b>Data de Validade do RNE</b> está incorreto.</div>,
        state:true,
      })

      return false
    }

    return requiredData.every((element,index) => {

        if(!data[element[0]]){
  
          alert({
            state:true,
            message: <div>O campo <b>{element[1]}</b> é obrigatório.</div>,
          })
  
          return false
        }
  
        return true
      })

}