import styled from 'styled-components';

export const Div = styled.div`
    flex: 1;

    h1{
        text-transform:  uppercase;
        color: var(--atlantico);
        margin-bottom: 1rem;
    }
`;