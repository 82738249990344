import styled from 'styled-components';

export const Div = styled.div`
    display: flex;
    flex-direction: column;

    .buttons{
        display: grid;
        grid-template-columns: repeat(1fr, 5);
        grid-template-areas: "button1 select select select select";
        grid-gap: 4rem;

        select{
            grid-area: select;
            width: 100%;
            border: 2px solid var(--blue);
            border-radius: 1rem;
            padding: 1rem;
        }
    }

    hr{
        border: solid 1px ${props => props.darkMode ? '#3d3d3d' : '#f0f0f0'};
        margin: 2rem 0;
    }

    .filters{
        width: 100%;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "button1" "button2" "button3" "button4" "button5";
        margin-bottom: 4rem;

    #button1{
      grid-area: button1;
    }
    #button2{
      grid-area: button2;
    }
    #button3{
      grid-area: button3;
    }
    #button4{
      grid-area: button4;
    }
    #button5{
      grid-area: button5;
    }

    @media(min-width: 1024px){
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "button1 button2 button3 button4 button5";
      grid-gap: 4rem;
    }
  }

    .info{
        display: flex;
        flex-direction: column;
        width: 100%;

        .score{
            display: flex;
            flex-direction: column;
            text-align: center;

            .categories{
                display: grid;
                grid-template-columns: repeat(1fr, 11);
                grid-template-areas: "detractors detractors detractors detractors detractors detractors detractors passives passives promoters promoters";
                grid-gap:1rem;

                #detractors{
                    grid-area: detractors;
                }
                #passives{
                    grid-area: passives;
                }
                #promoters{
                    grid-area: promoters;
                }

                .div-area{
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    .area{
                        height: 2rem; 
                        border: 1px solid var(--gray); 
                        border-bottom: 0; 
                        width: calc(100% - 7rem);
                        margin: 1rem 0;
                    }
                }

                .total{
                    display: flex;
                    width: 100%;
                    justify-content: space-evenly;
                    img{
                        width:  48px;
                        margin: 1rem 0;
                    }
                    .note{
                        color: var(--blue);
                    }
                }

            }
            
        }

        .totalScore{
            display: flex;
            width: 100%;
            justify-content: space-around;
            margin-top: 2rem;
            .circle{
                width: 20rem;
                height: 20rem;
                background-color: var(--green);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h1,h4{
                    color: white;
                    font-weight: bold;
                }
                h1{
                    font-size: 5rem;
                }
            }
            .listTotal{
                width:60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                
                .itemList{
                    background-color: ${props => props.darkMode ? '#121212' : '#f4f4f4'};
                    width: 100%;
                    border-radius: 5px;
                    display: flex;
                    img{
                        width: 48px;
                        height: 48px;
                    }

                    .info-item{
                        display: flex;
                        padding: 0 2rem;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }
    }
`;

export const CommentsDiv = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    h3{
      color: var(--blue);
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    @media(min-width: 700px){
        width: 100%;
    }
`;

export const CustomFilter = styled.div`
    display: flex; 
    width: 100%; 
    justify-content: center; 
    align-items: center; 
    margin-bottom: 2rem; 
    padding: 0 2rem;

    .title_input{
      display: flex;
      flex-direction: column;
      margin: 0 2rem;
      width: 100%;

      input{
        width: 100%;
        outline: 0;
        border:0;
        border-bottom: var(--divisor);
        background-color: var(--modal);
        color: var(--text);
      }
    }
`;

export const SelectQuestion = styled.select`
    background-color: ${props => props.darkMode ? '#121212' : "#fff"};
    color: var(--text);
`;