export const addQuestion = (question, setQuestion) => {
    setQuestion([...question, "" ]);
}

export const removeQuestion = (question, setQuestion, position) => {
    const updatedQuestions = [...question];
    updatedQuestions.splice(position, 1);
    setQuestion(updatedQuestions);
}

export const setQuestionValue = (question, setQuestion, position, value) => {
    const updatedQuestions = question.map((question, index) => {
      if (index === position) {
        return value;
      }
      return question;
    });
    setQuestion(updatedQuestions);
}