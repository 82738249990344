import React, { useCallback } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaArrowRight,
} from "react-icons/fa";
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  Grid,
  Typography,
  Link,
} from '@material-ui/core'

import api from "../../../../services/api";

const Banco = (props) => {
  const token = localStorage.getItem("@Atlantico:token")
  
  const loadData = async ({ skip, limit, sort }) => {
    const filter = { Filter: {} }
  
    const params = {
      classe: "TnGemDMBanco",
      limit,
      start:skip,
      sort,
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.post(`v1/nweb${qs}`,filter,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      return ({data: response.data.data, count: parseInt(response.data.records)})
    })
    .catch((err) => {
      return ({data: [], count: 0})
      console.error(err)
    })
  
  }

  const dataSource = useCallback(loadData, [])

  const columns = [
    { name: 'CODIGOBANCO', header: 'Cód.', maxWidth: 80},
    {
        name: 'NOMEBANCO',
        header: 'Nome do Banco',
        defaultFlex: 2,
        render: ({ data }) => {
        return <Link component="button" onClick={() => { 
            props.setBanco({
                CODIGOBANCO: data.CODIGOBANCO,
                NOMEBANCO: data.NOMEBANCO,
            }) 

            props.setIsSubModalVisible(false)
            }}>{data.NOMEBANCO}</Link>
        }
    },
    {
        name: 'OPTN',
        header: '',
        maxWidth: 80,
        render: ({ data }) => {
            return <Button size="small" color="default" variant="contained" onClick={() => { 
                props.setBanco({
                  CODIGOBANCO: data.CODIGOBANCO,
                  NOMEBANCO: data.NOMEBANCO,
                }) 

                props.setIsSubModalVisible(false)
            }}><FaArrowRight /></Button>
        }
    }
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  return (
    <>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginBottom:"20px"}}>Selecione um banco</Typography>
            
            <ReactDataGrid
              idProperty="CODIGOBANCO"
              columns={columns}
              dataSource={dataSource}
              defaultLimit={200}
              pagination 
              theme="default-light" 
              defaultSortInfo={{ name: 'NOMEBANCO', dir: 1, type: 'text' }}
              style={{ minHeight: 450 }}
              // onRenderRow={onRenderRow}
             />
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default Banco;
