import React, { useEffect, useState } from "react";
import { FaEnvelope, FaEnvelopeOpen, FaPlusSquare } from "react-icons/fa";
import ModalContent from "../../components/ModalContent";
import {Link} from 'react-router-dom'
import List from "../../components/List";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {SeeMore} from "../../components/SeeMore";
import { useColorMode } from "../../context/contextColorMode";
import { useAuth } from "../../context/contextAuth";
import { useNotification } from "../../context/contextNotification";

import api from "../../services/api";

const Notifications = () => {
  const { user, primary } = useAuth();
  const token = localStorage.getItem("@Atlantico:token");
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(true);
  const { setHasNotification } = useNotification();
  const {isEnabled} = useColorMode();
  const [limit, setLimit] = useState(10);

/*   useEffect(() => {
    async function getData(){
        await api.get(`v1/users/${user._id}/notifications?client_id=${primary._id}&limit=10&start=0`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setNotifications(response.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }

    getData();
  }, [primary._id, token, user._id]); */


  useEffect(() => {
    async function getData(){
      await api.get(`v1/users/${user._id}/notifications?client_id=${primary._id}&limit=${limit}&start=0`, {
          headers: {
              Authorization: token,
          }
      })
      .then(response => {
        if(limit === 10){
          setLoader(false)
        }
        setNotifications(response.data);
      })
      .catch(err => {
        if(limit === 10){
          setLoader(false)
        }
        console.log(err);
      })
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, primary]);

  function getDate(string) {
    const str2 = string.split("T");
    const str3 = str2[0].split("-");
    const day = str3[2];
    const month = str3[1];
    const year = str3[0];
    const array4 = [day, month, year];
    const finalArray = array4.join("/");
    return finalArray;
  }

  async function handleOpenNotification(notificationId) {
    return await api.put(`v1/users/${user._id}/notifications?id=${notificationId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .catch((err) => {
        console.log(err);
      });
  }

  async function HasNewNotifications() {
    return await api.get(`v1/users/${user._id}/notifications?client_id=${primary._id}&limit=1&start=0&unread=true`,
        { headers: { Authorization: token } }
      )
      .then((response) => {
        if (response.data.length !== 0) {
          setHasNotification(true);
        } else {
          setHasNotification(false);
        }
      });
  }

  return (
    <>
      <ModalContent hasFooter={false}>
        {loader ? (
          <Loader/>
        ) : notifications.length !== 0 ? (
          <List completeLine={true} darkMode={isEnabled}>
            {notifications.map((notification) => {
              return (
                <li key={notification.id} className="notification">
                    <Link to={{pathname: `/dashboard/${notification.type === 'tasks' ? 'tarefa' : 'ticket'}/${notification.data.id}`, state: {modal: true, props: notification.data}}} onClick={ async () => {
                      await handleOpenNotification(notification._id);
                      await HasNewNotifications();
                    }}>
                      <div className="header-notification">
                        <div className="title">
                          {notification.read_at !== null ? (
                            <>
                              <FaEnvelopeOpen color="var(--gray)" />
                              <h3>{notification.title}</h3>
                            </>
                          ) : (
                            <>
                              <FaEnvelope color="var(--blue)" />
                              <h3>
                                <strong>{notification.title}</strong>
                              </h3>
                            </>
                          )}
                        </div>
                        <h5>{getDate(notification.created_at)}</h5>
                      </div>
                      <div className="description-notification">
                          <h5>{notification.description}</h5>
                      </div>
                    </Link>
                </li>
            
              );
            })}
            <SeeMore onClick={() => setLimit(limit+10)} icon={<FaPlusSquare/>}  text="Ver Mais"/>
          </List>
        ) : (
          <Message severity="warning" message="Nenhuma notificação até o momento!"/>
        )}
      </ModalContent>
    </>
  );
};

export default Notifications;
