import React, { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import Button from "../../components/Button";
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import List from "../../components/List";
import { useAuth } from "../../context/contextAuth";
import Loader from '../../components/Loader';

import api from "../../services/api";
import { useHistory } from "react-router";

export const TaskDetails = ({ taskId }) => {
  const { user } = useAuth();
  const token = localStorage.getItem("@Atlantico:token");
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function getData(){
      await api.get(`v1/clients/taskDocuments?id=${taskId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then(setLoader(true))
      .then((response) => {
        setLoader(false);
        setDocuments(response.data.documents);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId])

  async function downloadZipFile() {
    const arrayDocuments = [];

    documents.map((item) => {
      return arrayDocuments.push(item.id);
    });
    const string = arrayDocuments.toString();
    return await api.get(`v1/users/${user._id}/downloadTaskZipDocuments?ids=${string}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        window.open(response.data.link);
      });
  }

  async function downloadFile(documentId) {
    return await api.get(`v1/users/${user._id}/downloadTaskDocument?id=${documentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        window.open(response.data.link);
      });
  }

  return (
    <>
      <ModalContent hasFooter={true}>
        {loader ? <Loader /> : (
          <List completeLine>
            {documents && (
              documents.map((document) => {
                return (
                  <li 
                    key={document.id}
                    className="documents"
                    onClick={() => {
                      downloadFile(document.id);
                    }}
                  >
                        <FaFileAlt size={32} style={{ color: 'var(--text)' }} />
                        <div className="info">
                          <h4>
                            <strong style={{ color: 'var(--text)' }}>
                              {document.data_file_name}
                            </strong>
                          </h4>
                        </div>
                  </li>
                );
              })
            )}
          </List>
        )}
      </ModalContent>
      <ModalButtons>
        <Button bgcolor="var(--green)"color="#fff" border="var(--green)" hover="var(--green-hover)" expansive={true} hollow={false} size="small" onClick={() => downloadZipFile()}>
          BAIXAR ARQUIVO ZIP
        </Button>
        <hr />

        <Button color="var(--gray)" border="var(--gray)" expansive={true} hollow={true} size="small" onClick={() => history.goBack()}>
          VOLTAR
        </Button>
       
      </ModalButtons>
    </>
  );
};