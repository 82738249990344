import React, { useEffect, useState } from "react";
import {
  FaFolder,
  FaFileAlt,
  FaArrowLeft,
  FaLocationArrow,
  FaFileDownload,
  FaEllipsisV
} from "react-icons/fa";
import ModalContent from "../../components/ModalContent";
import Loader from "../../components/Loader";
import ModalButtons from "../../components/ModalButtons";
import Button from "../../components/Button";
import List from "../../components/List";
import Message from "../../components/Message";
import spinner from "../../assets/images/spinner.svg";
import {docName, removeId, goForward} from '../../utils/handlePaths';
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";
import api from "../../services/api";
import { ButtonsMark } from "../../components/ButtonsMark";
import { createHash } from "../../utils/createHash";
import { OptionsFolder } from "../../components/OptionsFolder";
import { useSubModal } from "../../context/contextSubModal";
import { Link, useHistory } from "react-router-dom";
import { OptionsFile } from "../../components/OptionsFile";

export const Documents = ({ path }) => {
  const {isEnabled} = useColorMode();
  const { primary, user } = useAuth();
  const token = localStorage.getItem("@Atlantico:token");
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [loader, setLoader] = useState();
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [toZip, setToZip] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState();
  const {setIsSubModalVisible, setSubModalContent} = useSubModal();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory()

  useEffect(() => {
    if(path){
      const aux = path.split('/');
      setBreadcrumbs(['raiz', ...aux]);
    } else {
      setBreadcrumbs(['raiz'])
    }
  }, [path])


  useEffect(() => {
    async function getData(){
        await api.get(`v1/clients/${primary._id}/filesList?path=${path ? path+'/' : ''}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setFolders(response.data.folders);
          setFiles(response.data.files);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
  }, [primary._id, token, path]);

  async function downloadMultipleFiles(array){
    setDownloadLoader(true);

    if(array.length === 1){
      const formattedPath = removeId(array[0].path).replace('+', '%2B');
      await api.get(`v1/clients/${primary._id}/downloadUrl?path=${formattedPath}&user_id=${user._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      ).then((response) => {
        window.open(response.data.url)
        setDownloadLoader(false);
      })
      .then(() => setFilesToDownload([]))
    } else {
      array.forEach(async file => {
        const formattedPath = removeId(file.path).replace('+', '%2B');
  
        await api.get(`v1/clients/${primary._id}/downloadUrl?path=${formattedPath}&user_id=${user._id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ).then((response) => {
          setToZip(old => [...old, {
            filename: file.name,
            url: response.data.url
          }])
        })
  
      })
    }
}

  async function removeFileToDownload(file){
    const updatedDownload = filesToDownload.filter(arq => arq.name !== file.name);
    setFilesToDownload(updatedDownload);
  }

  useEffect(() => {
    if(toZip.length !== filesToDownload.length) return;
    if(filesToDownload.length === 0) return;

    api.post(`v1/clients/${primary._id}/downloadZip`, {
      files: toZip,
      hash: createHash(16)
    }, {
      headers: {
        Authorization: token,
      },
      responseType: 'blob'
    }).then(response => {
      const data = new Date();
      var url = window.URL.createObjectURL(response.data);
      var anchor = document.createElement("a");
      anchor.download = `AD-${data.getDate()}-${(data.getMonth() + 1)}-${data.getFullYear()}-${data.getHours()}-${data.getMinutes()}-${data.getSeconds()}.zip`;
      anchor.href = url;
      anchor.click();
    }).then(() => {
      setFilesToDownload([]);
      setToZip([]);
      setDownloadLoader(false);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toZip])

  function navigateBetweenFolders(position){
    const active = breadcrumbs;
    active.splice(0, 1);
    active.splice(position, active.length) 
    const newPath = active.join('/');

    history.push({pathname: `/dashboard/documentos/${newPath}`, state: {modal: true, props: newPath}})
  }

  return (
    <>
      <ModalContent hasFooter={true}>
        {loader ? (
          <Loader/>
        ) : (
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <h4 style={{color: 'var(--text)'}}>
                <FaLocationArrow size={10} color="var(--gray)" style={{marginRight: '0.5rem'}}/>
                {breadcrumbs.map((bc, index) => (
                  <span onClick={() => breadcrumbs.length > 1 ? navigateBetweenFolders(index) : {}} style={{marginRight: '0.5rem', textDecoration: 'none', color: 'var(--text)', cursor: 'pointer', fontWeight: index === breadcrumbs.length - 1 ? 'bold' : 'lighter'}}>
                    {bc + ' /'}
                  </span>
                ))}
              </h4>
              <ButtonsMark onMarkOn={() => setFilesToDownload(files)} onMarkOff={() => setFilesToDownload([])}/>
            </div>
            <hr style={{ margin: "1rem 0" }} />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <h3 onClick={() => breadcrumbs.length > 1 ? navigateBetweenFolders(breadcrumbs.length - 2) : history.push('/dashboard')} style={{display: "flex", alignItems: "center", cursor: "pointer", padding: "1rem 0"}}>
                <FaArrowLeft size={16} style={{ marginRight: "1rem" }} color='var(--text)' />
                <strong style={{color:'var(--text)'}}>Voltar</strong>
              </h3>
              <h4><strong>{files.length} Arquivo(s)</strong></h4>
            </div>
            <List darkMode={isEnabled} completeLine={true}>
              {folders.length !== 0
                ? folders.map((folder) => {
                    return (
                      <li key={folder}>
                        <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                          <div className="documents" >
                              <Link to={{pathname: `/dashboard/documentos/${goForward(folder)}`, state: {modal: true, props: goForward(folder)}}}>
                                <FaFolder size={32} style={{ color: "var(--folder)" }}/>
                                <div className="info">
                                  <h4><strong style={{ color: "var(--folder)" }}>
                                      {docName(folder)}
                                  </strong></h4>
                                </div>
                              </Link>
                          </div>

                          <FaEllipsisV style={{ margin: "0", color: "var(--gray)", cursor: 'pointer' }} size={20} onClick={() => {
                            setIsSubModalVisible(true);
                            setSubModalContent(<OptionsFolder deleteAllowed company={primary._id} folder={folder}/>)
                          }}/>
                        </div>
                      </li>
                    );
                  })
                : null}
              {files.length !== 0
                ? files.map((file, index) => {
                    return (
                      <li key={file.lastModified}>
                          <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                            <div className="documents" onClick={() => {filesToDownload.includes(file) ? removeFileToDownload(file) : setFilesToDownload([...filesToDownload, file]) }}>
                              {filesToDownload.includes(file) && <input type="checkbox" style={{marginRight: 10}} checked />}
                              <FaFileAlt size={32} style={{ color: 'var(--file)' }} />
                              <div className="info">
                                <h4>
                                  <strong style={{ color: 'var(--file)' }}>
                                    {file.name}
                                  </strong>
                                </h4>
                              </div>
                            </div>

                            <FaEllipsisV style={{ margin: "0", color: "var(--gray)", cursor: 'pointer' }} size={20} onClick={() => {
                              setIsSubModalVisible(true);
                              setSubModalContent(<OptionsFile deleteAllowed company={primary._id} file={file}/>)
                            }}/>
            
                          </div>
                        </li>
                    );
                  })
                : null}
              {folders.length === 0 && files.length === 0 ? (
                <Message severity="warning" message="Esta pasta está vazia!"/>
              ) : null}
            </List>
          </>
        )}
      </ModalContent>
      {filesToDownload.length > 0 && (
        <ModalButtons>
          <Button type="submit" bgcolor="var(--green)" color="#fff" border="var(--green)" hover="var(--green-hover)" expansive={true} hollow={false} size="small" onClick={() => downloadMultipleFiles(filesToDownload)}>
            {downloadLoader ? <img src={spinner} alt="Criar usuário"/> : (
              <>
              <FaFileDownload size={18} />
              <span>BAIXAR</span>
              </>
            )}
          </Button>
        </ModalButtons>
      )}
    </>
  );
};