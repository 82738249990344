import React, { useEffect, useState, useCallback } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaPlus,
  FaTrash,
  FaArrowRight,
} from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom'
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { 
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  Paper,
} from '@material-ui/core'
import { useAuth } from "../../context/contextAuth";
import { useColorMode } from "../../context/contextColorMode";

import { DivManagementPeople } from "./styles";

import api from "../../services/api";

const PeopleEmployeesList = ({ module, type }) => {
  const history = useHistory()
  const {user,primary} = useAuth()
  const [drafts,setDrafts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const {isEnabled} = useColorMode();
  const token = localStorage.getItem("@Atlantico:token")

  const headers = {
    headers: {
      Authorization: token,
    },
  }
  
  const loadData = async ({ skip, limit, sort }) => {

    const params = {
      reference: primary.reference,
      limit,
      skip,
      sort:"b.NOMEFUNC",
      datadem: 0,
      categoria: type==="Empregados" ? "01" : "00",
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.get(`v1/questor/employees${qs}`,headers)
    .then((response) => {
      const { data, count } = response.data
      return ({data,count})
    })
    .catch(err => {
      setError(true)
      console.log(err)
      return ({data: [], count: 0})
    })
  
  }

  const loadDrafts = () => {

    api.get(`v1/fopag/employee?client=${primary.reference}&task_id=`,headers)
      .then(response => {
        setDrafts(response.data)
      })
      .then(() => {
        setLoading(false)
      })

  }

  useEffect(() => {
    if(module===undefined){
      loadDrafts()
    }else{
      setLoading(false)
    }
  },[])

  const handleDraftDelete = (id) => {

    api.delete(`v1/fopag/employee/${id}`,headers)
      .then(response => {
        setDrafts(response.data)
        loadDrafts()
        setLoading(true)
      })
      .catch(error => {
        console.log(error)
      })

  }

  const dataSource = useCallback(loadData, [])

  const columns = [
    { name: 'CODIGOFUNCPESSOA', header: 'Número', maxWidth: 100},
    { name: 'DATAADM',
      header: 'Admissão',
      maxWidth: 110,
      render: ({ data }) => {
        const date = new Date(data.DATAADM)

        return `${('0'+date.getDate()).slice(-2)}/${('0'+(date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
      }
    },
    {
      name: 'NOMEFUNC',
      header: 'Nome',
      defaultFlex: 2,
      render: ({ data }) => {
        if(module===undefined) return data.NOMEFUNC;
        return <Link to={{pathname: module===undefined ? '/dashboard/pessoas-empregado-detalhe' : module==="ferias" ? '/dashboard/pessoas-aviso-ferias-empregado' : '/dashboard/pessoas-aviso-rescisao-inclusao', state: {modal: true}, employee: data, type}}>{data.NOMEFUNC}</Link>
      }
    }
  ]

  const columnsDrafts = [
    {
      name: 'NOMEFUNC',
      header: 'Rascunhos',
      defaultFlex: 2,
      render: ({ data }) => {
        return <Link to={{pathname: `/dashboard/pessoas-empregado-editar`, state: {modal: true}, id: data._id, type}}>{data.draft.NOMEFUNC}</Link>
      }
    },
    {
      name: 'SITUACAO',
      header: 'Situação',
      defaultFlex: 2,
      render: ({ data }) => {

        if(!data.task_id){
          return "Não enviado"
        }

        if(!data.CODIGOFUNCPESSOA&&!data.status.person){
          return <div><Typography variant="body2" style={{textAlign:"center"}}>12% enviado</Typography><LinearProgress variant="determinate" value={12} /></div>
        }

        if(!data.CODIGOFUNCCONTR&&!data.status.contract){
          return <div><Typography variant="body2" style={{textAlign:"center"}}>24% enviado</Typography><LinearProgress variant="determinate" value={24} /></div>
        }

        if(!data.status.wage){
          return <div><Typography variant="body2" style={{textAlign:"center"}}>36% enviado</Typography><LinearProgress variant="determinate" value={36} /></div>
        }

        if(!data.status.office){
          return <div><Typography variant="body2" style={{textAlign:"center"}}>48% enviado</Typography><LinearProgress variant="determinate" value={48} /></div>
        }

        if(!data.status.grade){
          return <div><Typography variant="body2" style={{textAlign:"center"}}>60% enviado</Typography><LinearProgress variant="determinate" value={60} /></div>
        }

        if(!data.status.dependent){
          return <div><Typography variant="body2" style={{textAlign:"center"}}>76% enviado</Typography><LinearProgress variant="determinate" value={76} /></div>
        }
      }
    },
    {
      name: 'OPT',
      header: '',
      width: 130,
      render: ({ data }) => {
        if(data.task_id) return <Button size="small" fullWidth color="primary" startIcon={<FaArrowRight size={12} />} onClick={() => { history.push({pathname: `/dashboard/pessoas-empregado-editar`, state: {modal: true}, id: data._id}) }}>Continuar</Button>
        return <Button size="small" fullWidth color="secondary" startIcon={<FaTrash size={12} />} onClick={() => { handleDraftDelete(data._id) }}>Excluir</Button>
      }
    },
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  return (
    <>
      <ModalContent hasFooter={true}>
        <DivManagementPeople darkMode={isEnabled}>
        
        {/* Lista do Cadastro de empregados

        <form noValidate>
          <div style={{fontSize:"15px !important"}}>
            <TextField required id="standard-required" label="Required" defaultValue="Hello World" />
          </div>
        </form> */}

        <Grid container>
          <Grid item xs={12}>
            {module===undefined && <Button size="small" variant="contained" startIcon={<FaPlus size={12} />} onClick={() => { history.push({pathname: '/dashboard/pessoas-empregado-novo', state: {modal: true}, type}) }}>Incluir</Button>}
            {module===undefined && <Divider style={{margin:"20px 0"}}/>}
            
            {(!loading&&drafts.length>0) && <ReactDataGrid
              idProperty="_id"
              columns={columnsDrafts}
              dataSource={drafts}
              defaultLimit={100}
              theme="default-light"
              defaultSortInfo={{ name: 'NOMEFUNC', dir: 1, type: 'text' }}
              style={{ minHeight: 150, marginBottom: 20 }}
             />}            

            {loading && <LinearProgress />}

            {error && <Paper style={{padding:50,textAlign:"center"}} fullWidth elevation={2}>
                <Typography variant="h3" style={{marginBottom:10}}>¯\_(ツ)_/¯</Typography>
                <Typography variant="h4" component="p" style={{marginBottom:20}}>Ops!</Typography>
                <Typography variant="body1" component="p">Não foi possível exibir o conteúdo agora, tente novamente mais tarde!</Typography>
            </Paper>}
            
            {(!loading&&!error) && <ReactDataGrid
              idProperty="CODIGOFUNCPESSOA"
              columns={columns}
              dataSource={dataSource}
              defaultLimit={12}
              pagination
              theme="default-light" 
              defaultSortInfo={{ name: 'NOMEFUNC', dir: 0, type: 'text' }}
              style={{ minHeight: 350 }}
              // onRenderRow={onRenderRow}
             />}
          </Grid>
        </Grid>
        
        </DivManagementPeople>
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default PeopleEmployeesList;
