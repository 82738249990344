import React, { useState } from "react";
import Button from "../../../components/Button";
import ItemForm from "../../../components/ItemForm";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import {validateField} from "../../../utils/validateField";
import { useNewRequest } from "../../../context/contextNewRequest";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";

const Title = ({ navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const { title, setTitle } = useNewRequest();
  const { next, previous } = navigation;

  return (
    <>
        {error ? (
          <ToastMessage
            variant="info"
            message="Preencha este campo antes de continuar!"
          />
        ) : null}
        <ModalContent>
          <FormContact darkMode={isEnabled}>
            <h2>DEFINA UM TÍTULO PARA SUA SOLICITAÇÃO</h2>
            <ItemForm
              placeholder="Ex. Admissão da funcionária Maria"
              name="title"
              value={title}
              onChange={e=>setTitle(e.target.value)}
            />
          </FormContact>
        </ModalContent>
        <ModalButtons>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            onClick={async () => {
              await validateField(title, 'text', next, setError);
              setError(false);
            }}
          >
            CONTINUAR
          </Button>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={previous}
          >
            VOLTAR
          </Button>
        </ModalButtons>
    </>
  );
};

export default Title;
