import React from "react";
import { useColorMode } from "../../context/contextColorMode";

import { ListDiv } from "./styles";

const List = ({children, completeLine, dashboard, overflow=1}) => {
  const {isEnabled} = useColorMode();
  return (
    <ListDiv completeLine={completeLine} darkMode={isEnabled} dashboard={dashboard} overflow={overflow}>
      {children}
    </ListDiv>
  );
};

export default List;
