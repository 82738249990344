import { Grid, TextField, Divider, Card, CardContent, Typography } from "@material-ui/core";
import React,{ useState, useEffect, useRef } from "react"

import { useSubModal } from "../../../context/contextSubModal"
import Situacao from "./submodals/situacao"

const General = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [situacao,setSituacao] = useState({
        CODIGOSIT: props.data.employee.CODIGOSIT || "",
        DESCRSIT: props.data.employee.DESCRSIT || "",
    })

    useEffect(() => {

        props.handleChange(null,situacao)

    }, [situacao])

    return <Grid container spacing={2}>

        <Grid item xs={12}>
            <TextField
                select
                fullWidth
                required
                label={props.data.tiposContrato.length===0 ? "Tipo de contrato (NÃO CADASTRADO)" : "Tipo de contrato"}
                defaultValue={props.data.employee.CODIGOTIPOCONTR}
                name="CODIGOTIPOCONTR"
                id="CODIGOTIPOCONTR"
                disabled={props.data.tiposContrato.length===0 ? true : false}
                SelectProps={{native: true}}
                onChange={ props.handleChange }
                >
                <option value=""></option>
                {props.data.tiposContrato.map(row => <option key={row.CODIGOTIPOCONTR} value={row.CODIGOTIPOCONTR}>{row.DESCRTIPOCONTR}</option>)}
            </TextField>
        </Grid>
        
        <Grid item xs={12} lg={6}>
            <TextField fullWidth required id="MATRICULAESOCIAL" label="Matrícula eSocial" defaultValue={props.data.employee.MATRICULAESOCIAL} name="MATRICULAESOCIAL" inputProps={{ maxLength: 30 }} onChange={ props.handleChange } />
        </Grid>

        <Grid item xs={12} lg={6}>
            <TextField
                select
                fullWidth
                required
                id="CODIGOCARGO"
                label={props.data.cargos.length===0 ? "Cargo (NÃO CADASTRADO)" : "Cargo"}
                defaultValue={props.data.employee.CODIGOCARGO}
                name="CODIGOCARGO"
                disabled={props.data.cargos.length===0 ? true : false}
                SelectProps={{ native: true }}
                onChange={ props.handleChange }
                >
                <option value=""></option>
                {props.data.cargos.map(row => <option key={row.CODIGOCARGO} value={row.CODIGOCARGO}>{row.DESCRCARGO}</option>)}
            </TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
            <TextField
                select
                fullWidth
                required
                label={props.data.funcoes.length===0 ? "Função (NÃO CADASTRADO)" : "Função"}
                defaultValue={props.data.employee.CODIGOFUNCAO}
                id="CODIGOFUNCAO"
                name="CODIGOFUNCAO"
                disabled={props.data.funcoes.length===0 ? true : false}
                SelectProps={{native: true}}
                onChange={ props.handleChange }
                >
                <option value=""></option>
                {props.data.funcoes.map(row => <option key={row.CODIGOFUNCAO} value={row.CODIGOFUNCAO}>{row.DESCRFUNCAO}</option>)}
            </TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
            <input
                type="text"
                name="CODIGOSIT"
                value={situacao.CODIGOSIT}
                hidden
            />
            <TextField
                style={{cursor:"pointer"}}
                fullWidth
                required
                label="Situação"
                value={situacao.DESCRSIT}
                id="DESCRSIT"
                name="DESCRSIT"
                inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                onFocus={() => {
                    setIsSubModalVisible(true);
                    setSubModalContent(<Situacao setSituacao={setSituacao} setIsSubModalVisible={setIsSubModalVisible} />)
                }}
            />
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Admissão</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Tipo de vínculo" defaultValue={props.data.employee.TIPOVINCULO} id="TIPOVINCULO" name="TIPOVINCULO" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="10">10 - Trab. urbano vinc. empr. pessoa jurídica, pela CLT, prazo indeterminado</option>
                                <option value="55">Aprendiz/Estagiário</option>
                                <option value="60">60 - Trab. urbano vinc. empr. pessoa jurídica, pela CLT, tempo determinado</option>
                                <option value="80">Pró-Labore</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Categoria do Contrato" defaultValue={props.data.employee.CATEGORIA} id="CATEGORIA" name="CATEGORIA" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="01">Empregado</option>
                                <option value="11">Pró-Labore</option>
                                <option value="13">Ministro religioso ou similar</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Data" type="date" placeholder="" required defaultValue={props.data.employee.DATAADM || ""} id="DATAADM" name="DATAADM" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } InputLabelProps={{ shrink: true }} />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Categoria do eSocial" defaultValue={props.data.employee.CATEGESOCIAL} id="CATEGESOCIAL" name="CATEGESOCIAL" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="901">901 - Estagiário</option>
                                <option value="781">781 - Ministro de confissão religiosa ou membro de vida consagrada, de congregação ou de ordem religiosa</option>
                                <option value="722">722 - Contr. Ind. – Diretor não empregado, sem FGTS</option>
                                <option value="101">101 - Empregado – Geral</option>
                                <option value="103">103 - Empregado – Aprendiz</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Primeiro Emprego" defaultValue={props.data.employee.INDICATPRIMEMP} id="INDICATPRIMEMP" name="INDICATPRIMEMP" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Tipo de Salário" defaultValue={props.data.employee.TIPOSALARIO} id="TIPOSALARIO" name="TIPOSALARIO" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="1">Mensal</option>
                                <option value="4">Diário</option>
                                <option value="5">Hora</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField select fullWidth required label="Recebe Adiantamento de Salário" defaultValue={props.data.employee.RECEBEADTO} id="RECEBEADTO" name="RECEBEADTO" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="1">Sim</option>
                                <option value="3">Não</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth label="Valor do Salário" required defaultValue={props.data.employee.VALORSAL} id="VALORSAL" name="VALORSAL" inputProps={{ maxLength: 16 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <TextField
                                select
                                fullWidth
                                required
                                label={props.data.funcoes.length===0 ? "Escala (NÃO CADASTRADO)" : "Escala"}
                                defaultValue={props.data.employee.CODIGOESCALA}
                                id="CODIGOESCALA"
                                name="CODIGOESCALA"
                                disabled={props.data.funcoes.length===0 ? true : false}
                                onChange={ props.handleChange }
                                SelectProps={{native: true}}>
                                <option value=""></option>
                                {props.data.escalas.map(row => <option key={row.CODIGOESCALA} value={row.CODIGOESCALA}>{row.DESCRESCALA}</option>)}
                            </TextField>
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Contrato de experiência</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth type="number" min="1" max="90" label="Quantidade de dias" defaultValue={props.data.employee.DIACONTREXP} name="DIACONTREXP" inputProps={{ maxLength: 5 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth type="number" min="1" max="90" label="Dias de prorrogação" defaultValue={props.data.employee.DIAPRORROGCONTREXP} name="DIAPRORROGCONTREXP" inputProps={{ maxLength: 4 }} onChange={ props.handleChange } />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Grid>
        
    </Grid>

}

export default General