import styled from "styled-components";

export const Div = styled.div`
  .headerUpload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title {
      text-transform: uppercase;
      color: var(--atlantico);
    }
    .header-close {
      background: none;
      border: 0;
      cursor: pointer;
    }
  }

  .contentUpload {
    .folder {
      margin-bottom: 1rem;
      color: var(--gray);
    }
  }
`;
