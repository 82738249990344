import { Grid, TextField, Divider, Card, CardContent, Typography } from "@material-ui/core"
import React,{ useState, useEffect } from "react"

import { useSubModal } from "../../../context/contextSubModal"
import Banco from "./submodals/banco"

const Professionals = (props) => {
    const {setIsSubModalVisible, setSubModalContent} = useSubModal()
    const [banco,setBanco] = useState({
        CODIGOBANCO: props.data.employee.CODIGOBANCO || "",
        NOMEBANCO: props.data.employee.NOMEBANCO || "",
    })

    useEffect(() => {

        props.handleChange(null,banco)

    }, [banco])

    return <Grid container spacing={2}>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Informações do PIS</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth type="date" label="Data de cadastro" defaultValue={props.data.employee.DATACADPIS} name="DATACADPIS" inputProps={{ maxLength: 10 }} onChange={ props.handleChange } InputLabelProps={{ shrink: true }} />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField fullWidth required label="Número" defaultValue={props.data.employee.PISFUNC} name="PISFUNC" inputProps={{ maxLength: 14 }} onChange={ props.handleChange } helperText="Preencher com numeração do cartão cidadão." />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
            <Card variant="outlined">
                <CardContent>

                    <Typography variant="overline" display="block" gutterBottom>Pagamento</Typography>
                    <Divider style={{marginBottom:20}} />

                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={4}>
                            <TextField select fullWidth required label="Modo de Pagamento" defaultValue={props.data.employee.MODOPGTO} name="MODOPGTO" id="MODOPGTO" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="3">Crédito em Conta</option>
                                <option value="1">Dinheiro</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField select fullWidth label="Tipo de conta" defaultValue={props.data.employee.TIPOCONTA} name="TIPOCONTA" SelectProps={{native: true}} onChange={ props.handleChange }>
                                <option value=""></option>
                                <option value="6">Cheque</option>
                                <option value="5">Conta Eletrônica</option>
                                <option value="4">Conta/Cartão Salário</option>
                                <option value="3">DOC</option>
                                <option value="2">Poupança</option>
                                <option value="1">Conta Corrente</option>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <input
                                name="CODIGOBANCO"
                                value={banco.CODIGOBANCO}
                                hidden={true}
                            />
                            <TextField
                                style={{cursor:"pointer"}}
                                fullWidth
                                label="Banco"
                                value={banco.NOMEBANCO}
                                name="NOMEBANCO"
                                inputProps={{ maxLength: 30, autoComplete: 'new-password' }}
                                onFocus={() => {
                                    setIsSubModalVisible(true);
                                    setSubModalContent(<Banco setBanco={setBanco} setIsSubModalVisible={setIsSubModalVisible} />)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Agência" defaultValue={props.data.employee.NUMEROAGENCIA} name="NUMEROAGENCIA" inputProps={{ maxLength: 6 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Número da Conta" defaultValue={props.data.employee.NUMEROCONTA} name="NUMEROCONTA" inputProps={{ maxLength: 20 }} onChange={ props.handleChange } />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <TextField fullWidth label="Dígito da Conta" defaultValue={props.data.employee.DIGITOCONTA} name="DIGITOCONTA" inputProps={{ maxLength: 2 }} onChange={ props.handleChange } />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Grid>

    </Grid>

}

export default Professionals