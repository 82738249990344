import React, { useCallback, useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaArrowRight,
} from "react-icons/fa";
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  Grid,
  Typography,
  Link,
  Paper,
} from '@material-ui/core'

import api from "../../../../services/api";

const PeriodoAquisitivo = (props) => {
  const token = localStorage.getItem("@Atlantico:token")
  const [rows,setRows] = useState(0)
  
  useEffect(() => {
    console.log(rows)
  },[rows])

  const loadData = async ({ skip, limit, sort, filterValue }) => {
    let filter = { Filter: { CODIGOEMPRESA: props.employee.CODIGOEMPRESA, CODIGOFUNCCONTR: props.employee.CODIGOFUNCCONTR, SITPERIODO:"Aberto" } }
  
    const params = {
      classe: "TnFpaDCPeriodoAquis",
      limit,
      start:skip,
      sort,
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.post(`v1/nweb${qs}`,filter,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      setRows(parseInt(response.data.records))

      return ({data: response.data.data, count: parseInt(response.data.records)})
    })
    .catch((err) => {
      console.error(err)
    })
  
  }

  const dataSource = useCallback(loadData, [])

  const customCellStyle = cellProps => {
    const {rowIndex} = cellProps

    return {
      color: (rowIndex+1)==rows ? "inherit" : "#FF0000"
    }
  }

  const columns = [
    {
        name: 'NOMEFUNC',
        style: customCellStyle,
        header: 'Empregado',
        defaultFlex: 1,
    },
    {
        name: 'DATAINICIAL',
        style: customCellStyle,
        header: 'Data inicial',
        defaultFlex: 1,
    },
    {
        name: 'DATAFINAL',
        style: customCellStyle,
        header: 'Data final',
        defaultFlex: 1,
    },
    {
        name: 'OPTN',
        style: customCellStyle,
        header: '',
        maxWidth: 80,
        render: ({ data, rowIndex }) => {
            if (rowIndex > 0) return;
            
            return <Button size="small" color="default" variant="contained" onClick={() => { 
                props.setState({...props.state, DATAINICIAL:data.DATAINICIAL}) 

                props.setIsSubModalVisible(false)
            }}><FaArrowRight /></Button>
        }
    }
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  return (
    <>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginBottom:"20px"}}>Selecione um período</Typography>

            {rows>1 && <Paper style={{padding:10,textAlign:'center',marginBottom:20,color:"#FC5757",backgroundColor:"#FEDDDE",fontWeight:600}}>ATENÇÃO PARA RISCO DE FÉRIAS EM DOBRO</Paper>}
            
            <ReactDataGrid
              // idProperty="CODIGOPAIS"
              columns={columns}
              dataSource={dataSource}
              defaultLimit={200}
              pagination 
              theme="default-light" 
              style={{ minHeight: 450 }}
              // onRenderRow={onRenderRow}
             />
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons></ModalButtons>
    </>
  );
};

export default PeriodoAquisitivo;