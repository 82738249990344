import React from "react";
import SubModalProvider from "./context/contextSubModal";
import NotificationProvider from "./context/contextNotification";
import { AuthProvider } from "./context/contextAuth";
import UploadProvider from "./context/contextUploadModal";
import SearchProvider from "./context/contextSearch";
import NewContactProvider from "./context/contextNewContact";
import NewRequestProvider from "./context/contextNewRequest";
import AdminCompanyProvider from "./context/contextAdminCompany";
import ColorModeProvider from "./context/contextColorMode";
import SurveyProvider from "./context/contextSurvey";
import { SnackbarProvider } from "notistack";
import TutorialProvider from "./context/contextTutorial";
import FilesProvider from "./context/contextFiles";
import RunrunitProvider from "./context/contextRunrunit";
import { Routes } from "./routes";

function App() {
  return (
    <SnackbarProvider maxSnack={1} className="snackbar">  
      <ColorModeProvider>
        <TutorialProvider>
          <AuthProvider>
            <RunrunitProvider>
              <UploadProvider>
                <SubModalProvider>
                  <NotificationProvider>
                    <SearchProvider>
                      <NewContactProvider>
                        <NewRequestProvider>
                          <AdminCompanyProvider>
                            <SurveyProvider>
                              <FilesProvider>
                                <Routes />
                              </FilesProvider>
                            </SurveyProvider>
                          </AdminCompanyProvider>
                        </NewRequestProvider>
                      </NewContactProvider>
                    </SearchProvider>
                  </NotificationProvider>
                </SubModalProvider>
              </UploadProvider>
            </RunrunitProvider>
          </AuthProvider>
        </TutorialProvider>
      </ColorModeProvider>
    </SnackbarProvider>
  );
}

export default App;
