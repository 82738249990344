import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import ModalContent from "../../components/ModalContent";
import List from "../../components/List";
import Loader from "../../components/Loader";
import { useAuth } from "../../context/contextAuth";
import { useSearch } from "../../context/contextSearch";
import { useColorMode } from "../../context/contextColorMode";

import api from "../../services/api";
import { useHistory } from "react-router";

const SwitchCompany = () => {
  const history = useHistory();
  const {isEnabled} = useColorMode();
  const { setSearch } = useSearch();
  const { user, setPrimary } = useAuth();
  const [loader, setLoader] = useState();
  const [companies, setCompanies] = useState([]);
  const token = localStorage.getItem("@Atlantico:token");

  useEffect(() => {
    async function getData(){
        await api.get(`v1/users/${user._id}/clients`, {
          headers: {
            Authorization: token,
          },
        })
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setCompanies(response.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalContent>
        {loader ? (
          <Loader/>
        ) : (
          <List darkMode={isEnabled}>
            <div className="switch-company">
              {companies.map((company) => {
                if(company) return (
                  <li
                    className="item" 
                    style={{cursor: "pointer"}}
                    key={company._id}
                    onClick={() => {
                      setLoader(true);
                      setSearch("");
                      setPrimary(company);
                      localStorage.setItem("@Atlantico:primary", JSON.stringify(company));
                      setLoader(false);
                      history.goBack();
                    }}
                  >
                    <div className="info">
                      <h4><strong>{company.name}</strong></h4>
                      <h5>{company.document}</h5>
                    </div>

                    <FaArrowRight />
                  </li>           
                )
              })}
            </div>
          </List>
        )}
      </ModalContent>
    </>
  );
};

export default SwitchCompany;
