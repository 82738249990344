import React, { useCallback, useState, useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import {
  FaArrowRight,
} from "react-icons/fa";
import ModalButtons from "../../../../components/ModalButtons";
import ModalContent from "../../../../components/ModalContent";
import { 
  Button,
  Grid,
  Typography,
  Link,
  Checkbox,
  LinearProgress
} from '@material-ui/core'

import api from "../../../../services/api";

const PickEmployee = (props) => {
  const token = localStorage.getItem("@Atlantico:token")
  const [selected, setSelected] = useState(props.state.employees)
  const [dataSource, setDataSource] = useState({})
  const [loading, setLoading] = useState(true)

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected)
  }, [])
  
  const loadData = async () => {

    const params = {
      reference: props.CODIGOEMPRESA,
      limit:100,
      sort:"b.NOMEFUNC",
      datadem: 0,
    }
  
    const qs = '?' + new URLSearchParams(params).toString()
  
    return api.get(`v1/questor/employees${qs}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      const data = response.data.data
      
      setLoading(false)
      setDataSource(data.map(row => ({
        CODIGOFUNCCONTR: row.CODIGOFUNCCONTR,
        CODIGOFUNCPESSOA: row.CODIGOFUNCPESSOA,
        CODIGOEMPRESA: row.CODIGOEMPRESA,
        NOMEFUNC: row.NOMEFUNC,
        EVENTOS: [],
      })))
    })
    .catch((err) => {
      setLoading(false)
      console.error(err)
    })
  
  }

  useEffect(() => {
    loadData()
  },[])

  const columns = [
    { name: 'CODIGOFUNCPESSOA', header: 'Cód.', defaultVisible: false, defaultWidth: 60, type: 'number'},
    { name: 'NOMEFUNC', header: 'Empregado', defaultFlex: 2 },
  ]

  // const onRowClick = useCallback((rowProps) => {
  //   console.log(rowProps.data.NOMEFUNC)
  // }, [])

  // const onRenderRow = useCallback((rowProps) => {

  //   rowProps.onClick = (event) => {
  //     onRowClick(rowProps)
  //   }

  // })

  const onSubmit = () => {
    props.setState({...props.state, employees: selected})
    props.setIsSubModalVisible(false)
  }

  if(loading){
    return <LinearProgress color="primary" />
  }

  return (
    <>
      <ModalContent hasFooter={true}>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginBottom:"20px"}}>Selecione os empregados</Typography>
            
            <ReactDataGrid
              idProperty="CODIGOFUNCPESSOA"
              selected={selected}
              checkboxColumn
              onSelectionChange={onSelectionChange}
              columns={columns}
              dataSource={dataSource}
              theme="default-light" 
              defaultSortInfo={{ name: 'NOMEFUNC', dir: 1, type: 'text' }}
              style={{ minHeight: 350 }}
              // onRenderRow={onRenderRow}
             />
          </Grid>
        </Grid>
        
      </ModalContent>
      <ModalButtons>
        <Button color="secondary" variant="outlined" style={{marginTop:20}} fullWidth onClick={onSubmit}>Adicionar</Button>
      </ModalButtons>
    </>
  );
};

export default PickEmployee;
