import styled from 'styled-components';

export const FormContact = styled.form`
  .twoFields{
    width: 100%;
    align-items: center;
  }

  .field{
    width: 100%;
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;

    label{
      margin-right: 1rem;
      width: 30%;
    }

    input, select{
      border: 0;
      border-bottom: 1px solid var(--text);
      background-color: var(--modal);
      color: var(--text);
      width: 65%;
      padding: 0.5rem 0;
    }
  }

  @media(min-width: 700px){
    .twoFields{
      display: flex;
    }
    .field{
      padding: 1rem;

      label{
        margin-right: 1rem;
        width: 10rem;
      }

      input, select{
        width: 100%;
      }
    }
  }
`;