import React, { useEffect, useState } from 'react';

import { FaSearch } from 'react-icons/fa';
import List from '../../../components/List';
import Loader from '../../../components/Loader';
import { useAdminCompany } from '../../../context/contextAdminCompany';

import api from '../../../services/api';
import { Link } from 'react-router-dom';
import ModalContent from '../../../components/ModalContent';
import { DivClients, Search } from './styles';
import { useColorMode } from '../../../context/contextColorMode';
import Message from '../../../components/Message';

const ListClients = () => {
    const token = localStorage.getItem('@Atlantico:token');
    const [clients, setClients] = useState([]);
    const [loader, setLoader] = useState(true);
    const {setCompany} = useAdminCompany();
    const {isEnabled} =  useColorMode();
    const [search, setSearch] = useState('');

    useEffect(() => {
        async function getData(){
           await api.get(`v1/clients?skip=0&sort=name`, {
                headers: {
                    Authorization: token,
                }
            })
            .then(response => {
                setLoader(false);
                setClients(response.data);
            })
            .catch(err => {
                setLoader(false);
                console.log(err);
            })
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <ModalContent>
            <DivClients>
                <Search darkMode={isEnabled} id="search">
                    <FaSearch size={18} />
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        <input type="text" value={search} placeholder="Filtro por nome" onChange={(e) => setSearch(e.target.value)}/>
                    </form>
                </Search>

                <hr />

                {loader ? (
                    <Loader/>
                ) : (
                    clients.filter(client => client.name.toLowerCase().includes(search.toLowerCase())).length > 0 ? (
                        <List completeLine={true}>
                            {clients.filter(client => client.name.toLowerCase().includes(search.toLowerCase())).map(client => {
                                return (
                                    <Link className="item" to={{pathname: `/admin/cliente/${client._id}`, state: {modal: true}}} onClick={() => setCompany(client)}>
                                        {client.name}
                                    </Link>
                                )
                            })}    
                        </List>
                    ) : (
                        <Message severity="warning" message="Nenhum cliente encontrado..."/>
                    )
                )} 
            </DivClients>
        </ModalContent>
    )
}

export default ListClients;
