import React from "react";
import { useColorMode } from "../../context/contextColorMode";

import { AvatarCircle } from "./styles";

const Avatar = (props) => {
  const {isEnabled} = useColorMode();
  return <AvatarCircle isEnabled={isEnabled}>{props.children}</AvatarCircle>;
};

export default Avatar;
