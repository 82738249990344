import React from 'react';
import { useSurvey } from '../../context/contextSurvey';
import Button from '../Button';

const SurveyButtons = () => {

    const {day, week, month, custom, handleButtons, setCustomDates } = useSurvey();

    return (
        <>
            <Button
                id="button1"
                bgcolor="var(--blue)"
                color={day ? "white" : "var(--blue)"}
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={day ? false : true}
                size="small"
                onClick={() => {
                    handleButtons('day');
                    setCustomDates(false);
                }} 
            >
                Dia
            </Button>
            <Button
                id="button2"
                bgcolor="var(--blue)"
                color={week ? "white" : "var(--blue)"}
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={week ? false : true}
                size="small"
                onClick={() => {
                    handleButtons('week');
                    setCustomDates(false);
                }}  
            >
                Semana
            </Button>
            <Button
                id="button3"
                bgcolor="var(--blue)"
                color={month ? "white" : "var(--blue)"}
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={month ? false : true}
                size="small" 
                onClick={() => {
                    handleButtons('month');
                    setCustomDates(false);
                }} 
            >
                Mês
            </Button>
            <Button
                id="button4"
                bgcolor="var(--blue)"
                color={custom ? "white" : "var(--blue)"}
                border="var(--blue)"
                hover="var(--blue-hover)"
                expansive={true}
                hollow={custom ? false : true}
                size="small" 
                onClick={() => {
                    
                    handleButtons('custom');
                    setCustomDates(true);
                }} 
            >
                Personalizado
            </Button>
            <Button
                id="button5"
                color="var(--gray)"
                border="var(--gray)"
                hover="var(--gray)"
                expansive={false}
                hollow={true}
                size="small" 
                onClick={() => {
                    handleButtons('week');
                    setCustomDates(false);
                }} 
            >
                Limpar
            </Button>
        </>
    )
}

export default SurveyButtons;