import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import List from '../../../components/List';
import {
  FaPen,
  FaBars,
  FaArrowLeft,
  FaSignOutAlt,
  FaArrowRight,
  FaSearch,
} from "react-icons/fa";
import { Hidden } from "@material-ui/core";
import logo from "../../../assets/images/logo.svg";
import logoDarkMode from '../../../assets/images/logo-dark-mode.svg';
import ToastMessage from "../../../components/ToastMessage";
import Message from '../../../components/Message'
import Button from "../../../components/Button";
import ContentBlock from "../../../components/ContentBlock";
import NavigatorAdmin from "../../../components/NavigatorAdmin";
import SearchInput from "../../../components/SearchInput";
import SubModal from "../../../components/SubModal";
import Loader from "../../../components/Loader";
import { useColorMode } from "../../../context/contextColorMode";
import { useSearch } from "../../../context/contextSearch";
import { useAdminCompany } from "../../../context/contextAdminCompany";
import { useAuth } from "../../../context/contextAuth";
import { useSubModal } from "../../../context/contextSubModal";
import { ConfirmLogout } from "../../../modals/ConfirmLogout";

import { Redirect } from "react-router-dom";

import { DashboardPage, PageHeader } from "./styles";

import api from "../../../services/api";
import { useTutorial } from "../../../context/contextTutorial";

const Admin = () => {
  const { user, signed, setUser } = useAuth();
  const token = localStorage.getItem("@Atlantico:token");
  const {isEnabled} = useColorMode();
  

  if (!signed) {
    return <Redirect to="/auth" />;
  }

  if (user.is_admin === false){
    return <Redirect to="/dashboard" />;
  }

  async function TokenIsValid() {
    const token2 = localStorage.getItem("@Atlantico:token");
    return await api.post("oauth/validateToken", {
        token: token2,
      })
      .then((response) => {
        if (response.data.valid) {
          return;
        } else {
          return (window.location.href = "/auth");
        }
      });
  }

  function storagedUser() {
    const storagedUser = localStorage.getItem("@Atlantico:user");
    setUser(JSON.parse(storagedUser));
  }

  const {
    setSubModalContent,
    setIsSubModalVisible,
    isSubModalVisible,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSubModal();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openedDrawerMobile, setOpenedDrawerMobile] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {searchLoader, result, search, setSearch, setResult} = useSearch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {setCompany} = useAdminCompany();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [taskId, setTaskId] = useState('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [logTaskId, setLogTaskId] = useState('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [taskActivityLogLoader, setTaskActivityLogLoader] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [taskActivityLogNone, setTaskActivityLogNone] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [taskActivityLogs, setTaskActivityLogs] = useState(null);
  
  function handleDrawerToggleMobile() {
    setOpenedDrawerMobile(!openedDrawerMobile);
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {setIsTutorialActive, isTutorialActive} = useTutorial();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if(isTutorialActive){
      setIsTutorialActive(false);
    }
    const storage = localStorage.getItem('@Atlantico:tutorial');
    if (storage){
      localStorage.setItem('@Atlantico:tutorial', false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleTasksActivityLogs(){
    setTaskActivityLogs()
    setTaskActivityLogLoader(true)
    setTaskActivityLogNone(false)
    return await api.get(`v1/tasks/activity/logs?id=${logTaskId}`,
    {
      headers: {
        Authorization: token,
      },
    })
    .then(response => {
      if(response.data.length){
        setTaskActivityLogs(response.data)
      }else{
        setTaskActivityLogNone(true)
      }
      setTaskActivityLogLoader(false)
    }).catch(err => {
      setTaskActivityLogLoader(false)
      console.log(err);
    })
  }

  function phoneFormatting(number) {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    var str = number.replace(/[^0-9]/g, "").slice(0, 11);
  
    const result = str.replace(regex, "($1)$2-$3");
  
    return result;
  }

  async function handleNotifyTask(){
    return await api.post(`oauth/tasks`, {
      event: "task:deliver",
      data: {
        task: {
          id: taskId,
        }
      }
    }).then(response => {
      setTaskId('');
      setSuccess(true);
      setSuccess(false);
    }).catch(err => {
      setTaskId('');
      setError(true);
      setError(false);
      console.log(err);
    })
  }

  if (!signed) {
    return <Redirect to="/auth" />;
  } else {
    setInterval(TokenIsValid, 600000);
    return (
      <DashboardPage className="container" darkMode={isEnabled}>
        {success && <ToastMessage variant="success" message="Notificação enviada com sucesso!" />}
        {error && <ToastMessage variant="error" message="Erro ao notificar! Verifique o número da tarefa e tente novamente."/>}
        {isSubModalVisible && <SubModal onClose={() => setIsSubModalVisible(false)} />}

        <nav>
            <Hidden lgUp implementation="js">
              <NavigatorAdmin
                PaperProps={{ style: { width: "100%", height: "80%" } }}
                variant="temporary"
                anchor="bottom"
                open={openedDrawerMobile}
                onClose={handleDrawerToggleMobile}
              />
            </Hidden>
            <NavigatorAdmin className="sidebar-desktop" />
        </nav>
          <PageHeader darkMode={isEnabled}>
            {openedDrawerMobile ? <FaArrowLeft onClick={handleDrawerToggleMobile} size={30} /> : <FaBars onClick={handleDrawerToggleMobile} size={30} />}
            <img src={isEnabled ? logoDarkMode : logo} width="120" alt="Atlântico Digital" />
          </PageHeader>

          <div className="subheader-mobile">
            <h3>
              <strong style={{color: 'var(--text)'}}>Olá, {user.nickname}!</strong>{" "}
            </h3>

            <div className="icons">
              <Link id="icon-edit" to={{ pathname: "/admin/perfil", state: {modal: true} }} onClick={() => storagedUser()}>
                <FaPen />
              </Link>

              <a id="icon-logout" href="#!" onClick={() => {
                setIsSubModalVisible(true);
                setSubModalContent(<ConfirmLogout />)
              }}>
                <FaSignOutAlt />
              </a>
            </div>
          </div>

          <div className="search">
            <form>
              <SearchInput
                id="admin-search"
                placeholder="Digite o que procura aqui"
              />
            </form>
          </div>

            {search !== "" && (
              searchLoader ? (
                <div className="div-search">
                  <div className="resultSet" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader/>
                  </div>
                </div>
              ) : (
                result.length > 0 ? (
                <div className="div-search">
                  <div className="resultSet">
                    <ul>
                      {result.map((res) => {
                        return (
                          <li key={res._id}>
                            <Link to={{pathname: `/admin/cliente/${res._id}`, state: {modal: true}}} onClick={() => {
                              setCompany(res)
                              setSearch('');
                              setResult([]);
                            }}>
                              {res.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="div-search">
                  <div className="resultSet">
                    <p>Não encontramos resultados...</p>
                  </div>
              </div>
              )
            )
          )}

          <div id="block1" className="blocks">
            <ContentBlock title="NOTIFICAR CLIENTE DE TAREFA DÍSPONIVEL">
              <div className="notifyClient">
                <div className="input">
                  <input type="number" min={0} placeholder="Informe o ID da tarefa" onChange={e => {setTaskId(e.target.value)}}/>
                  <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" size="small" hollow={false} expansive={false} onClick={() => handleNotifyTask()}>
                    <FaArrowRight style={{marginBottom:0,marginRight:0}} />
                  </Button>
                </div>
              </div>
            </ContentBlock>
          </div>

          <div id="block1" className="blocks" style={{paddingBottom:'4rem'}}>
            <ContentBlock title="LOG DE ATIVIDADE DAS TAREFAS ENTREGUES">
              <div className="notifyClient">
                <div className="input">
                  <input type="number" min={0} placeholder="Procure pelo ID da tarefa" onChange={e => {setLogTaskId(e.target.value)}}/>
                  <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" size="small" hollow={false} expansive={true} onClick={() => handleTasksActivityLogs()}>
                  <FaSearch style={{marginBottom:0,marginRight:0}} />
                  </Button>
                </div>
              </div>

              {taskActivityLogLoader && <Loader />}

              {taskActivityLogs &&
              <List completeLine overflow={0}>
                    <table className='linkedClientsTable'>
                        <colgroup>
                            <col style={{width: "10%"}}/>
                            <col style={{width: "50%"}}/>
                            <col style={{width: "30%"}}/>
                            <col style={{width: "10%"}}/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th><small># TAREFA</small></th>
                            <th><small>EVENTO</small></th>
                            <th><small>DETALHE</small></th>
                            <th><small>DATA</small></th>
                        </tr>
                        </thead>
                        <tbody>
                        {taskActivityLogs.map((log, index) => {

                          const date = new Date(log.registered_at)
                          let detail, newDescription;

                          switch (log.description) {
                            case "E-mail enviado!":
                            case "E-mail recebido!":
                            case "E-mail aberto!":
                            case "Clicou em algum link no E-mail!":
                            case "E-mail não foi entregue (Soft Bounce)!":
                            case "E-mail não foi entregue (Hard Bounce)!":
                            case "E-mail solicitou não receber mais notificações!":
                            case "E-mail considerado SPAM ou Lixo Eletrônico!":
                              detail = log.response.data.email.recipient.email
                              break;

                            case "Anexos identificados, notificação liberada.":
                              detail = `${log.response.attachments_count} documentos`
                              break;

                            case "Contatos encontrados para realizar a notificação.":
                              detail = log.response.map((person, index) => {

                                return <div key={`person${index}`}>{person.nickname}</div>

                              })
                              break;

                            case "Solicitação para notificação via E-mail concluída":
                              detail = log.response.recipients.map((recipient, index) => {

                                return <div key={`recipient${index}`}>{recipient.email}</div>

                              })
                              break;
                          
                            default:
                              detail = "-"
                              break;
                          }

                          if(log.description.includes("Cliente")&&log.description.includes("localizado para notificação!")){
                            detail = `${log.response.reference} - ${log.response.name}`
                          }

                          if(log.description.includes("Tipo de tarefa")&&log.description.includes("encontrado para notificação")){
                            detail = `${log.response.name}`
                          }

                          if(log.description.includes("Solicitação para notificação via SMS concluída")){
                            const phone = log.description.replace(/\D/g, "")
                            newDescription = "Solicitação para notificação via SMS concluída"

                            detail = `${phoneFormatting(phone.slice(2))}`
                          }

                          return (
                          <tr key={index}>
                              <td><b>{log.id}</b></td>
                              <td>
                                {newDescription ? newDescription : log.description}
                              </td>
                              <td style={{fontWeight:'bold'}}>{detail}</td>
                              <td>{`${('0'+date.getDate()).slice(-2)}/${('0'+(date.getMonth()+1)).slice(-2)}/${date.getFullYear()} às ${('0'+date.getHours()).slice(-2)}:${('0'+date.getMinutes()).slice(-2)}:${('0'+date.getSeconds()).slice(-2)}`}</td>
                          </tr>
                          )

                        })}
                        </tbody>
                    </table>
              </List>
              }
              { taskActivityLogNone &&
              <Message
              severity="error"
              style={{justifyContent:'center'}}
              message={<div style={{textAlign:'center'}}><h2>¯\_(ツ)_/¯</h2><b>Nenhum registro encontrado para esta tarefa.</b></div>}
              /> }
            </ContentBlock>
          </div>
        </DashboardPage>
    );
  }
};

export default Admin;