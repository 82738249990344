import React, { useState } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import List from "../../../components/List";
import {validateField} from "../../../utils/validateField";
import { useNewContact } from "../../../context/contextNewContact";
import { useColorMode } from "../../../context/contextColorMode";
import Tooltip from "@material-ui/core/Tooltip";

import { FormContact } from "../styles";
import { useHistory } from "react-router";

const Sector = ({ navigation }) => {
  const {sector} = useNewContact();
  const {isEnabled} = useColorMode();
  const [error, setError] = useState(false);
  const history = useHistory()
  
  const setores = [
    {
      sec: "Administração",
      value: "ADMIN",
      tooltip:
        "O usuário terá acesso as informações/documentos de todas as áreas.",
    },
    {
      sec: "Recursos Humanos",
      value: "HR",
      tooltip:
        "O usuário terá acesso as informações/documentos somente da área de Departamento Pessoal (Folha de Pagamento, Férias, Admissão, Rescisão, etc).",
    },
    {
      sec: "Financeiro",
      value: "FINANC",
      tooltip:
        "O usuário terá acesso as informações/documentos somente da área Contábil (Balancete, Balanço e DRE) e o que corresponde ao financeiro junto ao Atlântico.",
    },
    {
      sec: "Fiscal e Tributário",
      value: "FTAX",
      tooltip:
        "O usuário terá acesso as informações/documentos somente da área Fiscal (Guias de Impostos, Taxas, etc).",
    },
  ];

  const { next } = navigation;

  function handleInputChange(e, item){
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (value === true) {
      value = target.checked;
      if (!sector.includes(item)) {
        sector.push(item);
      }
    } else {
      value = target.checked;
      if (sector.includes(item)) {
        sector.splice(sector.indexOf(item), 1);
      }
    }
  }
  
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1.2rem",
          color: "white",
        },
      },
    },
  });

  return (
    <>
        {error ? (
          <ToastMessage
            variant="info"
            message="Preencha este campo antes de continuar!"
          />
        ) : null}
        <ModalContent displayFlex={true} hasFooter={true}>
          <FormContact style={{ padding: "0" }} darkMode={isEnabled}>
            <h2>SELECIONE AS ÁREAS DE ATUAÇÃO</h2>
            <List darkMode={isEnabled}>
              {setores.map((set) => {
                return (
                    <li key={set.value} className="checkbox">
                        <input
                          type="checkbox"
                          id={set.sec}
                          value={set.value}
                          name={set.sec}
                          onClick={(e) => {
                            handleInputChange(e, set.value)
                          }}
                        />
                        <MuiThemeProvider theme={theme}>
                          <Tooltip title={set.tooltip} placement="right" arrow>
                            <label htmlFor={set.sec}>
                              <h4>{set.sec}</h4>
                            </label>
                          </Tooltip>
                        </MuiThemeProvider>
                    </li>
                );
              })}
            </List>
          </FormContact>
        </ModalContent>
        <ModalButtons>
          <Button
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
            onClick={async () => {
              await validateField(sector.length, 'number', next, setError);
              setError(false);
            }}
          >
            CONTINUAR
          </Button>
          <hr />
          <Button color="var(--gray)" border="var(--gray)" expansive={true} hollow={true} size="small" onClick={() => history.goBack()}>
            VOLTAR
          </Button>
        </ModalButtons>
    </>
  );
};

export default Sector;
