import React, { useState } from "react";
import Button from "../../../components/Button";
import ModalContent from "../../../components/ModalContent";
import ModalButtons from "../../../components/ModalButtons";
import ToastMessage from "../../../components/ToastMessage";
import { useAuth } from "../../../context/contextAuth";
import { useNewContact } from "../../../context/contextNewContact";
import { useColorMode } from "../../../context/contextColorMode";

import { FormContact } from "../styles";

import api from "../../../services/api";

const Terms = ({  navigation }) => {
  const [error, setError] = useState(false);
  const {isEnabled} = useColorMode();
  const { sector, cpf, name, email, phone, cep, number, companies, date, setCompanies } = useNewContact();
  const { user } = useAuth();
  const token = localStorage.getItem("@Atlantico:token");
  const [checked, setChecked] = useState(false);

  function validateField() {
    if (checked !== false) {
      return createNewContact();
    } else {
      setError(true);
    }
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const { go, previous } = navigation;

  const data = {
    name: name,
    birthday: date,
    document: cpf,
    email: email,
    phone: phone,
    zipcode: cep,
    address_number: number,
    profile: sector,
    clients: companies,
  }

  async function createNewContact() {
    return await api.post(`v1/users/${user._id}/request`, data,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        go("submit");
      });
  }

  return (
    <>
      {error ? (
        <ToastMessage
          variant="info"
          message="Você deve concordar com os termos antes de prosseguir!"
        />
      ) : null}
      <ModalContent displayFlex={true} hasFooter={true}>
        <FormContact style={{ padding: "4rem 0" }} darkMode={isEnabled}>
          <h2>POR ÚLTIMO, CONCORDE COM NOSSOS TERMOS DE USO</h2>
          <div>
            <p style={{color: 'var(--text)'}}>
              Submetendo este formulário, você autoriza esta pessoa para manter
              o relacionamento com os canais de comunicação disponibilizados
              pelo Atlântico. O relacionamento se dará tanto para obter
              informações, tanto para fornecer como base para os serviços
              realizados pelo Atlântico, observando suas respectivas áreas de
              atuação acima especificadas.
            </p>
          </div>
          <div onClick={() => setChecked(!checked)} className="checkbox-terms">
            <input
              type="checkbox"
              onChange={handleChange}
              name="terms"
              id="terms"
            />
            <label htmlFor="terms" style={{ cursor: "pointer", color: 'var(--text)' }}>
              Declaro que li e concordo com os termos de uso do Atlântico.
            </label>
          </div>
        </FormContact>
      </ModalContent>
      <ModalButtons>
        <Button
          bgcolor="var(--blue)"
          color="#fff"
          border="var(--blue)"
          hover="var(--blue-hover)"
          expansive={true}
          hollow={false}
          size="small"
          onClick={async () => {
            await validateField();
            setError(false);
          }}
        >
          CRIAR NOVO CONTATO
        </Button>
        <hr />
        <Button
          color="var(--gray)"
          border="var(--gray)"
          expansive={true}
          hollow={true}
          size="small"
          onClick={() => {
            setCompanies([]);
            previous();
          }}
        >
          VOLTAR
        </Button>
      </ModalButtons>
    </>
  );
};

export default Terms;
