import React, { useEffect, useState } from "react";
import { FaPlusCircle, FaBan, FaUnlockAlt } from "react-icons/fa";
import Button from "../../components/Button";
import ModalButtons from "../../components/ModalButtons";
import ModalContent from "../../components/ModalContent";
import {convertName} from '../../utils/convertName';
import List from "../../components/List";
import { useColorMode } from "../../context/contextColorMode";
import { useAuth } from "../../context/contextAuth";

import api from "../../services/api";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

const Contacts = () => {
  const { primary } = useAuth();
  const {isEnabled} = useColorMode();
  const [contacts, setContacts] = useState([]);
  const [loader, setLoader] = useState();
  const [change, setChange] = useState(false);

  const token = localStorage.getItem("@Atlantico:token");

  useEffect(() => {
    async function getData(){
     await api.get(`v1/clients/${primary._id}/contacts`, {
          headers: {
            Authorization: token,
          },
        })
        .then(setLoader(true))
        .then((response) => {
          setLoader(false);
          setContacts(response.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleBlockUser(userId) {
    return await api.put(`v1/users/${userId}`,
        { status: false },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setChange(true);
        setChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleUnlockUser(userId) {
    return await api.put(`v1/users/${userId}`,
        { status: true },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setChange(true);
        setChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <ModalContent hasFooter={true}>
          {loader ? <Loader/> : (
            <List darkMode={isEnabled}>
              {contacts.map((contact) => {
                return (
                  <li key={contact._id} className="item">
                    <div className="info">
                      <h4><strong>{contact.name}</strong></h4>
                      {contact.profile.map((prof) => (
                        <p key={prof} style={{color: 'var(--text)'}}>{convertName(prof)}</p>
                      ))}
                    </div>
                    {contact.status === true ? <FaBan style={{cursor: "pointer"}} color="var(--red)" onClick={() => handleBlockUser(contact._id)}/> : <FaUnlockAlt style={{cursor: "pointer"}} color="var(--green)" onClick={() => handleUnlockUser(contact._id)} />}
                  </li>
                );
              })}
            </List>
          )}
          
      </ModalContent>
      <ModalButtons>
        <Link style={{textDecoration: 'none'}} to={{pathname: '/dashboard/novo-contato', state: {modal: true}}}>
          <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small">
            <FaPlusCircle />
            Novo Contato
          </Button>
        </Link>
      </ModalButtons>
    </>
  );
};

export default Contacts;
