import styled from 'styled-components';

export const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h2{
        color: var(--text);
    }

    .values{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 2rem 0;
        button{
            padding: 0.3rem;
            border-radius: 4px;
            cursor: pointer;
            background-color: white;
            border: 3px solid var(--blue);
            color: var(--blue);
            transition: background-color 0.2s, color 0.2s;
            &:hover{
                color: white;
                background-color: var(--blue-hover);
            }
        }
    }

    textarea{
        width: 100%;
        padding: 1rem;
        border-radius: 10px;
        resize: none;
        margin: 2rem 0;
        border: 2px solid var(--blue);
    }

    @media(min-width: 1024px){
        .values{
            width: 60%;
            button{
                padding: 1rem;
            }
        }
        textarea{
            width: 60%;
        }
    }
`;