import React from 'react';
import { Buttons } from './styles';

export const ButtonsMark = ({onMarkOn, onMarkOff}) => {
    return (
        <Buttons>
            <button onClick={onMarkOn}>Marcar todos</button>
            <button onClick={onMarkOff}>Desmarcar todos</button>
        </Buttons>
    )
}