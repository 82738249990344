import React from "react";
import { useStep } from "react-hooks-helper";

import Cep from "./Fields/Cep";
import Name from "./Fields/Name";
import Cpf from "./Fields/Cpf";
import Companies from "./Fields/Companies";
import Email from "./Fields/Email";
import Number from "./Fields/Number";
import Phone from "./Fields/Phone";
import Sector from "./Fields/Sector";
import Terms from "./Fields/Terms";
import Submit from "./Fields/Submit";
import Date from "./Fields/Date";

const steps = [
  { id: "sector" },
  { id: "cpf" },
  { id: "name" },
  { id: "date" },
  { id: "email" },
  { id: "phone" },
  { id: "cep" },
  { id: "number" },
  { id: "companies" },
  { id: "terms" },
  { id: "submit" },
];

const AddContact = () => {
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { navigation };

  switch (id) {
    case "sector":
      return <Sector {...props} />;
    case "cpf":
      return <Cpf {...props} />;
    case "name":
      return <Name {...props} />;
    case "date":
      return <Date {...props} />;
    case "email":
      return <Email {...props} />;
    case "phone":
      return <Phone {...props} />;
    case "cep":
      return <Cep {...props} />;
    case "number":
      return <Number {...props} />;
    case "companies":
      return <Companies {...props} />;
    case "terms":
      return <Terms {...props} />;
    case "submit":
      return <Submit {...props} />;
    default:
      return null;
  }
};

export default AddContact;
