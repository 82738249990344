import React, { useState, useEffect } from 'react';
import { FaDownload, FaPlayCircle, FaPlusCircle, FaSearch, FaUser } from 'react-icons/fa';
import List from '../../../components/List';
import Loader from '../../../components/Loader';
import { CSVLink } from "react-csv";
import ModalContent from '../../../components/ModalContent';
import ModalButtons from '../../../components/ModalButtons';
import { useColorMode } from '../../../context/contextColorMode';
import api from  '../../../services/api';
import {DivRrit, Search} from './styles';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import Message from '../../../components/Message';

const Runrunit = () => {
    const {isEnabled} = useColorMode();
    const token = localStorage.getItem('@Atlantico:token');
    const [tasks, setTasks] = useState([]);
    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState(true);
    const [csvLoader, setCsvLoader] = useState(true);
    const [clientsTasks, setClientsTasks] = useState([])
    const headers = [
        { label: "Referência", key: "reference" },
        { label: "Nome", key: "name" },
        { label: "Data", key: "date" },
        { label: "Responsável", key: "assignment" },
        { label: "Tarefa", key: "task.name" },
        { label: "ID da Tarefa", key: "task.runrunit_id" },
        { label: "ID do Time", key: "task.runrunit_team_id" },
    ];

    function dateTime(date, month = null){
        const today = new Date();

        if(today.getDate() > parseInt(date)&&month==null){
            month = today.getMonth()+1
        }

        month = month ? month : today.getMonth()

        var desiredDate = new Date(today.getFullYear(), month, date, 18)

        if(desiredDate.getDay() === 6){
            desiredDate.setDate(desiredDate.getDate()-1)
        }

        if(desiredDate.getDay() === 0){
            desiredDate.setDate(desiredDate.getDate()-2)
        }

        return `${('0'+desiredDate.getDate()).slice(-2)}/${('0'+(desiredDate.getMonth()+1)).slice(-2)}/${desiredDate.getFullYear()}`;
    }

    useEffect(() => {
        api.get(`v1/tasks`, {
            headers: {
                Authorization: token,
            }
        })
        .then(response => {
            setTasks(response.data);
            setLoader(false);
        })

        async function getData(){
            await api.get(`v1/linkedclients`, {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                setClientsTasks(response.data);
                setCsvLoader(false);
            })
            .catch(err => {
                console.log(err);
            })
        }

        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        <ModalContent>
            <DivRrit>
                <Search darkMode={isEnabled} id="search">
                    <FaSearch size={18} />
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        <input
                            type="text"
                            value={search}
                            placeholder="Filtro por nome"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </form>
                </Search>

                <hr />

                {loader ? <Loader /> : (
                    tasks.filter(task => task.name.toLowerCase().includes(search.toLowerCase())).length > 0 ? (
                        <List completeLine>
                            {tasks.filter(task => task.name.toLowerCase().includes(search.toLowerCase())).map(task => (
                                <>
                                <div className="task">
                                    <div className='task-div'>
                                        <div >
                                            <Link to={{pathname: "/admin/atualizar-tarefa", state: {modal: true, props: task}}}> 
                                                <h4 className='title'><strong>{task.name}</strong></h4>
                                            </Link>
                                            <h5 className='infos'>{task.desired_date && dateTime(task.desired_date)} {task.competence && `• ${task.competence}`} {task.assignment && `• ${task.assignment}`}</h5>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Link to={{pathname: "/admin/clientes-tarefas", state: {modal: true, props: task}}} > 
                                                <FaPlayCircle size={18} color='var(--blue)' style={{cursor: 'pointer'}}/>
                                            </Link>
                                            <Link to={{pathname: "/admin/clientes-vinculados", state: {modal: true, props: task}}} > 
                                                <FaUser size={18} color='var(--blue)' style={{marginLeft: '16px', cursor: 'pointer'}}/>
                                            </Link>
                                        </div>
                                    </div>

                                    <hr />
                                </div>

                                </>
                            ))}
                        </List>
                    ) : (
                        <Message severity="warning" message="Nenhum tipo de tarefa encontrado..."/>
                    )
                )}

            </DivRrit>
        </ModalContent>
        <ModalButtons>
            {/* <Link style={{textDecoration: 'none'}} to={{pathname: "/admin/criar-tarefa", state: {modal: true}}} >
                <Button bgcolor="var(--blue)"  color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small">
                    <FaPlusCircle />
                    Novo Tipo de Tarefa
                </Button>
            </Link> */}
            {csvLoader ? <Loader /> : (
                <CSVLink data={clientsTasks} headers={headers} filename={`clientes_x_tarefas_geral.csv`} style={{textDecoration:'none'}}>
                    <Button bgcolor="var(--blue)"  color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small">
                        <FaDownload style={{cursor: 'pointer', marginRight: '2rem'}} />
                        Download CSV Geral
                    </Button>
                </CSVLink>
            )}
        </ModalButtons>
        </>
    )
}

export default Runrunit;