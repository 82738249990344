import React, { useEffect, useState } from 'react';
import spinner from '../../assets/images/spinner.svg';
import Button from '../../components/Button';
import ModalButtons from '../../components/ModalButtons';
import ModalContent from '../../components/ModalContent';
import ToastMessage from '../../components/ToastMessage';
import { useAuth } from '../../context/contextAuth';
import { useColorMode } from '../../context/contextColorMode';

import {Div} from './styles';

import api from '../../services/api';
import { useHistory } from 'react-router';

export const Nps = ({survey}) => {
    const {user, primary} = useAuth();
    const {isEnabled} = useColorMode();
    const token = localStorage.getItem('@Atlantico:token');
    const [loader, setLoader] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(survey.questions[0])
    const [index, setIndex] = useState(0);
    const [answers, setAnswers] = useState([])
    const [comment, setComment] = useState('');
    const [value, setValue] = useState(null);
    const [success, setSuccess] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [error, setError] = useState(false);
    const values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const history = useHistory()

    useEffect(() => {
        setError(false);
    }, [error])

    const handleNextQuestion = () => {
        if (value === null){
            setError(true);
        } else {
            setAnswers([...answers, {question: activeQuestion, value, comment}])
            setIndex(index + 1);
            setActiveQuestion(survey.questions[index + 1]);
            setComment('');
            setValue(null);
            setQuestionNumber(questionNumber+1);
        } 
    }

    useEffect(() => {
        async function postData(){
            await api.post('v1/survey/nps/avaliation', {
                user: user._id,
                client: primary._id,
                survey: survey._id,
                answers
            }, {
                headers: {
                    Authorization: token,
                }
            })
            .then(setLoader(true))
            .then(response => {
                setLoader(false);
                setSuccess(true);
                history.goBack()
            })
            .then(setSuccess(false))
            .catch(err => setLoader(false))
        }
        if(answers.length === survey.questions.length){
            postData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers])

    const handleSubmitAnswer = () => {
        if(value === null){
            setError(true);
        } else {
            setAnswers([...answers, {question: activeQuestion, value, comment}]);
        }
    }

    return (
        <>
            {success && <ToastMessage variant="success" message="Obrigado por responder à pesquisa!" />}
            {error && <ToastMessage variant="info" message="Preencha uma nota antes de continuar!" />}
            <ModalContent>
                <Div darkMode={isEnabled}>
                    <h2><strong>{user.nickname}</strong>, em uma escala de 0 a 10: </h2> 
                    <h2><strong>{activeQuestion}</strong></h2>
                    <div className="values">
                        {values.map(val => (
                            <button onClick={() => setValue(val)} style={{backgroundColor: value === val && 'var(--blue)', color: value === val && '#fff'}}>
                                <strong>{val}</strong>
                            </button>
                        ))}
                    </div>
                    <h2>O que te motivou nesta nota?</h2>
                    <textarea rows="5" onChange={(e) => setComment(e.target.value)} value={comment}/>
                </Div>
            </ModalContent>
            <ModalButtons>
                {survey.questions[index + 1] ? (
                    <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={handleNextQuestion}>
                        PRÓXIMA
                    </Button>
                ) : (
                    <Button bgcolor="var(--blue)" color="#fff" border="var(--blue)" hover="var(--blue-hover)" expansive={true} hollow={false} size="small" onClick={handleSubmitAnswer}>
                        {loader ? <img src={spinner} alt="Logar"/> : 'SALVAR RESPOSTA'}
                    </Button>
                )}
                
            </ModalButtons>
        </>
        
    )
}